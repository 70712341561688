import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { FormControl, InputLabel, Select, MenuItem, Box } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";

import moment from "moment-timezone";
moment.tz.setDefault('Asia/Tokyo');

const useStyles = makeStyles((theme) => ({

    formControl: {
    },
    item: {
        marginRight: theme.spacing(1)
    },
    box: {
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "baseline",
        marginTop: theme.spacing(2)
    }
}));

const HOURS_LIST = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
const MINUTES_LIST = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

//時間設定のためのコントローラー
const TimePicker: React.FunctionComponent<{
    baseDate?: Date;
    value?: Date;
    useOverTime: boolean;
    label: string;
    required?: boolean;
    after?: Date
    onChange: (newDate: Date) => void;
}> = ({ baseDate, value, useOverTime, label, required, after, onChange }) => {

    const classes = useStyles();


    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinites] = useState<number>(0);

    const MaxHour: number = useOverTime ? 30 : 24;

    useEffect(() => {
        if (!!baseDate && !!value) {
            var b = moment(baseDate).endOf("days");
            var v = moment(value);

            //最小制限
            if (!!after && v.isBefore(after)) {
                v = moment(after).add(1, "minute");
                onChange(v.toDate());
                return;
            }

            if (v.isAfter(b)) {
                //次の日
                if (hours !== v.get("hour") + 24)
                    setHours(v.get("hour") + 24);
                if (minutes !== v.get("minute"))
                    setMinites(v.get("minute"));
            } else {
                //同じ日
                if (hours !== v.get("hour"))
                    setHours(v.get("hour"));
                if (minutes !== v.get("minute"))
                    setMinites(v.get("minute"));
            }
        } else {
            setHours(0);
            setMinites(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseDate, value, after]);

    const changeValue = (h: number, m: number) => {
        if (!!baseDate && !!value) {
            var b = moment(baseDate).endOf("days");
            if (h > 23) {
                //翌日処理
                b.add(1, "day");
                b.set("hour", h - 24);
                b.set("minute", m);
            } else {
                //当日処理
                b.set("hour", h);
                b.set("minute", m);
            }
            onChange(b.toDate());
        }
    }

    const checkHourDisable = (h: number): boolean => {
        if (!after)
            return false;
        var ah = moment(after).get("hour");
        if (ah > h) {
            return true;
        }
        if (ah === h) {
            var am = moment(after).get("minute");
            return am === 59 ? true : false;
        }
        return false;
    }

    const checkMinuteDisable = (m: number): boolean => {
        if (!after)
            return false;
        var ah = moment(after).get("hour");
        if (ah === hours) {
            var am = moment(after).get("minute");
            return am < m ? false : true;
        }
        return false;
    }


    return (
        <React.Fragment>


            <Box className={classes.box}>
                <FormControl className={classes.formControl}>
                    <InputLabel style={{ width: "400px" }} required={required}>{label}</InputLabel>
                    <Select
                        className={classes.item}
                        value={hours}
                        onChange={(e) => { var h = parseInt("" + e.target.value, 10); setHours(h); changeValue(h, minutes) }}
                    >
                        {
                            HOURS_LIST.map((v) => {

                                return (MaxHour <= v ? null :
                                    v <= 23 ? <MenuItem disabled={checkHourDisable(v)} key={"hk" + v} value={v}>{v}</MenuItem>
                                        : <MenuItem key={"hk" + v} value={v}>{"" + v + "(午前 " + (v - 24) + ")"}</MenuItem>)
                            })
                        }
                    </Select>

                </FormControl>

                <span className={classes.item}>時</span>

                <FormControl className={classes.formControl}>
                    <InputLabel>{" "}</InputLabel>
                    <Select
                        className={classes.item}
                        value={minutes}
                        onChange={(e) => { var m = parseInt("" + e.target.value, 10); setMinites(m); changeValue(hours, m) }}
                    >
                        {
                            MINUTES_LIST.map((v) => {
                                return (<MenuItem disabled={checkMinuteDisable(v)} key={"mk" + v} value={v}>{('00' + v).slice(-2)}</MenuItem>)
                            })
                        }
                    </Select>

                </FormControl>

                <span className={classes.item}>分</span>
            </Box>
        </React.Fragment>
    )

}

export default TimePicker;