import { createAsyncThunk } from "@reduxjs/toolkit";
import {  MATenant,  SysadV2Client, MAOrder, StartEndDate, PostOrderInfo, PutTenantLicenseInfo} from "./NetworkClient";
import { SERVER_URL } from "../Constants";
import { getAuthedAxisInstance } from "./AxisUtil";
var encoding = require('encoding-japanese');

//テナント情報取得
export const sysadGetTenantSv = createAsyncThunk<boolean, {tenantId:string, callback: (ok: boolean, tenant: MATenant | null) => void }>(
    "sysad/getTenantSv",
    async (arg, { dispatch }) => {
        try {
            var tenant = await (new SysadV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getTenant(arg.tenantId)
            arg.callback(true, tenant);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, null);
            throw err;
        }
    }
);


//テナント一覧取得
export const sysadTenantsSv = createAsyncThunk<boolean, { callback: (ok: boolean, user: MATenant[] | null) => void }>(
    "sysad/tenantsSv",
    async (arg, { dispatch }) => {
        try {
            var tenants = await (new SysadV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).tenants();
            arg.callback(true, tenants);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, null);
            throw err;
        }
    }
);

//請求書送付先設定
export const sysadPutTenantSv = createAsyncThunk<boolean, {tenantId:string, data:MATenant, callback: (ok: boolean) => void }>(
    "sysad/putTenant",
    async (arg, { dispatch }) => {
        try {
            await (new SysadV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).putTenant(arg.tenantId,arg.data);
            arg.callback(true);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false);
            throw err;
        }
    }
);



//会社登録用CSVファイル生成
export const getRegistTenantCsvSv = createAsyncThunk<boolean, {tenantId:string, callback: (ok: boolean, csv: Blob | undefined) => void }>(
    "sysad/getRegistTenantCsv",
    async (arg, { dispatch }) => {
        try {
            var csvstr = await (new SysadV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getRegistTenantCsv(arg.tenantId);
            arg.callback(true, toShiftJIS(csvstr));
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);

//注文登録用CSVファイル生成
export const getRegistOrderCsvSv = createAsyncThunk<boolean, {orderId:string, callback: (ok: boolean, csv: Blob | undefined) => void }>(
    "sysad/getRegistOrderCsv",
    async (arg, { dispatch }) => {
        try {
            var csvstr = await (new SysadV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getRegistOrderCsv(arg.orderId);
            arg.callback(true, toShiftJIS(csvstr));
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);

//文字列をShiftJisのBLOBに変換する
var toShiftJIS = (utf8String:string):Blob => {
    const detected = encoding.detect(utf8String)
    const unicodeList = []
 
    for (let i = 0; i < utf8String.length; i += 1) {
        unicodeList.push(utf8String.charCodeAt(i))
    }
 
    const sjisArray = encoding.convert(unicodeList, {
        to: 'SJIS',
        from: detected
    })
    
    return new Blob([new Uint8Array(sjisArray)], { type: 'text/plain' })
}


//注文履歴取得
export const GetOrdersSv = createAsyncThunk<boolean, {tenantId:string, callback: (ok: boolean, orders: MAOrder[] | undefined) => void }>(
    "sysad/getOrders",
    async (arg, { dispatch }) => {
        try {
            var orders = await (new SysadV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getOrders(arg.tenantId);
            arg.callback(true,orders);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);

//注文情報取得
export const SysadGetOrderSv = createAsyncThunk<boolean, {orderId:string, callback: (ok: boolean, orders: MAOrder | undefined) => void }>(
    "sysad/getOrder",
    async (arg, { dispatch }) => {
        try {
            var order = await (new SysadV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getOrder(arg.orderId);
            arg.callback(true,order);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);


//次のライセンス時刻の計算
export const CalcNextStartEndSv = createAsyncThunk<boolean, {tenantId:string, callback: (ok: boolean, startEnd: StartEndDate | undefined) => void }>(
    "sysad/calcNextStartEnd",
    async (arg, { dispatch }) => {
        try {
            var startEnd = await (new SysadV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).calcNextStartEnd(arg.tenantId,1);
            arg.callback(true,startEnd);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);

///注文情報の登録
export const PostOrderSv = createAsyncThunk<boolean, {tenantId:string, order:PostOrderInfo, callback: (ok: boolean, order: MAOrder | undefined) => void }>(
    "sysad/postOrder",
    async (arg, { dispatch }) => {
        try {
            var order = await (new SysadV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).postOrder(arg.tenantId,arg.order);
            arg.callback(true,order);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);

//注文削除
export const DeleteOrderSv = createAsyncThunk<boolean, {orderId:string, callback: (ok: boolean, error: string) => void }>(
    "sysad/deleteOrder",
    async (arg, { dispatch }) => {
        try {
            await (new SysadV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).deleteOrder(arg.orderId);
            arg.callback(true,"");
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, ""+err);
            throw err;
        }
    }
);


//会社の最大プロジェクト数とライセンス期限を設定
export const PutTenantLicenseSv = createAsyncThunk<boolean, {tenantId:string,maxPrj:number,limitDate:Date, callback: (ok: boolean, error: string) => void }>(
    "sysad/putTenantLicense",
    async (arg, { dispatch }) => {
        try {
            var info = new PutTenantLicenseInfo();
            info.maxProject = arg.maxPrj;
            info.limitUTC = arg.limitDate;
            await (new SysadV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).putTenantLicense(arg.tenantId,info);
            arg.callback(true,"");
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, ""+err);
            throw err;
        }
    }
);

//注文のステータス変更
//paystate 0=未設定,1=請求書登録済,2=支払い確認済み
export const SysadPutOrderPaystateSv = createAsyncThunk<boolean, {orderId:string,paystate:number,callback: (ok: boolean, error: string) => void }>(
    "sysad/putOrderPaystate",
    async (arg, { dispatch }) => {
        try {
            await (new SysadV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).putOrderPaystate(arg.orderId,arg.paystate);
            arg.callback(true,"");
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, ""+err);
            throw err;
        }
    }
);