import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  Grid,
  makeStyles,
  Paper,
  Card,
  CardContent,
  CardActions,
  Box,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";

import { ItemTypeKey } from "../../Constants";
import {
  ProcedureJson,
  ContentsJson,
  DetailJson,
  MorningAssemblyJson,
} from "../../net/NetworkClient";
import AddBtn from "./AddBtn";
import { v4 as uuid } from "uuid";
import { useDebounce } from "use-debounce";
import ActionSlice, { ActionType } from "../../misc/ActionSlice";
import { ConfirmDialogSlice } from "../../misc/ConfirmDialog";
import TitleText from "./TitleText";

const useStyles = makeStyles((theme) => ({
  paper: {
    //margin: "auto",
    overflow: "hidden",
  },
  content: {
    padding: "20px 16px",
    flexGrow: 1,
  },
  itemActions: {
    justifyContent: "flex-end",
  },
  addpinBox: {
    padding: "0 !important" as any,
    textAlign: "right",
    marginRight: "1px",
  },
}));

//作業内容と安全注意事項

const ContentsItemWorkProcedure: React.FunctionComponent<{
  jsonData: MorningAssemblyJson | undefined;
  selectedItem: { key: string; id: string } | undefined;
  onUpdate: (newData: ContentsJson) => void;
  onChanging: (changing: boolean) => void;
}> = ({ jsonData, selectedItem, onUpdate, onChanging }) => {
  const classes = useStyles();
  const dispatch = useDispatch();


  const [itemId, setItemId] = React.useState("");

  //表示データ
  const [data, setData] = React.useState<{
    uuid: string;
    title: string;
    procedures: ProcedureJson[];
  }>({
    uuid: "",
    title: "",
    procedures: [],
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (selectedItem?.key !== ItemTypeKey.workProcedure)
      return;

    if (selectedItem?.id && jsonData) {
      if (itemId !== selectedItem.id)
        setItemId(selectedItem.id);
      const contents = jsonData.contents?.find(
        (c) => c.uuid === selectedItem.id
      );
      if (contents?.details) {
        const toData = {
          uuid: contents.uuid ?? "",
          title: contents.title ?? "",
          procedures: contents.details.procedures ?? [],
        };
        if (JSON.stringify(data) !== JSON.stringify(toData)) {
          setData(toData);
        }
      }
    }
    
    dispatch(ActionSlice.actions.doAction({ type: ActionType.CONTENT_ITEM_LOAD_END }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, jsonData]);



  //データの保存とReduxデータへの反映
  function setDataAndPost(inData: {
    uuid: string;
    title: string;
    procedures: ProcedureJson[];
  }) {
    setData(inData);
    const contents = jsonData?.contents?.find((c) => c.uuid === itemId);
    const strsrc = JSON.stringify(contents);
    var copy = ContentsJson.fromJS(JSON.parse(strsrc));

    const tmp = new DetailJson();
    tmp.procedures = inData.procedures;
    const detail = DetailJson.fromJS(JSON.parse(JSON.stringify(tmp)));
    copy.details = detail;

    const dststr = JSON.stringify(copy);
    if (strsrc !== dststr) {
      onUpdate(copy);
    }
  }

  //表示データのコピー
  function copyData() {
    return {
      uuid: data.uuid,
      title: "" + data.title,
      procedures: data.procedures,
    };
  }

  //項目追加
  function addProcedure(index: number) {
    const item = new ProcedureJson();
    item.uuid = uuid();
    item.work = "";
    item.content = "";
    item.company = "";
    item.hazardFactor = "";
    item.counterplan = "";
    var list = [
      ...data.procedures.slice(0, index),
      item,
      ...data.procedures.slice(index),
    ];
    const newdata = copyData();
    newdata.procedures = list;
    setDataAndPost(newdata);
  }


  if (selectedItem?.key !== ItemTypeKey.workProcedure) return null;

  return (
    <Box>
      <Paper className={classes.paper} elevation={0} square>
        <Grid container spacing={2} className={classes.content}>
          <Grid item xs={12}>
          <TitleText
            type={ItemTypeKey.workProcedure}
            value={data.title}
            onUpdate={(t)=>{}}
            onChanging={(f)=>{}}/>
          </Grid>
          <Grid container className={classes.addpinBox} justify="flex-end">
            <AddBtn
              index={0}
              onClick={(index) => {
                addProcedure(index);
              }}
            />
          </Grid>

          {data.procedures.map((val, idx) => (
            <Grid item xs={12} key={val.uuid} className={classes.addpinBox}>
              <Grid item xs={12}>
                <Procedure
                  contents={jsonData?.contents?.find(
                    (c) => c.uuid === itemId
                  )}
                  procedure={val}
                  uparrow={idx > 0}
                  downarrow={(data.procedures.length ?? 0) > idx + 1}
                  selectedItem={selectedItem}
                  onUpdate={(newdata) => {
                    onUpdate(newdata);
                  }}
                  onShift={(up, uuid) => {
                    const newdata = copyData();
                    var idx = data.procedures.findIndex((p) => p.uuid === uuid);
                    if (idx >= 0) {
                      const item = newdata.procedures.splice(idx, 1)[0];
                      newdata.procedures.splice(idx + (up ? -1 : +1), 0, item);
                      setDataAndPost(newdata);
                    }
                  }}
                  onDelete={(uuid) => {
                    dispatch(
                      ConfirmDialogSlice.actions.openConfirm({
                        title: "削除の確認",
                        message: "本当に削除してもよろしいですか？",
                        callback: (ok) => {
                          if (ok) {
                            const newdata = copyData();
                            newdata.procedures = newdata.procedures.filter(
                              (p) => p.uuid !== uuid
                            );
                            setDataAndPost(newdata);
                            dispatch(ActionSlice.actions.clearAction(undefined));
                          }
                        }
                      })
                    );
                  }}
                  onChanging={onChanging}
                />
              </Grid>
              <Grid container className={classes.addpinBox} justify="flex-end">
                <AddBtn
                  index={idx + 1}
                  onClick={(index) => {
                    addProcedure(index);
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
}

export default ContentsItemWorkProcedure;

//作業項目1つ分
const Procedure: React.FunctionComponent<{
  contents?: ContentsJson;
  procedure: ProcedureJson;
  selectedItem: { key: string; id: string } | undefined;
  uparrow: boolean;
  downarrow: boolean;
  onUpdate: (newData: ContentsJson) => void;
  onShift: (up: boolean, uuid: string) => void;
  onDelete: (uuid: string) => void;
  onChanging: (changing: boolean) => void;
}> = ({ contents, procedure, selectedItem, uparrow, downarrow, onUpdate, onShift, onDelete, onChanging }) => {
  const classes = useStyles();

  //表示データ
  const [data, setData] = React.useState<{
    uuid?: string;
    work?: string;
    content?: string;
    company?: string;
    hazardFactor?: string;
    counterplan?: string;
  }>({
    uuid: procedure.uuid,
    work: procedure.work,
    content: procedure.content,
    company: procedure.company,
    hazardFactor: procedure.hazardFactor,
    counterplan: procedure.counterplan,
  });

  //データコピー
  function copyData() {
    return JSON.parse(JSON.stringify(data));
  }

  function checkChange(): boolean {
    if (
      procedure.work === data.work &&
      procedure.content === data.content &&
      procedure.company === data.company &&
      procedure.hazardFactor === data.hazardFactor &&
      procedure.counterplan === data.counterplan
    )
      return false;
    return true;
  }

  function updateContentsJson() {
    const proc = ProcedureJson.fromJS(data);
    var newdata = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));

    if (!newdata.details) newdata.details = new DetailJson();
    newdata.details.procedures = newdata.details?.procedures?.map((p) =>
      p.uuid === proc.uuid ? proc : p
    );
    onUpdate(newdata);
  }

  //OnChangeの度にJsonを更新すると処理が重たいので、debounce処理
  const [debouncedData] = useDebounce(data, 500);
  useEffect(() => {
    if (!checkChange()) return;
    updateContentsJson();
    onChanging(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedData]);

  //表示対象が変わるときに
  useEffect(() => {
    if (checkChange()) {
      updateContentsJson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <Card variant="outlined">
      <CardContent>
        <TextField
          fullWidth
          name="work"
          label="工種"
          error={!data.work}
          helperText={!data.work ? "値を入力して下さい" : ""}
          value={data.work}
          onChange={(e) => {
            onChanging(true);
            setData({ ...copyData(), work: e.target.value });
          }}
        />
        <TextField
          required
          fullWidth
          name="content"
          label="作業内容"
          error={!data.content}
          helperText={!data.content ? "値を入力して下さい" : ""}
          value={data.content}
          onChange={(e) => {
            onChanging(true);
            setData({ ...copyData(), content: e.target.value });
          }}
        />
        <TextField
          required
          fullWidth
          name="company"
          label="協力会社"
          error={!data.company}
          helperText={!data.company ? "値を入力して下さい" : ""}
          value={data.company}
          onChange={(e) => {
            onChanging(true);
            setData({ ...copyData(), company: e.target.value });
          }}
        />
        <TextField
          required
          fullWidth
          name="hazardFactor"
          label="危険有害要因"
          error={!data.hazardFactor}
          helperText={!data.hazardFactor ? "値を入力して下さい" : ""}
          value={data.hazardFactor}
          onChange={(e) => {
            onChanging(true);
            setData({ ...copyData(), hazardFactor: e.target.value });
          }}
        />
        <TextField
          required
          fullWidth
          name="counterplan"
          label="対策"
          error={!data.counterplan}
          helperText={!data.counterplan ? "値を入力して下さい" : ""}
          value={data.counterplan}
          onChange={(e) => {
            onChanging(true);
            setData({ ...copyData(), counterplan: e.target.value });
          }}
        />
      </CardContent>
      <CardActions disableSpacing={true} className={classes.itemActions}>
        <Box component="span" visibility={uparrow ? "visible" : "hidden"}>
          <Tooltip title="上へ移動">
            <IconButton
              edge="end"
              aria-label="up"
              onClick={() => {
                onShift(true, data.uuid ?? "");
              }}
            >
              <ArrowUpwardIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box component="span" visibility={downarrow ? "visible" : "hidden"}>
          <Tooltip title="下へ移動">
            <IconButton
              edge="end"
              aria-label="down"
              onClick={() => {
                onShift(false, data.uuid ?? "");
              }}
            >
              <ArrowDownwardIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box component="span">
          <Tooltip title="削除">
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => {
                onDelete(data.uuid ?? "");
              }}
            >
              <HighlightOffIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
    </Card>
  );
};
