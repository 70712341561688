import moment from "moment-timezone";
import React, { useEffect } from "react";
import { Guid } from "guid-typescript";
import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  Box,
  /*FormControl,
  InputLabel,
  Select,
  MenuItem,*/
} from "@material-ui/core";

import {
  ItemTypeKey
} from "../../Constants";
import { MorningAssemblyJson, NewsJson } from "../../net/NetworkClient";
import { useDebounce } from "use-debounce";
import { useDispatch } from "react-redux";
import ActionSlice, { ActionType } from "../../misc/ActionSlice";
import TitleText from "./TitleText";
import TimePicker from "../../misc/TimePicker";

moment.tz.setDefault('Asia/Tokyo');
const useStyles = makeStyles((theme) => ({
  paper: {
    //margin: "auto",
    height: "100%",
    overflow: "hidden",
    flexGrow: 1,
  },
  box: {
    margin: "20px",
  },
  ctrl: {
    minWidth: "180px",
    //width: "100%",
  },
}));

//文字列の配列を1つの文字列に合成
function arrayToString(list: string[] | undefined): string {
  if (!list) return "";
  var re = "";

  list.forEach((val, i) => {
    if (i > 0) re += "\n";
    re += val;
  });
  return re;
}
//文字列を改行文字で分割
function stringToArray(str: string): string[] {
  if (!str) return [];
  var tmp = str.split(/[\r\n]/);
  return tmp;
}

const ContentsItemConfig: React.FunctionComponent<{
  jsonData: MorningAssemblyJson | undefined;
  selectedItem: { key: string; id: string } | undefined;
  onSetJson: (json: MorningAssemblyJson) => void;
  onChanging: (changing: boolean) => void;
}> = ({ jsonData, selectedItem, onSetJson, onChanging }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [data, setData] = React.useState<{
    leader: string;
    place:string;
    news: string;
    startDateTime:Date;
    endDateTime:Date;
  }>({
    leader: jsonData?.leader ?? "",
    place:jsonData?.place ?? "",
    news: arrayToString(jsonData?.news?.content),
    startDateTime : jsonData?.start ?? new Date(),
    endDateTime : jsonData?.end ?? new Date()
  });

  const [errors, setErrors] = React.useState({
    leader: "",
    place:"",
   // startTime: "",
   // endTime: "",
    news: "",
  });

  //JSONデータの更新
  useEffect(() => {
    if (selectedItem?.key !== ItemTypeKey.config)
      return;
    if (!jsonData) {
      var s = moment().set({"hour":8,"minute":0}).toDate();
      var e = moment().set({"hour":23,"minute":59}).toDate();

      setData({ leader: "",place:"",  news: "" ,startDateTime:s,endDateTime:e});
    } else {

      const toData = {
        leader: jsonData?.leader ?? "",
        place:jsonData.place ?? "",
        startTime: moment(jsonData?.start).format("HH:mm"),
        endTime: moment(jsonData?.end).format("HH:mm"),
        news: arrayToString(jsonData?.news?.content),
        startDateTime:jsonData?.start ?? moment().set({"hour":8,"minute":0}).toDate(),
        endDateTime:jsonData?.end ?? moment().set({"hour":23,"minute":59}).toDate(),
      };

      if (JSON.stringify(data) !== JSON.stringify(toData)) {
        setData(toData);
      }
    }

    dispatch(ActionSlice.actions.doAction({ type: ActionType.CONTENT_ITEM_LOAD_END }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonData, selectedItem]);

  function checkErrors(): boolean {
    var hasError = false;
    var copy = Object.assign({}, errors);
    if (!data.leader) {
      copy.leader = "値を入力して下さい";
      hasError = true;
    } else {
      copy.leader = "";
    }

    // if (moment(data.endTime, "HH:mm").isBefore(moment(data.startTime, "HH:mm"))) {
    //   copy.endTime = "開催時刻以前になっています";
    // }

    if (JSON.stringify(errors) !== JSON.stringify(copy)) {
      setErrors(copy);
    }
    return hasError;
  }

  //内部データの更新
  useEffect(() => {
    checkErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //OnChangeの度にJsonを更新すると処理が重たいので、debounce処理
  const [debouncedData] = useDebounce(data, 500);
  useEffect(() => {
    var news = new NewsJson();
    news.content = stringToArray(data.news);
    news.uuid = jsonData?.news?.uuid ? jsonData?.news?.uuid : Guid.create().toString();


    const date = moment(data.startDateTime)
      .second(0)
      .millisecond(0);

    var enddate = moment(data.endDateTime)
      .second(0)
      .millisecond(0);

    if (enddate.isBefore(date)) {
      enddate = moment(jsonData?.start)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      enddate = enddate.add(1, "day");
    }
    //const enddate = moment(date).add(data.timespan, "minutes");
    if (data.leader === jsonData?.leader
      && data.place === jsonData.place
      && date.isSame(jsonData?.start, "minute")
      && enddate.isSame(jsonData?.end, "minute")
      && JSON.stringify(news) === JSON.stringify(jsonData?.news)) {
      return;
    }

    var json = Object.assign({}, jsonData, {
      leader: data.leader,
      place:data.place,
      start: date.toDate(),
      end: enddate.toDate(),
      news: news,
    });
    onSetJson(json);
    onChanging(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedData]);

 /* type TimeMenuItem = {
    label:string;
    value:number;
  }
  function endTimes():TimeMenuItem[] {
    var list: TimeMenuItem[] = new Array();
    var t = moment()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

    for(var i = 0; i <= 60 ; i++ ){
      var mi:TimeMenuItem = {label:t.format("HH:mm"),value:i*30};
      list.push(mi);
      t.add(15,"minutes");
    }
    return list;
  }*/

  if (selectedItem?.key !== ItemTypeKey.config) {
    return null;
  }

  return (
    <Box style={{ height: "100%" }}>
      <Paper className={classes.paper} elevation={0} square >
        <Box className={classes.box}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TitleText
                type={ItemTypeKey.config}
                value={"基本設定"}
                onUpdate={(t) => { }}
                onChanging={(f) => { }} />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.ctrl}
                required
                fullWidth
                name="Name"
                label="朝礼主催者"
                value={data.leader}
                error={!!errors.leader}
                helperText={errors.leader}
                onChange={(e) => {
                  onChanging(true);
                  setData(
                    Object.assign({}, data, {
                      leader: e.target.value,
                    })
                  );
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.ctrl}
                fullWidth
                name="Place"
                label="場所"
                value={data.place}
                error={!!errors.place}
                helperText={errors.place}
                onChange={(e) => {
                  onChanging(true);
                  setData(
                    Object.assign({}, data, {
                      place: e.target.value,
                    })
                  );
                }}
              />
            </Grid>
{/** 
            <Grid item xs={12}>
              <TextField
                className={classes.ctrl}
                required
                name="Date"
                type="time"
                label="開始時刻"
                value={data.startTime}
                error={!!errors.startTime}
                helperText={errors.startTime}
                onChange={(e) => {
                  onChanging(true);
                  setData(
                    Object.assign({}, data, {
                      startTime: e.target.value,
                    })
                  );
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.ctrl}
                required
                name="Date"
                type="time"
                label="端末での利用終了時刻"
                value={data.endTime}
                error={!!errors.endTime}
                helperText={errors.endTime}
                onChange={(e) => {
                  onChanging(true);
                  setData(
                    Object.assign({}, data, {
                      endTime: e.target.value,
                    })
                  );
                }}
              />
            </Grid>
*/}
            <Grid item xs={12}>
              <TimePicker 
              baseDate={data.startDateTime}
              value={data.startDateTime} 
              useOverTime={false} 
              label={"開始時刻"}
              required={true}
              onChange={(d)=>{
                onChanging(true);
                  setData(
                    Object.assign({}, data, {
                      startDateTime:d,
                    })
                  );
                }}></TimePicker>
            </Grid>

            <Grid item xs={12}>
              <TimePicker 
              baseDate={data.startDateTime}
              value={data.endDateTime} 
              useOverTime={true} 
              label={"端末での利用終了時刻"}
              required={true}
              after={data.startDateTime}
              onChange={(d)=>{
                onChanging(true);
                setData(
                  Object.assign({}, data, {
                    endDateTime:d,
                  })
                );
              }}>

              </TimePicker>
            </Grid>
{/** 
            <Grid item xs={12}>
            <FormControl >
                <InputLabel id="demo-mutiple-name-label">Name</InputLabel>
                <Select
                  labelId="demo-mutiple-name-label"
                  id="demo-mutiple-name"
                  value={0}
                >
                  
                  {endTimes().map((item) => {
return (
<MenuItem key={""+item.value} value={""+item.value}>
{item.label}
</MenuItem>
)
                  }
                  )}
                </Select>
              </FormControl>
            </Grid>
*/}
            <Grid item xs={12}>
              <TextField
                className={classes.ctrl}
                multiline
                fullWidth
                name="news"
                label="今日のお知らせ"
                value={data.news}
                rows={10}
                error={!!errors.news}
                helperText={errors.news}
                onChange={(e) => {
                  onChanging(true);
                  setData(
                    Object.assign({}, data, {
                      news: e.target.value,
                    })
                  );
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}

export default ContentsItemConfig;
