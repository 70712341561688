
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { CircularProgress, Grid, Paper, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AlertDialogSlice } from '../misc/AlertDialog';

import moment from "moment-timezone"
import { MAOrder, OrderRedirect } from '../net/NetworkClient';
import { ButtonBackGround, FooterHeight } from '../Constants';
import { GMOGetResultSv } from '../net/PaymentAsync';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

moment.tz.setDefault('Asia/Tokyo');

const useStyles = makeStyles((theme) => ({

    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "calc(100vh - " + (48 + FooterHeight) + "px)",
    },
    paperSytle: {
        height: "100%"
    },
    column: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    topBtn: {
        color: "white",
        backgroundColor: ButtonBackGround
    },
}));

function GMOResultView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    // const history = useHistory();
    // const user = useSelector(
    //    (state: RootState) => state.auth.user
    //);
    //テナント一覧
    //const tenants = useSelector(
    //     (state: RootState) => state.tenants.tenants
    // );

    const [checked, setChecked] = React.useState(false);
    const [order, setOrder] = React.useState<MAOrder>();
    /*
        function findValue(key: string): string {
            if (!keyValues)
                return "";
            var re = "";
            keyValues.forEach((kv) => {
                if (kv.key === key) {
                    re = kv.value ?? "";
                }
            });
            return re;
        }*/

    //クレジット決済
    function isCredit(): boolean {
        if (!order?.paymentCode)
            return false;
        return order.paymentCode === 1 || order.paymentCode === 2;
    }

    //銀行振込決済
    function isTransfer(): boolean {
        if (!order?.paymentCode)
            return false;
        return order.paymentCode === 22;
    }

    //初回呼び出し時
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        var order_number = params.get("order_number") ?? "";
        var res = params.get("result") ?? "0";
        var trans_code = params.get("trans_code") ?? "";
        var user_id = params.get("user_id") ?? "";

        var orderRe = new OrderRedirect();
        orderRe.result = res;
        orderRe.orderNumber = (order_number ?? "");
        orderRe.transCode = trans_code;
        orderRe.userId = user_id;

        dispatch(GMOGetResultSv({
            orderRe: orderRe, callback: (ok, res) => {
                if (ok && res) {
                    setOrder(res);
                    setChecked(true);
                } else {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "サーバへの接続に失敗しました",
                        })
                    );
                }
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Paper className={classes.root}>
            <Grid container spacing={1} style={{ display: (!checked ? "flex" : "none") }}>
                <Grid item xs={12}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={10} className={classes.column}>

                    <Typography><CircularProgress style={{ color: "#1a90ff" }} size={18} />　手続きを確認しました。決済サーバからデータを取得中です...</Typography>

                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>

            <Grid container spacing={1} style={{ display: (isCredit() ? "flex" : "none") }}>
                <Grid item xs={12}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={10} className={classes.column}>
                    <Typography>クレジットカードでのお支払いを確認しました</Typography>
                </Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={10}><Divider /></Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={3}>注文番号</Grid>
                <Grid item xs={7}>{(order?.id ?? "").split("-").join("")}</Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={3}>価格</Grid>
                <Grid item xs={7}>{order?.price}円</Grid>
                <Grid item xs={1}></Grid>
            </Grid>

            <Grid container spacing={1} style={{ display: (isTransfer() ? "flex" : "none") }}>
                <Grid item xs={12}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={10} className={classes.column}>
                    <Typography>銀行振込でのご注文を受け付けました</Typography><br />
                    <Typography>下記口座へ有効期限内に</Typography>
                    <Typography>代金をお振込いただきますようお願いいたします。</Typography>
                </Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={10}><Divider /></Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={3}>注文番号</Grid>
                <Grid item xs={7}>{(order?.id ?? "").split("-").join("")}</Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={3}>価格</Grid>
                <Grid item xs={7}>{order?.price}円</Grid>
                <Grid item xs={1}></Grid>


                <Grid item xs={1}></Grid>
                <Grid item xs={3}>金融機関名</Grid>
                <Grid item xs={7}>{order?.verBankName}</Grid>
                <Grid item xs={1}></Grid>


                <Grid item xs={1}></Grid>
                <Grid item xs={3}>金融機関コード</Grid>
                <Grid item xs={7}>{order?.verBankCode}</Grid>
                <Grid item xs={1}></Grid>


                <Grid item xs={1}></Grid>
                <Grid item xs={3}>支店名</Grid>
                <Grid item xs={7}>{order?.verBranchName}</Grid>
                <Grid item xs={1}></Grid>


                <Grid item xs={1}></Grid>
                <Grid item xs={3}>支店コード</Grid>
                <Grid item xs={7}>{order?.verBranchCode}</Grid>
                <Grid item xs={1}></Grid>


                <Grid item xs={1}></Grid>
                <Grid item xs={3}>口座番号</Grid>
                <Grid item xs={7}>{order?.verAccountNo}</Grid>
                <Grid item xs={1}></Grid>


                <Grid item xs={1}></Grid>
                <Grid item xs={3}>口座名義</Grid>
                <Grid item xs={7}>{order?.verAccountName}</Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={3}>ご案内メールアドレス</Grid>
                <Grid item xs={7}>{order?.mailAddress}</Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={10}><Divider /></Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={10} className={classes.column}>
                    <Typography>※振込み手数料はお客様負担となりますのでご了承ください。</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>

            <Grid container spacing={1} style={{ display: (checked ? "flex" : "none") }}>
                <Grid item xs={12}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={10} className={classes.column}>
                    <Button variant="contained" fullWidth href="#contained-buttons" className={classes.topBtn}
                        onClick={() => { history.push("/") }}>
                        トップページへ移動</Button></Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </Paper >
    )
}


export default GMOResultView;

