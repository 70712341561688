
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store";
import TenantSlice from "../tenant/TenantSlice";
import { Toolbar, Box, CircularProgress, Tabs, Tab, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { putTenantSv, getTenantSv } from "../net/TenantAsync";
import { AlertDialogSlice } from '../misc/AlertDialog';

import moment from "moment-timezone"
import { MATenant, TenantInfo } from '../net/NetworkClient';
import { ToolbarBackGroundColor, ButtonBackGround, FooterHeight, LinkTextColor } from '../Constants';
import InfoIcon from '@material-ui/icons/Info';
import PersonIcon from '@material-ui/icons/Person';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AdminUserView from './AdminUserView';
import PaymentIcon from '@material-ui/icons/Payment';
import AdminLicenseView from './AdminLicenseView';

moment.tz.setDefault('Asia/Tokyo');

const useStyles = makeStyles((theme) => ({

    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "calc(100vh - 48px -" + FooterHeight + "px)",
    },
    toolbarTenantBtn: {
        color: theme.palette.common.white,
        fontSize: "0.8rem",
    },
    nonactive_btn: {
        cursor: "default",
        pointerEvents: "none",
    },
    toolbar: {
        backgroundColor: ToolbarBackGroundColor,
        paddingLeft: "0px",
    },
    contents: {
        flex: 1,
        height: "100%",
        overflow: "hidden",
        padding: theme.spacing(0, 1),

        position: "relative",
    },
    backBox: {
        marginLeft: "auto",
        marginRight: "0px"
    },
    backBtn: {
        color: LinkTextColor,
        fontSize: "0.8rem",
    },
    tabPanel: {
        padding: theme.spacing(2),
        height: "calc(100vh - 170px - " + FooterHeight + "px)",
        overflowY: "auto"
    },
    btn: {
        marginTop: theme.spacing(2),
        backgroundColor: "#1976d2",
        color: theme.palette.common.white,
    },
    tablist: {
        height: "75px"
    },
    usersBox: {


    },
    avaterActive: {
        backgroundColor: theme.palette.success.main,
    },
    avaterDeactive: {

    },
    userCard: {
        marginBottom: "2px",
    },

    mailbtn: {
        marginLeft: "auto",
    },
    delbtn: {
        marginLeft: "auto",
        color: theme.palette.error.main
    }
}));

function AdminView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(
        (state: RootState) => state.auth.user
    );
    const tenants = useSelector(
        (state: RootState) => state.tenants.tenants
    );

    const { tenantid } = useParams<{ tenantid: string }>();
    const [currentTenant, setCurrentTenant] = React.useState<TenantInfo>();
    const [loading, setLoading] = React.useState(false);

    const [tabIndex, setTabIndex] = React.useState(0);

    const [tenantName, setTenantName] = React.useState("");
    const [tenantNameError, setTenantNameError] = React.useState("");

    //表示中のテナントが変更された場合
    useEffect(() => {
        if (!!currentTenant) {
            setTenantName(currentTenant.tenant?.name ?? "");
        } else {
            setTenantName("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTenant]);


    //選択中のテナント情報が変更された
    useEffect(() => {
        if (!!tenantid) {
            var cur = tenants.find(t => { return tenantid === t.tenant?.id });
            if (cur?.tenant?.id !== currentTenant?.tenant?.id)
                setCurrentTenant(cur ?? undefined);
        } else {
            setCurrentTenant(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantid, tenants]);

    //テナント更新
    function onUpdateTenant() {
        if (!!tenantNameError)
            return;

        var tenant = new MATenant();
        tenant.name = tenantName;
        dispatch(putTenantSv({
            tenantId: currentTenant?.tenant?.id ?? "", value: tenant, callback: (ok) => {
                if (!ok) {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "会社情報を更新できませんでした。",
                        })
                    );
                } else {
                    dispatch(getTenantSv({
                        tenantId: currentTenant?.tenant?.id ?? "", callback: (ok2, retenant) => {
                            if (!ok2 || retenant == null) {
                                dispatch(
                                    AlertDialogSlice.actions.openAlert({
                                        title: "エラー",
                                        message: "更新した会社情報を取得できませんでした。",
                                    })
                                );
                            } else {
                                dispatch(
                                    TenantSlice.actions.updateTenants(retenant)
                                );
                                dispatch(
                                    AlertDialogSlice.actions.openAlert({
                                        title: "更新しました",
                                        message: "会社情報を更新しました。",
                                    })
                                );
                            }
                        }
                    }))
                }
            }
        }))
    }

    //エラーチェック
    useEffect(() => {
        if (!!tenantName) {
            setTenantNameError("");
        } else {
            setTenantNameError("名称を入力してください。")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantName]);

    //タブ選択が変更された
    function onChangeTab(event: React.ChangeEvent<{}>, newValue: any) {
        setTabIndex(newValue);
    }

    if (!user)
        return null;

    if (!currentTenant?.admin)
        return null;

    return (
        <Box className={classes.root}>
            <Toolbar variant="dense" className={classes.toolbar}>
                <Box component="span" hidden={!loading}>
                    <CircularProgress />
                </Box>

                <Box >
                    <Button
                        aria-label="tenant top"
                        className={classes.backBtn}
                        onClick={() => {
                            history.push("/tenants/" + tenantid);
                        }}>
                        <ArrowBackIosIcon />
                        プロジェクト一覧へ戻る
                    </Button>
                </Box>
            </Toolbar>
            <Box className={classes.contents}>

                <Tabs value={tabIndex} onChange={onChangeTab} className={classes.tablist}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: ButtonBackGround
                        }
                    }}>
                    <Tab label="会社情報" icon={<InfoIcon />} />
                    <Tab label="ユーザー管理" icon={<PersonIcon />} />
                    <Tab label="お支払い" icon={<PaymentIcon />} />
                </Tabs>

                {/**登録情報 */}
                <Box hidden={tabIndex !== 0} className={classes.tabPanel}>
                    <h4>会社名を変更する場合は以下を以下を修正して更新ボタンを押してください。</h4>
                    <TextField
                        fullWidth
                        required
                        name="tenant name"
                        type="text"
                        label="会社名"
                        value={tenantName}
                        error={!!tenantNameError}
                        helperText={tenantNameError}
                        onChange={(e) => {
                            setTenantName(e.target.value);
                        }}
                        hidden={!currentTenant.admin}
                    />
                    <Button fullWidth variant="contained" className={classes.btn}
                        disabled={currentTenant?.tenant?.name === tenantName}
                        onClick={onUpdateTenant}>
                        更新
                    </Button>
                </Box>
                {/**ユーザー管理 */}
                <Box hidden={tabIndex !== 1} className={classes.tabPanel}>
                    <AdminUserView active={tabIndex === 1} tenantid={tenantid} setLoading={setLoading} />
                </Box>
                {/**課金管理 */}
                <Box hidden={tabIndex !== 2} className={classes.tabPanel}>
                    <AdminLicenseView active={tabIndex === 3} tenantid={tenantid} setLoading={setLoading} />
                </Box>
            </Box>
        </Box>
    )
}


export default AdminView;

