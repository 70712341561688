import moment, { Moment } from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { findContentsSv, getContentsDataSv, postContentsSv } from "../net/CalendarAsync"
import { MAContents, MAProject } from "../net/NetworkClient";
import { RootState } from "../Store";
import ActionSlice, { ActionType } from "../misc/ActionSlice";
import { WaitingSlice } from "../misc/WaitingBar";
import CalendarCell, { DupInfo } from "./CalendarCell"
import { FooterHeight } from "../Constants";

moment.tz.setDefault('Asia/Tokyo');
var classNames = require("classnames");

const useStyles = makeStyles((theme) => ({
    dayofweek: {
        height: "24px",
    },
    dayofweekcell: {
        padding: "0px",
        textAlign: "center",
        border: "#dadce0 1px solid ",
        backgroundColor: "white"
    },
    sunday: {
        color: "red",
    },
    saturday: {
        color: "blue",
    },
    row4: {
        height: "25%",
    },
    row5: {
        height: "20%",
    },
    row6: {
        height: "16%",
    },
    /*caltable: {
        tableLayout: "fixed",
        height: "calc(100% - 48px)",
    },*/
    contents: {
        height: "calc(100vh - 96px - " + FooterHeight + "px)"
    },
}));

//1日分の表示情報
interface DayInfo {
    date: Moment;
    contents: MAContents[];
    serverChecked: boolean;
}

const CalendarGrid: React.FunctionComponent<{
    month: Moment;
    project?: MAProject;
    onClickContents: (contents: MAContents, anchorElement: Element) => void
    onCreate: (date: Moment) => void;
    setLoading: (loading: boolean) => void;
}> = ({ month, project, onClickContents, onCreate, setLoading }) => {
    var classes = useStyles();
    const dispatch = useDispatch();
    const [days, setDays] = useState<DayInfo[][]>();
    const [refreshFlag, setRefreshFlag] = useState(false);
    const actionMessage = useSelector((state: RootState) => state.action.action);

    /**
     * 表示日時から、月の1日と最後の日付を取得する
     * @param date 表示日時
     */
    function getFirstLastDate(date: Date): { first: Moment; last: Moment } {
        var tgt = moment(date);
        var first = moment().year(tgt.year()).month(tgt.month()).date(1);
        var last = moment(first).add(1, "months").add(-1, "days");

        if (first.day() > 0) first.add(-first.day(), "days");
        if (last.day() < 6) last.add(6 - last.day(), "days");

        first.hour(0).minute(0).second(0).millisecond(0);
        last.hour(0).minute(0).second(0).millisecond(0);
        return { first: first, last: last };
    }
    /**
     * 特定の日時から、含まれる日時を週単位で切り分けた配列を生成する
     * @param fromDate 表示対象の日時
     */
    function makeDays(fromDate: Date): DayInfo[][] {
        const { first, last } = getFirstLastDate(fromDate);
        var current = moment(first);
        var re: DayInfo[][] = [];
        var tmpdays: DayInfo[] = [];

        while (!current.isAfter(last, "days")) {
            tmpdays.push({
                date: moment(current),
                contents: [],
                serverChecked: false,
            });
            if (tmpdays.length >= 7) {
                re.push(tmpdays);
                tmpdays = [];
            }

            current.add(1, "days");
        }
        return re;
    }

    //---------------------
    //アクション処理
    useEffect(() => {
        switch (actionMessage.type) {
            case ActionType.CHANGE_CONTENTS_IN_CAL:
                setRefreshFlag(true);
                dispatch(ActionSlice.actions.clearAction(undefined));
                break;
            case ActionType.CONFIRM_DUPULICATE:
                //D&Dで複製するときの処理
                var dpinfo = JSON.parse(actionMessage.value ?? "") as DupInfo;
                dispatch(ActionSlice.actions.clearAction(undefined));
                duplicateContainer(dpinfo);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionMessage]);

    //表示月、表示現場が変更されたとき
    useEffect(() => {
        if (refreshFlag) {
            setRefreshFlag(false);
           // return; 
        }
        if (project) {
            setLoading(true);
            const { first, last } = getFirstLastDate(month?.toDate());
            last.add(1, "day");
            dispatch(
                findContentsSv({
                    projectId: project.id ?? "",
                    start: first?.utc().toDate(),
                    end: last?.utc().toDate(),
                    callback: (ok, contents) => {
                        if (ok) {
                            var copyDays = makeDays(month?.toDate());
                            if (contents) {
                                contents.forEach((c) => {
                                    copyDays.forEach((lst) => {
                                        const data = lst.find((d) => {
                                            return d.date.isSame(moment(c.startUTC), "day");
                                        });
                                        if (data) {
                                            data.contents.push(c);
                                        }
                                    });
                                });
                            }
                            //サーバのチェックフラグ設定
                            copyDays.forEach((lst) => {
                                lst.forEach((d) => {
                                    d.serverChecked = true;
                                });
                            });
                            setDays(copyDays);
                        }
                        setLoading(false);
                    },
                })
            );


        } else {
            setDays(makeDays(month.toDate()));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month, project, refreshFlag]);

    //朝礼データの複製
    function duplicateContainer(dpinfo: DupInfo) {
        dispatch(WaitingSlice.actions.beginWaiting("データ複製中..."));
        //JSONデータ取得
        dispatch(
            getContentsDataSv({
                contentsId: dpinfo.contentsId,
                callback: (ok, json) => {
                    if (ok && json) {
                        var start = moment(json.start);
                        var end = moment(json.end);
                        const newStart = moment(dpinfo.to)
                            .hour(start.hour())
                            .minute(start.minute())
                            .second(0)
                            .millisecond(0);
                        const newEnd = newStart.clone().add(end.diff(start));
                        json.start = newStart.utc().toDate();
                        json.end = newEnd.utc().toDate();
                        dispatch(
                            postContentsSv({
                                projectId: json.projectId ?? "",
                                data: json,
                                callback: (ok, contents) => {
                                    if (ok && contents) {
                                        dispatch(
                                            ActionSlice.actions.doAction({
                                                type: ActionType.CHANGE_CONTENTS_IN_CAL
                                            })
                                        );
                                    }
                                    dispatch(WaitingSlice.actions.stopWaiting(undefined));
                                },
                            })
                        );

                    } else {
                        dispatch(WaitingSlice.actions.stopWaiting(undefined));
                    }
                },
            })
        );
    }


    if (!days) return null;



    var rowcls = classNames(
        { [classes.row4]: days.length === 4 },
        { [classes.row5]: days.length === 5 },
        { [classes.row6]: days.length === 6 }
    );

    return (
        <Table className={classes.contents}>
            <TableHead>
                <TableRow className={classes.dayofweek}>
                    <TableCell className={classNames(classes.dayofweekcell, classes.sunday)}>日</TableCell>
                    <TableCell className={classes.dayofweekcell}>月</TableCell>
                    <TableCell className={classes.dayofweekcell}>火</TableCell>
                    <TableCell className={classes.dayofweekcell}>水</TableCell>
                    <TableCell className={classes.dayofweekcell}>木</TableCell>
                    <TableCell className={classes.dayofweekcell}>金</TableCell>
                    <TableCell className={classNames(classes.dayofweekcell, classes.saturday)}>土</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {days.map((lst, index) => (
                    <TableRow key={"row" + index} className={rowcls}>
                        {lst.map((d) => (
                            <CalendarCell
                                key={d.date.format("YYYYMMDD")}
                                date={d.date}
                                contents={d.contents}
                                serverChecked={d.serverChecked}
                                onClick={(contents, element) => {
                                    onClickContents(contents, element);
                                }}
                                onCreate={(date) => {
                                    onCreate(date);
                                }}
                            ></CalendarCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}



export default CalendarGrid;