import moment from "moment-timezone";
import React, { useEffect, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import {
    Button,
    Dialog,
    DialogContent,
    FormControl,
    Grid,
    InputLabel,
    Select,
    TextField, Divider, Link, DialogActions, Typography, IconButton,ButtonGroup
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { AlertDialogSlice } from "../misc/AlertDialog";
import {
    MATenant,
} from "../net/NetworkClient";

import { RootState } from "../Store";
import { ButtonBackGround, emailValidation, phoneNumberValidation, postalCodeValidation } from "../Constants";

import { sysadPutTenantSv } from "../net/SysadAsync";

let YubinBango = require('yubinbango-core2')

moment.tz.setDefault('Asia/Tokyo');

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

const useStyles = makeStyles((theme) => ({
    top: {
        position: "absolute",
        right: 25,
        top: 20,
    },
    historyList: {

        overflowX: "hidden",
        overflowY: "auto"
    },
    linkStyle: {
        color: ButtonBackGround
    },
    gridCenter: {
        alignSelf: "flex-start",
        fontSize: "1rem",
        height: theme.spacing(6)
    },
    divider: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1)
    },
    notation: {
        fontSize: "0.7rem",
        paddingLeft: theme.spacing(1)
    },
    textField: {
        //marginTop: theme.spacing(1),
        //marginBottom: theme.spacing(1)
    },
    postalCodeField: {
        //marginTop: theme.spacing(1),
        width: "180px"
    },
    amountTextStyle: {
        display: "flex",
        alignItems: "center",
        fontSize: "2rem",
        justifyContent: "center"
    },
    payBtn: {
        color: "white",
        backgroundColor: ButtonBackGround
    },
    actionBox: {
        width: "100%"
    },
    postalDiv: {
        display: "flex",
        alignItems: "baseline"
    },
    sendTypeBtnGroup: {
        width: "100%"
    },
    sendTypeBtnSelected: {
        color: "white",
        backgroundColor: ButtonBackGround,
        width: "50%",
    },
    sendTypeBtn: {
        color: "white",
        backgroundColor: theme.palette.grey[500],
        width: "50%",
    },
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}


type InvoiceData = {
    nameForInvoice: string,
    mailAddress: string,
    departmentName: string,
    chargePerson: string,
    phoneNumber: string,
    postalCode: string,
    region: string,
    address1: string,
    address2: string,
    address3: string,
    invoiceByEmail : boolean
}

const SysadEditAddressDialog: FunctionComponent<{
    open: boolean;
    tenant: MATenant | undefined;
    onClose: (update: boolean) => void;
}> = ({ open, onClose, tenant }) => {
    const classes = useStyles();

    const user = useSelector(
        (state: RootState) => state.auth.user
    );

    const [regions, setRegions] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(false);


    const emptyInvoiceData: InvoiceData = {

        nameForInvoice: "",
        mailAddress: "",
        departmentName: "",
        chargePerson: "",
        phoneNumber: "",
        postalCode: "",
        region: "",
        address1: "",
        address2: "",
        address3: "",
        invoiceByEmail : false
    };

    const [invoiceData, setInvoiceData] = React.useState<InvoiceData>(emptyInvoiceData);
    const [invoiceError, setInvoiceError] = React.useState<InvoiceData>(emptyInvoiceData);

    const dispatch = useDispatch();

    //選択中のテナント情報が変更された
    useEffect(() => {
        if (!!tenant) {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant]);

    //架け払いのデータが変更された
    useEffect(() => {
        var error: InvoiceData = {
            nameForInvoice: "",
            mailAddress: "",
            departmentName: "",
            chargePerson: "",
            phoneNumber: "",
            postalCode: "",
            region: "",
            address1: "",
            address2: "",
            address3: "",
            invoiceByEmail : false
        };

        error.nameForInvoice = !invoiceData.nameForInvoice ? "会社名を入力してください" : "";

        error.postalCode = !invoiceData.postalCode ? "郵便番号を入力してください" :
            !postalCodeValidation(invoiceData.postalCode) ? "不正な形式です" : "";

        error.mailAddress = !invoiceData.mailAddress ? "メールアドレスを入力してください" :
            !emailValidation(invoiceData.mailAddress) ? "不正な形式です" : "";

        error.phoneNumber = !invoiceData.phoneNumber ? "電話番号を入力してください" :
            !phoneNumberValidation(invoiceData.phoneNumber) ? "不正な形式です" : "";


        error.address1 = !invoiceData.address1 ? "市区町村を入力してください" : "";
        error.address2 = !invoiceData.address2 ? "町名・番地を入力してください" : "";

        var add = invoiceData.region + invoiceData.address1 + invoiceData.address2 + invoiceData.address3;
        if (!error.address1 && !error.address1 && add.length > 50) {
            error.address1 = error.address2 = error.address3 = "住所は全体で50文字以内になるように入力してください";
        }

        setInvoiceError(error);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceData]);


    //選択中のテナント情報が変更された
    useEffect(() => {
        if (open) {
            setRegions(new YubinBango.Core().REGION);
            if (tenant) {
                var data: InvoiceData = {
                    mailAddress: tenant.mailAddress ?? user?.email ?? "",
                    nameForInvoice: tenant.nameForInvoice ?? tenant.name ?? "",
                    departmentName: tenant.departmentName ?? "",
                    chargePerson: tenant.chargePerson ?? user?.name ?? "",
                    phoneNumber: tenant.phoneNumber ?? "",
                    postalCode: tenant.postalCode ?? "",
                    region: tenant.region ?? "",
                    address1: tenant.address1 ?? "",
                    address2: tenant.address2 ?? "",
                    address3: tenant.address3 ?? "",
                    invoiceByEmail : tenant.invoiceByEmail ?? false
                };
                setInvoiceData(data);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);


    const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });


    const checkError = (): boolean => {
        return (!invoiceError.mailAddress &&
            !invoiceError.nameForInvoice &&
            !invoiceError.departmentName &&
            !invoiceError.chargePerson &&
            !invoiceError.phoneNumber &&
            !invoiceError.postalCode &&
            !invoiceError.region &&
            !invoiceError.address1 &&
            !invoiceError.address2 &&
            !invoiceError.address3);
    }

    //変更内容をサーバへ送信する
    const onPutInfo = () => {
        let t = new MATenant();
        t.nameForInvoice = invoiceData.nameForInvoice;
        t.mailAddress = invoiceData.mailAddress;
        t.departmentName = invoiceData.departmentName;
        t.chargePerson = invoiceData.chargePerson;
        t.phoneNumber = invoiceData.phoneNumber;
        t.postalCode = invoiceData.postalCode;
        t.region = invoiceData.region;
        t.address1 = invoiceData.address1;
        t.address2 = invoiceData.address2;
        t.address3 = invoiceData.address3;
        t.invoiceByEmail = invoiceData.invoiceByEmail;

        setLoading(true);
        dispatch(sysadPutTenantSv({
            tenantId: tenant?.id ?? "",
            data: t,
            callback: (ok) => {
                setLoading(false);
                if (!ok) {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "請求書配送先の設定に失敗しました",
                        })
                    );
                } else {
                    onClose(true);
                }
            }
        }))
    }


    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose(false);
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="close-dialog-title" onClose={() => {
                onClose(false);
            }}>請求先情報編集
            </DialogTitle>
            <DialogContent>

                <Grid container spacing={1} className={classes.historyList}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} >
                        <TextField
                            className={classes.textField}
                            required
                            fullWidth
                            inputProps={{ maxLength: 30 }}
                            name="tenantName"
                            label="会社名（最大30文字）"
                            value={invoiceData.nameForInvoice}
                            error={!!invoiceError.nameForInvoice}
                            helperText={invoiceError.nameForInvoice}
                            placeholder="株式会社○○○"
                            onChange={(ev) => {
                                let data = Object.assign({}, invoiceData);
                                data.nameForInvoice = ev.target.value;
                                setInvoiceData(data);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>


                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            name="departmentName"
                            label="部署名（最大30文字）"
                            inputProps={{ maxLength: 30 }}
                            value={invoiceData.departmentName}
                            error={!!invoiceError.departmentName}
                            helperText={invoiceError.departmentName}
                            placeholder="○○○事業部"
                            onChange={(ev) => {
                                let data = Object.assign({}, invoiceData);
                                data.departmentName = ev.target.value;
                                setInvoiceData(data);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} >
                        <TextField
                            className={classes.textField}
                            fullWidth
                            name="chargePerson"
                            label="ご担当者（最大15文字）"
                            inputProps={{ maxLength: 15 }}
                            value={invoiceData.chargePerson}
                            error={!!invoiceError.chargePerson}
                            helperText={invoiceError.chargePerson}
                            placeholder="朝礼　太郎"
                            onChange={(ev) => {
                                let data = Object.assign({}, invoiceData);
                                data.chargePerson = ev.target.value;
                                setInvoiceData(data);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>

                    
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} >
                        <InputLabel shrink={true} >請求書のお届方法</InputLabel>
                        <ButtonGroup disableElevation variant="contained" className={classes.sendTypeBtnGroup}>
                            <Button className={!invoiceData.invoiceByEmail ? classes.sendTypeBtnSelected : classes.sendTypeBtn}
                                onClick={() => {  
                                    let data = Object.assign({}, invoiceData);
                                    data.invoiceByEmail = false;
                                    setInvoiceData(data);
                                }}>郵送</Button>
                            <Button className={invoiceData.invoiceByEmail ? classes.sendTypeBtnSelected : classes.sendTypeBtn}
                                onClick={() => { 
                                    let data = Object.assign({}, invoiceData);
                                    data.invoiceByEmail = true;
                                    setInvoiceData(data);
                                 }}>Eメール</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} >
                        <TextField
                            className={classes.textField}
                            required
                            fullWidth
                            name="EmailAddress"
                            label="メールアドレス（最大100文字）"
                            inputProps={{ maxLength: 100 }}
                            value={invoiceData.mailAddress}
                            error={!!invoiceError.mailAddress}
                            helperText={invoiceError.mailAddress}
                            placeholder="support@chorei084.com"
                            onChange={(ev) => {
                                let data = Object.assign({}, invoiceData);
                                data.mailAddress = ev.target.value;
                                setInvoiceData(data);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} >
                        <TextField
                            className={classes.textField}
                            required
                            fullWidth
                            name="phoneNumber"
                            label="電話番号（最大13文字）"
                            value={invoiceData.phoneNumber}
                            error={!!invoiceError.phoneNumber}
                            inputProps={{ maxLength: 13 }}
                            helperText={invoiceError.phoneNumber}
                            onChange={(ev) => {
                                let data = Object.assign({}, invoiceData);
                                data.phoneNumber = ev.target.value;
                                setInvoiceData(data);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} className={classes.postalDiv}>
                        <TextField
                            className={classes.postalCodeField}
                            required
                            name="postalCode"
                            label="郵便番号（最大8文字）"
                            inputProps={{ maxLength: 8 }}
                            value={invoiceData.postalCode}
                            error={!!invoiceError.postalCode}
                            helperText={invoiceError.postalCode}
                            onChange={(ev) => {
                                let data = Object.assign({}, invoiceData);
                                data.postalCode = ev.target.value;
                                setInvoiceData(data);
                            }}
                        /*onKeyUp={(ev) => {
                            if (ev.target instanceof HTMLInputElement) {
                                let data = Object.assign({}, invoiceData);
                                data.postalCode = ev.target.value;
                                setInvoiceData(data);
                            }
                        }}*/
                        />

                        <Link href="#" onClick={() => {
                            new YubinBango.Core(invoiceData.postalCode, (addr: any) => {
                                let data = Object.assign({}, invoiceData);
                                if (!addr.region) {
                                    dispatch(
                                        AlertDialogSlice.actions.openAlert({
                                            title: "郵便番号エラー",
                                            message: "住所が取得できませんでした。郵便番号が正確か再度ご確認ください。",
                                        })
                                    );
                                }
                                data.region = addr.region;
                                data.address1 = addr.locality;
                                data.address2 = addr.street;
                                setInvoiceData(data);
                            })
                        }} className={classes.linkStyle}>
                            住所を自動入力
                        </Link>
                    </Grid>
                    <Grid item xs={1}></Grid>


                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} >
                        <FormControl
                            className={classes.postalCodeField}>
                            <InputLabel id="region-label">都道府県</InputLabel>
                            <Select
                                labelId="region-label"
                                value={invoiceData.region}
                                onChange={(ev) => {
                                    let data = Object.assign({}, invoiceData);
                                    data.region = "" + ev.target.value;
                                    setInvoiceData(data);
                                }}
                            >
                                {
                                    regions.map((val, idx) => (
                                        <option key={"region-" + idx} value={val ?? "-"} selected={val === invoiceData.region ? true : (!invoiceData.region && !val ? true : false)}>{val ?? "---"}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} >
                        <TextField
                            className={classes.textField}
                            fullWidth
                            name="address1"
                            label="市区町村"
                            value={invoiceData.address1}
                            error={!!invoiceError.address1}
                            helperText={invoiceError.address1}
                            placeholder="○市○区"
                            onChange={(ev) => {
                                let data = Object.assign({}, invoiceData);
                                data.address1 = ev.target.value;
                                setInvoiceData(data);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} >
                        <TextField
                            className={classes.textField}
                            fullWidth
                            name="address2"
                            label="町名・番地"
                            value={invoiceData.address2}
                            error={!!invoiceError.address2}
                            helperText={invoiceError.address2}
                            placeholder="○○○町1-1"
                            onChange={(ev) => {
                                let data = Object.assign({}, invoiceData);
                                data.address2 = ev.target.value;
                                setInvoiceData(data);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} >
                        <TextField
                            className={classes.textField}
                            fullWidth
                            name="address3"
                            label="アパート・マンション・ビル"
                            value={invoiceData.address3}
                            error={!!invoiceError.address3}
                            helperText={invoiceError.address3}
                            onChange={(ev) => {
                                let data = Object.assign({}, invoiceData);
                                data.address3 = ev.target.value;
                                setInvoiceData(data);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>

                </Grid>
            </DialogContent>

            <DialogActions>
                <Grid container spacing={1}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}><Divider light className={classes.divider} /></Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={5} className={classes.amountTextStyle}><Button variant="contained" fullWidth href="#contained-buttons" className={classes.payBtn}
                        onClick={() => { onClose(false); }}>キャンセル</Button></Grid>
                    <Grid item xs={5} className={classes.amountTextStyle}><Button variant="contained" fullWidth href="#contained-buttons" className={classes.payBtn}
                        onClick={() => { onPutInfo(); }} disabled={!checkError() || loading}>
                        編集</Button></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
export default SysadEditAddressDialog;
