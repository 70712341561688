import moment from "moment-timezone";
import React, { useEffect, useState, FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import {
    Button,
    Dialog,
    DialogContent,
    Grid,
    MenuItem,
    Select,
    TextField, DialogActions,  Typography, IconButton,
    Box,CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { AlertDialogSlice } from "../misc/AlertDialog";
import {
    MAOrder,
    PostOrderInfo,
} from "../net/NetworkClient";

import { ButtonBackGround } from "../Constants";
import { CalcNextStartEndSv, PostOrderSv } from "../net/SysadAsync";

moment.tz.setDefault('Asia/Tokyo');

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

const useStyles = makeStyles((theme) => ({
    top: {
        position: "absolute",
        right: 25,
        top: 20,
    },
    historyList: {

        overflowX: "hidden",
        overflowY: "auto"
    },
    linkStyle: {
        color: ButtonBackGround
    },
    gridCenter: {
        alignSelf: "flex-start",
        fontSize: "1rem",
        height: theme.spacing(6)
    },
    divider: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1)
    },
    notation: {
        fontSize: "0.7rem",
        paddingLeft: theme.spacing(1)
    },
    textField: {
        //marginTop: theme.spacing(1),
        //marginBottom: theme.spacing(1)
    },
    postalCodeField: {
        //marginTop: theme.spacing(1),
        width: "180px"
    },
    amountTextStyle: {
        display: "flex",
        alignItems: "center",
        fontSize: "2rem",
        justifyContent: "center"
    },
    payBtn: {
        color: "white",
        backgroundColor: ButtonBackGround
    },
    actionBox: {
        width: "100%"
    },
    postalDiv: {
        display: "flex",
        alignItems: "baseline"
    }
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const SysadAddOrderDialog: FunctionComponent<{
    open: boolean;
    tenantId: string | undefined;
    srcOrder: MAOrder | undefined;
    onClose: (update: boolean) => void;
}> = ({ open, onClose, tenantId, srcOrder }) => {
    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);
    const [projectCount, setProjectCount] = useState(10);

    const [price, setPrice] = useState(10 * 1100);

    //const [order, setOrder] = React.useState<MAOrder>();

    const [startDate, setStartDate] = React.useState(moment());
    const [endDate, setEndDate] = React.useState(moment());
    const dispatch = useDispatch();




    //選択中のテナント情報が変更された
    useEffect(() => {
        if (open) {
            setLoading(true);
            if(!!srcOrder){
                var prj = 0;
                srcOrder.licenses?.map((l)=>{
                    prj += l.projectCount ?? 0;
                })
                if(prj < 10)
                    prj = 10;
                setProjectCount(prj);
                setPrice(1100*prj);
            }

            dispatch(CalcNextStartEndSv({
                tenantId:tenantId??"",
                callback: (ok, startEnd) => {
                    setLoading(false);
                    if (!ok || !startEnd) {
                        dispatch(
                            AlertDialogSlice.actions.openAlert({
                                title: "エラー",
                                message: "ライセンス時刻の計算ができませんでした",
                            })
                        );
                    } else {
                        setStartDate(moment(startEnd.startUTC))
                        setEndDate(moment(startEnd.endUTC))
                    }
                }
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const projectCountList = (): number[] => {
        var re: number[] = [];
        var i = 0;
        re[i++] = 10;
        re[i++] = 15;
        re[i++] = 20;
        re[i++] = 25;
        re[i++] = 30;
        re[i++] = 40;
        re[i++] = 50;
        re[i++] = 75;
        re[i++] = 100;

        return re;
    }


    const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const calcProjectCount = (): number => {
        if (!srcOrder || !srcOrder.licenses)
            return 10;
        var count = 0;
        srcOrder.licenses.forEach(v => {
            count += v.projectCount ?? 0;
        });

        return count;
    }

    const postOrder = () =>{
        var info = new PostOrderInfo();
        info.startUTC = new Date(startDate.startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss"));
        info.endUTC =  new Date(endDate.endOf("day").utc().format("YYYY-MM-DDTHH:mm:ss"));
        info.price = price;
        info.projectCount = projectCount;
        info.timeSpanMonth = 1;

        setLoading(true);
        dispatch(PostOrderSv({
            tenantId:tenantId??"",
            order:info,
            callback: (ok, order) => {
                setLoading(false);
                if (!ok || !order) {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "注文を登録できませんでした",
                        })
                    );
                } else {
                    onClose(true);
                }
            }
        }))
    }
    

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose(false);
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="close-dialog-title" onClose={() => {
                onClose(false);
            }}>
                注文登録
            </DialogTitle>
            <DialogContent>
                
            <Box hidden={!loading}>
                <CircularProgress style={{ color: "#1a90ff" }} size={24} />
            </Box>

                <Grid container spacing={1} className={classes.historyList}>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={6} className={classes.gridCenter}>ライセンス開始</Grid>
                    <Grid item xs={4} className={classes.gridCenter}>
                        <TextField
                            type="date"
                            value={startDate.format("YYYY-MM-DD")}
                            onChange={(e) => {
                                setStartDate(moment(e.target.value));
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={6} className={classes.gridCenter}>ライセンス終了</Grid>
                    <Grid item xs={4} className={classes.gridCenter}>
                        <TextField
                            type="date"
                            value={endDate.format("YYYY-MM-DD")}
                            onChange={(e) => {
                                setEndDate(moment(e.target.value));
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={6} className={classes.gridCenter}>最大有効プロジェクト数</Grid>
                    <Grid item xs={4} className={classes.gridCenter}>

                        <Select
                            value={projectCount}
                            onChange={(e) => {
                                var pk = parseInt("" + e.target.value);
                                setProjectCount(pk);
                                setPrice(pk * 1100);
                            }}
                        >
                            {
                                projectCountList().map((v) => (
                                    <MenuItem key={"pk" + v} value={v} selected={v === calcProjectCount()}>{v}</MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={1}></Grid>


                    <Grid item xs={1}></Grid>
                    <Grid item xs={6} className={classes.gridCenter}>料金</Grid>
                    <Grid item xs={4} className={classes.gridCenter}>
                        <TextField
                            className={classes.textField}
                            required
                            fullWidth
                            name="price"
                            value={price}
                            onChange={(ev) => {
                                var pk = parseInt(ev.target.value, 10);
                                if(!isNaN(pk))
                                    setPrice( pk );
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>

            </DialogContent>
            <DialogActions>

                <Grid container spacing={1}>


                    <Grid item xs={1}></Grid>
                    <Grid item xs={5} className={classes.amountTextStyle}><Button variant="contained" fullWidth href="#contained-buttons" className={classes.payBtn}
                        onClick={() => { onClose(false) }}>キャンセル</Button></Grid>
                    <Grid item xs={5} className={classes.amountTextStyle}><Button variant="contained" fullWidth href="#contained-buttons" className={classes.payBtn}
                        onClick={() => { postOrder() }}>登録</Button></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>


            </DialogActions>
        </Dialog>
    );
};
export default SysadAddOrderDialog;
