import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from 'react-router-dom';
import { Box, CircularProgress } from "@material-ui/core";
import { getContentsDataSv } from "../net/CalendarAsync"
import { getProjectByContentsSv, postFile, putContentsSv } from "../net/ContentsAsync"
import localFiles from "./items/LocalFiles";
import { MorningAssemblyJson, MAProject, ContentsJson } from "../net/NetworkClient";
import ContentsToolbar from "./ContentsToolbar";
import ContentsNav from "./ContentsNav";
import { FooterHeight, ItemMenuAction, ItemMenuAction_ADD, ItemTypeKey, UnregisteredUpdateURL } from "../Constants";
import { ConfirmDialogSlice } from "../misc/ConfirmDialog";
import ActionSlice, { ActionType } from "../misc/ActionSlice";
import ContentsItemConfig from "./items/ContentsItemConfig";
import ContentsItemRadioCalisthenics from "./items/ContentsItemRadioCalisthenics";
import ContentsItemWorkProcedure from "./items/ContentsItemWorkProcedure";
import ContentsItemPrecautions from "./items/ContentsItemPrecautions";
import ContentsItemRollCall from "./items/ContentsItemRollCall";
import ContentsItemSafetyCall from "./items/ContentsItemSafetyCall";
import ContentsItemKYActivity from "./items/ContentsItemKYActivity";
import { AlertDialogSlice } from "../misc/AlertDialog";
import NewCatItemDialog from "./NewCatItemDialog";
import ContentsItemSpeach from "./items/ContentsItemSpeach";
import ContentsItemText from "./items/ContentsItemText";
import ContentsItemTitleText from "./items/ContentsItemTitleText";
import ContentsItemPhotoList from "./items/ContentsItemPhotoList";
import ContentsItemQuestionnaire from "./items/ContentsItemQuestionnarie";
import NewTemplateDialog from "./NewTemplateDialog";
import { postTemplateSv } from "../net/TemplateAsync";
import PhotoSelectDialog from "./PhotoSelectDialog";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        //flexDirection: "column",
        //alignItems: "stretch",
        height: "calc(100vh - 48px - " + FooterHeight + "px)",
        backgroundColor: theme.palette.common.white
    },
    contents: {
        //flex: 1,
        //padding: theme.spacing(6, 4),
        flexGrow: 1,
        //background: "#000",
        marginTop: "49px",
        height: "calc(100vh - 97px - " + FooterHeight + "px)",
        overflow: "auto",
    },
}));

function ContentsView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { contentsid } = useParams<{ contentsid: string }>();


    const [openNavMenu, setOpenNavMenu] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [dirty, setDirty] = useState<boolean>(false);
    const [jsonData, setJsonData] = useState<MorningAssemblyJson>();
    const [project, setProject] = useState<MAProject | undefined>();
    const [selectedItem, setSelectedItem] = useState<{ key: string; id: string } | undefined>();
    const [selectedItemWaiting, setSelectedItemWaiting] = useState<{ key: string; id: string } | undefined>();

    //新規作成ダイアログ
    const [openDlgNewItem, setOpenDlgNewItem] = React.useState(false);
    const [newItemIndex, setNewItemIndex] = React.useState<number | undefined>();
    const [openDlgNewTemplate, setOpenDlgNewTemplate] = React.useState(false);


    const [changing, setChanging] = useState<boolean>(false);

    //選択中のテナント情報が変更された
    useEffect(() => {
        if (contentsid) {
            setLoading(true);
            reloadData()
                .then(() => {
                    console.log("load all data");
                })
                .catch(() => {
                    clearAll();
                })
                .finally(() => { setLoading(false); })
        } else {
            clearAll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentsid]);

    /**
     * データのリロード
     */
    async function reloadData() {

        //プロジェクトデータ
        var prj = await getProject();
        setProject(prj.project);

        //Jsonデータ取得
        var json = await getContentsData();
        setJsonData(json.json);

        //選択項目設定
        setSelectedItem({
            key: ItemTypeKey.config,
            id: "",
        });

        //ローカルファイル情報をクリア
        localFiles.clearFiles();
        //JSON設定
        setDirty(false);
    }
    /**
     * 表示データクリア
     */
    function clearAll() {
        setProject(undefined);
        setJsonData(undefined);
        setSelectedItem({
            key: ItemTypeKey.config,
            id: "",
        });
        localFiles.clearFiles();
        setDirty(false);
    }


    /**
     * プロジェクトデータ取得
     */
    async function getProject(): Promise<{ ok: boolean; project?: MAProject }> {
        return new Promise((resolve, reject) => {
            dispatch(
                getProjectByContentsSv({
                    contentsId: contentsid,
                    callback: async function (ok, prj) {
                        resolve({ ok: ok, project: prj })
                    }
                }));
        });
    }

    //コンテンツのJSONデータ取得
    async function getContentsData(): Promise<{ ok: boolean; json?: MorningAssemblyJson }> {
        return new Promise((resolve, reject) => {
            dispatch(
                getContentsDataSv({
                    contentsId: contentsid,
                    callback: async function (ok, data) {
                        resolve({ ok: ok, json: data })
                    }
                }));
        });
    }


    /**
     * JSONデータの複製を作成
     */
    const copyJson = (): MorningAssemblyJson => {
        return MorningAssemblyJson.fromJS(JSON.parse(JSON.stringify(jsonData)));
    };

    //修正が反映されるまで待機して選択項目を変更する
    function setSelectedItemWait(val: { key: string; id: string } | undefined) {
        if (changing) {
            setLoading(true);
            setSelectedItemWaiting(val);
        } else {
            setSelectedItem(val);
        }
    }
    useEffect(() => {
        if (!changing && selectedItemWaiting) {
            setSelectedItem(selectedItemWaiting);
            setSelectedItemWaiting(undefined);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changing]);

    /**
     * 各メニューなどからのアクション実行
     * @param itemId ContentsJsonのID
     * @param itemkey 種類
     * @param action 実行するアクション
     */
    function onAction(itemId: string | undefined, itemkey: ItemTypeKey | undefined, action: ItemMenuAction) {
        switch (action.key) {
            //項目を開く
            case "CLICK":
                dispatch(ActionSlice.actions.doAction({ type: ActionType.CONTENT_ITEM_LOAD_START}));
                setSelectedItemWait({
                    key: itemkey?.toString() ?? "",
                    id: itemId ?? "",
                });
                break;

            //新規追加
            case "ADD":
                if (itemId !== undefined) {
                    var index =
                        jsonData?.contents?.findIndex((c) => {
                            return c.uuid === itemId;
                        });
                    if (itemId !== undefined && index !== undefined)
                        index += 1;
                    setNewItemIndex(index);
                } else {
                    setNewItemIndex(undefined);
                }
                setOpenDlgNewItem(true);
                break;

            //最上段へ移動
            case "UP_FIRST":
                var json = copyJson();
                var target = json.contents?.find((c) => {
                    return c.uuid === itemId;
                });
                if (target) {
                    var filtered =
                        json.contents?.filter((c) => {
                            return c.uuid !== itemId;
                        }) ?? [];
                    json.contents = [target, ...filtered];
                    setJsonData(json);
                    setDirty(true);
                }
                break;

            //1つ上へ移動
            case "UP":
                json = copyJson();
                index =
                    json.contents?.findIndex((c) => {
                        return c.uuid === itemId;
                    });
                if (index) {
                    const item = json.contents?.splice(index, 1)[0];
                    if (item) {
                        json.contents?.splice(index - 1, 0, item);
                        setJsonData(json);
                        setDirty(true);
                    }
                }
                break;

            //１つ下へ移動
            case "DOWN":
                json = copyJson();
                index =
                    json.contents?.findIndex((c) => {
                        return c.uuid === itemId;
                    });
                if (index) {
                    const item = json.contents?.splice(index, 1)[0];
                    if (item) {
                        json.contents?.splice(index + 1, 0, item);
                        setJsonData(json);
                        setDirty(true);
                    }
                }
                break;

            //最下段へ移動
            case "DOWN_LAST":
                json = copyJson();
                target = json.contents?.find((c) => {
                    return c.uuid === itemId;
                });
                if (target) {
                    filtered =
                        json.contents?.filter((c) => {
                            return c.uuid !== itemId;
                        }) ?? [];
                    json.contents = [...filtered, target];
                    setJsonData(json);
                    setDirty(true);
                }
                break;

            //削除
            case "DELETE":
                target = jsonData?.contents?.find((c) => {
                    return c.uuid === itemId;
                });
                if (target) {
                    dispatch(
                        ConfirmDialogSlice.actions.openConfirm({
                            title: "削除の確認",
                            message:
                                "本当に「" + target.title + "」を削除してもよろしいですか？",
                            callback: (ok) => {
                                if (ok) {
                                    //項目削除
                                    dispatch(ActionSlice.actions.clearAction(undefined));
                                    var uuid = itemId;
                                    var json = copyJson();
                                    //現在選択中のインデックス取得
                                    var idx = -1;
                                    json.contents?.forEach((c, n) => {
                                        if (c.uuid === uuid) idx = n;
                                    });
                                    //データ削除
                                    json.contents =
                                        json.contents?.filter((c) => {
                                            return c.uuid !== uuid;
                                        }) ?? [];
                                    setJsonData(json);
                                    setDirty(true);

                                    if (json.contents.length > 0 && json.contents.length > idx) {
                                        const cnt = json.contents[idx];

                                        setSelectedItem({ key: cnt.type ?? "", id: cnt.uuid ?? "", });
                                    } else {
                                        setSelectedItem({ key: ItemTypeKey.config, id: "", });
                                    }
                                }
                            }
                        })
                    );
                }
                break;
        }
    }


    /**
     * 1項目を更新する
     * @param contents 更新するコンテンツ
     */
    function UpdateContents(contents: ContentsJson) {
        var json = copyJson();
        json.contents = json.contents?.map((v) => {
            if (v.uuid === contents.uuid)
                return contents;
            else
                return v;
        });
        setJsonData(json);
        setDirty(true);
    }

    /**
     * テンプレート保存
     */
    function onSaveTemplate(name: string) {
        if (dirty) {
            onSave((ok)=>{
                onSaveTemplateInner(name);
            },true);
        }else{
            onSaveTemplateInner(name);
        }
    }

    function onSaveTemplateInner(name: string) {
        
        dispatch(postTemplateSv({
            tenantId: project?.tenantId ?? "", contentsId: contentsid, name: name, callback: (ok, template) => {
                if (ok) {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "保存完了",
                            message: "「"+template?.name+"」を保存しましました。",
                        })
                    );
                } else {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "テンプレートの保存に失敗しました。",
                        })
                    );
                }
            }
        }));
    }

    //Jsonデータをサーバへ保存
    async function onSave(callback?:(ok:boolean)=>void,withoutUI: boolean = false) {
        if (contentsid && jsonData) {
            setLoading(true);
            var json = await uploadAllTempFiles();

            if (json) {
                //Newsの空行削除
                if(!!json.news?.content){
                    json.news.content = json.news?.content.filter((v)=>{return !!v})
                }
                //画像がない写真項目を削除
                json?.contents?.forEach(cts => {
                    if(cts.details?.updates && cts.details?.updates.length > 0){
                        cts.details.updates = cts.details.updates.filter((u)=>{return !!u.url;})
                    }
                });



                dispatch(
                    putContentsSv({
                        id: contentsid,
                        data: json,
                        callback: (ok) => {
                            setLoading(false);
                            if (ok) {
                                if (!withoutUI) {
                                    dispatch(
                                        AlertDialogSlice.actions.openAlert({
                                            title: "保存完了",
                                            message: "サーバへデータを保存しました",
                                        })
                                    );
                                }
                                setDirty(false);
                                reloadData();
                                if(callback)
                                    callback(true);
                            } else {
                                dispatch(
                                    AlertDialogSlice.actions.openAlert({
                                        title: "エラー",
                                        message: "データの保存に失敗しました。",
                                    })
                                );
                                
                                if(callback)
                                    callback(false);
                            }
                        },
                    })
                );
            } else {
                setLoading(false);
                dispatch(
                    AlertDialogSlice.actions.openAlert({
                        title: "エラー",
                        message: "ファイルのアップロードに失敗しました。",
                    })
                );
                
                if(callback)
                    callback(false);
            }
        }else{
            if(callback)
                    callback(false);
        }
    }
    /**
     * アップロード待ちのファイルを全てサーバへアップロードする
     */
    async function uploadAllTempFiles(): Promise<MorningAssemblyJson | undefined> {
        var copy = copyJson();
        var updata = false;
        if (!copy.contents) return jsonData;
        for (const cnt of copy.contents) {
            if (cnt.details?.updates) {
                for (const up of cnt.details?.updates) {
                    if (up.url === UnregisteredUpdateURL) {
                        const browserFile = localFiles.getFile(up.uuid ?? "");
                        if (browserFile) {
                            var re = await postFile(
                                browserFile.file,
                                contentsid,
                                ItemTypeKey.siteUpdates,
                                cnt.uuid ?? "",
                                up.sizeType ?? 0,
                                dispatch
                            );
                            if (re.ok && re.file) {
                                up.url = re.file.path;
                                updata = true;
                            } else {
                                return undefined;
                            }
                        }
                    }
                }
            }
        }
        if (updata)
            setJsonData(copy);
        return updata ? copy : jsonData;
    }

    /**
     * カレンダーへ戻るボタンが押された
     */
    function onBackCalendar() {
        if (!dirty) {
            history.push("/calendar/" + project?.tenantId + "/" + project?.id);
        } else {
            //OKの場合の複製処理はContentsListCalendar内で処理
            dispatch(
                ConfirmDialogSlice.actions.openConfirm({
                    title: "保存の確認",
                    message: "修正したデータを保存せずにカレンダーへ戻ってもよろしいですか？",
                    callback: (ok) => {
                        if (ok) {
                            history.push("/calendar/" + project?.tenantId + "/" + project?.id);
                            dispatch(ActionSlice.actions.clearAction(undefined));
                        }
                    }
                })
            );
        }
    }

    return (
        <Box className={classes.root}>
            <ContentsToolbar dirty={dirty}
                onSave={() => {
                    onSave();
                }}
                onMenuToggle={() => {
                    setOpenNavMenu(!openNavMenu);
                }}
                onAddItem={() => { onAction(undefined, undefined, ItemMenuAction_ADD) }}
                onSaveTemplate={() => {
                    setOpenDlgNewTemplate(true);
                }}
            />
            <ContentsNav
                open={openNavMenu}
                jsonData={jsonData}
                selectedItem={selectedItem}
                project={project}
                onClose={() => { setOpenNavMenu(false) }}
                onBackCalendar={onBackCalendar}
                onAction={onAction} />
            <Box className={classes.contents} style={{ position: "relative" }}>
                <Box style={{ position: "absolute", right: "16px", top: "8pt" }} hidden={!loading}>
                    <CircularProgress style={{ color: "#1a90ff" }} size={30} />
                </Box>
                <ContentsItemConfig
                    jsonData={jsonData}
                    selectedItem={selectedItem}
                    onSetJson={(json) => {
                        setJsonData(json);
                        setDirty(true);
                    }}
                    onChanging={(c) => { setChanging(c); }}
                />
                <ContentsItemRadioCalisthenics
                    selectedItem={selectedItem}
                />
                <ContentsItemWorkProcedure
                    jsonData={jsonData}
                    selectedItem={selectedItem}
                    onUpdate={UpdateContents}
                    onChanging={(c) => { setChanging(c); }}
                />
                <ContentsItemPrecautions
                    jsonData={jsonData}
                    selectedItem={selectedItem}
                    onUpdate={UpdateContents}
                    onChanging={(c) => { setChanging(c); }}
                />
                {
                    /*<ContentsItemSiteUpdates
                        jsonData={jsonData}
                        selectedItem={selectedItem}
                        onUpdate={UpdateContents}
                        onChanging={(c) => { setChanging(c); }}
                    />*/
                }
                <ContentsItemRollCall
                    contentsId={contentsid}
                    jsonData={jsonData}
                    selectedItem={selectedItem}
                    onUpdate={UpdateContents}
                    onChanging={(c) => { setChanging(c); }}
                />

                <ContentsItemSafetyCall
                    jsonData={jsonData}
                    selectedItem={selectedItem}
                    onUpdate={UpdateContents}
                    onChanging={(c) => { setChanging(c); }}
                />

                <ContentsItemKYActivity
                    contentsId={contentsid}
                    jsonData={jsonData}
                    selectedItem={selectedItem}
                    onUpdate={UpdateContents}
                    onChanging={(c) => { setChanging(c); }}
                />

                <ContentsItemSpeach
                    contentsid={contentsid}
                    jsonData={jsonData}
                    selectedItem={selectedItem}
                    onUpdate={UpdateContents}
                    onChanging={(c) => { setChanging(c); }}
                />
                <ContentsItemText
                    contentsid={contentsid}
                    jsonData={jsonData}
                    selectedItem={selectedItem}
                    onUpdate={UpdateContents}
                    onChanging={(c) => { setChanging(c); }}
                />

                <ContentsItemTitleText
                    contentsid={contentsid}
                    jsonData={jsonData}
                    selectedItem={selectedItem}
                    onUpdate={UpdateContents}
                    onChanging={(c) => { setChanging(c); }}
                />

                <ContentsItemPhotoList
                    contentsid={contentsid}
                    jsonData={jsonData}
                    selectedItem={selectedItem}
                    onUpdate={UpdateContents}
                    onChanging={(c) => { setChanging(c); }}
                />

                <ContentsItemQuestionnaire
                    contentsid={contentsid}
                    jsonData={jsonData}
                    selectedItem={selectedItem}
                    onUpdate={UpdateContents}
                    onChanging={(c) => { setChanging(c); }}
                />

            </Box>
            <NewCatItemDialog
                open={openDlgNewItem}
                tenantId={project?.tenantId ?? ""}
                onClose={(ok, item) => {
                    setOpenDlgNewItem(false);
                    if (ok && item) {
                        var json = copyJson();
                        if (newItemIndex !== undefined && (json.contents?.length ?? 0) > newItemIndex) {
                            json.contents?.splice(newItemIndex, 0, item);
                            setJsonData(json);
                            setDirty(true);
                        } else {
                            json.contents = [...json.contents ?? [], item];
                            setJsonData(json);
                            setDirty(true);
                        }
                    }
                }} />
            <NewTemplateDialog
                open={openDlgNewTemplate}
                tenantId={project?.tenantId ?? ""}
                dirty={dirty}
                onClose={(ok, name) => {
                    setOpenDlgNewTemplate(false);
                    if(ok){
                        onSaveTemplate(name);
                    }
                }} />
            <PhotoSelectDialog/>
        </Box>
    );
}


export default ContentsView;