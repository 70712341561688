
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store";
import TenantSlice from "./TenantSlice";
import { Box, Grid, Button, Card, CardHeader, CardActions, InputAdornment, TextField, FormControlLabel, Switch, CardContent, Typography, Link as MLink, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    ButtonBackGround,
    FooterHeight,
    ProjectKeyToString,
} from "../Constants";
import { ConfirmDialogSlice } from "../misc/ConfirmDialog";
import ActionSlice, { ActionType } from "../misc/ActionSlice";
import { findProjectsSv, deleteProjectSv, setProjectInvalidSv, getProjectSv, getTenantSv, /*addInitialProjectSv,*/ canIncrementActiveProject } from "../net/TenantAsync";
import { AlertDialogSlice } from '../misc/AlertDialog';
import PlaceIcon from '@material-ui/icons/Place';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TodayIcon from '@material-ui/icons/Today';
import GroupIcon from '@material-ui/icons/Group';
import ProjectCreateDialog from "./ProjectCreateDialog";
import ProjectAdminDialog from "./ProjectAdminDialog";
import ProjectEditDialog from "./ProjectEditDialog";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SearchIcon from '@material-ui/icons/Search';
import { useDebounce } from "use-debounce";

import moment from "moment-timezone"
import { MAProject } from '../net/NetworkClient';
import TenantToolbar from './TenantToolbar';


moment.tz.setDefault('Asia/Tokyo');

const useStyles = makeStyles((theme) => ({

    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "calc(100vh - 48px - " + FooterHeight + "px)",
    },
    contents: {
        flex: 1,
        height: "100%",
        overflow: "auto",
        padding: theme.spacing(0, 1),
    },
    listLabel: {
        marginTop: "auto",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            display: "none"
        }
    },
    validOnlyBtn: {
        marginRight: theme.spacing(1),
        marginTop: 14
    },
    searchBtn: {
        marginLeft: theme.spacing(1),
        marginTop: 18
    },
    addBtn: {
        marginRight: theme.spacing(1),
        backgroundColor: ButtonBackGround,
        marginTop: 12,
        marginLeft: "auto",
    },
    subBtn: {
        marginRight: theme.spacing(1),
        backgroundColor: ButtonBackGround,
        marginTop: 12
    },
    deleteMenuItem: {
        color: theme.palette.error.main
    },
    activeIcon: {
        color: ButtonBackGround
    },
    deactiveIcon: {
        color: theme.palette.text.disabled
    },
    hideDownXs: {
        [theme.breakpoints.down("xs")]: {
            display: "none"
        },
    },
    cardTitle: {
        color: theme.palette.text.primary,
        cursor: "pointer",
    },
    cardContent: {
        paddingLeft: theme.spacing(4),
        paddingTop: 0,
        paddingBottom: 0,
    },
    cardText: {
        fontSize: "0.8rem",
    },
    cardButton: {
        fontSize: "0.8rem",
    }

}));

function TenantView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    //ログイン中のユーザー情報
    const user = useSelector(
        (state: RootState) => state.auth.user
    );

    //テナント一覧
    const tenants = useSelector(
        (state: RootState) => state.tenants.tenants
    );
    //選択中のテナント
    const currentTenant = useSelector(
        (state: RootState) => state.tenants.currentTenant
    )
    //選択中のプロジェクト
    const currentProjects = useSelector(
        (state: RootState) => state.tenants.projects
    );


    const { tenantid } = useParams<{ tenantid: string }>();
    //const [currentTenant, setCurrentTenant] = React.useState<TenantInfo>();
    const [loading, setLoading] = React.useState(false);
    const [openCreateProjectDialog, setOpenCreateProjectDialog] = React.useState(false);
    const [openProjectAdminDialog, setOpenProjectAdminDialog] = React.useState(false);
    const [openProjectEditDialog, setOpenProjectEditDialog] = React.useState(false);
    const [projectIdForDialog, setProjectIdForDialog] = React.useState<string>("");


    const [searchWord, setSearchWord] = React.useState<string>("");
    const [searchInvalid, setSearchInvalid] = React.useState(false);

    //表示中のテナントが変更された場合
    useEffect(() => {
        if (!!currentTenant) {
            refreshProjectList();
        } else {
            setSearchWord("");
            setSearchInvalid(false);
            dispatch(TenantSlice.actions.setProjects([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTenant]);



    //OnChangeの度に検索すると処理が重たいので、debounce処理
    const [debouncedData] = useDebounce(searchWord, 500);
    useEffect(() => {
        //検索条件が変更された
        refreshProjectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedData, searchInvalid]);

    function refreshTenantInfo() {
        dispatch(getTenantSv({
            tenantId: currentTenant?.tenant?.id ?? "", callback: (ok, retenant) => {
                if (!ok || retenant == null) {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "会社情報を取得できませんでした。",
                        })
                    );
                } else {
                    dispatch(
                        TenantSlice.actions.updateTenants(retenant)
                    );
                }
            }
        }))
    }


    function refreshProjectList() {
        if (!currentTenant?.tenant?.id)
            return;
        setLoading(true);
        dispatch(findProjectsSv({
            tenantId: currentTenant.tenant?.id,
            searchName: searchWord,
            searchInvalid: searchInvalid,
            callback: (ok, prjinfos) => {
                if (ok && prjinfos) {
                    dispatch(TenantSlice.actions.setProjects(prjinfos));
                } else {
                    dispatch(TenantSlice.actions.setProjects([]));
                    if (!ok) {
                        dispatch(
                            AlertDialogSlice.actions.openAlert({
                                title: "エラー",
                                message: "プロジェクト一覧情報を取得できませんでした。",
                            })
                        );
                    }
                }
                setLoading(false);
            }
        }));
    }


    //アクション処理
    const actionMessage = useSelector((state: RootState) => state.action.action);
    useEffect(() => {
        switch (actionMessage.type) {
            case ActionType.EDIT_PROJECT_IN_TENANT: {
                //プロジェクト編集が押された
                const pid = actionMessage.value ?? "";
                setProjectIdForDialog(pid);
                setOpenProjectEditDialog(true);
                dispatch(ActionSlice.actions.clearAction(undefined));
            } break;
            case ActionType.SET_PROJECT_ADMIN_IN_TENANT: {
                //管理者設定が押された
                const pid = actionMessage.value ?? "";
                setProjectIdForDialog(pid);
                setOpenProjectAdminDialog(true);
                dispatch(ActionSlice.actions.clearAction(undefined));
            } break;
            case ActionType.VALIDATE_PROJECT_IN_TENANT: {
                //利用可能にするボタンが押された
                const pid = actionMessage.value ?? "";
                dispatch(setProjectInvalidSv({
                    tenantId: currentTenant?.tenant?.id ?? "",
                    projectId: pid,
                    invalid: false,
                    callback(ok, error) {
                        if (!ok) {
                            dispatch(canIncrementActiveProject({
                                tenantId:currentTenant?.tenant?.id ?? "",
                                callback:(canIncrease)=>{
                                  if (!canIncrease) {
                                    dispatch(
                                      AlertDialogSlice.actions.openAlert({
                                        title: "利用上限エラー",
                                        message: "すでに利用上限に達しているのでいずれかのプロジェクトを利用停止にするか、契約プロジェクト数を増やしてください。",
                                      })
                                    );
                                  }else{
                                    dispatch(
                                      AlertDialogSlice.actions.openAlert({
                                        title: "エラー",
                                        message: "プロジェクトを利用可能にできませんでした。",
                                      })
                                    );
                                  }
                                }
                              }))
                        } else {
                            dispatch(getProjectSv({
                                tenantId: currentTenant?.tenant?.id ?? "", projectId: pid, callback(ok2, sp) {
                                    if (ok2 && sp)
                                        dispatch(TenantSlice.actions.updateProject(sp));
                                }
                            }));
                            refreshTenantInfo();
                        }
                    }
                }));
                dispatch(ActionSlice.actions.clearAction(undefined));
            } break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionMessage]);

    //選択中のテナント情報が変更された
    useEffect(() => {
        if (!!tenantid) {
            var cur = tenants.find(t => { return tenantid === t.tenant?.id });
            dispatch(TenantSlice.actions.setCurrent(cur ?? null));
        } else {
            dispatch(TenantSlice.actions.setCurrent(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantid, tenants]);

    function onCalendar(prj: MAProject | undefined) {
        history.push("/calendar/" + tenantid + "/" + prj?.id);
    }
    /**
     * 管理者設定を押された
     */
    function onSetProjectAdmin(prj: MAProject | undefined) {
        dispatch(ActionSlice.actions.doAction({ type: ActionType.SET_PROJECT_ADMIN_IN_TENANT, value: prj?.id }));
    }

    //編集メニューが押された
    function onEditAction(prj: MAProject | undefined) {
        dispatch(ActionSlice.actions.doAction({ type: ActionType.EDIT_PROJECT_IN_TENANT, value: prj?.id }));
    }
    //プロジェクトを利用停止する
    function onInvalidate(prj: MAProject | undefined) {
        dispatch(
            ConfirmDialogSlice.actions.openConfirm({
                title: "利用停止の確認",
                message:
                    "本当に「" + prj?.name + "」の利用を停止してもよろしいですか？利用再開するまで端末での利用ができません。",
                callback: (inok) => {
                    if (inok) {
                        dispatch(setProjectInvalidSv({
                            tenantId: currentTenant?.tenant?.id ?? "",
                            projectId: prj?.id ?? "",
                            invalid: true,
                            callback(ok) {
                                if (!ok) {
                                    dispatch(
                                        AlertDialogSlice.actions.openAlert({
                                            title: "エラー",
                                            message: "プロジェクトを利用停止できませんでした。",
                                        })
                                    );
                                } else {
                                    dispatch(getProjectSv({
                                        tenantId: currentTenant?.tenant?.id ?? "", projectId: prj?.id ?? "", callback(ok2, sp) {
                                            if (ok && sp)
                                                dispatch(TenantSlice.actions.updateProject(sp));
                                        }
                                    }));
                                    refreshTenantInfo();
                                }
                            }
                        }));
                        dispatch(ActionSlice.actions.clearAction(undefined));
                    }
                }
            })
        );
    }
    //プロジェクトを利用再開する
    function onValidate(prj: MAProject | undefined) {
        dispatch(ActionSlice.actions.doAction({ type: ActionType.VALIDATE_PROJECT_IN_TENANT, value: prj?.id }));
    }

    //削除メニューが押された。確認ダイアログを表示。
    function onDeleteAction(prj: MAProject | undefined) {
        dispatch(
            ConfirmDialogSlice.actions.openConfirm({
                title: "削除の確認",
                message:
                    "本当に「" + prj?.name + "」を削除してもよろしいですか？登録済みの朝礼データなども削除され、復旧することはできません。",
                callback: (inok) => {
                    if (inok) {
                        dispatch(deleteProjectSv({
                            tenantId: currentTenant?.tenant?.id ?? "",
                            projectId: prj?.id ?? "",
                            callback(ok) {
                                if (!ok) {
                                    dispatch(
                                        AlertDialogSlice.actions.openAlert({
                                            title: "エラー",
                                            message: "プロジェクトを削除できませんでした。",
                                        })
                                    );
                                } else {
                                    dispatch(TenantSlice.actions.removeProject(prj?.id ?? ""));
                                }
                            }
                        }));
                        dispatch(ActionSlice.actions.clearAction(undefined));
                    }
                }
            })
        );
    }
    if (!user)
        return null;


    return (
        <Box className={classes.root}>
            <TenantToolbar loading={loading} tenant={currentTenant} />

            <Box className={classes.contents}>
                <Grid container spacing={1} >
                    <Grid item xs={12} style={{ padding: 0, display: "flex", marginBottom: "8px" }} >

                        <TextField
                            className={classes.searchBtn}
                            id="searchInput"
                            label=""
                            placeholder="名称で検索"
                            value={searchWord}
                            onChange={(e) => { setSearchWord(e.target.value) }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />


                        <FormControlLabel
                            className={classes.validOnlyBtn}
                            control={
                                <Switch
                                    checked={!searchInvalid}
                                    onChange={(e) => { setSearchInvalid(!e.target.checked) }}
                                    color="secondary"
                                />
                            }
                            label="利用中のみ"
                        />
                        {/*
                        <Button variant="contained" color="primary" className={classes.subBtn}
                            onClick={() => {
                                dispatch(addInitialProjectSv({
                                    tenantId:currentTenant?.tenant?.id ?? "",type:"construction",callback:(ok) => {
                                        refreshProjectList();
                                    }})
                                );
                            }}>
                            <span className={classes.hideDownXs}>初プ（建築）</span>
                        </Button>
                        <Button variant="contained" color="primary" className={classes.subBtn}
                            onClick={() => {
                                dispatch(addInitialProjectSv({
                                    tenantId:currentTenant?.tenant?.id ?? "",type:"general",callback:(ok) => {
                                        refreshProjectList();
                                    }})
                                );
                            }}>
                            <span className={classes.hideDownXs}>初プ（一般）</span>
                        </Button>
                        */}
                        <Button variant="contained" color="primary" aria-label="add project" className={classes.addBtn}
                            onClick={() => {
                                setOpenCreateProjectDialog(true);
                            }}>
                            <AddIcon /><span className={classes.hideDownXs}>プロジェクトを追加</span>
                        </Button>
                        
                    </Grid>
                    {currentProjects.map((prj, idx) => {
                        return (
                            <Grid item xs={12} key={prj.project?.id ?? ""}>
                                <Card >
                                    <CardHeader
                                        avatar={
                                            <PlaceIcon className={prj.project?.invalid ? classes.deactiveIcon : classes.activeIcon} />
                                        }

                                        title={<MLink onClick={() => { onCalendar(prj.project) }} underline="always" className={classes.cardTitle} >{prj.project?.name + " (" + ProjectKeyToString(prj.project, " / ") + ")"}</MLink>}
                                        subheader={moment(prj.project?.updatedUTC).format("YYYY/MM/DD HH:mm:ss")}
                                    />
                                    <CardContent className={classes.cardContent}>
                                        <Typography className={classes.cardText} style={{ display: !prj.project?.description ? "none" : "inherit" }} gutterBottom>
                                            {prj.project?.description}
                                        </Typography>
                                        <Typography className={classes.cardText} style={{ display: !prj.project?.managementNumber ? "none" : "inherit" }} >
                                            {prj.project?.managementNumber}
                                        </Typography>
                                        <Typography className={classes.cardText} >
                                            リーダー:{
                                                [...(prj.projectAdmins ?? []), ...(prj.tenantAdmins ?? [])]?.map((user => {
                                                    return user.name + " ";
                                                }))
                                            }
                                        </Typography>
                                    </CardContent>
                                    <CardActions disableSpacing>
                                        <Box style={{ marginLeft: "auto" }} hidden={prj.project?.invalid}>
                                            <Tooltip title={"プロジェクトリーダー設定"}>
                                                <Button
                                                    className={classes.cardButton}
                                                    onClick={() => { onSetProjectAdmin(prj.project) }}>
                                                    <GroupIcon /><span className={classes.hideDownXs}>リーダー設定</span>
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title={"プロジェクト情報を編集"}>
                                                <Button
                                                    className={classes.cardButton}
                                                    onClick={() => { onEditAction(prj.project) }}>
                                                    <EditIcon /><span className={classes.hideDownXs}>編集</span>
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title={"利用停止"}>
                                                <Button
                                                    className={classes.cardButton}
                                                    onClick={() => { onInvalidate(prj.project) }}>
                                                    <HighlightOffIcon /><span className={classes.hideDownXs}>利用停止</span>
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                        <Box style={{ marginLeft: "auto" }} hidden={!prj.project?.invalid}>
                                            <Tooltip title={"端末での利用を再開"}>
                                                <Button
                                                    className={classes.cardButton}
                                                    onClick={() => { onValidate(prj.project) }}>
                                                    <CheckCircleIcon /><span className={classes.hideDownXs}>利用再開</span>
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title={"データを削除"}>
                                                <Button
                                                    className={classes.cardButton}
                                                    onClick={() => { onDeleteAction(prj.project) }}>
                                                    <DeleteIcon /><span className={classes.hideDownXs}>削除</span>
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                        <Tooltip title={"カレンダーへ移動"}>
                                            <Button
                                                className={classes.cardButton}
                                                onClick={() => { onCalendar(prj.project) }}>
                                                <TodayIcon /><span className={classes.hideDownXs}>カレンダー</span>
                                            </Button>
                                        </Tooltip>
                                    </CardActions>
                                </Card>
                            </Grid>

                        )
                    })}

                </Grid>
            </Box>
            <ProjectCreateDialog
                open={openCreateProjectDialog}
                tenantId={currentTenant?.tenant?.id ?? ""}
                activeProject={currentTenant?.active ?? 0}
                maxProject={currentTenant?.tenant?.maxProject ?? 0}
                obayashi={currentTenant?.obayashi ?? false}
                onClose={(ok, prj) => {
                    setOpenCreateProjectDialog(false);
                    if (ok && prj) {
                        dispatch(TenantSlice.actions.addProject(prj));
                        refreshTenantInfo();
                    }
                }}
            />
            <ProjectAdminDialog
                open={openProjectAdminDialog}
                tenantId={currentTenant?.tenant?.id ?? ""}
                projectId={projectIdForDialog}
                onClose={() => {
                    setOpenProjectAdminDialog(false);
                }}
            />
            <ProjectEditDialog
                open={openProjectEditDialog}
                tenantId={currentTenant?.tenant?.id ?? ""}
                projectId={projectIdForDialog}
                activeProject={currentTenant?.active ?? 0}
                maxProject={currentTenant?.tenant?.maxProject ?? 0}
                obayashi={currentTenant?.obayashi ?? false}
                onClose={(ok, project) => {
                    setOpenProjectEditDialog(false);
                    if (ok && project) {
                        dispatch(TenantSlice.actions.updateProject(project));
                        refreshTenantInfo();
                    }
                }} />
        </Box>
    )
}


export default TenantView;

