import React, { FunctionComponent } from "react";

import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {
  MorningAssemblyJson,
  ContentsJson,
  DetailJson,
  NewsJson,
} from "../net/NetworkClient";
import TodayIcon from "@material-ui/icons/Today";
import FaceIcon from "@material-ui/icons/Face";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import moment from "moment-timezone";
import PlaceIcon from '@material-ui/icons/Place';

import { GetItemKeyIcon, stringToItemTypeKey } from "../Constants";

moment.tz.setDefault('Asia/Tokyo');

var classNames = require("classnames");

const useStyles = makeStyles((theme) => ({
  news: {
    paddingLeft: theme.spacing(2),
    fontSize: "0.8rem",
  },
  precaution: {
    paddingLeft: theme.spacing(2),
    fontSize: "0.8rem",
  },
  procedureBox: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  procedure: {
    fontSize: "0.8rem",
  },
  procedureItem: {
    paddingLeft: theme.spacing(3),
    fontSize: "0.8rem",
  },
  procedureSummary: {
    maxHeight: "30px",
    minHeight: "30px",
  },
  boxTop: {
    paddingTop: "10px",
  },
  boxBase: {
    paddingLeft: "33px",
  },
  boxContainer: {
    position: "relative",
  },
  boxIcon: {
    position: "absolute",
    top: "5px",
    left: "5px",
  },
  boxItem: {
    paddingLeft: "46px",
  },
  procLabel: {
    display: "inline-block",
    width: "110px",
  },
  textContents: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
}));

/**
 *ContentsSimpleViewer用プロパティ
 *
 * @export
 * @interface ContentsSimpleViewerProps
 */
export interface ContentsSimpleViewerProps {
  /**
   *JSONデータ
   *
   * @type {MorningAssemblyJson}
   * @memberof ContentsSimpleViewerProps
   */
  data?: MorningAssemblyJson;

  /**
   *プロジェクトや開催日などを表示するかどうかのフラグ
   *
   * @type {boolean}
   * @memberof ContentsSimpleViewerProps
   */
  contentsOnly: boolean;
}
const News: FunctionComponent<{ data?: NewsJson }> = ({ data = undefined }) => {
  const classes = useStyles();
  if (!data) return null;
  return (
    <Box className={classes.boxContainer}>
      <NotificationsNoneIcon className={classes.boxIcon} />
      <Box className={classNames(classes.boxBase, classes.boxTop)}>
        今日のお知らせ
      </Box>
      {data.content?.map((val, index) => {
        return (
          <Box key={data.uuid + "-" + index} className={classes.boxItem}>
            {val}
          </Box>
        );
      })}
    </Box>
  );
};
const Content: FunctionComponent<{ index: number; data: ContentsJson }> = ({
  index,
  data,
}) => {
  const classes = useStyles();
  const key = stringToItemTypeKey(data.type ?? "");
  //const type = getItemType(key);
  const itemIcon = GetItemKeyIcon(key);

  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.boxIcon}>{itemIcon}</Box>
      <Box className={classNames(classes.boxBase, classes.boxTop)}>
        {data.title}
      </Box>
      <Details detail={data.details} uuid={data.uuid ?? ""} />
    </Box>
  );
};
const Details: FunctionComponent<{ detail?: DetailJson, uuid: string }> = ({
  detail = undefined,
  uuid = "",
}) => {
  const classes = useStyles();
  if (!detail) return null;

  return (
    <Box key={uuid}>
      {detail?.procedures?.map((proc) => {
        return (
          <Box key={proc.uuid}>
            <Box className={classNames(classes.boxItem, classes.boxTop)}>
              <span className={classes.procLabel}>工種</span>
              {proc.work}
            </Box>
            <Box className={classes.boxItem}>
              <span className={classes.procLabel}>作業内容</span>
              {proc.content}
            </Box>
            <Box className={classes.boxItem}>
              <span className={classes.procLabel}>協力会社</span>
              {proc.company}
            </Box>
            <Box className={classes.boxItem}>
              <span className={classes.procLabel}>危険有害要因</span>
              {proc.hazardFactor}
            </Box>
            <Box className={classes.boxItem}>
              <span className={classes.procLabel}>対策</span>
              {proc.counterplan}
            </Box>
          </Box>
        );
      })}
      {detail?.precautions?.map((prec) => {
        return (
          <Box key={prec.uuid} className={classes.boxItem}>
            {prec.item}
          </Box>
        );
      })}
      {detail?.updates?.map((file) => {
        return (
          <Box key={file.uuid} className={classes.boxItem}>
            {file.title}
          </Box>
        );
      })}
      {detail?.speakers?.map((speaker) => {
        return (
          <Box key={speaker.uuid} className={classes.boxItem}>
            {speaker.name}
          </Box>
        );
      })}
      {detail?.texts?.map((txt) => (
        <Box key={txt.uuid} className={classes.boxItem}>
          {txt.lines?.map((l, idx) => (
            <Box key={(txt.uuid ?? "x") +"_idx"+ idx} className={classes.textContents}>
              {l.title + " : " +l.text}
            </Box>
          ))}
        </Box>
      )
      )}
      {detail?.questions?.map((q) => (
        <Box key={q.uuid} className={classes.boxItem}>
          {q.question}
        </Box>
      ))}

      {detail?.call ? (
        <Box className={classes.boxItem}>{detail?.call}</Box>
      ) : null}
      {detail?.response ? (
        <Box className={classes.boxItem}>{detail?.response}</Box>
      ) : null}
    </Box>
  );
};
/**
 *コンテンツJSONデータを解析して、内容の一覧を表示するコンポーネント
 *
 * @param {ContentsSimpleViewerProps} {
 *   data,
 *   contentsOnly = false,
 * }
 * @returns　コンポーネント
 */
function ContentsSimpleViewer({
  data,
  contentsOnly = false,
}: ContentsSimpleViewerProps) {
  const classes = useStyles();
  return (
    <Box display={data === undefined ? "none" : "block"}>
      <Box
        display={contentsOnly ? "none" : "block"}
        className={classes.boxContainer}
      >
        <TodayIcon className={classes.boxIcon} />
        <Box
          display={!data?.start ? "none" : "block"}
          className={classNames(classes.boxBase, classes.boxTop)}
        >
          {moment(data?.start).format("Y/M/D")}
        </Box>
        <Box
          display={!data?.start ? "none" : "block"}
          className={classes.boxBase}
        >
          {moment(data?.start).format("H:mm")}～{moment(data?.end).format("H:mm")}
        </Box>
      </Box>
      <Box
        display={(contentsOnly ||  !data?.leader)? "none" : "block"}
        className={classes.boxContainer}
      >
        <FaceIcon className={classes.boxIcon} />
        <Box
          display={!data?.leader ? "none" : "block"}
          className={classNames(classes.boxBase, classes.boxTop)}
        >
          {data?.leader}
        </Box>
      </Box>

      <Box
        display={(contentsOnly || !data?.place)? "none" : "block"}
        className={classes.boxContainer}
      >
        <PlaceIcon className={classes.boxIcon} />
        <Box
          display={!data?.place ? "none" : "block"}
          className={classNames(classes.boxBase, classes.boxTop)}
        >
          {data?.place}
        </Box>
      </Box>

      <News data={data?.news}></News>

      <Box display={data?.contents === undefined ? "none" : "block"}>
        {data?.contents?.map((cnt, index) => (
          <Content key={cnt.uuid} index={index + 1} data={cnt}></Content>
        ))}
      </Box>
    </Box>
  );
}

export default ContentsSimpleViewer;
