import moment, { Moment } from "moment-timezone";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { MAContents } from "../net/NetworkClient";
import {
    Box,
    Button,
    makeStyles,
    TableCell,
    Typography,
    IconButton,
    Popover
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useDrag, useDrop, DragSourceMonitor } from "react-dnd";
import ActionSlice, { ActionType } from "../misc/ActionSlice";
import { ConfirmDialogSlice } from "../misc/ConfirmDialog";
import { ButtonBackGround } from "../Constants";
moment.tz.setDefault('Asia/Tokyo');
var classNames = require("classnames");

const useStyles = makeStyles((theme) => ({
    editBtn: {
        marginTop: "2px",
        padding: "2px",
        //backgroundColor:"#333",
        borderRadius: "5px",
        lineHeight: "1.1",
    },
    editText: {
        fontSize: "0.9rem",
        whiteSpace:"nowrap",
        textOverflow:"ellipsis",
        overflow:"hidden",
        width:"calc(100vw / 7 - 12px)",
        textAlign:"left",
    },
    timeText: {
        fontSize: "0.9rem",
        whiteSpace:"nowrap",
        overflow:"hidden",
        width:"42px",
        textAlign:"left",
        position:"absolute",
        left:0
    },
    placeText: {
        fontSize: "0.9rem",
        whiteSpace:"nowrap",
        overflow:"hidden",
        textAlign:"left",
        marginLeft:"42px",
    },
    daybtn: {
        width: "14.286%",
        borderRadius: 0,
        minWidth: "initial",
        border: "#dadce0 1px solid ",
        textAlign: "center",
        verticalAlign: "top",
        padding: "6px",
        wordBreak: "break-word",
        position: "relative",
    },
    sunday: {
        color: "red",
    },
    saturday: {
        color: "blue",
    },
    passed: {
        backgroundColor: "rgba(240,240,240,0.8)",
    },
    dropHighlight: {
        backgroundColor: "rgba(255,255,0,0.5)",
    },
    nomal: {
        backgroundColor: "white",
    },
    today: {
        color: "white",
        backgroundColor: ButtonBackGround,
        borderRadius: "20px",
        fontSize: "0.8rem",
        marginLeft: "22px",
        marginRight: "22px",
        whiteSpace:"nowrap"
    },
    day: {
        fontSize: "0.8rem",
    },
    btnStyle: {
        borderRadius: 25,
        padding: "1px",
        minWidth: "10px",
        position: "absolute",
        top: "6px",
        left: "5px",
    },
    menuBtnStyle: {
        borderRadius: 25,
        padding: "1px",
        minWidth: "10px",
        position: "absolute",
        top: "6px",
        right: "5px",
    },

    btnBigStyle: {
        marginTop: "5px",
        minWidth:"10px",
        paddingLeft:"8px",
        paddingRight:"8px"
    },
    btnSvg: {
        fontSize: "1rem"
    },
    hideDownXs: {
        [theme.breakpoints.down("xs")]: {
            display: "none"
        },
    },
}));

const today = moment()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

export class DupInfo {
    contentsId: string = "";
    to: Date = new Date();
}

const ContentsPop: React.FunctionComponent<{
    contents: MAContents[];
    onClick: (contents: MAContents, anchorElement: Element) => void;
}> = ({ contents, onClick }) => {
    var classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    if ((contents?.length ?? 0) <= 0)
        return null;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Box>
            <Button onClick={handleClick}
                className={classes.editBtn}>その他{(contents?.length ?? 0)}件...</Button>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box style={{ margin: "8px" }}>
                    {
                        contents?.map((cnt) => <ContensBtn key={cnt.id} contents={cnt} onClick={onClick} />)
                    }
                </Box>
            </Popover>
        </Box>
    );

};

const ContensBtn: React.FunctionComponent<{
    contents: MAContents;
    onClick: (contents: MAContents, anchorElement: Element) => void
}> = ({ contents, onClick }) => {
    var classes = useStyles();
    var dispatch = useDispatch();

    var str = "";
    var time = moment(contents.startUTC).format("HH:mm");
    var place =  "";

    if(!contents.place){
        str = moment(contents.startUTC).format("HH:mm") +
        "~" +
        moment(contents.endUTC).format("HH:mm");
        place = "終了:"+moment(contents.endUTC).format("HH:mm")
    }else{
        str = moment(contents.startUTC).format("HH:mm") +
        " " +
        (contents.place ?? "");
        place = (contents.place ?? "");
    }
        
        
    var month = moment(contents.startUTC).format("M/d");

    const [{ isDragging }, drag] = useDrag({
        item: { name: str, type: "Contents", id: contents.id },
        options: {
            dropEffect: "copy",
        },
        end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                var dpinfo = new DupInfo();
                dpinfo.contentsId = contents.id ?? "";
                dpinfo.to = dropResult.date;
                if (dropResult.dropok) {
                    //OKの場合の複製処理はContentsListCalendar内で処理
                    dispatch(
                        ConfirmDialogSlice.actions.openConfirm({
                            title: "複製の確認",
                            message:
                                "「" +
                                month +
                                " " +
                                str +
                                "」の朝礼を「" +
                                dropResult.name +
                                "」へ複製してもよろしいですか？",
                            callback:(ok)=>{
                                if(ok){
                                    dispatch(ActionSlice.actions.doAction({type:ActionType.CONFIRM_DUPULICATE,value:JSON.stringify(dpinfo)}));
                                }
                            }
                        })
                    );
                }
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const onClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        var el = document.getElementById(contents.id ?? "");
        if (el) {
            onClick(contents, el);
        }
    };

    return (
        <div>
            <IconButton
                id={contents.id}
                onClick={onClickMenu}
                className={classes.editBtn}
                style={{ opacity: isDragging ? 0.4 : 1 }}
            >
                <Typography className={classes.editText} ref={drag} component="div">
                    <span className={classes.timeText}>{time}</span><span className={classes.placeText}>{place}</span>
                </Typography>
            </IconButton>
        </div>
    );
};

const CalendarCell: React.FunctionComponent<{
    date?: Moment;
    contents: MAContents[];
    serverChecked: boolean;
    onClick: (contents: MAContents, anchorElement: Element) => void;
    onCreate: (date: Moment) => void;
}> = ({ date, contents, serverChecked, onClick, onCreate }) => {
    var classes = useStyles();

    const [{ isOver }, drop] = useDrop({
        accept: "Contents",
        drop: () => ({
            name: "" + date?.format("M/D"),
            date: moment(date).utc().toDate(),
            dropok: !(date?.isBefore(today) ?? false),
        }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: () => !(date?.isBefore(today) ?? false),
        }),
    });

    if (!date) return <Box></Box>;

    var cls = classNames(
        classes.daybtn,
        { [classes.sunday]: date.day() === 0 },
        { [classes.saturday]: date.day() === 6 },
        { [classes.passed]: !isOver && date.isBefore(today) },
        { [classes.nomal]: !isOver && !date.isBefore(today) },
        { [classes.dropHighlight]: isOver && !date.isBefore(today) }
    );
    return (
        <TableCell className={cls}>
            <div style={{ width: "100%", height: "100%" }} ref={drop}>
                <Typography
                    className={
                        date.isSame(today, "day") ? classes.today : classes.day
                    }
                >
                    {date.format("M/D")}
                </Typography>

                {
                    /*3件未満の場合*/
                    (contents?.length ?? 0) <= 3 &&
                    contents?.map((cnt) => <ContensBtn key={cnt.id} contents={cnt} onClick={onClick} />)
                }

                {
                    /*3件以上の場合のトップ2つ*/
                    (contents?.length ?? 0) > 3 &&
                    contents
                        ?.slice(0, 2)
                        ?.map((cnt) => <ContensBtn key={cnt.id} contents={cnt} onClick={onClick} />)
                }
                {
                    /*3件以上の場合のポップアップ*/
                    (contents?.length ?? 0) > 3 && (
                        <ContentsPop contents={contents.slice(2)} onClick={onClick} />
                    )
                }

                <Box hidden={!serverChecked || date.isBefore(today)}>
                    <Button
                        style={{ display: contents.length > 0 ? "inline-flex" : "none" }}
                        className={classes.btnStyle}
                        variant="outlined"
                        onClick={() => {
                            onCreate(date);
                        }}
                    >
                        <AddIcon className={classes.btnSvg} />
                    </Button>

                    <Button
                        style={{ display: contents.length > 0 ? "none" : "inline-flex" }}
                        className={classes.btnBigStyle}
                        variant="outlined"
                        onClick={() => {
                            onCreate(date);
                        }}>
                        <AddIcon className={classes.btnSvg} /><span className={classes.hideDownXs}>登録</span>
                    </Button>
                </Box>
            </div>
        </TableCell>
    );
};

export default CalendarCell;
