import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Button,
  Box,
  DialogContent,
  useMediaQuery,
  useTheme,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";

import { ItemTypeKey, stringToItemTypeKey } from "../../Constants";
import moment, { Moment } from "moment-timezone";
import InfoIcon from "@material-ui/icons/Info";
import { ContentsJson, MAFile, MorningAssemblyJson } from "../../net/NetworkClient";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import RefreshIcon from "@material-ui/icons/Refresh";
import { getContentsFilesSv } from "../../net/CalendarAsync"
import TitleText from "./TitleText";
import ActionSlice, { ActionType } from "../../misc/ActionSlice";

moment.tz.setDefault('Asia/Tokyo');
var classNames = require("classnames");

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: "hidden",
  },
  content: {
    margin: "20px 16px",
  },
  file: {
    marginLeft: "30px",
    display: "inline-flex",
  },
  infoIcon: {
    color: theme.palette.common.white,
  },
  img: {
    maxWidth: "200px",
    maxHeight: "200px",
  },
  viewerImgH: {
    width: "auto",
    height: "100%",
    marginTop: theme.spacing(2),
  },
  viewerImgW: {
    width: "100%",
    height: "auto",
    marginTop: theme.spacing(2),
  },

  saveBtn: {
    marginLeft: "auto",
  },
}));

const ContentsItemKYActivity: React.FunctionComponent<{
  contentsId: string | undefined;
  jsonData: MorningAssemblyJson | undefined;
  selectedItem: { key: string; id: string } | undefined;
  onUpdate: (newData: ContentsJson) => void;
  onChanging: (changing: boolean) => void;
}> = ({ contentsId, jsonData, selectedItem, onUpdate, onChanging }) => {

  const classes = useStyles();
  const dispatch = useDispatch();


  const [title, setTitle] = React.useState("");
  const [files, setFiles] = React.useState<MAFile[]>([]);

  const [selectedFile, setSelectedFile] = React.useState<MAFile>();
  const [selectedFileLandscape, setSelectedFileLandscape] = React.useState(true);


  const [loading, setLoading] = React.useState(false);
  const [filesUpdated, setFilesUpdated] = React.useState<Moment | undefined>();

  function isSupport(): boolean {
    return selectedItem?.key === ItemTypeKey.kyActivity
      || selectedItem?.key === ItemTypeKey.general_photoUpload;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isSupport())
      return;

    if (selectedItem?.id && jsonData) {
      const contents = jsonData?.contents?.find(
        (c) => c.uuid === selectedItem?.id
      );
      if (contents && contents.title && title !== contents.title) {
        setTitle(contents.title);
      }

      refreshData();
    }
    
    dispatch(ActionSlice.actions.doAction({ type: ActionType.CONTENT_ITEM_LOAD_END}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, jsonData]);
  const theme = useTheme();
  const upxl = useMediaQuery(theme.breakpoints.up("xl"));
  const uplg = useMediaQuery(theme.breakpoints.up("lg"));
  const upmd = useMediaQuery(theme.breakpoints.up("md"));
  const upsm = useMediaQuery(theme.breakpoints.up("sm"));
  const getGridListCols = () => {
    if (upxl) {
      return 5;
    }
    if (uplg) {
      return 4;
    }
    if (upmd) {
      return 3;
    }
    if (upsm) {
      return 2;
    }
    return 1;
  };
  const refreshData = () => {
    setLoading(true);
    dispatch(
      getContentsFilesSv({
        contentsId: contentsId ?? "",
        callback: (ok, infiles) => {
          setFiles(infiles ?? []);
          setFilesUpdated(moment());
          setLoading(false);
        }
      })
    );
  };

  var selectedImageClass = classNames(
    { [classes.viewerImgH]: !selectedFileLandscape },
    { [classes.viewerImgW]: selectedFileLandscape }
  );

  const handleClose = () => {
    setSelectedFile(undefined);
  };

  function updateContentsJson(newTitle: string) {
    const contents = jsonData?.contents?.find(
      (c) => c.uuid === selectedItem?.id
    );

    if (contents) {
      const strsrc = JSON.stringify(contents);
      const copy = new ContentsJson(contents);
      copy.title = newTitle;

      const dststr = JSON.stringify(copy);
      if (strsrc !== dststr) {
        onUpdate(copy);
      }
    }
  }

  if (!isSupport()) return null;

  return (
    <Box>
      <Paper className={classes.paper} elevation={0} square>

        <Grid container spacing={2} className={classes.content}>

          <Grid item xs={10}>
            <TitleText
              type={stringToItemTypeKey(selectedItem?.key ?? "")}
              value={title}
              onUpdate={(t) => {
                setTitle(t);
                updateContentsJson(t);
              }}
              onChanging={onChanging}
            />
            <Box component="span" hidden={!loading}>
              <CircularProgress style={{ color: "#1a90ff", display: loading ? "block" : "none" }} size={30} />
            </Box>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right", paddingRight: "30px" }}>
            <Tooltip title={filesUpdated?.format("YYYY/MM/DD HH:mm:ssに更新") ?? ""}>
              <IconButton aria-label="reflesh" onClick={refreshData}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Grid>


          <Grid item xs={12}>
            <GridList
              cellHeight={200}
              cols={getGridListCols()}
              spacing={2}
              style={{ marginRight: "40px" }}
            >
              {files.map((val) => {
                if (val.uuid !== selectedItem?.id) {
                  return null;
                }
                return (
                  <GridListTile key={val.id} cols={1}>
                    <img src={val.path} alt="thumbnail"></img>
                    <GridListTileBar
                      title={moment(val.createUTC).format("HH:mm:ss")}
                      subtitle={<span>{val.userName}</span>}
                      actionIcon={
                        <IconButton
                          onClick={() => {
                            setSelectedFile(val);
                          }}
                        >
                          <InfoIcon className={classes.infoIcon} />
                        </IconButton>
                      }
                    />
                  </GridListTile>
                );
              })}
            </GridList>
          </Grid>
        </Grid>
      </Paper>
      <Dialog open={!!selectedFile} onClose={handleClose} fullScreen>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {selectedFile
                ? moment(selectedFile.createUTC).format("YYYY/MM/DD HH:mm:ss") +
                "(" +
                selectedFile.userName +
                ")"
                : ""}
            </Typography>
            <Button
              href={selectedFile?.path ?? ""}
              download={selectedFile?.originalName ?? ""}
              className={classes.saveBtn}
              target="_blank"
            >
              <GetAppIcon />
              ダウンロード
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent>
          {selectedFile && (
            <Box style={{ height: "calc(100% - 90px)" }}>
              <Toolbar></Toolbar>
              <img
                id={"sourceImage"}
                src={selectedFile.path}
                alt={"ky活動"}
                className={selectedImageClass}
                onLoad={() => {
                  var img = document.getElementById("sourceImage") as HTMLImageElement;
                  if (img) {
                    setSelectedFileLandscape(img.naturalHeight < img.naturalWidth);
                  }
                }}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ContentsItemKYActivity;
