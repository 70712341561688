import { createAsyncThunk } from "@reduxjs/toolkit";
import { MorningAssemblyJson, TemplatesV2Client, MATemplate } from "./NetworkClient";
import { AlertDialogSlice } from "../misc/AlertDialog";
import { SERVER_URL } from "../Constants";
import { getAuthedAxisInstance } from "./AxisUtil";

//テンプレートリスト取得
export const getTemplateListSv = createAsyncThunk<MATemplate[],
    { tenantId: string, callback: (ok: boolean, data: MATemplate[]) => void }>(
        "template/find",
        async (arg, { dispatch }) => {
            try {
                const tmplist = await (new TemplatesV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).find(arg.tenantId);
                arg.callback(true, tmplist);
                return tmplist;
            } catch (err) {
                console.log(err);
                dispatch(
                    AlertDialogSlice.actions.openAlert({
                        title: "エラー",
                        message: "テンプレートリストの読み込みに失敗しました",
                    })
                );
                arg.callback(false, []);
                throw err;
            }
        }
    );


//テンプレートJSONデータ取得
export const getTemplateDataSv = createAsyncThunk<MorningAssemblyJson,
    { templateId: string, callback: (ok: boolean, data?: MorningAssemblyJson) => void }>(
        "template/getData",
        async (arg, { dispatch }) => {
            try {
                const json = await (new TemplatesV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getData(arg.templateId);
                arg.callback(true, json);
                return json;
            } catch (err) {
                console.log(err);
                dispatch(
                    AlertDialogSlice.actions.openAlert({
                        title: "エラー",
                        message: "テンプレートデータの読み込みに失敗しました",
                    })
                );
                arg.callback(false, undefined);
                throw err;
            }
        }
    );

//テンプレート作成
export const postTemplateSv = createAsyncThunk<MATemplate, { tenantId: string, name: string, contentsId: string, callback: (ok: boolean, template?: MATemplate) => void }>(
    "contents/postTemplate",
    async (arg, { dispatch }) => {
        try {
            var re = await (new TemplatesV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).post(arg.tenantId, arg.name, arg.contentsId);
            arg.callback(true, re);
            return re;
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);

//テンプレート削除
export const deleteTemplateSv = createAsyncThunk<void, { templateId: string, callback: (ok: boolean) => void }>(
    "contents/deleteTemplate",
    async (arg, { dispatch }) => {
        try {
            await (new TemplatesV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).delete(arg.templateId);
            arg.callback(true);
        } catch (err) {
            console.log(err);
            arg.callback(false);
            throw err;
        }
    }
);