

var revokeObjectURL = (window.URL || window.webkitURL).revokeObjectURL;

type FILE_TYPE = {uuid:string,file:any,objectUrl:string};

class LocalFiles{
    files:FILE_TYPE[] = [];

    getFile(uuid:string):FILE_TYPE|undefined{
        return this.files.find((f)=>{return f.uuid === uuid})
    }

    getObjectUrl(uuid:string):string
    {
        const f = this.getFile(uuid);
        return f?.objectUrl ?? "";
    }
    getFileObject(uuid:string):any{
        const f = this.getFile(uuid);
        return f?.file;
    }

    //ファイル追加
    addFile(file:FILE_TYPE){
        const uuid = file.uuid;
        const f1 = this.files.find((f)=>{return f.uuid === uuid});
        if(f1){
            this.files = this.files.map((f2)=>{return f2.uuid !== uuid ? f2 : file});
        }else{
            this.files = [...this.files,file];
        }
    }

    //ファイル削除
    removeFile(uuid:string){
        const f1 = this.files.find((f)=>{return f.uuid === uuid});
        if(f1){
            if(f1.file && f1.objectUrl){
                revokeObjectURL(f1.objectUrl);
            }
            this.files = this.files.filter((f2)=>{return f2.uuid !== uuid});
        }
    }

    //すべてのファイルを削除
    clearFiles(){
        this.files.forEach((f)=>{
            if(f.file && f.objectUrl){
                revokeObjectURL(f.objectUrl);
            }
        });
        this.files = [];
    }
}

const localFiles = new LocalFiles();

export default localFiles;