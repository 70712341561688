import React, { useEffect } from "react";

import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  CardContent,
  Card,
} from "@material-ui/core";

import { ItemTypeKey, stringToItemTypeKey } from "../../Constants";
import {
  DetailJson,
  ContentsJson,
  MorningAssemblyJson,
} from "../../net/NetworkClient";
import TitleText from "./TitleText";
import { useDebounce } from "use-debounce";
import { useDispatch } from "react-redux";
import ActionSlice, { ActionType } from "../../misc/ActionSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: "hidden",
    height: "100%"
  },
  content: {
    padding: "20px 16px",
    flexGrow: 1,
  },
  ctr: {
    marginTop: "12px",
    marginBottom: "12px",
  },
}));

interface SafetyCallData {

  title: string;
  call: string;
  response: string;
}

const ContentsItemSafetyCall: React.FunctionComponent<{
  jsonData: MorningAssemblyJson | undefined;
  selectedItem: { key: string; id: string } | undefined;
  onUpdate: (newData: ContentsJson) => void;
  onChanging: (changing: boolean) => void;
}> = ({ jsonData, selectedItem, onUpdate ,onChanging}) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [data, setData] = React.useState<SafetyCallData>({
    title: "",
    call: "",
    response: "",
  });

  function isSupported(key:string|undefined) {
    return key === ItemTypeKey.safetyCall
      || key === ItemTypeKey.general_chant;
  }
  function copyData(): SafetyCallData {
    return JSON.parse(JSON.stringify(data));
  }

  //JSONデータの更新
  useEffect(() => {
    if (!isSupported(selectedItem?.key))
      return;

    if (selectedItem?.id && jsonData) {
      const contents = jsonData?.contents?.find(
        (c) => c.uuid === selectedItem?.id
      );
      if (contents?.details) {
        setData({
          title: contents.title ?? "",
          call: contents.details.call ?? "",
          response: contents.details.response ?? "",
        });
      }else{
        setData({ title: "", call: "", response: "" });
      }
    }
    dispatch(ActionSlice.actions.doAction({ type: ActionType.CONTENT_ITEM_LOAD_END }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, jsonData]);

  //データ更新
  /*useEffect(() => {
    const contents = jsonData?.contents?.find(
      (c) => c.uuid === selectedItem?.id
    );
    if (contents && (contents.details?.call !== data.call || contents.details.response !== data.response)) {
      var copy = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));

      const detail = new DetailJson();
      detail.call = data.call;
      detail.response = data.response;
      copy.details = detail;
      onUpdate(copy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);*/

  //OnChangeの度にJsonを更新すると処理が重たいので、debounce処理
  const [debouncedData] = useDebounce(data, 500);
  useEffect(() => {
    const contents = jsonData?.contents?.find(
      (c) => c.uuid === selectedItem?.id
    );
    if(!isSupported(contents?.type))
      return;
    if (contents && (contents.details?.call !== data.call || contents.details.response !== data.response || contents.title !== data.title)) {
      var copy = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));
      copy.title = data.title;
      const detail = new DetailJson();
      detail.call = data.call;
      detail.response = data.response;
      copy.details = detail;
      onUpdate(copy);
      onChanging(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedData]);


  if (!isSupported(selectedItem?.key))
    return null;

  return (
    <Paper className={classes.paper} elevation={0} square>
      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12}>
          <TitleText
            type={stringToItemTypeKey(selectedItem?.key ?? "")}
            value={data.title}
            onUpdate={(title) => {
              var copy = copyData();
              copy.title = title;
              setData(copy);
            }}
            onChanging={onChanging}
          />

        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <TextField
                name="item"
                label="呼びかけ"
                value={data.call}
                fullWidth
                multiline
                rows={5}
                className={classes.ctr}
                onChange={(e) => {
                  onChanging(true);
                  var d = copyData();
                  d.call = e.target.value;
                  setData(d);
                }}
              ></TextField>
              <TextField
                name="item"
                label="レスポンス（掛け声）"
                value={data.response}
                fullWidth
                multiline
                rows={5}
                className={classes.ctr}
                onChange={(e) => {
                  onChanging(true);
                  var d = copyData();
                  d.response = e.target.value;
                  setData(d);
                }}
              ></TextField>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ContentsItemSafetyCall;
