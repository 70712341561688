import moment from "moment-timezone";
import React, { useEffect, useState, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { AlertDialogSlice } from "../misc/AlertDialog";
import {
    MATemplate,
    MorningAssemblyJson,
    MAContents,
} from "../net/NetworkClient";
import { getTemplateDataSv, getTemplateListSv } from "../net/TemplateAsync";
import ContentsSimpleViewer from "../misc/ContentsSimpleViewer";


import { findContentsSv, getContentsDataSv, postContentsSv } from "../net/CalendarAsync"
import { RootState } from "../Store";
import TimePicker from "../misc/TimePicker";
moment.tz.setDefault('Asia/Tokyo');
moment.locale("ja", {
    weekdays: [
        "日曜日",
        "月曜日",
        "火曜日",
        "水曜日",
        "木曜日",
        "金曜日",
        "土曜日",
    ],
    weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
});

const useStyles = makeStyles((theme) => ({
    btn: {
        color: theme.palette.text.primary,
    },
    top: {
        position: "absolute",
        right: 25,
        top: 20,
    },
    progress: {
        color: "#1a90ff",
    },
    contents: {
        width: "100%",
        height: "300px",
        overflow: "auto",
        padding: "5px",
    },
    textBottom: {
        verticalAlign: "bottom",
    },
    ctrl: {
        minWidth: "180px",
    },
}));

interface BaseData {
    id: string;
    label: string;
    time: string;
    endtime: string;
    contents?: MAContents;
    template?: MATemplate;
    json?: MorningAssemblyJson;
}

const NewContentsDialog: FunctionComponent<{
    open: boolean;
    initialDate: Date;
    tenantId: string;
    projectId: string;
    onClose: (ok: boolean, id?: string) => void;
}> = ({ open, initialDate, tenantId, projectId, onClose }) => {
    const classes = useStyles();

    const [data, setData] = useState({
        startDateTime: moment(initialDate).hour(8).minute(0),
        endDateTime: moment(initialDate).hour(23).minute(59),
    });

    const user = useSelector(
        (state: RootState) => state.auth.user
    );

    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [baseDataList, setBaseDataList] = useState<BaseData[]>([]);
    const [selectedBaseData, setSelectedBaseData] = useState<BaseData>();
    const [jsonData, setJsonData] = useState<MorningAssemblyJson>();
    const [errors, setErrors] = useState({
        template: "",
    });
    const dispatch = useDispatch();

    useEffect(() => {
        setData({
            startDateTime: moment(initialDate).hour(8).minute(0),
            endDateTime: moment(initialDate).hour(23).minute(59),
        });
        setErrors({ template: "" });
        setLoading(false);
        setSubmitting(false);
        setBaseDataList([]);
        setSelectedBaseData(undefined);
        setJsonData(undefined);
        moment.locale("ja");
        if (open) {
            setLoading(true);
            var end = moment(new Date())
                .hour(0)
                .minute(0)
                .second(0)
                .add(1, "days")
                .utc()
                .toDate();
            var start = moment(end).add(-7, "days").toDate();
            var bdlist: BaseData[] = [];

            dispatch(
                findContentsSv({
                    projectId: projectId,
                    start: start,
                    end: end,
                    callback: (ok1, list1) => {
                        if (ok1) {
                            list1?.forEach((v) => {
                                var d = moment(v.startUTC);
                                var ed = moment(v.endUTC);
                                const label = d.format("MM/DD(ddd)");
                                bdlist.push({
                                    id: uuid(),
                                    label: label,
                                    time: d.format("HH:mm"),
                                    endtime: ed.format("HH:mm"),
                                    contents: v,
                                });
                            });
                            var tmptime = moment().format("HH:mm");
                            /*var timespan = 120;
                            if (bdlist.length > 0) {
                                tmptime = bdlist[bdlist.length - 1].time;
                                timespan = bdlist[bdlist.length - 1].timespan;
                            }*/
                            //テンプレートを追加読み込み
                            dispatch(
                                getTemplateListSv({
                                    tenantId: tenantId,
                                    callback: (ok2, list2) => {
                                        if (ok2) {
                                            list2?.forEach((v) => {
                                                bdlist.push({
                                                    id: uuid(),
                                                    label: v.name ?? "テンプレート",
                                                    time: tmptime,
                                                    endtime: "23:59",
                                                    template: v,
                                                });
                                            });
                                        }
                                        setBaseDataList(bdlist);
                                        if (bdlist.length > 0) {
                                            setSelectedBaseData(bdlist[bdlist.length - 1]);
                                        }
                                        setLoading(false);
                                    },
                                })
                            );
                        } else {
                            setLoading(false);
                        }
                    },
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);


    //テンプレートの選択が変更されたときの処理
    useEffect(() => {
        if (selectedBaseData) {
            if (selectedBaseData.json) {
                setData(
                    Object.assign({}, data, {
                        time: selectedBaseData.time,
                        endtime: selectedBaseData.endtime,
                    })
                );
                setJsonData(selectedBaseData.json);
                return;
            }
            setLoading(true);
            if (selectedBaseData.contents) {
                //コンテンツを選択
                const tmpid = selectedBaseData.contents.id ?? "";
                dispatch(
                    getContentsDataSv({
                        contentsId: tmpid,
                        callback: (ok, data) => {
                            if (ok && data) {
                                const idx = baseDataList.findIndex((v) => {
                                    return v.contents?.id === tmpid;
                                });
                                if (idx >= 0) {
                                    var copy = Object.assign({}, selectedBaseData);
                                    copy.json = data;
                                    var copyList = baseDataList.slice();
                                    copyList[idx] = copy;
                                    setBaseDataList(copyList);
                                    setSelectedBaseData(copy);
                                }
                            }
                            setLoading(false);
                        },
                    })
                );
            } else if (selectedBaseData.template) {
                //テンプレートを選択
                const tmpid = selectedBaseData.template.id ?? "";
                dispatch(
                    getTemplateDataSv({
                        templateId: tmpid,
                        callback: (ok, json) => {
                            if (ok && json !== undefined) {
                                const idx = baseDataList.findIndex((v) => {
                                    return v.template?.id === tmpid;
                                });
                                if (idx >= 0) {
                                    var copy = Object.assign({}, selectedBaseData);
                                    copy.json = json;
                                    if (user)
                                        copy.json.leader = user.name;
                                    var copyList = baseDataList.slice();
                                    copyList[idx] = copy;
                                    setBaseDataList(copyList);
                                    setSelectedBaseData(copy);
                                }
                            }
                            setLoading(false);
                        },
                    })
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBaseData]);

    useEffect(() => {
        var copy = Object.assign({}, errors);

        if (!selectedBaseData) {
            copy.template = "選択して下さい";
        } else {
            copy.template = "";
        }
        setErrors(copy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedBaseData]);

    function submit() {
        if (!errors.template) {
            setSubmitting(true);
            var json = MorningAssemblyJson.fromJS(
                JSON.parse(JSON.stringify(jsonData))
            );
            json.leader = jsonData?.leader ?? "責任者";
            const date = moment(data.startDateTime)
                .second(0)
                .millisecond(0);
            var enddate = moment(data.endDateTime)
                .second(0)
                .millisecond(0);

            if (enddate.isBefore(date)) {
                enddate = moment(initialDate)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .millisecond(0);
                enddate = enddate.add(1, "day");
            }

            json.start = new Date(date.toISOString());
            json.end = new Date(enddate.toISOString());
            dispatch(
                postContentsSv({
                    projectId: projectId,
                    data: json,
                    callback: (ok, contents) => {
                        setSubmitting(false);
                        if (ok && contents) {
                            onClose(true, contents.id ?? "");
                        } else {
                            dispatch(
                                AlertDialogSlice.actions.openAlert({
                                    title: "エラー",
                                    message: "データの登録に失敗しました",
                                })
                            );
                        }
                    },
                })
            );
        } else {
            dispatch(
                AlertDialogSlice.actions.openAlert({
                    title: "入力エラー",
                    message: "入力項目に問題があります。エラー表示に従って内容を修正してください。",
                })
            );
        }
    }

    return (
        <Dialog
            disableBackdropClick
            open={open}
            onClose={() => {
                onClose(false);
            }}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">
                「{moment(initialDate).format("M月D日")}」に新しい朝礼を登録します
                <Box
                    className={classes.top}
                    display={submitting || loading ? "block" : "none"}
                >
                    <CircularProgress className={classes.progress} size={30} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid container item xs={5} spacing={2}>
                        <Grid item xs={12}>
                            <TimePicker
                                baseDate={data.startDateTime.toDate()}
                                value={data.startDateTime.toDate()}
                                useOverTime={false}
                                label={"開始時刻"}
                                required={true}
                                onChange={(d) => {
                                    setData(
                                        Object.assign({}, data, {
                                            startDateTime: moment(d),
                                        })
                                    );
                                }}></TimePicker>
                        </Grid>

                        <Grid item xs={12}>
                            <TimePicker
                                baseDate={data.startDateTime.toDate()}
                                value={data.endDateTime.toDate()}
                                useOverTime={true}
                                label={"端末での利用終了時刻"}
                                required={true}
                                after={data.startDateTime.toDate()}
                                onChange={(d) => {
                                    setData(
                                        Object.assign({}, data, {
                                            endDateTime: moment(d),
                                        })
                                    );
                                }}>
                            </TimePicker>
                        </Grid>
                        {/** 
                        <Grid item xs={12}>
                            <TextField
                                className={classes.ctrl}
                                fullWidth
                                required
                                name="Date"
                                type="time"
                                label="開催時刻"
                                value={data?.time ?? "08:00"}
                                error={!!errors.time}
                                helperText={errors.time}
                                onChange={(e) => {
                                    setData(
                                        Object.assign({}, data, {
                                            time: e.target.value,
                                        })
                                    );
                                }}
                                hidden={submitting === false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    className={classes.ctrl}
                                    fullWidth
                                    required
                                    name="Date"
                                    type="time"
                                    label="端末での利用終了時刻"
                                    value={data?.endtime ?? "23:59"}
                                    error={!!errors.endtime}
                                    helperText={errors.endtime}
                                    onChange={(e) => {
                                        setData(
                                            Object.assign({}, data, {
                                                endtime: e.target.value,
                                            })
                                        );
                                    }}
                                    hidden={submitting === false}
                                />

                            </FormControl>
                        </Grid>
*/}
                        <Grid item xs={12}>
                            <FormControl fullWidth error={!!errors.template}>
                                <InputLabel id="template-select-label">テンプレート</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="template-select-label"
                                    id="template-select"
                                    value={selectedBaseData?.id ?? ""}
                                    onChange={(e) => {
                                        const v = baseDataList.find((d) => {
                                            return d.id === e.target.value;
                                        });
                                        setSelectedBaseData(v);
                                    }}
                                >
                                    {baseDataList.map((d) => (
                                        <MenuItem key={d.id} value={d.id}>
                                            {d.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors.template}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item xs={7} spacing={0}>
                        <Grid item xs={12}>
                            内容
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.contents} variant="outlined">
                                <ContentsSimpleViewer
                                    contentsOnly={true}
                                    data={jsonData}
                                ></ContentsSimpleViewer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justify="flex-end" alignItems="center" spacing={2}>
                    <Grid item style={{ marginTop: 10 }}>
                        <Button
                            type="button"
                            onClick={() => {
                                onClose(false);
                            }}
                            disabled={submitting}
                        >
                            キャンセル
                        </Button>
                    </Grid>
                    <Grid item style={{ marginTop: 10 }}>
                        <Button
                            type="submit"
                            onClick={() => {
                                submit();
                            }}
                            disabled={submitting}
                        >
                            登録
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
export default NewContentsDialog;
