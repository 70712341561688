import React, { ReactNode, useEffect } from "react";
import {  useDispatch } from "react-redux";

import {
  Grid,
  makeStyles,
  Paper,
  Card,
  CardContent,
  CardActions,
  Box,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { stringToItemTypeKey } from "../../Constants";
import AddBtn from "./AddBtn";
import {
  ContentsJson,
  MorningAssemblyJson,
} from "../../net/NetworkClient";
import ActionSlice, { ActionType } from "../../misc/ActionSlice";
import { ConfirmDialogSlice } from "../../misc/ConfirmDialog";
import TitleText from "./TitleText";


const useStyles = makeStyles((theme) => ({
  paper: {
    //margin: "auto",
    overflow: "hidden",
  },
  content: {
    padding: "20px 16px",
    flexGrow: 1,
  },

  addpin: {
    height: "30px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  addpinBox: {
    padding: "0 !important" as any,
    textAlign: "right",
    marginRight: "1px",
  },
  headerBox: {
    padding: "0 !important" as any,
    marginRight: "1px",
  },
  cardcontent: {
    
    paddingBottom:"8px"
  },
  itemActions: {
    justifyContent: "flex-end",
    paddingTop:"0px",
    paddingBottom:"0px"
  },
}));

export interface DataType<T> {
  title: string;
  list: T[];
}

export interface IContentsItemListDelegate<T extends {}> {
  getUUID(item: T): string;
  isSuported(type: string | undefined): boolean;
  copyData(data: DataType<T>): DataType<T>;
  contentsToData(contents: ContentsJson): DataType<T>;
  buildContentsJson(contents: ContentsJson, data: DataType<T>): ContentsJson;
  makeNewItem(): T;
}



export interface IItemCardContentsProp {
    index:number;
    item?: object;
    contents?: ContentsJson;
    onUpdate?: (newData: ContentsJson) => void;
    onChanging?: (changing: boolean) => void;
    contentsid?:string;
}


export interface IContentsItemListProps<T,U extends React.FC<IItemCardContentsProp>,H> {
  contentsid:string;
  delegate: IContentsItemListDelegate<T>;
  cardContents: U
  jsonData: MorningAssemblyJson | undefined;
  selectedItem: { key: string; id: string } | undefined;
  onUpdate: (newData: ContentsJson) => void;
  onChanging: (changing: boolean) => void;
  cardHeader?:H;
}


export const ContentsItemListBase 
= <T extends {},U extends React.FC<IItemCardContentsProp>,H>
(props: IContentsItemListProps<T,U,H> & { children?: ReactNode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [itemId, setItemId] = React.useState("");


  //表示データ
  const [data, setData] = React.useState<DataType<T>>({
    title: "",
    list: [],
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!props.delegate.isSuported(props.selectedItem?.key)){
      setItemId("");
      setData({
        title: "",
        list: [],
      });
      return;
    }

    if (props.selectedItem?.id && props.jsonData) {
      if (props.selectedItem?.id !== itemId) {
        setItemId(props.selectedItem?.id);
      }
      const contents = props.jsonData?.contents?.find(
        (c) => c.uuid === props.selectedItem?.id
      );
      if (contents?.details) {
        const toData = props.delegate.contentsToData(contents);
        if (JSON.stringify(data) !== JSON.stringify(toData)) {
          setData(toData);
        }
      }
    }
    
    dispatch(ActionSlice.actions.doAction({ type: ActionType.CONTENT_ITEM_LOAD_END}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedItem, props.jsonData]);

  function setDataAndPost(inData: DataType<T>) {
    setData(inData);


    const contents = props.jsonData?.contents?.find(
      (c) => c.uuid === props.selectedItem?.id
    );

    if (contents) {
      const strsrc = JSON.stringify(contents);
      var copy = props.delegate.buildContentsJson(contents, inData);

      const dststr = JSON.stringify(copy);
      if (strsrc !== dststr) {
        props.onUpdate(copy);
      }
    }
  }

  //表示データのコピー
  function copyData() {
    return props.delegate.copyData(data);
  }

  //項目追加
  function addItem(index: number) {
    const item = props.delegate.makeNewItem();
    var list = [
      ...data.list.slice(0, index),
      item,
      ...data.list.slice(index),
    ];
    const newdata = copyData();
    newdata.list = list;
    setDataAndPost(newdata);
  }

  if (!props.delegate.isSuported(props.selectedItem?.key))
    return null;

  return (
    <Paper className={classes.paper} elevation={0} square>
      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12}>
          <TitleText
            type={stringToItemTypeKey(props.selectedItem?.key??"")}
            value={data.title}
            onUpdate={(title) => {
              var copy = copyData();
              copy.title = title;
              setDataAndPost(copy);
            }}
            onChanging={props.onChanging}
          />
        </Grid>

        <Grid item xs={12} className={classes.headerBox} style={{display:(props.cardHeader ? "block" : "none")}}>
          {props.cardHeader}
        </Grid>

        <Grid container className={classes.addpinBox} justify="flex-end">
          <AddBtn
            index={0}
            onClick={(index) => {
              addItem(index);
            }}
          />
        </Grid>

        {data.list.map((val, idx) => (
          <Grid item xs={12} key={props.delegate.getUUID(val)} className={classes.addpinBox}>
            <Grid item xs={12}>
              <ItemCard
                index={idx}
                contentsid={props.contentsid}
                delegate={props.delegate}
                cardContents={props.cardContents}
                contents={props.jsonData?.contents?.find(
                  (c) => c.uuid === itemId
                )}
                item={val}
                uparrow={idx > 0}
                downarrow={(data.list.length ?? 0) > idx + 1}
                selectedItem={props.selectedItem}
                onUpdate={props.onUpdate}
                onShift={(up, uuid) => {
                  const newdata = copyData();
                  var idx = data.list.findIndex((p) => props.delegate.getUUID(p) === uuid);
                  if (idx >= 0) {
                    const item = newdata.list.splice(idx, 1)[0];
                    newdata.list.splice(idx + (up ? -1 : +1), 0, item);
                    setDataAndPost(newdata);
                  }
                }}
                onDelete={(uuid) => {
                  dispatch(
                    ConfirmDialogSlice.actions.openConfirm({
                      title: "削除の確認",
                      message: "本当に削除してもよろしいですか？",
                      callback: (ok) => {
                        if (ok) {
                          const newdata = copyData();
                          newdata.list = newdata.list.filter(
                            (p) => props.delegate.getUUID(p) !== uuid
                          );
                          setDataAndPost(newdata);
                          dispatch(ActionSlice.actions.clearAction(undefined));
                        }
                      }
                    })
                  );
                }}
                onChanging={props.onChanging}
              ></ItemCard>
            </Grid>
            <Grid container className={classes.addpinBox} justify="flex-end">
              <AddBtn
                index={idx + 1}
                onClick={(index) => {
                  addItem(index);
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}



export interface ItemCardProps<T,U> {
  index:number;
  contentsid:string;
  delegate: IContentsItemListDelegate<T>;
  cardContents: U;
  contents?: ContentsJson;
  item: T;
  uparrow: boolean;
  downarrow: boolean;
  selectedItem: { key: string; id: string } | undefined;
  onUpdate: (newData: ContentsJson) => void;
  onShift: (up: boolean, uuid: string) => void;
  onDelete: (uuid: string) => void;
  onChanging: (changing: boolean) => void;
}

//作業項目1つ分
const ItemCard = <T extends {},U extends React.FC<IItemCardContentsProp>>(props: ItemCardProps<T,U>) => {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardContent className={classes.cardcontent}>
        {
          React.createElement(props.cardContents,{   
            index:props.index,
            contentsid:props.contentsid,
            item: props.item,
            contents: props.contents,
            onUpdate:props.onUpdate,
            onChanging:props.onChanging})
        }
      </CardContent>

      <CardActions disableSpacing={true} className={classes.itemActions}>
        <Box component="span" visibility={props.uparrow ? "visible" : "hidden"}>
          <Tooltip title="上へ移動">
            <IconButton
              edge="end"
              aria-label="up"
              onClick={() => {
                props.onShift(true, props.delegate.getUUID(props.item));
              }}
            >
              <ArrowUpwardIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box component="span" visibility={props.downarrow ? "visible" : "hidden"}>
          <Tooltip title="下へ移動">
            <IconButton
              edge="end"
              aria-label="down"
              onClick={() => {
                props.onShift(false, props.delegate.getUUID(props.item));
              }}
            >
              <ArrowDownwardIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box component="span">
          <Tooltip title="削除">
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => {
                props.onDelete(props.delegate.getUUID(props.item));
              }}
            >
              <HighlightOffIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
    </Card>
  );
};

export default ContentsItemListBase;
