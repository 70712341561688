import React, { useEffect, useState, FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
} from "@material-ui/core";

import { getProjectSv, putProjectSv } from "../net/TenantAsync";
import { MAProject } from "../net/NetworkClient";
import { BaseBackGroundColor } from "../Constants";
import { AlertDialogSlice } from "../misc/AlertDialog";

const useStyles = makeStyles((theme) => ({
  btn: {
    color: theme.palette.text.primary,
  },
  top: {
    position: "absolute",
    right: 25,
    top: 20,
  },
  progress: {
    color: "#1a90ff",
  },
  clipbtn: {
    backgroundColor: BaseBackGroundColor,
  },
}));
const ProjectEditDialog: FunctionComponent<{
  tenantId: string;
  projectId: string;
  open: boolean;
  activeProject:number;
  maxProject:number;
  obayashi: boolean;
  onClose: (ok: boolean, prj?: MAProject | undefined) => void;
}> = ({ open, tenantId, projectId,activeProject,maxProject, obayashi, onClose }) => {
  const classes = useStyles();

  const [name, setName] = useState("");
  
  const [useProject, setUseProject] = useState(false);
  const [usePassword, setUsePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [description, setDescription] = useState("");
  const [managementNumber, setManagementNumber] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    managementNumber: "",
    password: ""
  });

  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  //開くときに初期化
  useEffect(() => {
    if (open) {
      setSubmitting(true);
      dispatch(getProjectSv({
        tenantId: tenantId, projectId: projectId, callback(ok, prjinfo) {
          setUseProject(!(prjinfo?.project?.invalid ?? true))
          setName(prjinfo?.project?.name ?? "");
          setManagementNumber(prjinfo?.project?.managementNumber ?? "");
          setDescription(prjinfo?.project?.description ?? "");
          setSubmitting(false);
          if (prjinfo?.project?.clientPassword && prjinfo?.project?.clientPassword.length > 0) {
            setPassword(prjinfo?.project.clientPassword);
            setUsePassword(true);
          } else {
            setPassword("");
            setUsePassword(false);
          }
        }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  //エラーチェック
  useEffect(() => {
    var copy = Object.assign({}, errors);
    if (!name) {
      copy.name = "プロジェクト名を入力して下さい";
    } else {
      copy.name = "";
    }
    copy.password = "";
    if (usePassword) {
      if (password == null || password.length < 4) {
        copy.password = "4文字以上入力してください";
      } else if (!password.match(/^[A-Za-z0-9]*$/)) {
        copy.password = "英数字で入力してください";
      }
    }

    setErrors(copy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, managementNumber, description, password, usePassword]);

  //登録可能かどうかの確認
  function validToSubmit(){
    return (!errors.name && !errors.managementNumber && !errors.description && !errors.password);
  }

  //サーバへ登録
  function submit() {
    if (validToSubmit()) {
      setSubmitting(true);
      var prj = new MAProject();
      
      prj.invalid = !useProject;
      prj.id = projectId;
      prj.name = name;
      prj.managementNumber = managementNumber;
      prj.description = description;
      if (usePassword) {
        prj.clientPassword = password;
      } else {
        prj.clientPassword = "";
      }
      dispatch(
        putProjectSv({
          tenantId: tenantId,
          project: prj,
          callback: (ok) => {
            if (ok) {
              dispatch(getProjectSv({
                tenantId: tenantId, projectId: projectId, callback(ok, sp) {
                  setSubmitting(false);
                  onClose(true, sp ?? undefined);
                }
              }));
            } else {

              setSubmitting(false);
              dispatch(
                AlertDialogSlice.actions.openAlert({
                  title: "エラー",
                  message: "現場情報編集に失敗しました。",
                })
              );
            }
          },
        })
      );
    }
  }

  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={() => {
        onClose(false);
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        プロジェクト編集
        <Box className={classes.top} display={submitting ? "block" : "none"}>
          <CircularProgress className={classes.progress} size={30} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container justify="flex-end" alignItems="center" spacing={2}>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={useProject}
                  onChange={(e) => { setUseProject(e.target.checked) }}
                  color="secondary"
                />
              }
              label={"端末で利用可能（"+activeProject+"/"+maxProject+"利用中）"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              name={"Name"}
              type="text"
              label="プロジェクト名"
              value={name}
              error={!!errors.name}
              helperText={errors.name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Grid>


          <Grid item xs={12} style={{ display: obayashi ? "none" : "block" }}>
            <TextField
              fullWidth
              name={"Description"}
              type="text"
              label="説明"
              value={description}
              error={!!errors.description}
              helperText={errors.description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              name={"ManagementNumber"}
              type="text"
              label={obayashi ? "管理番号(15桁)" : "住所"}
              value={managementNumber}
              error={!!errors.managementNumber}
              helperText={errors.managementNumber}
              onChange={(e) => {
                setManagementNumber(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={usePassword}
                  onChange={(e) => { setUsePassword(e.target.checked) }}
                />
              }
              label="端末から利用する時にパスワードを使用する"
            />
            <TextField
              name={"Password"}
              type="text"
              value={password}
              style={{ marginLeft: "16px", width: "calc(100% - 16px)" }}
              disabled={!usePassword}
              error={!!errors.password}
              helperText={errors.password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Grid>

          <Grid item style={{ marginTop: 10 }}>
            <Button
              type="button"
              onClick={() => {
                onClose(false);
              }}
              disabled={submitting}
            >
              キャンセル
              </Button>
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Button
              type="submit"
              onClick={() => {
                submit();
              }}
              disabled={submitting || !validToSubmit()}
            >
              更新
              </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default ProjectEditDialog;
