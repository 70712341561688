import { createAsyncThunk } from "@reduxjs/toolkit";
import { InviteUser, MAProject, MATenant, ProjectAdminResult,  TenantV2Client ,InviteUserResult, SetProjectAdmin, MAUser, TenantInfo, ProjectInfo} from "./NetworkClient";
import { SERVER_URL } from "../Constants";
import { getAuthedAxisInstance } from "./AxisUtil";


//テナント一覧検索
export const tenantFindSv = createAsyncThunk<boolean, { callback: (ok: boolean, user: TenantInfo[] | null) => void }>(
    "tenants/tenantFind",
    async (arg, { dispatch }) => {
        try {
            var tenants = await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).find();
            arg.callback(true, tenants);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, null);
            throw err;
        }
    }
);


//テナント更新
export const putTenantSv = createAsyncThunk<boolean, { tenantId:string,value:MATenant, callback: (ok: boolean) => void }>(
    "tenants/putTenant",
    async (arg, { dispatch }) => {
        try {
            await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch)))
                .putTenant(arg.tenantId,arg.value);
            arg.callback(true);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false);
            throw err;
        }
    }
);

//テナント情報をID指定をして取得
export const getTenantSv = createAsyncThunk<MATenant, { tenantId:string,callback: (ok: boolean,tenant:TenantInfo|undefined) => void }>(
    "tenants/getTenant",
    async (arg, { dispatch }) => {
        try {
            var tenant = await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch)))
                .getTenant(arg.tenantId);
            arg.callback(true,tenant);
            return tenant;
        } catch (err) {
            console.log(err);
            arg.callback(false,undefined);
            throw err;
        }
    }
);


//プロジェクト一覧検索
export const findProjectsSv = createAsyncThunk<boolean, { tenantId:string,searchName:string,searchInvalid:boolean, callback: (ok: boolean, user: ProjectInfo[] | null) => void }>(
    "tenants/findProjects",
    async (arg, { dispatch }) => {
        try {
            var tenants = await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).findProjects(arg.tenantId,arg.searchName,arg.searchInvalid);
            arg.callback(true, tenants);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, null);
            throw err;
        }
    }
);

//プロジェクト情報取得
export const getProjectSv = createAsyncThunk<boolean, { tenantId:string,projectId:string, callback: (ok: boolean, user: ProjectInfo | null) => void }>(
    "tenants/getProject",
    async (arg, { dispatch }) => {
        try {
            var prj = await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getProject(arg.tenantId,arg.projectId);
            arg.callback(true, prj);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, null);
            throw err;
        }
    }
);
//利用可能なプロジェクトを追加できるかどうか確認
export const canIncrementActiveProject = createAsyncThunk<boolean, { tenantId:string, callback: (ok: boolean) => void }>(
    "tenants/canIncrementActiveProject",
    async (arg, { dispatch }) => {
        try {
            var v = await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).canIncrementActiveProject(arg.tenantId);
            arg.callback(v);
            return v;
        } catch (err) {
            console.log(err);
            arg.callback(false);
            throw err;
        }
    }
);

//テナント管理者かどうかの確認
/*export const isTenantAdminSv= createAsyncThunk<boolean, {tenantId:string, callback: (ok: boolean, admin: boolean) => void }>(
    "tenants/isTenantAdmin",
    async (arg) => {
        try {
            var isAdmin = await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance())).isTenantAdmin(arg.tenantId);
            arg.callback(true, isAdmin);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, false);
            throw err;
        }
    }
);*/

//プロジェクト登録
export const postProjectSv= createAsyncThunk<MAProject, {tenantId:string,project:MAProject, callback: (ok: boolean, reProject: MAProject|null,error:string|null) => void }>(
    "tenants/postProject",
    async (arg, { dispatch }) => {
        try {

            var rePrd = await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).postProject(arg.tenantId,arg.project);
            arg.callback(true, rePrd,null);
            return rePrd;
        } catch (err) {
            console.log(err);
            arg.callback(false, null,err.response);
            throw err;
        }
    }
);

//プロジェクト編集
export const putProjectSv= createAsyncThunk<void, {tenantId:string, project:MAProject,callback: (ok: boolean) => void }>(
    "tenants/putProject",
    async (arg, { dispatch }) => {
        try {
            await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).putProject(arg.tenantId,arg.project.id??"",arg.project);
            arg.callback(true);
        } catch (err) {
            console.log(err);
            arg.callback(false);
            throw err;
        }
    }
);
//プロジェクトの有効・無効の切り替え
export const setProjectInvalidSv= createAsyncThunk<void, {tenantId:string, projectId:string,invalid:boolean,callback: (ok: boolean,error:string|null) => void }>(
    "tenants/setProjectInvalid",
    async (arg, { dispatch }) => {
        try {
            await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).setProjectInvalid(arg.tenantId,arg.projectId,arg.invalid);
            arg.callback(true,null);
        } catch (err) {
            console.log(err);
            arg.callback(false,err.response);
            throw err;
        }
    }
);


//プロジェクト管理者取得
export const getProjectAdminsSv= createAsyncThunk<MAProject, {tenantId:string,projectId:string, callback: (ok: boolean, result: ProjectAdminResult|null) => void }>(
    "tenants/getProjectAdmins",
    async (arg, { dispatch }) => {
        try {
            var re = await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getProjectAdmins(arg.tenantId,arg.projectId);
            arg.callback(true, re);
            return re;
        } catch (err) {
            console.log(err);
            arg.callback(false, null);
            throw err;
        }
    }
);


//プロジェクト管理者取得
export const inviteProjectAdminSv= createAsyncThunk<InviteUserResult, {tenantId:string,projectId:string,name:string,email:string, callback: (ok: boolean, result: InviteUserResult|null) => void }>(
    "tenants/inviteProjectAdmin",
    async (arg, { dispatch }) => {
        try {
            var val = new InviteUser();
            val.name = arg.name;
            val.email = arg.email;

            var re = await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).inviteProjectAdmin(arg.tenantId,arg.projectId,val);
            arg.callback(true, re);
            return re;
        } catch (err) {
            console.log(err);
            arg.callback(false, null);
            throw err;
        }
    }
);

//現場管理者への切り替え
export const setUserAdminSv= createAsyncThunk<void, {tenantId:string,projectId:string,userId:string,toAdmin:boolean, callback: (ok: boolean) => void }>(
    "tenants/setUserAdmin",
    async (arg, { dispatch }) => {
        try {
            var val = new SetProjectAdmin();
            val.userId = arg.userId;
            val.toAdmin = arg.toAdmin;

            await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).setUserAdmin(arg.tenantId,arg.projectId,val);
            arg.callback(true);
        } catch (err) {
            console.log(err);
            arg.callback(false);
            throw err;
        }
    }
);

//現場削除
export const deleteProjectSv= createAsyncThunk<void, {tenantId:string,projectId:string, callback: (ok: boolean) => void }>(
    "tenants/deleteProject",
    async (arg, { dispatch }) => {
        try {

            await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).deleteProject(arg.tenantId,arg.projectId);
            arg.callback(true);
        } catch (err) {
            console.log(err);
            arg.callback(false);
            throw err;
        }
    }
);

//参加ユーザー一覧取得
export const getTenantUsersSv= createAsyncThunk<MAUser[], {tenantId:string, callback: (ok: boolean,users:MAUser[]|undefined) => void }>(
    "tenants/getTenantUsers",
    async (arg, { dispatch }) => {
        try {
            var users = await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getTenantUsers(arg.tenantId);
            arg.callback(true,users);
            return users;
        } catch (err) {
            console.log(err);
            arg.callback(false,undefined);
            throw err;
        }
    }
);

//テナントからユーザーを削除
export const deleteTenantUserSv= createAsyncThunk<void, {tenantId:string,userName:string, callback: (ok: boolean) => void }>(
    "tenants/deleteTenantUser",
    async (arg, { dispatch }) => {
        try {
            await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).deleteTenantUser(arg.tenantId,arg.userName);
            arg.callback(true);
        } catch (err) {
            console.log(err);
            arg.callback(false);
            throw err;
        }
    }
);

//テナント参加者へ承認のためのメールを再送信する
export const sendConfirmMailSv= createAsyncThunk<void, {tenantId:string,userName:string, callback: (ok: boolean) => void }>(
    "tenants/sendConfirmMail",
    async (arg, { dispatch }) => {
        try {
            await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).sendConfirmMail(arg.tenantId,arg.userName);
            arg.callback(true);
        } catch (err) {
            console.log(err);
            arg.callback(false);
            throw err;
        }
    }
);

//会社に新規プロジェクトを追加する
export const addInitialProjectSv= createAsyncThunk<void, {tenantId:string,type:string, callback: (ok: boolean) => void }>(
    "tenants/sendConfirmMail",
    async (arg, { dispatch }) => {
        try {
            await (new TenantV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).addInitialProject(arg.tenantId,arg.type);
            arg.callback(true);
        } catch (err) {
            console.log(err);
            arg.callback(false);
            throw err;
        }
    }
);