import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ConfirmAction = {
  title:string,
  message:string,
  callback:((ok:boolean) => void)
}
export type ConfirmDialogProp = {
  title:string,
  message:string,
  callback:((ok:boolean) => void) | undefined,
  open:boolean
}
const initialState: ConfirmDialogProp = {
  title:"",
  message: "",
  callback:undefined,
  open: false,
};

export const ConfirmDialogSlice = createSlice({
  name: "ConfirmDialog",
  initialState: initialState,
  reducers: {
    openConfirm: (state, action:PayloadAction<ConfirmAction>) => {
      state.open = true;
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.callback = action.payload.callback;
    },
    closeConfirm:(state,action) => {
      state.open = false;
      state.title = "";
      state.message = "";
      state.callback = undefined;
    }
  },
});



function ConfirmDialog() {
  const {title,message,callback,open} = useSelector((state: RootState) => state.confirm);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(ConfirmDialogSlice.actions.closeConfirm(undefined));
    if(callback){
      callback(false);
    }
  };
  const handleOK = () => {
    dispatch(ConfirmDialogSlice.actions.closeConfirm(undefined));
    if(callback){
      callback(true);
    }
  };

  return (
      <Dialog
      
      fullWidth={true}
      maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
          {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}  autoFocus>
            いいえ
          </Button>
          <Button onClick={handleOK} >
            はい
          </Button>
        </DialogActions>
      </Dialog>
  );
}
export default  ConfirmDialog;