
import React, { useEffect, useState } from 'react';
import { useParams,useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getProjectSv } from "../net/TenantAsync";

import moment from "moment-timezone"
import { MAContents, MAProject } from '../net/NetworkClient';
import CalendarToolbar from './CalendarToolbar';
import CalendarGrid from "./CalendarGrid";
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'; // or any other pipeline
import NewContentsDialog from './NewContentsDialog';
import ContentsDetailDialog from './ContentsDetailDialog';
import { FooterHeight } from '../Constants';


moment.tz.setDefault('Asia/Tokyo');


const useStyles = makeStyles((theme) => ({

    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "calc(100vh - 48px - "+FooterHeight+"px)",
    },
    contents: {
        height: "calc(100vh - 96px - "+FooterHeight+"px)",
    },
}));

function CalendarView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    //ログイン中のユーザー情報
    const user = useSelector(
        (state: RootState) => state.auth.user
    );


    const { tenantid } = useParams<{ tenantid: string }>();
    const { projectid } = useParams<{ projectid: string }>();


    const [project, setProject] = useState<MAProject>();
    const [currentDate, setCurrentDate] = useState(moment());
    const [loading, setLoading] = useState(false);

    //新規ダイアログ
    const [newContentsDate, setNewContentsDate] = useState(new Date());
    const [openNewDialog, setOpenNewDialog] = useState(false);

    //詳細ダイアログ
    const [openDetailDialog, setOpenDetailDialog] = useState(false);
    const [detailContents, setDetailContents] = useState<MAContents>();
    const [detailElement, setDetailElement] = useState<Element>();

    //選択中のテナント情報が変更された
    useEffect(() => {
        if (projectid && tenantid) {
            setLoading(true);
            dispatch(getProjectSv({
                tenantId: tenantid, projectId: projectid, callback: (ok, prj) => {
                    setLoading(false);
                    if (ok && prj) {
                        setProject(prj.project);
                    }
                }
            }));
        } else {
            setProject(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectid, tenantid]);

    if (!user)
        return null;
    return (

        <DndProvider options={HTML5toTouch}>
            <Box className={classes.root}>
                <CalendarToolbar
                    date={currentDate}
                    project={project}
                    loading={loading}
                    onChangeDate={(date) => {
                        setCurrentDate(date);
                    }}
                />

                <CalendarGrid
                    month={currentDate}
                    project={project}
                    setLoading={(ldg) => { setLoading(ldg) }}
                    onClickContents={(contents, element) => {
                        setDetailContents(contents);
                        setDetailElement(element);
                        setOpenDetailDialog(true);
                    }}
                    onCreate={(date) => {
                        setNewContentsDate(date.toDate());
                        setOpenNewDialog(true);
                    }}>
                </CalendarGrid>
            </Box>
            <NewContentsDialog
                open={openNewDialog}
                tenantId={tenantid}
                projectId={project?.id ?? ""}
                initialDate={newContentsDate}
                onClose={(ok, id) => {
                    setOpenNewDialog(false);
                    if (ok && id) {
                        setOpenNewDialog(false);
                        history.push("/contents/" + id);
                    }
                }}
            />
            <ContentsDetailDialog
                open={openDetailDialog}
                contents={detailContents}
                position={detailElement}
                onClose={()=>{
                    console.log("onclose ContentsDetailDialog")
                    setOpenDetailDialog(false);
                    setDetailContents(undefined);
                    setDetailElement(undefined);
                }}
            />
        </DndProvider>
    )
}


export default CalendarView;
