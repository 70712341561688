import React, { useEffect } from "react";
import {
  Popover,
  Typography,
  Tabs,
  Tab,
  AppBar,
  makeStyles,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Dialog,
  Toolbar,
  Button,
  DialogContent,
  Box,
  Grid,
  DialogTitle,
  TextField,
  DialogActions,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setTimeout } from "timers";
import ContentsSimpleViewer from "../misc/ContentsSimpleViewer";
import { MAFile, MARollCall, MorningAssemblyJson, MAContents } from "../net/NetworkClient";
import { WaitingSlice } from "../misc/WaitingBar";

/*import {
  createContentsSv,
  deleteContentsSv,
  getContentsDataSv,
  getRollCallSv,
} from "../net/ContentsAsync";*/
//import { getContentsFilesSv } from "../net/FileAsync";

import { getContentsDataSv, getContentsRollCallsSv, getContentsFilesSv, postContentsSv, deleteContentsSv, getExcelDataSv } from "../net/CalendarAsync";
import InfoIcon from "@material-ui/icons/Info";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { green, red, grey } from "@material-ui/core/colors";
import ActionSlice, { ActionType } from "../misc/ActionSlice";
import { ConfirmDialogSlice } from "../misc/ConfirmDialog";
import { AlertDialogSlice } from "../misc/AlertDialog";

moment.tz.setDefault('Asia/Tokyo');
var classNames = require("classnames");

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    height: "350px",
    overflow: "auto",
    padding: "5px",
  },
  infoIcon: {
    color: theme.palette.common.white,
  },
  viewerImgH: {
    width: "auto",
    height: "100%",
    marginTop: theme.spacing(2),
  },
  viewerImgW: {
    width: "100%",
    height: "auto",
    marginTop: theme.spacing(2),
  },

  saveBtn: {
    marginLeft: "auto",
  },
  rollcall: {
    marginLeft: "30px",
    display: "inline-flex",
  },
  checkIcon: {
    marginRight: "5px",
    color: "green",
  },
  actionBtn: {
    margin: "3px",
  },
}));

const ContentsDetailDialog: React.FunctionComponent<{
  open: boolean;
  contents?: MAContents;
  position?: Element;
  onClose: () => void;
}> = ({ open, contents, position, onClose }) => {

  var dispatch = useDispatch();
  var classes = useStyles();
  const history = useHistory();

  const [tabValue, setTabValue] = React.useState(0);

  const [contentsData, setContentsData] = React.useState<
    MorningAssemblyJson | undefined
  >(undefined);
  const [filesData, setFilesData] = React.useState<MAFile[] | undefined>(
    undefined
  );

  const [rollcallsData, setRollcallsData] = React.useState<
    MARollCall[] | undefined
  >(undefined);
  const [selectedFile, setSelectedFile] = React.useState<MAFile>();

  const [selectedFileLandscape, setSelectedFileLandscape] = React.useState(true);
  const [dupPopOpen, setDupPopOpen] = React.useState(false);

  const [dupDate, setDupDate] = React.useState(moment());
  // const [downloadPath, setDownloadPath] = React.useState("");

  const handleClose = () => {
    setContentsData(undefined);
    setFilesData(undefined);
    setRollcallsData(undefined);
    onClose();
  };
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  const handleCloseDialog = () => {
    setSelectedFile(undefined);
  };


  var selectedImageClass = classNames(
    { [classes.viewerImgH]: !selectedFileLandscape },
    { [classes.viewerImgW]: selectedFileLandscape }
  );

  //ダイアログ表示情報が変更されたとき
  useEffect(() => {
    if (open && contents) {
      setTabValue(0);
      setContentsData(undefined);
      setFilesData(undefined);
      setRollcallsData(undefined);
      dispatch(WaitingSlice.actions.beginWaiting("読み込み中..."));
      dispatch(
        getContentsDataSv({
          contentsId: contents?.id ?? "",
          callback: (ok, data) => {
            if (ok && data) {
              setContentsData(data);
              //   setDownloadPath(SERVER_URL+"/api/print/"+contents?.projectId+"/excel?contentsId="+contents?.id);

            }
            dispatch(WaitingSlice.actions.stopWaiting(undefined));
            if(!ok){
              handleClose();
            }
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, contents]);

  //タブが切り替わったとき
  useEffect(() => {
    if (open) {
      //KYにタブ変更
      if (tabValue === 1 && filesData === undefined) {
        dispatch(WaitingSlice.actions.beginWaiting("KYデータ読み込み中"));
        dispatch(
          getContentsFilesSv({
            contentsId: contents?.id ?? "",
            callback: (ok, files) => {
              if (ok && files) {
                setFilesData(files);
              } else {
                setFilesData([]);
              }
              dispatch(WaitingSlice.actions.stopWaiting(undefined));
            },
          })
        );
      } else if (tabValue === 2 && rollcallsData === undefined) {
        dispatch(WaitingSlice.actions.beginWaiting("参加データ読み込み中"));
        dispatch(
          getContentsRollCallsSv({
            contentsId: contents?.id ?? "",
            callback: (ok, rollcalls) => {
              if (ok && rollcalls) {
                setRollcallsData(rollcalls);
              } else {
                setRollcallsData([]);
              }
              dispatch(WaitingSlice.actions.stopWaiting(undefined));
            },
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  //---------------------
  //編集ハンドラー
  function OnEdit() {
    handleClose();
    history.push("/contents/" + contents?.id ?? "");
  }
  //---------------------
  //削除確認コールバック
  function OnDeleteConfirm() {
    dispatch(
      ConfirmDialogSlice.actions.openConfirm({
        title: "削除の確認",
        message: "本当に削除してもよろしいですか？",
        callback: (ok) => {
          if (ok) {
            //項目削除の確認
            dispatch(ActionSlice.actions.clearAction(undefined));
            dispatch(WaitingSlice.actions.beginWaiting("削除中"));
            dispatch(
              deleteContentsSv({
                contentsId: contents?.id ?? "",
                callback: (ok) => {
                  dispatch(WaitingSlice.actions.stopWaiting(undefined));
                  if (ok) {
                    dispatch(
                      ActionSlice.actions.doAction({
                        type: ActionType.CHANGE_CONTENTS_IN_CAL
                      })
                    );
                    handleClose();
                  }
                },
              })
            );
          }
        }
      })
    );
  }

  //---------------------
  //複製処理
  function OnDuplicate() {
    dispatch(WaitingSlice.actions.beginWaiting("複製中..."));
    var json = MorningAssemblyJson.fromJS(
      JSON.parse(JSON.stringify(contentsData))
    );

    var start = moment(contentsData?.start);
    var end = moment(contentsData?.end);
    const newStart = moment(dupDate)
      .hour(start.hour())
      .minute(start.minute())
      .second(0)
      .millisecond(0);
    const newEnd = newStart.clone().add(end.diff(start));
    json.start = newStart.utc().toDate();
    json.end = newEnd.utc().toDate();
    dispatch(
      postContentsSv({
        projectId: json.projectId ?? "",
        data: json,
        callback: (ok, contents) => {
          if (ok && contents) {
            dispatch(
              ActionSlice.actions.doAction({
                type: ActionType.CHANGE_CONTENTS_IN_CAL
              })
            );
            handleClose();
          }
          dispatch(WaitingSlice.actions.stopWaiting(undefined));
        },
      })
    );

  }


  /**
   * 出力ボタンが押されたときの処理
   */
  function onExcel() {
    dispatch(WaitingSlice.actions.beginWaiting("ファイル生成中..."));
    dispatch(
      getExcelDataSv({
        contentsId: contents?.id ?? "",
        callback: (ok, url) => {
          if (ok && url) {
            /*
            const blob = new BlockBlobClient(url);
            blob.download();
            console.log(url);*/
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            dispatch(
              AlertDialogSlice.actions.openAlert({
                title: "エラー",
                message: "データ生成に失敗しました。",
              })
            );
          }
          dispatch(WaitingSlice.actions.stopWaiting(undefined));
        },
      })
    );

  }



  return (
    <div>
      <Popover
        open={open}
        anchorEl={position}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        style={{ width: 600 }}
      >
        <div>
          <AppBar position="static">
            <Tabs
              action={(r) => {
                if (r) {
                  setTimeout(() => {
                    if (open) {
                      r.updateIndicator();
                    }
                  }, 200);
                }
              }}
              value={tabValue}
              onChange={handleTabChange}
              centered
            >
              <Tab label="内容" />
              <Tab label="KY写真" />
              <Tab label="参加確認" />
            </Tabs>
          </AppBar>
          <div hidden={tabValue !== 0} className={classes.tabPanel}>
            <ContentsSimpleViewer
              contentsOnly={false}
              data={contentsData}
            ></ContentsSimpleViewer>
          </div>
          <div hidden={tabValue !== 1} className={classes.tabPanel}>
            <div hidden={(filesData?.length ?? 0) > 0}>
              KY写真は登録されていません
            </div>

            <GridList
              cellHeight={200}
              cols={2}
              spacing={2}
              style={{ marginRight: "40px" }}
            >
              {(filesData ?? []).map((val) => {
                if (val.kind !== "kyActivity") {
                  return null;
                }
                return (
                  <GridListTile key={val.id} cols={1}>
                    <img src={val.path} alt="thumbnail"></img>
                    <GridListTileBar
                      title={moment(val.createUTC).format("HH:mm:ss")}
                      subtitle={<span>{val.userName}</span>}
                      actionIcon={
                        <IconButton
                          onClick={() => {
                            setSelectedFile(val);
                          }}
                        >
                          <InfoIcon className={classes.infoIcon} />
                        </IconButton>
                      }
                    />
                  </GridListTile>
                );
              })}
            </GridList>
          </div>
          <div hidden={tabValue !== 2} className={classes.tabPanel}>
            <div hidden={(rollcallsData?.length ?? 0) > 0}>
              参加情報は登録されていません
            </div>
            {(rollcallsData ?? []).map((val, idx) => {
              return (
                <Grid key={val.id} item xs={12}>
                  <Typography className={classes.rollcall}>
                    <HowToRegIcon className={classes.checkIcon} />
                    {val.name}({moment(val.createdUTC).format("HH:mm:ss")})
                  </Typography>
                </Grid>
              );
            })}
          </div>
          <hr style={{ marginTop: "1px" }} />
          <div style={{ width: "100%", display: "flex", marginBottom: "8px" }}>
            <Button
              className={classes.actionBtn}
              variant="contained"
              style={{
                marginLeft: "8px",
                backgroundColor: red[500],
                color: "white",
              }}
              onClick={() => {
                OnDeleteConfirm();
              }}
            >
              <DeleteIcon></DeleteIcon>
              削除
            </Button>
            <Button
              className={classes.actionBtn}
              variant="contained"
              style={{ marginLeft: "auto", color: grey[800] }}
              onClick={onExcel}
            >
              <GetAppIcon></GetAppIcon>
              出力
            </Button>
            <Button
              className={classes.actionBtn}
              variant="contained"
              style={{ marginLeft: "8px", color: grey[800] }}
              id={"contentsDuplicateButton"}
              onClick={() => {
                setDupDate(moment(contentsData?.start));
                setDupPopOpen(true);
              }}
            >
              <FileCopyIcon></FileCopyIcon>
              複製
            </Button>
            <Button
              style={{
                marginRight: "8px",
                backgroundColor: green[500],
                color: "white",
              }}
              className={classes.actionBtn}
              variant="contained"
              onClick={() => {
                OnEdit();
              }}
            >
              <EditIcon></EditIcon>
              編集
            </Button>
          </div>
        </div>
      </Popover>

      <Dialog
        onClose={() => {
          setDupPopOpen(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={dupPopOpen}
      >
        <DialogTitle id="simple-dialog-title">
          複製先の日付を指定してください
        </DialogTitle>

        <DialogContent>
          <TextField
            type="date"
            value={dupDate.format("YYYY-MM-DD")}
            onChange={(e) => {
              setDupDate(moment(e.target.value));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDupPopOpen(false);
            }}
            autoFocus
          >
            キャンセル
          </Button>
          <Button
            onClick={() => {
              setDupPopOpen(false);
              OnDuplicate();
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!selectedFile} onClose={handleCloseDialog} fullScreen>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {selectedFile
                ? moment(selectedFile.createUTC).format("YYYY/MM/DD HH:mm:ss") +
                "(" +
                selectedFile.userName +
                ")"
                : ""}
            </Typography>
            <Button
              href={selectedFile?.path ?? ""}
              download={selectedFile?.originalName ?? ""}
              className={classes.saveBtn}
              target="_blank"
            >
              <GetAppIcon />
              ダウンロード
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent>
          {selectedFile && (
            <Box style={{ height: "calc(100% - 90px)" }}>
              <Toolbar></Toolbar>
              <img
                id={"sourceImage"}
                src={selectedFile.path}
                alt={"ky活動"}
                className={selectedImageClass}
                onLoad={() => {
                  var img = document.getElementById("sourceImage") as HTMLImageElement;
                  if (img) {
                    setSelectedFileLandscape(img.naturalHeight < img.naturalWidth);
                  }
                }}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ContentsDetailDialog;
