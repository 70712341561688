import { TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { ItemTypeKey } from "../../Constants";
import { ContentsJson, DetailJson, MorningAssemblyJson, QuestionJson } from "../../net/NetworkClient";
import ContentsItemListBase, { DataType, IContentsItemListDelegate, IItemCardContentsProp } from "./ContentsItemListBase";
import { useDebounce } from "use-debounce";
import { v4 as uuid } from "uuid";


///テキストパネル
const ContentsItemQuestionnaire: React.FunctionComponent<{
    contentsid: string | undefined;
    jsonData: MorningAssemblyJson | undefined;
    selectedItem: { key: string; id: string } | undefined;
    onUpdate: (newData: ContentsJson) => void;
    onChanging: (changing: boolean) => void;
}> = ({ contentsid, jsonData, selectedItem, onUpdate, onChanging }) => {

    return (
        <ContentsItemListBase
            contentsid={contentsid ?? ""}
            delegate={new QuestionnaireDelegate()}
            cardContents={QuestionnaireCardContents}
            jsonData={jsonData}
            selectedItem={selectedItem}
            onUpdate={onUpdate}
            onChanging={onChanging}
        >

        </ContentsItemListBase>
    )
}

///テキスト処理デリゲート
class QuestionnaireDelegate implements IContentsItemListDelegate<QuestionJson>{
    getUUID(item: QuestionJson): string {
        return item.uuid ?? "";
    }
    isSuported(type: string | undefined): boolean {
        return type === ItemTypeKey.general_questionnaire;
    }

    copyData(data: DataType<QuestionJson>): DataType<QuestionJson> {
        return {
            title: "" + data.title,
            list: data.list.map((p) => new QuestionJson(p)),
        }
    }

    contentsToData(contents: ContentsJson): DataType<QuestionJson> {
        return {
            title: contents.title ?? "",
            list: contents.details?.questions ?? []
        }
    }

    buildContentsJson(contents: ContentsJson, data: DataType<QuestionJson>): ContentsJson {
        var copy = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));
        copy.title = "" + data.title;

        const tmp = new DetailJson();
        tmp.questions = data.list;
        const detail = DetailJson.fromJS(JSON.parse(JSON.stringify(tmp)));
        copy.details = detail;
        return copy;
    }

    makeNewItem(): QuestionJson {
        var re = new QuestionJson();
        re.uuid = uuid();
        return re;
    }
}


///テキストのカード内容
const QuestionnaireCardContents: React.FunctionComponent<IItemCardContentsProp>
    = ({ index, item, contents, onUpdate, onChanging }) => {

        const [data, setData] = React.useState<string>(item instanceof QuestionJson ? item.question ?? "" : "");

        //OnChangeの度にJsonを更新すると処理が重たいので、debounce処理
        const [debouncedData] = useDebounce(data, 500);
        useEffect(() => {
            if (item instanceof QuestionJson) {
                if (item.question !== data) {
                    updateContentsJson();
                }
            }
            return;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [debouncedData]);

        //データ更新
        function updateContentsJson() {
            const prec = new QuestionJson(item);

            prec.question = data;

            var newdata = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));

            if (!newdata.details) newdata.details = new DetailJson();
            newdata.details.questions = newdata.details?.questions?.map((p) =>
                p.uuid === prec.uuid ? prec : p
            );
            if (onUpdate)
                onUpdate(newdata);
            if (onChanging)
                onChanging(false);
        }


        if (!item) return null;

        return (
            <TextField
                name="item"
                label="質問内容"
                value={data}
                fullWidth
                error={!data}
                helperText={!data ? "値を入力して下さい" : ""}
                onChange={(e) => {
                    if (onChanging)
                        onChanging(true);
                    setData(e.target.value);
                }}
            ></TextField>
        )
    }


export default ContentsItemQuestionnaire;
