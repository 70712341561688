import React, { useEffect } from 'react';
import {

    makeStyles,
    Grid, Paper, Divider, Link, Box
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store";

import { CurrentPaymentInfo, MAOrder, TenantInfo } from '../net/NetworkClient';
import moment from "moment-timezone";
import { ButtonBackGround } from '../Constants';
import { GetCurrentPaymentInfoSv, StopSubscriptionSv } from '../net/PaymentAsync';
import { WaitingSlice } from '../misc/WaitingBar';
import { AlertDialogSlice } from '../misc/AlertDialog';
import OrderHistoryDialog from './OrderHistoryDialog';
import NewPaymentDialog from './NewPaymentDialog';
import { tenantFindSv } from '../net/TenantAsync';
import TenantSlice from '../tenant/TenantSlice';
import { ConfirmDialogSlice } from '../misc/ConfirmDialog';

moment.tz.setDefault('Asia/Tokyo');


const useStyles = makeStyles((theme) => ({

    paperSytle: {
        height: "100%",
        overflowX: "hidden",
        overflowY: "auto"
    },
    columnStyle: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.grey[200]
    },

    columnDataStyle: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.grey[200],
        textAlign: "right"
    },
    linkStyle: {
        color: ButtonBackGround
    },
    divider: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1)
    },
    caution: {
        color: theme.palette.error.main,
        fontSize: "0.9em",
        paddingTop: theme.spacing(2)
    }

}));

const AdminLicenseView: React.FunctionComponent<{
    active: boolean;
    tenantid: string;
    setLoading: (loading: boolean) => void;
}> = ({ active, tenantid, setLoading }) => {
    var classes = useStyles();
    var dispatch = useDispatch();

    const tenants = useSelector(
        (state: RootState) => state.tenants.tenants
    );

    const [currentTenant, setCurrentTenant] = React.useState<TenantInfo>();

    const [openNewPaymentDialog, setOpenNewPaymentDialog] = React.useState(false);

    const [openHistoryDialog, setOpenHistoryDialog] = React.useState(false);

    // const [name, setName] = React.useState('');

    // const [mailAddress, setMailAddress] = React.useState('');

    const [currentOrder, setCurrentOrder] = React.useState<MAOrder | undefined>();
    const [currentInfo, setCurrentInfo] = React.useState<CurrentPaymentInfo>();


    const [currentPlans, setCurrentPlans] = React.useState<MAOrder[] | undefined>();

    const [registPlans, setRegistPlans] = React.useState<MAOrder[] | undefined>();

    //選択中のテナント情報が変更された
    useEffect(() => {
        if (!!tenantid && !!tenants) {
            var cur = tenants.find(t => { return tenantid === t.tenant?.id });
            //if (cur?.tenant?.id !== currentTenant?.tenant?.id) {
            setCurrentTenant(cur ?? undefined);
            //setName(cur?.tenant?.name + "　" + user?.name);
            //setMailAddress(user?.email ?? "");
            refreshInfo();
            //}
        } else {
            setCurrentTenant(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantid, tenants]);

    //初期設定
    useEffect(() => {
        // refreshPayments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshInfo = () => {
        dispatch(WaitingSlice.actions.beginWaiting("接続中..."));
        dispatch(GetCurrentPaymentInfoSv({
            tenantId: tenantid, callback: (ok, info) => {
                dispatch(WaitingSlice.actions.stopWaiting(undefined));
                if (ok && info) {
                    setCurrentInfo(info);
                    if (info.currentOrders && info.currentOrders.length > 0) {
                        setCurrentPlans(info.currentOrders.filter((o) => {return !o.paid && !o.registered }));
                        setRegistPlans(info.currentOrders.filter((o) => {return  !o.paid && o.registered }));
                    } else {
                        setCurrentPlans(undefined);
                        setRegistPlans(undefined);
                    }
                } else {
                    setCurrentInfo(undefined);
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "現在の支払い状況を取得することができませんでした。",
                        })
                    );
                }
            }
        }));
    }

    const stopSubscription = ()=>{
        dispatch(
            ConfirmDialogSlice.actions.openConfirm({
                title: "請求停止の確認",
                message:
                    "現在の請求を停止しますか？",
                callback: (inok) => {
                    if (inok) {
                        dispatch(StopSubscriptionSv({
                            tenantId: tenantid , callback: (ok) => {
                                if (!ok) {
                                    dispatch(
                                        AlertDialogSlice.actions.openAlert({
                                            title: "エラー",
                                            message: "請求の停止に失敗しました",
                                        })
                                    );
                                } else {
                                    refreshInfo();
                                }
                            }
                        }))
                    }
                }
            })
        );
    }

    const numberWithDelimiter = (number: string | number) => {
        return String(number).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }


    return (
        <React.Fragment>
            <Paper className={classes.paperSytle}>



                <Grid container spacing={1}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>お支払い情報<br />
                        <Divider light className={classes.divider} /></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>

                <Box hidden={(currentInfo && currentInfo.currentOrders && currentInfo.currentOrders.length > 0) && !currentInfo.stopSubscription}>
                    <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3} className={classes.columnStyle}>新規プラン設定</Grid>
                        <Grid item xs={7} className={classes.columnStyle}>
                            <Link href="#" onClick={() => { setCurrentOrder(undefined); setOpenNewPaymentDialog(true); }} className={classes.linkStyle}>
                                プラン設定とお支払い
                            </Link>
                            <Box className={classes.caution} hidden={currentInfo && !currentInfo.stopSubscription}>
                                （現在、次の請求を停止しています。）
                            </Box>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Box>


                <Box hidden={(!currentInfo || !currentInfo.currentOrders || currentInfo.currentOrders.length <= 0 || currentInfo.stopSubscription) }>
                    <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3} className={classes.columnStyle}>プラン設定</Grid>
                        <Grid item xs={7} className={classes.columnStyle}>
                            <Link href="#" onClick={() => {
                                if (currentInfo && currentInfo.currentOrders)
                                    setCurrentOrder(currentInfo?.currentOrders[0]);
                                setOpenNewPaymentDialog(true);
                            }} className={classes.linkStyle}>
                                現在のプランを変更
                            </Link>
                            <br />
                            <Link href="#" onClick={() => { stopSubscription(); }} className={classes.linkStyle}>
                                次回からの請求を停止
                            </Link>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Box>

                <Grid container spacing={1}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} className={classes.columnStyle}>現在のプラン</Grid>
                    <Grid item xs={7} className={classes.columnStyle}>
                        {(
                            (currentPlans && currentPlans.length > 0) ?
                                currentPlans.map((order, idx) => {
                                    return (
                                        <React.Fragment key={order.id}>
                                            {order.licenses?.map((license) => {
                                                return (
                                                    <div key={license.id}>
                                                        
                                                        {idx <= 0 ? <></> : <br/>}
                                                        <span>{license.name ?? ""}</span>
                                                        <br /><span>{"有効期間:" + moment(order.startUTC).add(1, "minutes").format("YYYY/MM/DD")
                                                            + "～" + moment(order.endUTC).format("YYYY/MM/DD")}</span>

                                                        <br /><span>{"料金:" + numberWithDelimiter(order.price ?? 0) + "円"}</span>
                                                    </div>)
                                            })}
                                        </React.Fragment>)

                                })
                                :
                                "現在ご利用中のプランはありません"
                        )}
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} className={classes.columnStyle}>請求確定</Grid>
                    <Grid item xs={7} className={classes.columnStyle}>
                        {(
                            (registPlans && registPlans.length > 0) ?
                                registPlans.map((order, idx) => 
                                    (
                                        <React.Fragment key={order.id}>
                                            {order.licenses?.map((license) => {
                                                return (
                                                    <div key={license.id}>
                                                        {idx <= 0 ? <></> : <br/>}
                                                        <span>{license.name ?? ""}</span>
                                                        <br /><span>{"有効期間:" + moment(order.startUTC).add(1, "minutes").format("YYYY/MM/DD")
                                                            + "～" + moment(order.endUTC).format("YYYY/MM/DD")}</span>

                                                        <br /><span>{"料金:" + numberWithDelimiter(order.price ?? 0) + "円"}</span>
                                                    </div>)
                                            })}
                                        </React.Fragment>)
                                )
                                :
                                "請求確定したプランはありません"
                        )}
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} className={classes.columnStyle}>次回のご請求</Grid>
                    <Grid item xs={7} className={classes.columnStyle}>
                        {(
                            (currentInfo && currentInfo.nextOrders && currentInfo.nextOrders.length > 0) ?
                                currentInfo.nextOrders.map((order, idx) => {
                                    return (
                                        <React.Fragment key={"order" + idx}>
                                            {idx <= 0 ? <></> : <br />}
                                            {order.licenses?.map((license, idx2) => {
                                                return (
                                                    <div key={"license" + idx2}>
                                                        <span>{license.name ?? ""}</span>
                                                        <br /><span>{"有効期間:" + moment(order.startUTC).add(1, "minutes").format("YYYY/MM/DD")
                                                            + "～" + moment(order.endUTC).format("YYYY/MM/DD")}</span>
                                                    </div>)
                                            })}
                                            {order.missionCode ?? 0 >= 21 ? "※ご利用前月の２５日にご登録のクレジットカードから継続課金されます" : ""}
                                        </React.Fragment>)
                                })
                                :
                                "現在ご利用中のプランはありません"
                        )}

                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>

                <Grid container spacing={1}>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} className={classes.columnStyle}>履歴</Grid>
                    <Grid item xs={7} className={classes.columnStyle}>

                        <Link href="#" onClick={() => { setOpenHistoryDialog(true); }} className={classes.linkStyle}>
                            お支払い履歴
                        </Link>
                    </Grid>
                    <Grid item xs={1}></Grid>

                </Grid>

            </Paper>
            <NewPaymentDialog open={openNewPaymentDialog} tenant={currentTenant?.tenant} currentOrder={currentOrder} onClose={(update) => {
                setOpenNewPaymentDialog(false);
                if (update) {
                    dispatch(tenantFindSv({
                        callback: (ok, tenants) => {
                            dispatch(TenantSlice.actions.setTenants(tenants ?? []));
                            if (!ok) {
                                dispatch(
                                    AlertDialogSlice.actions.openAlert({
                                        title: "エラー",
                                        message: "会社情報を取得できませんでした。",
                                    })
                                );
                            }
                        }
                    }));
                }
            }}></NewPaymentDialog>
            <OrderHistoryDialog open={openHistoryDialog} tenantId={tenantid} onClose={() => { setOpenHistoryDialog(false); }}></OrderHistoryDialog>
        </React.Fragment>
    );
}



export default AdminLicenseView;