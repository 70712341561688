import moment from "moment-timezone";
import React, { useEffect, useState, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelIcon from '@material-ui/icons/Label';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';


import InvoiceBanner from "../images/bn_468x78.png";

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField, Divider, Link, DialogActions, Radio, FormControlLabel, Typography, IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { AlertDialogSlice } from "../misc/AlertDialog";
import {
    MAOrder,
    MATenant,
    PostNPInvoiceInfo,
} from "../net/NetworkClient";

import { RootState } from "../Store";
import { PostNPInvoiceSv } from "../net/PaymentAsync";
import { ButtonBackGround, emailValidation, numberWithDelimiter, phoneNumberValidation, PostalCodeFormat, postalCodeValidation } from "../Constants";
import { WaitingSlice } from "../misc/WaitingBar";
import { ButtonGroup } from "@material-ui/core";

let YubinBango = require('yubinbango-core2')

moment.tz.setDefault('Asia/Tokyo');

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

const useStyles = makeStyles((theme) => ({
    top: {
        position: "absolute",
        right: 25,
        top: 20,
    },
    historyList: {

        overflowX: "hidden",
        overflowY: "auto"
    },
    linkStyle: {
        color: ButtonBackGround
    },
    gridCenter: {
        alignSelf: "flex-start",
        fontSize: "1rem",
        height: theme.spacing(6)
    },
    divider: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1)
    },
    notation: {
        fontSize: "0.7rem",
        paddingLeft: theme.spacing(1)
    },
    textField: {
        //marginTop: theme.spacing(1),
        //marginBottom: theme.spacing(1)
    },
    postalCodeField: {
        //marginTop: theme.spacing(1),
        width: "180px"
    },
    amountTextStyle: {
        display: "flex",
        alignItems: "center",
        fontSize: "2rem",
        justifyContent: "center"
    },
    payBtn: {
        color: "white",
        backgroundColor: ButtonBackGround
    },
    actionBox: {
        width: "100%"
    },
    postalDiv: {
        display: "flex",
        alignItems: "baseline"
    },
    sendTypeBtnGroup: {
        width: "100%"
    },
    sendTypeBtnSelected: {
        color: "white",
        backgroundColor: ButtonBackGround,
        width: "50%",
    },
    sendTypeBtn: {
        color: "white",
        backgroundColor: theme.palette.grey[500],
        width: "50%",
    },
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}


type InvoiceData = {
    mailAddress: string,
    tenantName: string,
    departmentName: string,
    chargePerson: string,
    phoneNumber: string,
    postalCode: string,
    region: string,
    address1: string,
    address2: string,
    address3: string,
    invoiceByEmail: boolean
}

const NewPaymentDialog: FunctionComponent<{
    open: boolean;
    tenant: MATenant | undefined;
    currentOrder: MAOrder | undefined;
    onClose: (update: boolean) => void;
}> = ({ open, onClose, tenant, currentOrder }) => {
    const classes = useStyles();

    const user = useSelector(
        (state: RootState) => state.auth.user
    );

    const [projectCount, setProjectCount] = useState(10);
    const [paytype, setPaytype] = useState("INVOICE");

    //const [name, setName] = React.useState('');
    //const [mailAddress, setMailAddress] = React.useState('');

    const [page, setPage] = React.useState(0);

    const [regions, setRegions] = React.useState<string[]>([]);

    const [orderResult, setOrderResult] = React.useState<MAOrder>();

    const emptyInvoiceData: InvoiceData = {
        mailAddress: "",
        tenantName: "",
        departmentName: "",
        chargePerson: "",
        phoneNumber: "",
        postalCode: "",
        region: "",
        address1: "",
        address2: "",
        address3: "",
        invoiceByEmail: false
    };

    const [invoiceData, setInvoiceData] = React.useState<InvoiceData>(emptyInvoiceData);
    const [invoiceError, setInvoiceError] = React.useState<InvoiceData>(emptyInvoiceData);

    const dispatch = useDispatch();

    //選択中のテナント情報が変更された
    useEffect(() => {
        if (!!tenant) {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant]);

    //架け払いのデータが変更された
    useEffect(() => {
        var error: InvoiceData = {
            mailAddress: "",
            tenantName: "",
            departmentName: "",
            chargePerson: "",
            phoneNumber: "",
            postalCode: "",
            region: "",
            address1: "",
            address2: "",
            address3: "",
            invoiceByEmail: false
        };

        error.tenantName = !invoiceData.tenantName ? "会社名を入力してください" : "";

        error.postalCode = !invoiceData.postalCode ? "郵便番号を入力してください" :
            !postalCodeValidation(invoiceData.postalCode) ? "不正な形式です" : "";

        error.mailAddress = !invoiceData.mailAddress ? "メールアドレスを入力してください" :
            !emailValidation(invoiceData.mailAddress) ? "不正な形式です" : "";

        error.phoneNumber = !invoiceData.phoneNumber ? "電話番号を入力してください" :
            !phoneNumberValidation(invoiceData.phoneNumber) ? "不正な形式です" : "";


        error.address1 = !invoiceData.address1 ? "市区町村を入力してください" : "";
        error.address2 = !invoiceData.address2 ? "町名・番地を入力してください" : "";

        var add = invoiceData.region + invoiceData.address1 + invoiceData.address2 + invoiceData.address3;
        if (!error.address1 && !error.address1 && add.length > 50) {
            error.address1 = error.address2 = error.address3 = "住所は全体で50文字以内になるように入力してください";
        }

        setInvoiceError(error);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceData]);


    //選択中のテナント情報が変更された
    useEffect(() => {
        if (open) {
            setPaytype("INVOICE");
            setRegions(new YubinBango.Core().REGION);

            setPage(0);
            if (tenant) {
                //setName(tenant.name + "　" + user?.name);

                var data: InvoiceData = {
                    mailAddress: tenant.mailAddress ?? user?.email ?? "",
                    tenantName: tenant.nameForInvoice ?? (tenant.name ?? ""),
                    departmentName: tenant.departmentName ?? "",
                    chargePerson: tenant.chargePerson ?? (user?.name ?? ""),
                    phoneNumber: tenant.phoneNumber ?? "",
                    postalCode: tenant.postalCode ?? "",
                    region: tenant.region ?? "",
                    address1: tenant.address1 ?? "",
                    address2: tenant.address2 ?? "",
                    address3: tenant.address3 ?? "",
                    invoiceByEmail: tenant.invoiceByEmail ?? false
                };
                setInvoiceData(data);
            }
            if (currentOrder != null) {
                var prjcount = 0;
                currentOrder.licenses?.map((l) => {
                    prjcount += (l.projectCount ?? 0);
                })
                if (prjcount < 10)
                    prjcount = 10;
                setProjectCount(prjcount);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const projectCountList = (): number[] => {
        var re: number[] = [];
        var i = 0;
        re[i++] = 10;
        re[i++] = 15;
        re[i++] = 20;
        re[i++] = 25;
        re[i++] = 30;
        re[i++] = 40;
        re[i++] = 50;
        re[i++] = 75;
        re[i++] = 100;

        return re;
    }


    const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const postNVInvoice = () => {

        dispatch(WaitingSlice.actions.beginWaiting("接続中..."));
        var info = new PostNPInvoiceInfo();
        info.timeSpanMonth = 1;
        info.projectCount = projectCount;
        info.mailAddress = invoiceData.mailAddress;
        info.tenantName = invoiceData.tenantName;
        info.departmentName = invoiceData.departmentName;
        info.chargePerson = invoiceData.chargePerson;
        info.phoneNumber = invoiceData.phoneNumber;
        info.postalCode = invoiceData.postalCode;
        info.region = invoiceData.region;
        info.address1 = invoiceData.address1;
        info.address2 = invoiceData.address2;
        info.address3 = invoiceData.address3;
        info.invoiceByEmail = invoiceData.invoiceByEmail;

        dispatch(PostNPInvoiceSv({
            tenantId: tenant?.id ?? "", info: info, callback: (ok, order) => {
                dispatch(WaitingSlice.actions.stopWaiting(undefined));
                setOrderResult(order);
                if (ok && order) {
                    setPage(3);
                } else {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "購入情報の登録に失敗しました",
                        })
                    );
                }
            }
        }));
    }

    const checkError = (): boolean => {
        return (!invoiceError.mailAddress &&
            !invoiceError.tenantName &&
            !invoiceError.departmentName &&
            !invoiceError.chargePerson &&
            !invoiceError.phoneNumber &&
            !invoiceError.postalCode &&
            !invoiceError.region &&
            !invoiceError.address1 &&
            !invoiceError.address2 &&
            !invoiceError.address3);
    }

    const calcProjectCount = (): number => {
        if (!currentOrder || !currentOrder.licenses)
            return 10;
        var count = 0;
        currentOrder.licenses.forEach(v => {
            count += v.projectCount ?? 0;
        });

        return count;
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose(false);
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="close-dialog-title" onClose={() => {
                onClose(false);
            }}>
                {currentOrder ? "ライセンス内容変更" : "ライセンス購入"}
            </DialogTitle>
            <DialogContent>
                <Box hidden={page !== 0}>
                    <Grid container spacing={1} className={classes.historyList}>
                        <Grid item xs={1}><LabelIcon /></Grid>
                        <Grid item xs={10}>契約内容<br /><Divider light className={classes.divider} /></Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={6} className={classes.gridCenter}>基本ライセンス</Grid>
                        <Grid item xs={4} className={classes.gridCenter}>１ヶ月分</Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={6} className={classes.gridCenter}>最大有効プロジェクト数</Grid>
                        <Grid item xs={4} className={classes.gridCenter}>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={projectCount}
                                onChange={(e) => { setProjectCount(parseInt("" + e.target.value)) }}
                            >
                                {
                                    projectCountList().map((v) => (
                                        <MenuItem key={"pk" + v} value={v} selected={v === calcProjectCount()}>{v}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={1}></Grid>


                        <Grid item xs={1}></Grid>
                        <Grid item xs={6} className={classes.gridCenter}>料金</Grid>
                        <Grid item xs={4} className={classes.gridCenter}>￥{numberWithDelimiter(1100 * projectCount)}</Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}><LabelIcon /></Grid>
                        <Grid item xs={10}>お支払い方法<Divider light className={classes.divider} /></Grid>
                        <Grid item xs={1}></Grid>


                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            <FormControlLabel
                                control={<Radio
                                    checked={paytype === "INVOICE"}
                                    onChange={(e) => { setPaytype(e.target.value); }}
                                    value={"INVOICE"}
                                    name={"INVOICE"}
                                />}
                                label="請求書払い（銀行・コンビニ）"
                            />
                            <Box style={{ display: paytype === "INVOICE" ? "block" : "none" }} className={classes.notation}>


                                <a href="https://np-kakebarai.com/buy/" target="_blank" rel="noopener noreferrer">
                                    <img style={{ width: "468px" }} src={InvoiceBanner} alt="BtoB企業間後払い・掛売り決済「NP掛け払い」" /></a><br />
                                法人/個人事業主を対象とした掛け払いサービスです。（月末締め翌月末請求書払い）<br />
                                請求書は、<a href="https://np-kakebarai.com/" rel="noopener noreferrer" target="_blank">株式会社ネットプロテクションズ</a>からご購入の翌月第4営業日に発行されます。<br />
                                与信枠は、個別に設定させていただきます。<br />
                                請求書に記載されている銀行口座または、コンビニの払込票でお支払いください。<br />
                                ※銀行振込の際の振込手数料はお客様にてご負担ください。<br />
                                請求代行のため代金債権は同社へ譲渡されます。<br />
                                詳細はバナーをクリックしてご確認ください。<br />

                            </Box>
                        </Grid>
                        <Grid item xs={1}></Grid>

                        {/* 
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            <FormControlLabel
                                control={<Radio
                                    checked={paytype === "CARD"}
                                    onChange={(e) => { setPaytype(e.target.value); }}
                                    value={"CARD"}
                                    name={"CARD"}
                                />}
                                label="クレジットカード"
                            />

                        </Grid>
                        <Grid item xs={1}></Grid>
                        */}
                        {/* 
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <FormControlLabel
                            control={<Radio
                                checked={paytype === "BANK"}
                                onChange={(e) => { setPaytype(e.target.value); }}
                                value={"BANK"}
                                name={"BANK"}
                            />}
                            label="銀行振込"
                        />

                        <Box style={{ display: paytype === "BANK" ? "block" : "none" }} className={classes.notation}>
                            <TextField
                                className={classes.textField}
                                required
                                fullWidth
                                name="Name"
                                label="請求先氏名"
                                value={name}
                                error={!name}
                                helperText={(!name ? "氏名を入力してください" : "")}
                                onChange={(ev) => {
                                    setName(ev.target.value);
                                }}
                            />
                            <TextField
                                className={classes.textField}
                                required
                                fullWidth
                                name="EmailAddress"
                                label="振込先情報をお知らせするメールアドレス"
                                value={mailAddress}
                                error={!mailAddress}
                                helperText={(!mailAddress ? "メールアドレスを入力してください" : "")}
                                onChange={(ev) => {
                                    setMailAddress(ev.target.value);
                                }}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={1}></Grid>*/}



                    </Grid>
                </Box>

                <Box hidden={page !== 1}>
                    <Grid container spacing={1} className={classes.historyList}>
                        <Grid item xs={1}><LabelIcon /></Grid>
                        <Grid item xs={10}>請求書送付先<br /><Divider light className={classes.divider} /></Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} >
                            <TextField
                                className={classes.textField}
                                required
                                fullWidth
                                inputProps={{ maxLength: 30 }}
                                name="tenantName"
                                label="会社名（最大30文字）"
                                value={invoiceData.tenantName}
                                error={!!invoiceError.tenantName}
                                helperText={invoiceError.tenantName}
                                placeholder="株式会社○○○"
                                onChange={(ev) => {
                                    let data = Object.assign({}, invoiceData);
                                    data.tenantName = ev.target.value;
                                    setInvoiceData(data);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>


                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            <TextField
                                className={classes.textField}
                                fullWidth
                                name="departmentName"
                                label="部署名（最大30文字）"
                                inputProps={{ maxLength: 30 }}
                                value={invoiceData.departmentName}
                                error={!!invoiceError.departmentName}
                                helperText={invoiceError.departmentName}
                                placeholder="○○○事業部"
                                onChange={(ev) => {
                                    let data = Object.assign({}, invoiceData);
                                    data.departmentName = ev.target.value;
                                    setInvoiceData(data);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} >
                            <TextField
                                className={classes.textField}
                                fullWidth
                                name="chargePerson"
                                label="ご担当者（最大15文字）"
                                inputProps={{ maxLength: 15 }}
                                value={invoiceData.chargePerson}
                                error={!!invoiceError.chargePerson}
                                helperText={invoiceError.chargePerson}
                                placeholder="朝礼　太郎"
                                onChange={(ev) => {
                                    let data = Object.assign({}, invoiceData);
                                    data.chargePerson = ev.target.value;
                                    setInvoiceData(data);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} >
                            <InputLabel shrink={true} >請求書のお届方法</InputLabel>
                            <ButtonGroup disableElevation variant="contained" className={classes.sendTypeBtnGroup}>
                                <Button className={!invoiceData.invoiceByEmail ? classes.sendTypeBtnSelected : classes.sendTypeBtn}
                                    onClick={() => {
                                        let data = Object.assign({}, invoiceData);
                                        data.invoiceByEmail = false;
                                        setInvoiceData(data);
                                    }}>郵送</Button>
                                <Button className={invoiceData.invoiceByEmail ? classes.sendTypeBtnSelected : classes.sendTypeBtn}
                                    onClick={() => {
                                        let data = Object.assign({}, invoiceData);
                                        data.invoiceByEmail = true;
                                        setInvoiceData(data);
                                    }}>Eメール</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} >
                            <TextField
                                className={classes.textField}
                                required
                                fullWidth
                                name="EmailAddress"
                                label="メールアドレス（最大100文字）"
                                inputProps={{ maxLength: 100 }}
                                value={invoiceData.mailAddress}
                                error={!!invoiceError.mailAddress}
                                helperText={invoiceError.mailAddress}
                                placeholder="support@chorei084.com"
                                onChange={(ev) => {
                                    let data = Object.assign({}, invoiceData);
                                    data.mailAddress = ev.target.value;
                                    setInvoiceData(data);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} >
                            <TextField
                                className={classes.textField}
                                required
                                fullWidth
                                name="phoneNumber"
                                label="電話番号（最大13文字）"
                                value={invoiceData.phoneNumber}
                                error={!!invoiceError.phoneNumber}
                                inputProps={{ maxLength: 13 }}
                                helperText={invoiceError.phoneNumber}
                                onChange={(ev) => {
                                    let data = Object.assign({}, invoiceData);
                                    data.phoneNumber = ev.target.value;
                                    setInvoiceData(data);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} className={classes.postalDiv}>
                            <TextField
                                className={classes.postalCodeField}
                                required
                                name="postalCode"
                                label="郵便番号（最大8文字）"
                                inputProps={{ maxLength: 8 }}
                                value={invoiceData.postalCode}
                                error={!!invoiceError.postalCode}
                                helperText={invoiceError.postalCode}
                                onChange={(ev) => {
                                    let data = Object.assign({}, invoiceData);
                                    data.postalCode = ev.target.value;
                                    setInvoiceData(data);
                                }}
                            /*onKeyUp={(ev) => {
                                if (ev.target instanceof HTMLInputElement) {
                                    let data = Object.assign({}, invoiceData);
                                    data.postalCode = ev.target.value;
                                    setInvoiceData(data);
                                }
                            }}*/
                            />

                            <Link href="#" onClick={() => {
                                new YubinBango.Core(invoiceData.postalCode, (addr: any) => {
                                    let data = Object.assign({}, invoiceData);
                                    if (!addr.region) {
                                        dispatch(
                                            AlertDialogSlice.actions.openAlert({
                                                title: "郵便番号エラー",
                                                message: "住所が取得できませんでした。郵便番号が正確か再度ご確認ください。",
                                            })
                                        );
                                    }
                                    data.region = addr.region;
                                    data.address1 = addr.locality;
                                    data.address2 = addr.street;
                                    setInvoiceData(data);
                                })
                            }} className={classes.linkStyle}>
                                住所を自動入力
                            </Link>
                        </Grid>
                        <Grid item xs={1}></Grid>


                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} >
                            <FormControl
                                className={classes.postalCodeField}>
                                <InputLabel id="region-label">都道府県</InputLabel>
                                <Select
                                    labelId="region-label"
                                    value={invoiceData.region}
                                    onChange={(ev) => {
                                        let data = Object.assign({}, invoiceData);
                                        data.region = "" + ev.target.value;
                                        setInvoiceData(data);
                                    }}
                                >
                                    {
                                        regions.map((val, idx) => (
                                            <option key={"region-" + idx} value={val ?? "-"} selected={val === invoiceData.region ? true : (!invoiceData.region && !val ? true : false)}>{val ?? "---"}</option>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} >
                            <TextField
                                className={classes.textField}
                                fullWidth
                                name="address1"
                                label="市区町村"
                                value={invoiceData.address1}
                                error={!!invoiceError.address1}
                                helperText={invoiceError.address1}
                                placeholder="○市○区"
                                onChange={(ev) => {
                                    let data = Object.assign({}, invoiceData);
                                    data.address1 = ev.target.value;
                                    setInvoiceData(data);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} >
                            <TextField
                                className={classes.textField}
                                fullWidth
                                name="address2"
                                label="町名・番地"
                                value={invoiceData.address2}
                                error={!!invoiceError.address2}
                                helperText={invoiceError.address2}
                                placeholder="○○○町1-1"
                                onChange={(ev) => {
                                    let data = Object.assign({}, invoiceData);
                                    data.address2 = ev.target.value;
                                    setInvoiceData(data);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} >
                            <TextField
                                className={classes.textField}
                                fullWidth
                                name="address3"
                                label="アパート・マンション・ビル"
                                value={invoiceData.address3}
                                error={!!invoiceError.address3}
                                helperText={invoiceError.address3}
                                onChange={(ev) => {
                                    let data = Object.assign({}, invoiceData);
                                    data.address3 = ev.target.value;
                                    setInvoiceData(data);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Box>
                <Box hidden={page !== 2}>
                    <Grid container spacing={1} className={classes.historyList}>
                        <Grid item xs={1}><LabelIcon /></Grid>
                        <Grid item xs={10}>請求内容確認<br /><Divider light className={classes.divider} /></Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} >会社名</Grid>
                        <Grid item xs={6} >{invoiceData.tenantName}</Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} >部署名</Grid>
                        <Grid item xs={6} >{invoiceData.departmentName}</Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} >ご担当者</Grid>
                        <Grid item xs={6} >{invoiceData.chargePerson}</Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} >請求書のお届方法</Grid>
                        <Grid item xs={6} >{invoiceData.invoiceByEmail ? "Eメール" : "郵送"}</Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} >メールアドレス</Grid>
                        <Grid item xs={6} >{invoiceData.mailAddress}</Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} >電話番号</Grid>
                        <Grid item xs={6} >{invoiceData.phoneNumber}</Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} >郵便番号</Grid>
                        <Grid item xs={6} >{PostalCodeFormat(invoiceData.postalCode)}</Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} >住所</Grid>
                        <Grid item xs={6} >{invoiceData.region + invoiceData.address1 + invoiceData.address2 + invoiceData.address3}</Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} >合計金額</Grid>
                        <Grid item xs={6} >{numberWithDelimiter(1100 * projectCount)}円</Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Box>

                <Box hidden={page !== 3}>
                    <Grid container spacing={1} className={classes.historyList}>
                        <Grid item xs={1}><LabelIcon /></Grid>
                        <Grid item xs={10}>ライセンス内容<br /><Divider light className={classes.divider} /></Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} >有効プロジェクト数</Grid>
                        <Grid item xs={6} >{!orderResult?.licenses ? "0" : orderResult.licenses[0].projectCount}</Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} >期間</Grid>
                        <Grid item xs={6} >{!orderResult?.licenses ? "-" : moment(orderResult.startUTC).add(1,"minutes").format("YYYY年M月D日") + "～" + moment(orderResult.endUTC).format("YYYY年M月D日")}</Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} >料金</Grid>
                        <Grid item xs={6} >{numberWithDelimiter(orderResult?.price ?? 0)}円</Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Box>

            </DialogContent>
            <DialogActions>

                <Box hidden={page !== 0} className={classes.actionBox}>
                    <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}><Divider light className={classes.divider} /></Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} className={classes.amountTextStyle}>合計：{numberWithDelimiter(1100 * projectCount)}円</Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} className={classes.amountTextStyle}><Button variant="contained" fullWidth href="#contained-buttons" className={classes.payBtn}
                            onClick={() => { setPage(1) /*pay("base-1year", "基本ライセンス１年分", 121000)*/ }}>
                            {paytype === "INVOICE" ? "お支払い情報の設定" : "お支払い（外部サイトに移動します）"}</Button></Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Box>

                <Box hidden={page !== 1} className={classes.actionBox}>
                    <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}><Divider light className={classes.divider} /></Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={5} className={classes.amountTextStyle}><Button variant="contained" fullWidth href="#contained-buttons" className={classes.payBtn}
                            onClick={() => { setPage(0) }}>戻る</Button></Grid>
                        <Grid item xs={5} className={classes.amountTextStyle}><Button variant="contained" fullWidth href="#contained-buttons" className={classes.payBtn}
                            onClick={() => { setPage(2) }} disabled={!checkError()}>
                            内容確認</Button></Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Box>

                <Box hidden={page !== 2} className={classes.actionBox}>
                    <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}><Divider light className={classes.divider} /></Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={5} className={classes.amountTextStyle}><Button variant="contained" fullWidth href="#contained-buttons" className={classes.payBtn}
                            onClick={() => { setPage(1) }}>戻る</Button></Grid>
                        <Grid item xs={5} className={classes.amountTextStyle}><Button variant="contained" fullWidth href="#contained-buttons" className={classes.payBtn}
                            onClick={() => { postNVInvoice() }}>
                            申し込み</Button></Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Box>

                <Box hidden={page !== 3} className={classes.actionBox}>
                    <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}><Divider light className={classes.divider} /></Grid>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} className={classes.amountTextStyle}><Button variant="contained" fullWidth href="#contained-buttons" className={classes.payBtn}
                            onClick={() => { onClose(true) }}>閉じる</Button></Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
export default NewPaymentDialog;
