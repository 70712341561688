

import React, { useEffect, useState, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    TextField,
    Typography,
    Tooltip,
    Switch,
} from "@material-ui/core";

import { RootState } from "../Store";
import { getProjectAdminsSv, getProjectSv, inviteProjectAdminSv, setUserAdminSv } from "../net/TenantAsync";
import { ProjectAdminResult, MAUser } from "../net/NetworkClient";
import FlagIcon from '@material-ui/icons/Flag';
import MailIcon from '@material-ui/icons/Mail';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { AlertDialogSlice } from "../misc/AlertDialog";
import TenantSlice from "./TenantSlice";

const useStyles = makeStyles((theme) => ({
    btn: {
        color: theme.palette.text.primary,
    },
    top: {
        position: "absolute",
        right: 25,
        top: 20,
    },
    progress: {
        color: "#1a90ff",
    },
    contents: {
        flex: 1,
        height: "280px",
        width: "400px",
        overflowY: "auto",
        overflowX: "hidden"
        //padding: theme.spacing(0, 1),
    },
    userText: {
        margin: "auto",
        marginLeft: "1px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    userIcon: {
        margin: "auto",
        marginRight: "0px",
        marginLeft: "0px"
    },
    disabledSwitch: {
        cursor: "default",
        pointerEvents: "none"
    },
    textField: {
        margin: "0px",
        width: "100%",
    },
}));


const ProjectAdminDialog: FunctionComponent<{
    open: boolean;
    tenantId: string;
    projectId: string;
    onClose: () => void;
}> = ({ open, tenantId, projectId, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const currentUser = useSelector(
        (state: RootState) => state.auth.user
    );
    const [adminInfo, setAdminInfo] = useState<ProjectAdminResult>();
    const [submitting, setSubmitting] = useState(false);
    const [recheckFlag, setRecheckFlag] = useState(false);
    const [invitePanel, setInvitePanel] = useState(false);
    const [userName, setUserName] = useState("");
    const [mailAddress, setMailAddress] = useState("");
    const [mailError, setMailError] = useState("メールアドレスを入力してください。");
    
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        if (open || recheckFlag) {
            if (recheckFlag)
                setRecheckFlag(false);
            setSubmitting(true);
            dispatch(getProjectAdminsSv({
                projectId: projectId, tenantId: tenantId, callback(ok, result) {
                    setAdminInfo(result ?? undefined);
                    setSubmitting(false);
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, recheckFlag]);

    useEffect(() => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ((mailAddress?.length ?? "") <= 0) {
            setMailError("メールアドレスを入力してください。");
        } else if (!re.test(mailAddress)) {
            setMailError("有効なメールアドレスではありません。");
        } else {
            setMailError("");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mailAddress]);

    function updateProjectInfo(){
        if(!dirty)
            return;
        setSubmitting(true);
        dispatch(getProjectSv({
            projectId: projectId, tenantId: tenantId, callback(ok, result) {
                if(result)
                    dispatch(TenantSlice.actions.updateProject(result));
                setSubmitting(false);
            }
        }));
    }


    //プロジェクトリーダーとして登録
    function onInvite() {
        if (mailError.length > 0 || (userName?.length ?? "") <= 0)
            return;
            setDirty(true);
        setSubmitting(true);
        dispatch(inviteProjectAdminSv({
            name: userName, email: mailAddress, projectId: projectId, tenantId: tenantId, callback: (ok, result) => {
                setSubmitting(false);
                if (ok && result) {
                    if (result.isExistUser) {
                        dispatch(
                            AlertDialogSlice.actions.openAlert({
                                title: "登録済みユーザー",
                                message: "既存ユーザーのためプロジェクトリーダーとして登録できませんでした。",
                            })
                        );
                    } else {
                        dispatch(
                            AlertDialogSlice.actions.openAlert({
                                title: "登録完了",
                                message: "ユーザー登録をして、確認メールをお送りしました。",
                            })
                        );
                        setRecheckFlag(true);
                        setInvitePanel(false);
                    }
                } else {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "登録に失敗しました。管理者へお問い合わせください。",
                        })
                    );
                }
            }
        }));
    }

    //ユーザーの権限を切り替える
    function onSwitchAdmin(user: MAUser, setAdmin: boolean) {
        setDirty(true);
        setSubmitting(true);
        dispatch(
            setUserAdminSv({
                projectId: projectId,
                tenantId: tenantId,
                userId: user.id ?? "",
                toAdmin: setAdmin,
                callback: (ok) => {
                    setSubmitting(false);
                    if(ok){
                        setRecheckFlag(true);
                    }else{
                        dispatch(
                            AlertDialogSlice.actions.openAlert({
                                title: "エラー",
                                message: "ユーザーのプロジェクトリーダー設定に失敗しました。",
                            })
                        );
                    }
                }
            })
        )
    }

    return (
        <Dialog
            disableBackdropClick
            open={open}
            onClose={()=>{updateProjectInfo();onClose()}}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                プロジェクトリーダー
            <Box className={classes.top} display={submitting ? "block" : "none"}>
                    <CircularProgress className={classes.progress} size={30} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box style={{ display: (invitePanel ? "none" : "block") }}>
                    <Box style={{ marginBottom: "24px" }}>
                        <Button variant="contained" onClick={() => { setUserName(""); setMailAddress(""); setInvitePanel(true) }} autoFocus>
                            <MailIcon />新規ユーザーを招待する
                    </Button>
                    </Box>
                    <Box className={classes.contents}>
                        <Grid container spacing={1}>
                            {
                                adminInfo?.tenantUser?.map((user, idx) => {
                                    if (!!adminInfo.tenantAdmin?.find((u) => { return u.id === user.id })) {
                                        //テナント管理者
                                        return (
                                            <Tooltip key={user.id ?? ""} title="会社管理者は自動的に登録され削除できません。">
                                                <Grid item xs={12} style={{ padding: 0, display: "flex", marginBottom: "8px" }} >

                                                    <FlagIcon className={classes.userIcon} />
                                                    <Typography className={classes.userText}>{user.name + "(" + user.email + ")"}</Typography>

                                                    <Switch checked={true} color="secondary" className={classes.disabledSwitch} />
                                                </Grid>
                                            </Tooltip>
                                        )
                                    } else if (currentUser?.id === user.id) {
                                        //自分自身
                                        return (
                                            <Tooltip key={user.id ?? ""} title="自分自身の設定は変更できません。" >
                                                <Grid item xs={12} style={{ padding: 0, display: "flex", marginBottom: "8px" }} >

                                                    <PersonIcon className={classes.userIcon} />
                                                    <Typography className={classes.userText}>{user.name + "(" + user.email + ")"}</Typography>

                                                    <Switch checked={true} color="secondary" className={classes.disabledSwitch} />
                                                </Grid>
                                            </Tooltip>
                                        )
                                    } else if (!!adminInfo.projectAdmin?.find((u) => { return u.id === user.id })) {
                                        //プロジェクト管理者
                                        return (
                                            <Grid key={user.id ?? ""} item xs={12} style={{ padding: 0, display: "flex", marginBottom: "8px" }} >

                                                <PersonIcon className={classes.userIcon} />
                                                <Typography className={classes.userText}>{user.name + "(" + user.email + ")"}</Typography>

                                                <Switch checked={true} color="secondary" onChange={(e) => { onSwitchAdmin(user, e.target.checked); }} />
                                            </Grid>
                                        )
                                    } else {
                                        //未登録ユーザー
                                        return (
                                            <Grid key={user.id ?? ""} item xs={12} style={{ padding: 0, display: "flex", marginBottom: "8px" }} >

                                                <PersonOutlineIcon className={classes.userIcon} />
                                                <Typography className={classes.userText}>{user.name + "(" + user.email + ")"}</Typography>

                                                <Switch checked={false} color="secondary" onChange={(e) => { onSwitchAdmin(user, e.target.checked); }} />
                                            </Grid>
                                        )
                                    }

                                })
                            }
                        </Grid>
                    </Box>
                    <Box style={{ textAlign: "right" ,marginTop:"16px"}}>
                        <Button variant="contained" onClick={()=>{updateProjectInfo();onClose()}} autoFocus>
                            OK
                        </Button>
                    </Box>
                </Box>
                <Box style={{ display: (invitePanel ? "block" : "none") }} >

                    <Box className={classes.contents}>
                        <Grid container spacing={1} style={{ marginBottom: "auto" }}>
                            <Grid container item xs={12} >
                                新しくプロジェクトリーダーとして登録し、指定のアドレスへ招待メールを送信します。
                            </Grid>
                            <Grid container item xs={12} >
                                <TextField required label="名称" value={userName}
                                    className={classes.textField}
                                    onChange={(e) => {
                                        setUserName(e.target.value);
                                    }}
                                    error={(userName?.length ?? "") <= 0}
                                    helperText={(userName?.length ?? 0) <= 0 ? "名称を入力してください。" : ""}
                                />
                            </Grid>
                            <Grid container item xs={12}>
                                <TextField required label="メールアドレス" value={mailAddress}
                                    className={classes.textField}
                                    onChange={(e) => {
                                        setMailAddress(e.target.value);
                                    }}
                                    error={mailError.length > 0}
                                    helperText={mailError}
                                />
                            </Grid>
                        </Grid>

                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <Button style={{ width: "100%" }} variant="contained" onClick={() => { setInvitePanel(false) }} autoFocus>
                                キャンセル
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button style={{ width: "100%" }} variant="contained" onClick={onInvite} disabled={mailError.length > 0 || (userName?.length ?? "") <= 0} autoFocus>
                                <MailIcon />招待メール送信
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog >
    );


}


export default ProjectAdminDialog;
