import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MATemplate } from "../net/NetworkClient";
import { deleteTemplateSv, getTemplateListSv } from "../net/TemplateAsync";
import DeleteIcon from '@material-ui/icons/Delete';
import { ConfirmDialogSlice } from "../misc/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
    btn: {
        color: theme.palette.text.primary,
    },
    heading: {

    },
    templateItem: {
        paddingLeft: "1em",
        fontSize: "0.9em"
    },
    label: {
        display: "flex"
    },
    progress: {
        color: "#1a90ff",
    },
    caution: {
        color: theme.palette.error.main,
        fontSize: "0.9em",
        paddingTop: theme.spacing(2)
    },
    list:{
        overflowY:"auto",
        maxHeight:"250px",
        overflowX:"hidden"
    }
}));

const NewTemplateDialog: React.FC<{
    open: boolean;
    tenantId: string;
    dirty: boolean;
    onClose: (ok: boolean, name: string) => void;
}> = ({ open, tenantId, dirty, onClose }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [templates, setTemplates] = useState<MATemplate[]>([]);

    const [name, setName] = useState<string>("");

    //ダイアログを開いた・閉じた
    useEffect(() => {
        if (open) {
            setName("");
            refresh();
        } else {
            setTemplates([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const refresh = () => {
        setLoading(true);
        dispatch(
            getTemplateListSv({
                tenantId: tenantId,
                callback: (ok, list) => {
                    if (ok) {
                        var tmps = list.filter(t => !t.isDefault);
                        tmps = tmps.sort((t1, t2) => (t1.orderNo ?? 0) - (t2.orderNo ?? 0));
                        setTemplates(tmps);
                    }
                    setLoading(false);
                },
            })
        );
    }

    //既存項目の削除ボタン
    const onDelete = (template: MATemplate) => {
        dispatch(
            ConfirmDialogSlice.actions.openConfirm({
                title: "削除の確認",
                message: "本当に「" + template.name + "」を削除してもよろしいですか？",
                callback: (ok) => {
                    if (ok) {
                        dispatch(deleteTemplateSv({
                            templateId: template.id ?? "", callback: (ok) => {
                                refresh();
                            }
                        }));
                    }
                }
            })
        );
    }


    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose(false, name);
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">テンプレート管理
                <Box display={loading ? "block" : "none"} >
                    <CircularProgress className={classes.progress} size={30} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    登録済みのテンプレート
                </DialogContentText>
                <DialogContentText className={classes.templateItem} style={{ display: (templates.length > 0 ? "none" : "block") }}>未登録</DialogContentText>
                <List style={{ display: (templates.length <= 0 ? "none" : "block") }}
                className={classes.list}
                >
                    {templates.map((t) => {
                        return (
                            <ListItem key={t.id ?? ""}>
                                <ListItemText>{t.name}</ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => { onDelete(t) }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    })}
                </List>
                <DialogContentText>
                    現在の朝礼内容を新規作成時のテンプレートとして<br />登録できます（最大１０件）。
                </DialogContentText>

                <DialogContentText className={classes.caution} style={{ display: (templates.length >= 10 ? "block" : "none") }}>
                    既に１０件のテンプレートが登録されているため、これ以上追加できません。<br/>
                    上記の登録済みの項目を削除してから追加してください。
                </DialogContentText>



                <TextField
                    required
                    fullWidth
                    name="Name"
                    label="テンプレート名称"
                    value={name}
                    error={!name}
                    helperText={(!name ? "テンプレート名を入力してください" : "")}
                    onChange={(ev) => {
                        setName(ev.target.value);
                    }}
                />
                <DialogContentText className={classes.caution} style={{ display: (dirty ? "block" : "none") }}>注)追加ボタンを押すと、現在編集中のデータも同時に保存されます</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose(false, name);
                    }}
                    className={classes.btn}>
                    キャンセル</Button>
                <Button
                    onClick={() => {
                        onClose(true, name);
                    }}
                    className={classes.btn}
                    disabled={loading || !name || templates.length >= 10}>

                    追加</Button>
            </DialogActions>
        </Dialog>
    );
};
export default NewTemplateDialog;
