import { combineReducers, configureStore ,getDefaultMiddleware} from "@reduxjs/toolkit";
//import logger from "redux-logger";

//import ContentsSlice from "./contents/ContentsSlice";
//import ProjectsSlice from "./projects/ProjectsSlice";
//import ContentsListSlice from "./contents-list/ContentsListSlice";
import {WaitingSlice} from "./misc/WaitingBar"
import {AlertDialogSlice} from "./misc/AlertDialog";
import {ConfirmDialogSlice} from "./misc/ConfirmDialog";
import ActionSlice from "./misc/ActionSlice";
import AuthSlice from "./openid/AuthSlice"
import TenantSlice from "./tenant/TenantSlice"
import { PhotoSelectDialogSlice } from "./contents/PhotoSelectDialog";
 
// rootReducer の準備
const rootReducer = combineReducers({
 // contents: ContentsSlice.reducer,
 // projects:ProjectsSlice.reducer,
 // contentsList:ContentsListSlice.reducer,
  waiting:WaitingSlice.reducer,
  action:ActionSlice.reducer,

  alert:AlertDialogSlice.reducer,
  confirm:ConfirmDialogSlice.reducer,
  auth:AuthSlice.reducer,
  tenants:TenantSlice.reducer,
  photoSelect:PhotoSelectDialogSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

// setup 関数を用意してエクスポートする。
const setupStore = () => {
  const middleware = getDefaultMiddleware({ serializableCheck: false });
  const store = configureStore({
    reducer: rootReducer,devTools: true,middleware
  });
  return store;
};

const AppStore = setupStore();
export default AppStore;