
import React, { useEffect } from 'react';
import { Box, CircularProgress, Grid, Button, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment-timezone"
import { MAOrder, MATenant } from '../net/NetworkClient';
import { useDispatch } from 'react-redux';
import { DeleteOrderSv, GetOrdersSv, getRegistOrderCsvSv, getRegistTenantCsvSv } from '../net/SysadAsync';
import { AlertDialogSlice } from '../misc/AlertDialog';
import { saveAs } from 'file-saver';
import SysadEditAddressDialog from './SysadEditAddressDialog';
import SysadAddOrderDialog from './SysadAddOrderDialog';
import { ConfirmDialogSlice } from '../misc/ConfirmDialog';
import SysadTenantLicenseDialog from './SysadTenantLicenseDialog';
import SysadSetOrderStateDialog from './SysadSetOrderStateDialog';

moment.tz.setDefault('Asia/Tokyo');

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        margin: 0,
        position: "relative"
    },
    toolbar: {
        height: "40px",
        margin: 0,
        overflowY: "hidden",
    },
    companyInfoPane: {
        height: "200px",
        margin: 0,

        overflowY: "auto",
    },
    ordersPane: {
        maxHeight: "100%",
        margin: 0,

        overflowY: "auto",
    },
    contents: {
        //flex: 1,
        height: "100%",
        overflow: "hidden",
        padding: theme.spacing(0, 1),
        position: "relative",
        backgroundColor: theme.palette.background.paper
    },
    gridContainer: {
        //flex: 1,
        height: "100%",
        margin: 0
    },
    col: {
        textAlign: "right",
        paddingRight: theme.spacing(1)
    },
    title: {
        textAlign: "center"
    },
    registBtn: {
        position: "absolute",
        top: theme.spacing(1),
        left: theme.spacing(1),
    },
    caution: {
        backgroundColor:theme.palette.error.main,
        color: theme.palette.common.white
    }
}));



const SysadOrderView: React.FunctionComponent<{
    tenant: MATenant | undefined;
    onReload: (tenantId: string) => void;
}> = ({ tenant, onReload }) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(false);
    const [loadingOrders, setLoadingOrders] = React.useState(false);
    const [orders, setOrders] = React.useState<MAOrder[]>([]);

    const [currentOrderId, setCurrentOrderId] = React.useState<string>();


    const [openTenantLicenseDialog, setOpenTenantLicenseDialog] = React.useState(false);
    const [openEditAddress, setOpenEditAddress] = React.useState(false);
    const [openOrderDialog, setOpenOrderDialog] = React.useState(false);
    const [openSetOrderStateDialog, setOpenSetOrderStateDialog] = React.useState(false);

    //表示中のテナントが変更された場合
    useEffect(() => {
        loadOrders();
        //refreshTenants();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant]);

    var loadOrders = () => {
        if (!tenant)
            return;
        setLoadingOrders(true);
        dispatch(GetOrdersSv({
            tenantId: tenant?.id ?? "",
            callback: (ok, orders) => {
                setLoadingOrders(false);
                if (!ok || !orders) {
                    setOrders([]);
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "注文情報を取得できませんでした",
                        })
                    );
                } else {
                    setOrders(orders);
                }
            }
        }))
    }

    ///会社登録用CSVデータダウンロード
    var onDownloadRegistCSV = () => {
        setLoading(true);
        dispatch(getRegistTenantCsvSv({
            tenantId: tenant?.id ?? "",
            callback: (ok, blobData) => {
                setLoading(false);
                if (!ok || !blobData) {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "CSVファイル生成に失敗しました",
                        })
                    );
                } else {
                    var filename = (tenant?.name ?? "") + "登録.csv"
                    saveAs(blobData, filename);
                }
            }
        }))
    }


    ///注文登録用CSVデータダウンロード
    var onDownloadOrderCSV = (order: MAOrder) => {
        setLoading(true);
        dispatch(getRegistOrderCsvSv({
            orderId: order.id ?? "",
            callback: (ok, blobData) => {
                setLoading(false);
                if (!ok || !blobData) {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "CSVファイル生成に失敗しました",
                        })
                    );
                } else {
                    var filename = (tenant?.nameForInvoice ?? tenant?.name) + "_";
                    filename += moment(order.startUTC).add(1, "minutes").format("YYYYMMDD") + "-" + moment(order.endUTC).format("YYYYMMDD");
                    filename += "請求分.csv"
                    saveAs(blobData, filename);
                }
            }
        }))
    }


    ///注文登録用CSVデータダウンロード
    var onDeleteOrder = (order: MAOrder) => {
        dispatch(
            ConfirmDialogSlice.actions.openConfirm({
                title: "削除の確認",
                message:
                    "指定の注文情報を本当に削除してもよろしいですか？",
                callback: (inok) => {
                    if (inok) {
                        setLoading(true);
                        dispatch(DeleteOrderSv({
                            orderId: order.id ?? "",
                            callback: (ok, error) => {
                                setLoading(false);
                                if (!ok) {
                                    dispatch(
                                        AlertDialogSlice.actions.openAlert({
                                            title: "エラー",
                                            message: "注文の削除に失敗しました",
                                        })
                                    );
                                } else {
                                    loadOrders();
                                }
                            }
                        }))
                    }
                }
            })
        );



    }


    return (
        <Box className={classes.root}>
            <Grid container spacing={0} className={classes.toolbar}>
                <Grid item xs={1}></Grid>
                <Grid item xs={11} >

                    <Button onClick={() => { onDownloadRegistCSV() }}
                        variant="outlined">会社登録CSV</Button>
                    <Button onClick={() => { setOpenEditAddress(true) }}
                        variant="outlined">送付先編集</Button>
                    <Button onClick={() => { setOpenOrderDialog(true); }}
                        variant="outlined">請求追加</Button>

                    <Button onClick={() => { setOpenTenantLicenseDialog(true); }}
                        variant="outlined">最大Projectと期限</Button>
                </Grid>
            </Grid>
            <Box hidden={!loading}>
                <CircularProgress style={{ color: "#1a90ff" }} size={24} />
            </Box>

            <Grid container spacing={0} className={classes.companyInfoPane}>
                <Grid item xs={4} className={classes.col}>会社名</Grid>
                <Grid item xs={8}>{(tenant?.nameForInvoice ?? tenant?.name ?? "未選択") + "（ID:" + (tenant?.codeForInvoice ?? "-") + "）"}</Grid>

                <Grid item xs={4} className={classes.col}>住所</Grid>
                <Grid item xs={8}>{"〒" + (tenant?.postalCode ?? "-") + "　" + (tenant?.region ?? "") + (tenant?.address1 ?? "") + (tenant?.address2 ?? "") + (tenant?.address3 ?? "")}</Grid>

                <Grid item xs={4} className={classes.col}>支払い担当者</Grid>
                <Grid item xs={8}>{tenant?.chargePerson ?? "-"}</Grid>

                <Grid item xs={4} className={classes.col}>電話番号</Grid>
                <Grid item xs={8}>{tenant?.phoneNumber ?? "-"}</Grid>


                <Grid item xs={4} className={classes.col}>メールアドレス</Grid>
                <Grid item xs={8}>{tenant?.mailAddress ?? "-"}</Grid>

                <Grid item xs={4} className={classes.col}>最大プロジェクト数</Grid>
                <Grid item xs={8}>{tenant?.maxProject ?? "-"}</Grid>

                <Grid item xs={4} className={classes.col}>ライセンス期限</Grid>
                <Grid item xs={8}>{tenant?.limitUTC ? moment(tenant?.limitUTC).format("YYYY/MM/DD") : "-"}</Grid>
            </Grid>

            <Divider light />

            <Grid container spacing={1} className={classes.ordersPane}>

                <Grid item xs={12} hidden={!loadingOrders}>
                    <CircularProgress style={{ color: "#1a90ff" }} size={24} />
                </Grid>

                {
                    orders.map((order) => (
                        order.licenses?.map((lic) => (
                            <React.Fragment key={lic.id ?? ""}>
                                
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box hidden={!order.stopSubscription} className={classes.caution}>停止({moment(order.stopSubscriptionDate).format("YYYY/MM/DD HH:mm")})</Box>
                                    {order.paid ? "支払い済み" : (order.registered ? "請求書登録済み" : "登録のみ")}
                                </Grid>
                                <Grid item xs={6}>
                                    {lic.name}
                                </Grid>

                                <Grid item xs={2}>
                                    {order.payType === "INVOICE" ? "請求書払い" : (order.payType === "CARD" ? "カード" : order.payType)}
                                </Grid>


                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={3}>
                                    {moment(order.startUTC).add(1, "minutes").format("YYYY/MM/DD") + "~" + moment(order.endUTC).add(-1, "minutes").format("MM/DD")}
                                </Grid>

                                <Grid item xs={8}>
                                    <Button disabled={order.paid || order.registered}
                                        onClick={() => { onDeleteOrder(order) }}
                                        variant="outlined">削除</Button>
                                    <Button onClick={() => { onDownloadOrderCSV(order) }}
                                        variant="outlined">CSV</Button>
                                    <Button onClick={() => { setCurrentOrderId(order.id ?? ""); setOpenSetOrderStateDialog(true); }}
                                        variant="outlined">状態設定</Button>
                                </Grid>
                            </React.Fragment>
                        ))
                    ))

                }

            </Grid>

            <SysadTenantLicenseDialog open={openTenantLicenseDialog} tenantId={tenant?.id} onClose={(up) => {
                setOpenTenantLicenseDialog(false);
                if (up) {
                    onReload(tenant?.id ?? "");
                }
            }}></SysadTenantLicenseDialog>


            <SysadEditAddressDialog open={openEditAddress} tenant={tenant} onClose={(up) => {
                setOpenEditAddress(false);
                if (up) {
                    onReload(tenant?.id ?? "");
                }
            }}></SysadEditAddressDialog>
            <SysadAddOrderDialog open={openOrderDialog} tenantId={tenant?.id} srcOrder={orders[0]} onClose={(up) => {
                setOpenOrderDialog(false);
                if (up) {
                    loadOrders();
                    onReload(tenant?.id ?? "");
                }
            }}></SysadAddOrderDialog>


            <SysadSetOrderStateDialog open={openSetOrderStateDialog} orderId={currentOrderId} onClose={(up) => {
                setOpenSetOrderStateDialog(false);
                if (up) {
                    onReload(tenant?.id ?? "");
                }
            }}></SysadSetOrderStateDialog>
        </Box>
    )
}


export default SysadOrderView;

