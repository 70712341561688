
import authService from '../openid/AuthorizeService';

import axios from 'axios';
import { Dispatch } from 'react';
import ActionSlice, { ActionType } from '../misc/ActionSlice';

//JWTの認証情報を付加するAxisインスタンスを生成する
export async function getAuthedAxisInstance(dispatch: Dispatch<any>) {
  var instance = axios.create();
  var token = await authService.getAccessToken();
  dispatch(ActionSlice.actions.setLoading(true));
  instance.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      return config
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  });

  instance.interceptors.response.use((response) => {
    dispatch(ActionSlice.actions.setLoading(false));
    return response;
  }, (error) => {
    dispatch(ActionSlice.actions.setLoading(false));
    if (error.response.status === 401) {
      dispatch(ActionSlice.actions.doAction({ type: ActionType.HTTP_401_ERROR }));
    }
    return error;
  });

  return instance;
}