import React, { useEffect } from "react";

import { Grid, makeStyles, Paper } from "@material-ui/core";

import RadioGymImage from "../../images/radioGymnastics.png";
import { ItemTypeKey } from "../../Constants";
import { useDispatch } from "react-redux";
import ActionSlice, { ActionType } from "../../misc/ActionSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "auto",
    overflow: "hidden",
    height: "100%"
  },
  content: {
    margin: "20px 16px",
  },
  image: {
    maxWidth: "676px",
    with: "100%",
    height: "auto",
    paddingRight: "16px"
  }
}));

const ContentsItemRadioCalisthenics: React.FunctionComponent<{
  selectedItem: { key: string; id: string } | undefined;
}> = ({ selectedItem }) => {

  const classes = useStyles();
  const dispatch = useDispatch();


  useEffect(() => {
    if (selectedItem?.key !== ItemTypeKey.radioCalisthenics
      && selectedItem?.key !== ItemTypeKey.general_radioCalisthenics)
      return;
    dispatch(ActionSlice.actions.doAction({ type: ActionType.CONTENT_ITEM_LOAD_END }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  if (selectedItem?.key !== ItemTypeKey.radioCalisthenics
    && selectedItem?.key !== ItemTypeKey.general_radioCalisthenics)
    return null;


  return (
    <Paper
      className={classes.paper} elevation={0} square>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.content}
      >
        <p className={classes.image}>
          <img style={{ width: "calc(100% - 16px)" }} src={RadioGymImage} alt="ラジオ体操" />
        </p>
      </Grid>
    </Paper>
  );
}

export default ContentsItemRadioCalisthenics;
