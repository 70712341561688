import React, { useEffect, useState, FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@material-ui/core";

import { canIncrementActiveProject, postProjectSv } from "../net/TenantAsync";
import { MAProject } from "../net/NetworkClient";
import { BaseBackGroundColor, ProjectKeyToString, ProjectUserKeyToString, ProjectAdminKeyToString } from "../Constants";
import CopyToClipBoard from "react-copy-to-clipboard";
import { AlertDialogSlice } from "../misc/AlertDialog";

const useStyles = makeStyles((theme) => ({
  btn: {
    color: theme.palette.text.primary,
  },
  top: {
    position: "absolute",
    right: 25,
    top: 20,
  },
  progress: {
    color: "#1a90ff",
  },
  clipbtn: {
    backgroundColor: BaseBackGroundColor,
  },
}));
const ProjectCreateDialog: FunctionComponent<{
  open: boolean;
  tenantId: string;
  activeProject: number;
  maxProject: number;
  obayashi: boolean;
  onClose: (ok: boolean, prj?: MAProject | undefined) => void;
}> = ({ open, tenantId, activeProject, maxProject, obayashi, onClose }) => {
  const classes = useStyles();


  const [useProject, setUseProject] = useState(false);
  const [name, setName] = useState("");
  const [managementNumber, setManagementNumber] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [openTip, setOpenTip] = useState<boolean>(false);
  const [created, setCreated] = useState<MAProject>();
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    managementNumber: "",
    password: ""
  });
  const [usePassword, setUsePassword] = useState(false);
  const [password, setPassword] = useState("");


  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  //開くときに初期化
  useEffect(() => {
    if (open) {
      setUseProject(maxProject > activeProject);
      setName("");
      setManagementNumber("");
      setDescription("");
      setCreated(undefined);
      setTitle("新しいプロジェクトを追加します");
      setUsePassword(false);
      setPassword("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  //エラーチェック
  useEffect(() => {
    var copy = Object.assign({}, errors);
    if (!name) {
      copy.name = "プロジェクト名を入力して下さい";
    } else {
      copy.name = "";
    }
    copy.password = "";
    if (usePassword) {
      if (password == null || password.length < 4) {
        copy.password = "4文字以上入力してください";
      } else if (!password.match(/^[A-Za-z0-9]*$/)) {
        copy.password = "英数字で入力してください";
      }
    }
    setErrors(copy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, managementNumber, description, password, usePassword]);

  //登録可能かどうかの確認
  function validToSubmit(){
    return (!errors.name && !errors.managementNumber && !errors.password);
  }

  //サーバへ登録
  function submit() {
    if (validToSubmit()) {
      setSubmitting(true);
      var prj = new MAProject();
      prj.invalid = !useProject;
      prj.name = name;
      prj.managementNumber = managementNumber;
      prj.description = description;
      if (usePassword) {
        prj.clientPassword = password;
      } else {
        prj.clientPassword = "";
      }
      dispatch(
        postProjectSv({
          project: prj,
          tenantId: tenantId,
          callback: (ok, project, response) => {
            setSubmitting(false);
            if (ok && project) {
              setTitle("プロジェクトが作成されました");
              if(project.invalid){
                dispatch(
                  AlertDialogSlice.actions.openAlert({
                    title: "プロジェクトが作成されました。",
                    message: "「"+project.name+"」が作成されました。",
                  })
                );
                onClose(true, project);
              }else{
                setCreated(project);
              }
            } else {
              dispatch(canIncrementActiveProject({
                tenantId:tenantId,
                callback:(canIncrease)=>{
                  if (!canIncrease) {
                    dispatch(
                      AlertDialogSlice.actions.openAlert({
                        title: "利用上限エラー",
                        message: "すでに利用上限に達しているのでいずれかのプロジェクトを利用停止にするか、契約プロジェクト数を増やしてください。",
                      })
                    );
                  }else{
                    dispatch(
                      AlertDialogSlice.actions.openAlert({
                        title: "エラー",
                        message: "問題が発生してプロジェクトを作成することができませんでした。",
                      })
                    );
                  }
                }
              }))

            }
          },
        })
      );
    }
  }

  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={() => {
        setCreated(undefined);
        onClose(false);
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {title}
        <Box className={classes.top} display={submitting ? "block" : "none"}>
          <CircularProgress className={classes.progress} size={30} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display={!created ? "block" : "none"}>
          <Grid container justify="flex-end" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={useProject}
                    onChange={(e) => { setUseProject(e.target.checked) }}
                    color="secondary"
                  />
                }
                label={"端末で利用可能（" + activeProject + "/" + maxProject + "利用中）"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name={"Name"}
                type="text"
                label="プロジェクト名"
                error={!!errors.name}
                helperText={errors.name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} style={{ display: obayashi ? "none" : "block" }}>
              <TextField
                fullWidth
                name={"Description"}
                type="text"
                label="説明"
                value={description}
                error={!!errors.description}
                helperText={errors.description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name={"ManagementNumber"}
                type="text"
                label={obayashi ? "管理番号(15桁)" : "住所"}
                error={!!errors.managementNumber}
                helperText={errors.managementNumber}
                onChange={(e) => {
                  setManagementNumber(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={usePassword}
                    onChange={(e) => { setUsePassword(e.target.checked) }}
                  />
                }
                label="端末から利用する時にパスワードを使用する"
              />
              <TextField
                name={"Password"}
                type="text"
                value={password}
                style={{ marginLeft: "16px", width: "calc(100% - 16px)" }}
                disabled={!usePassword}
                error={!!errors.password}
                helperText={errors.password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Grid>

            <Grid item style={{ marginTop: 10 }}>
              <Button
                type="button"
                onClick={() => {
                  onClose(false);
                }}
                disabled={submitting }
              >
                キャンセル
              </Button>
            </Grid>
            <Grid item style={{ marginTop: 10 }}>
              <Button
                type="submit"
                onClick={() => {
                  submit();
                }}
                disabled={submitting || !validToSubmit()}
              >
                登録
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box display={!!created ? "block" : "none"}>
          <Grid container justify="flex-end" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                朝礼番号と朝礼管理番号は、編集や朝礼ログイン時に必要ですので別途控えておいて下さい。
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Tooltip
                arrow
                open={openTip}
                onClose={() => {
                  setOpenTip(false);
                }}
                disableHoverListener
                placement="top"
                title="コピーしました"
              >
                <CopyToClipBoard
                  text={
                    ProjectKeyToString(created, "\n") +
                    "\n" +
                    (created?.clientPassword ? "端末利用時のパスワード:" + created?.clientPassword + "\n" : "")
                  }
                >
                  <Button
                    id="clipboardbtn"
                    variant="contained"
                    className={classes.clipbtn}
                    onClick={() => {
                      setOpenTip(true);
                    }}
                  >
                    <AssignmentIcon />
                    クリップボードにコピー
                  </Button>
                </CopyToClipBoard>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name={"UserKey"}
                type="text"
                label="朝礼番号"
                value={ProjectUserKeyToString(created) ?? ""}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name={"AdminKey"}
                type="text"
                label="朝礼管理番号"
                value={ProjectAdminKeyToString(created) ?? ""}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box hidden={!created?.clientPassword}>
                <TextField
                  fullWidth
                  required
                  name={"password"}
                  type="text"
                  label="端末利用時のパスワード"
                  value={created?.clientPassword ?? "未使用"}
                  InputProps={{
                    readOnly: true,
                  }}
                /></Box>
            </Grid>

            <Grid item style={{ marginTop: 10 }}>
              <Button
                type="button"
                onClick={() => {
                  onClose(true, created);
                }}
                disabled={submitting}
              >
                閉じる
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default ProjectCreateDialog;
