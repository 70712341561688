import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useLocalStorage from 'react-use-localstorage';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { GetItemIcon, ItemTypeKey, UseRadioGym } from "../Constants";
import { ContentsJson, DetailJson, MACategoryJson } from "../net/NetworkClient";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { v4 as uuid } from "uuid";
import { getCategoriesSv } from "../net/ContentsAsync";
const useStyles = makeStyles((theme) => ({
    btn: {
        color: theme.palette.text.primary,
    },
    heading: {

    },
    caution:{
        color:theme.palette.warning.main,
        fontSize:"0.75em",
        paddingLeft:theme.spacing(1)
    },
    label: {
        display: "flex"
    },
    progress: {
        color: "#1a90ff",
    },
}));

const NewCatItemDialog: React.FC<{
    open: boolean;
    tenantId: string;
    onClose: (ok: boolean, contents?: ContentsJson) => void;
}> = ({ open, tenantId, onClose }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [catJson, setCatJson] = useState<MACategoryJson>();
    const [selectedValue, setSelectedValue] = useState("" + ItemTypeKey.radioCalisthenics);
    // const [expanded, setExpanded] = React.useState('general');
    const [expanded, setExpanded] = useLocalStorage('newitem.expandedAccordion', 'general');


    //ダイアログを開いた・閉じた
    useEffect(() => {
        if (open) {
            setLoading(true);
            dispatch(
                getCategoriesSv({
                    tenantId: tenantId,
                    callback: async function (ok, cat) {
                        setLoading(false);
                        setCatJson(cat);
                        console.log(cat);
                    }
                }));

        } else {
            setCatJson(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);


    const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const selectedTitle = (): string => {
        var re = "";
        if (catJson) {
            catJson.categories?.map((v) => {
                v.contents?.map((c) => {
                    if (c.type === selectedValue) {
                        re = c.title ?? "";
                    }
                })
            })
        }
        return re;
    }

    const onCreateNewItem = (): ContentsJson | undefined => {
        var contents = new ContentsJson();
        var title = selectedTitle();
        contents.uuid = uuid();
        contents.title = title;
        contents.type = selectedValue;
        var detail = new DetailJson();
        switch (selectedValue) {
            case ItemTypeKey.radioCalisthenics:
            case ItemTypeKey.general_radioCalisthenics:
            case ItemTypeKey.kyActivity:
            case ItemTypeKey.rollCall:
            case ItemTypeKey.general_registration:
                return contents;
            case ItemTypeKey.workProcedure:
                detail.procedures = [];
                contents.details = detail;
                return contents;
            case ItemTypeKey.precautions:
                detail.precautions = [];
                contents.details = detail;
                return contents;
            case ItemTypeKey.safetyCall:
            case ItemTypeKey.general_chant:
                detail.call = "";
                detail.response = "";
                contents.details = detail;
                return contents;
            case ItemTypeKey.siteUpdates:
            case ItemTypeKey.general_photoList:
            case ItemTypeKey.general_phototextList:
                detail.updates = [];
                contents.details = detail;
                return contents;
            case ItemTypeKey.general_text:
            case ItemTypeKey.general_titletext1:
            case ItemTypeKey.general_titletext2:
            case ItemTypeKey.general_titletext3:
            case ItemTypeKey.general_titletext4:
            case ItemTypeKey.general_titletext5:
                detail.texts = [];
                contents.details = detail;
                return contents;
            case ItemTypeKey.general_speech:
                detail.speakers = [];
                contents.details = detail;
                return contents;
            case ItemTypeKey.general_questionnaire:
                detail.questions = [];
                contents.details = detail;
                return contents;
        }
        return undefined;
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose(false);
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">新しい項目を追加します
                <Box display={loading ? "block" : "none"} >
                    <CircularProgress className={classes.progress} size={30} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    追加したい項目の種類を選択して追加ボタンを押してください。
                </DialogContentText>
                {
                    catJson?.categories?.map((cat) => {
                        if (cat.key === "demo") return null;

                        return (
                            <Accordion key={cat.label} expanded={expanded === cat.key} onChange={(ev, expand) => {
                                setExpanded(expand ? cat.key ?? "" : "");
                            }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>{cat.label ?? ""}
                                    <div  className={classes.caution} style={{display:cat.key === "general" ? "inline" : "none"}}>
                                        <br/>※スマホアプリでのご利用には、iPhone/AndroidアプリVer 2.0.1以上が必要です。</div>
                                        </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <FormControl component="fieldset">
                                        {
                                            cat.contents?.map((cnt) => {
                                                if (!UseRadioGym &&
                                                    (cnt.type === ItemTypeKey.radioCalisthenics
                                                        || cnt.type === ItemTypeKey.general_radioCalisthenics)) {
                                                    return null;
                                                } else if(cnt.type === ItemTypeKey.general_questionnaire){
                                                    return null;
                                                }else{
                                                    return (
                                                        <FormControlLabel
                                                            key={cnt.type}
                                                            value={cnt.type}
                                                            control={
                                                                <Radio
                                                                    checked={selectedValue === cnt.type}
                                                                    onChange={onChangeRadio}
                                                                    value={cnt.type}
                                                                />
                                                            }
                                                            label={<span className={classes.label}>{GetItemIcon(cnt.type ?? "")}{cnt.title}</span>}
                                                            labelPlacement="end"
                                                        />)
                                                }
                                            })
                                        }
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }



            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose(false);
                    }}
                    className={classes.btn}
                >
                    キャンセル
        </Button>
                <Button
                    onClick={() => {
                        const contents = onCreateNewItem();
                        if (contents) {
                            onClose(true, contents);
                        }
                    }}
                    className={classes.btn}
                    disabled={selectedValue === ""}
                >
                    追加
        </Button>
            </DialogActions>
        </Dialog>
    );
};
export default NewCatItemDialog;
