import React from 'react';

import {
     IconButton, makeStyles,  Tooltip
} from '@material-ui/core';
import AddPin from '../../images/add-pin.svg';

const useStyles = makeStyles((theme) => ({
  addpin: {
    height: "30px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  addpinBox: {
    padding: "0 !important" as any,
    textAlign: "right",
    marginRight: "1px",
  },
}));

const AddBtn: React.FunctionComponent<{
  index: number;
  onClick: (idx: number) => void;
  hide?: boolean;
}> = ({ index, onClick, hide = false }) => {
  const classes = useStyles();
  if (hide) return null;
  return (
    <Tooltip title="ここへ項目を追加">
      <IconButton
        edge="end"
        aria-label="delete"
        className={classes.addpinBox}
        onClick={() => {
          onClick(index);
        }}
      >
        <img className={classes.addpin} src={AddPin} alt="add"></img>
      </IconButton>
    </Tooltip>
  );
};
export default AddBtn;
