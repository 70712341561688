import { TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { ItemTypeKey } from "../../Constants";
import { ContentsJson, DetailJson, LineJson, MorningAssemblyJson, TextJson } from "../../net/NetworkClient";
import ContentsItemListBase, { DataType, IContentsItemListDelegate, IItemCardContentsProp } from "./ContentsItemListBase";
import { useDebounce } from "use-debounce";
import { v4 as uuid } from "uuid";



///テキストパネル
const ContentsItemText: React.FunctionComponent<{
    contentsid:string|undefined;
    jsonData: MorningAssemblyJson | undefined;
    selectedItem: { key: string; id: string } | undefined;
    onUpdate: (newData: ContentsJson) => void;
    onChanging: (changing: boolean) => void;
}> = ({ contentsid,jsonData, selectedItem, onUpdate, onChanging }) => {

    return (
        <ContentsItemListBase
            delegate={new TextDelegate()}
            contentsid={contentsid??""}
            cardContents={TextCardContents}
            jsonData={jsonData}
            selectedItem={selectedItem}
            onUpdate={onUpdate}
            onChanging={onChanging}
        >

        </ContentsItemListBase>
    )
}

///テキスト処理デリゲート
class TextDelegate implements IContentsItemListDelegate<TextJson>{
    getUUID(item: TextJson): string {
        return item.uuid ?? "";
    }
    isSuported(type: string | undefined): boolean {
        return type === ItemTypeKey.general_text;
    }

    copyData(data: DataType<TextJson>): DataType<TextJson> {
        return {
            title: "" + data.title,
            list: data.list.map((p) => new TextJson(p)),
        }
    }

    contentsToData(contents: ContentsJson): DataType<TextJson> {
        return {
            title: contents.title ?? "",
            list: contents.details?.texts ?? []
        }
    }

    buildContentsJson(contents: ContentsJson, data: DataType<TextJson>): ContentsJson {
        var copy = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));
        copy.title = "" + data.title;

        const tmp = new DetailJson();
        tmp.texts = data.list;
        const detail = DetailJson.fromJS(JSON.parse(JSON.stringify(tmp)));
        copy.details = detail;
        return copy;
    }

    makeNewItem(): TextJson {
        var re = new TextJson();
        re.uuid = uuid();
        return re;
    }
}


///テキストのカード内容
const TextCardContents: React.FunctionComponent<IItemCardContentsProp>
    = ({ index, item, contents, onUpdate, onChanging }) => {

        const [data, setData] = React.useState<string>(item instanceof TextJson ? getValue(item) : "");

        function getValue(json: TextJson | undefined): string {
            if (json && json.lines) {
                return json.lines[0].text ?? "";
            }
            return "";
        }

        //OnChangeの度にJsonを更新すると処理が重たいので、debounce処理
        const [debouncedData] = useDebounce(data, 500);
        useEffect(() => {
            if (item instanceof TextJson) {
                if (getValue(item) !== data) {
                    updateContentsJson();
                }
            }
            return;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [debouncedData]);

        //データ更新
        function updateContentsJson() {
            const prec = new TextJson(item);
            var line = new LineJson();
            line.text = data;
            prec.lines = [line];

            var newdata = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));

            if (!newdata.details) newdata.details = new DetailJson();
            newdata.details.texts = newdata.details?.texts?.map((p) =>
                p.uuid === prec.uuid ? prec : p
            );
            if (onUpdate)
                onUpdate(newdata);
            if (onChanging)
                onChanging(false);
        }


        if (!item) return null;

        return (
            <TextField
                name="item"
                label="テキスト"
                value={data}
                fullWidth
                multiline
                rows={3}
                error={!data}
                helperText={!data ? "値を入力して下さい" : ""}
                onChange={(e) => {
                    if (onChanging)
                        onChanging(true);
                    setData(e.target.value);
                }}
            ></TextField>
        )
    }


export default ContentsItemText;
