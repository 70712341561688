import moment from "moment-timezone";
import React, { useEffect, FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import {
    Button,
    Dialog,
    DialogContent,
    Grid, DialogActions, Typography, IconButton,
    Box, CircularProgress,  FormControl,  FormControlLabel, Radio
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { AlertDialogSlice } from "../misc/AlertDialog";

import { ButtonBackGround } from "../Constants";
import {  SysadGetOrderSv,  SysadPutOrderPaystateSv } from "../net/SysadAsync";


moment.tz.setDefault('Asia/Tokyo');

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

const useStyles = makeStyles((theme) => ({
    top: {
        position: "absolute",
        right: 25,
        top: 20,
    },
    historyList: {

        overflowX: "hidden",
        overflowY: "auto"
    },
    linkStyle: {
        color: ButtonBackGround
    },
    gridCenter: {
        alignSelf: "flex-start",
        fontSize: "1rem",
        height: theme.spacing(17)
    },
    divider: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1)
    },
    notation: {
        fontSize: "0.7rem",
        paddingLeft: theme.spacing(1)
    },
    textField: {
        //marginTop: theme.spacing(1),
        //marginBottom: theme.spacing(1)
    },
    postalCodeField: {
        //marginTop: theme.spacing(1),
        width: "180px"
    },
    amountTextStyle: {
        display: "flex",
        alignItems: "center",
        fontSize: "2rem",
        justifyContent: "center"
    },
    payBtn: {
        color: "white",
        backgroundColor: ButtonBackGround
    },
    actionBox: {
        width: "100%"
    },
    postalDiv: {
        display: "flex",
        alignItems: "baseline"
    }
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const SysadSetOrderStateDialog: FunctionComponent<{
    open: boolean;
    orderId: string | undefined;
    onClose: (update: boolean) => void;
}> = ({ open, onClose, orderId }) => {
    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);


    const [paystate, setPaystate] = React.useState(0);

    const dispatch = useDispatch();




    //選択中のテナント情報が変更された
    useEffect(() => {
        if (open) {
            setLoading(true);

            dispatch(SysadGetOrderSv({
                orderId: orderId ?? "",
                callback: (ok, o) => {
                    setLoading(false);
                    if (!ok || !o) {
                        dispatch(
                            AlertDialogSlice.actions.openAlert({
                                title: "エラー",
                                message: "注文情報の取得に失敗しました。",
                            })
                        );
                    } else {
                        if (o.paid) {
                            setPaystate(2);
                        } else if (o.registered) {
                            setPaystate(1);
                        } else {
                            setPaystate(0);
                        }
                    }
                }
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const postTenantLicense = () => {

        setLoading(true);

         dispatch(SysadPutOrderPaystateSv({
             orderId: orderId ?? "",
             paystate:paystate,
             callback: (ok, error) => {
                 setLoading(false);
                 if (!ok) {
                     dispatch(
                         AlertDialogSlice.actions.openAlert({
                             title: "エラー",
                             message: "登録に失敗しました",
                         })
                     );
                 } else {
                     onClose(true);
                 }
             }
         }))
    }

    const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });
    const onRadio = (event:any) => {
        setPaystate(parseInt(""+event.target.value,10));
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose(false);
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="close-dialog-title" onClose={() => {
                onClose(false);
            }}>
                注文の状態設定
            </DialogTitle>
            <DialogContent>

                <Box hidden={!loading}>
                    <CircularProgress style={{ color: "#1a90ff" }} size={24} />
                </Box>

                <Grid container spacing={1} className={classes.historyList}>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} className={classes.gridCenter}>
                        <FormControl component="fieldset">
                            <FormControlLabel  control={<Radio value="0" onChange={onRadio} checked={paystate === 0}/>} label="登録のみ" />
                            <FormControlLabel  control={<Radio value="1" onChange={onRadio} checked={paystate === 1}/>} label="請求書登録済" />
                            <FormControlLabel  control={<Radio value="2" onChange={onRadio} checked={paystate === 2}/>} label="支払い確認済み" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}></Grid>


                </Grid>

            </DialogContent>
            <DialogActions>

                <Grid container spacing={1}>


                    <Grid item xs={1}></Grid>
                    <Grid item xs={5} className={classes.amountTextStyle}><Button variant="contained" fullWidth href="#contained-buttons" className={classes.payBtn}
                        onClick={() => { onClose(false) }}>キャンセル</Button></Grid>
                    <Grid item xs={5} className={classes.amountTextStyle}><Button variant="contained" fullWidth href="#contained-buttons" className={classes.payBtn}
                        onClick={() => { postTenantLicense() }}>登録</Button></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>


            </DialogActions>
        </Dialog>
    );
};
export default SysadSetOrderStateDialog;
