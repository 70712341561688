import React, { useEffect, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Popover,
    Typography,
    makeStyles,
    Button,
    Box,
    Grid,
    TextField,
    Divider,
    CircularProgress,
    Avatar,
} from "@material-ui/core";
import { RootState } from "../Store";
import AuthSlice, { ClosedPrifileDialogInfo } from "../openid/AuthSlice";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';

import { getProfileSv, setUserNameSv, setUserPasswordSv } from "../net/ProfileAsync";
import { AlertDialogSlice } from "../misc/AlertDialog";
import { ButtonBackGround } from "../Constants";

const useStyles = makeStyles((theme) => ({
    gridCenter: {
        textAlign: 'center',
        width: "100%"
    },
    gridBox: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: 'center',
        width: "100%"
    },
    button: {
        padding: 0,
        paddingLeft: theme.spacing(3),
        width: "100%",
        justifyContent: "flex-start"
    },
    buttonCenter: {
        width: "100%",
    },
    textField: {
        margin: "8px",
        width: "100%",
    },
    top: {
        position: "absolute",
        left: 25,
        top: 20,
    },
    progress: {
        color: "#1a90ff",
    },

    profileBtn: {
        color: theme.palette.common.white,
        backgroundColor: ButtonBackGround,
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginLeft: "auto",
        marginRight: "auto"
    },
}));

//***************************
// ユーザープロファイルダイアログ
//***************************
const UserProfileDialog: FunctionComponent = () => {
    var dispatch = useDispatch();
    const classes = useStyles();


    const user = useSelector(
        (state: RootState) => state.auth.user
    );
    /* authService.getAccessToken().then(function (token) {
         console.log("accesstoken=" + token);
     })*/

    //表示ビューインデックス
    const [viewIndex, setViewIndex] = React.useState(0);
    //変更名称
    const [nameText, setNameText] = React.useState(user?.name);
    //パスワード
    const [passwordText, setPasswordText] = React.useState("");
    //パスワード確認用
    const [confirmText, setConfirmText] = React.useState("");
    //待機中フラグ
    const [loading, setLoading] = React.useState(false);

    const dlgInfo = useSelector(
        (state: RootState) => state.auth.userProfileDialogInfo
    );

    //パスワード設定時のエラー
    const [passwordErrors, setPasswordErrors] = React.useState<string[]>([]);

    //パスワードのエラー配列を表示形式に変換
    const toPassErrorToHelperText = () => {
        if (passwordErrors.length <= 0)
            return "(※英数字6文字以上で、必ず数字を含めてください)";
        else
            return (<div>{passwordErrors.map((err) => { return <div>{err}</div> })}</div>);
    }
    useEffect(() => {
        if (!passwordText) {
            setPasswordErrors([]);
            return;
        }
        if (passwordText.length < 6) {
            setPasswordErrors(["6文字以上入力してください"]);
            return;
        }
        if (!/[0-9]/.test(passwordText)) {

            setPasswordErrors(["数字を含めてください"]);
            return;
        }
        setPasswordErrors([]);
    }, [passwordText]);

    useEffect(() => {
        if (dlgInfo.open) {
            setViewIndex(0);
            setPasswordText("");
            setConfirmText("");
        }
    }, [dlgInfo]);

    //***************************
    // 閉じる処理
    //***************************
    const handleClose = () => {
        dispatch(
            AuthSlice.actions.setProfileDialog(ClosedPrifileDialogInfo)
        );
    };

    //***************************
    // ログアウト
    //***************************
    /*const onLogout = () => {
        handleClose();
        history.push(ApplicationPaths.LogOut);
    }*/

    //***************************
    // 名称変更
    //***************************
    const onSetName = () => {
        if ((nameText?.length ?? 0) <= 0)
            return;

        setLoading(true);
        dispatch(
            setUserNameSv({
                name: nameText ?? "", callback: (ok, error) => {
                    setLoading(false);
                    if (!ok) {
                        dispatch(
                            AlertDialogSlice.actions.openAlert({
                                title: "エラー",
                                message: "設定できませんでした。" + error?.response,
                            })
                        );
                    } else {
                        dispatch(getProfileSv({
                            callback: (ok2, inUser) => {
                                if (ok2) {
                                    dispatch(AuthSlice.actions.setUser(inUser));

                                    handleClose();
                                    dispatch(
                                        AlertDialogSlice.actions.openAlert({
                                            title: "変更完了",
                                            message: "名称を変更しました。",
                                        })
                                    );
                                } else {
                                    AlertDialogSlice.actions.openAlert({
                                        title: "エラー",
                                        message: "ユーザー情報を取得できませんでした。"
                                    })
                                }
                            }
                        }))
                    }
                }
            })
        );
    }

    //***************************
    // パスワード変更
    //***************************
    const onChangePassword = () => {
        if (passwordText !== confirmText || passwordErrors.length > 0 || !passwordText)
            return;
        setLoading(true);
        dispatch(
            setUserPasswordSv({
                password: passwordText, callback: (ok, error) => {
                    setLoading(false);
                    if (!ok) {
                        dispatch(
                            AlertDialogSlice.actions.openAlert({
                                title: "エラー",
                                message: "サーバに問題が発生してパスワードを変更できませんでした。",
                            })
                        );
                    } else {
                        handleClose();
                        dispatch(
                            AlertDialogSlice.actions.openAlert({
                                title: "変更完了",
                                message: "パスワードを変更しました。",
                            })
                        );
                    }
                }
            })
        );
    }


    return (
        <Popover
            open={dlgInfo.open}
            anchorEl={
                document.getElementById(dlgInfo.anchorId) ??
                document.getElementById("root")
            }
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
        >
            <Box style={{ width: 300, margin: "18px" }}>
                <Grid container spacing={1}>
                    <Grid container item xs={12} style={{ paddingTop: "18px" }}>
                        <Box className={classes.gridCenter}>
                            <Avatar className={classes.profileBtn}>
                                {user?.name?.slice(0, 1) ?? " "}
                            </Avatar>
                        </Box>
                        <Box component="span" className={classes.top} hidden={!loading}>
                            <CircularProgress className={classes.progress} />
                        </Box>
                    </Grid>
                    <Grid container item xs={12}>
                        <Typography variant="subtitle2" className={classes.gridCenter}>{user?.name}</Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <Typography variant="caption" className={classes.gridCenter}>({user?.email})</Typography>
                    </Grid>
                    <Grid container item xs={12} style={{ paddingTop: "24px" }}>
                        <Divider light className={classes.gridCenter} />
                    </Grid>
                    <Grid container spacing={1}>
                        <Box style={{ display: viewIndex === 0 ? "block" : "none" }} className={classes.gridBox}>
                            <Grid container spacing={1}>
                                <Grid container item xs={12}>
                                    <Button
                                        className={classes.button}
                                        startIcon={<EditIcon />}
                                        onClick={() => { setViewIndex(1) }}
                                    >氏名変更</Button>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Button
                                        className={classes.button}
                                        startIcon={<VpnKeyIcon />}
                                        onClick={() => { setViewIndex(2) }}
                                    >パスワード変更</Button>
                                </Grid>
                                {/* 
                                <Grid container item xs={12}>
                                    <Button
                                        onClick={onLogout}
                                        className={classes.button}
                                        startIcon={<ExitToAppIcon />}
                                    >ログアウト</Button>
                                </Grid>
                                */}
                            </Grid>
                        </Box>
                        <Box style={{ display: viewIndex === 1 ? "block" : "none" }} className={classes.gridBox}>
                            <Grid container spacing={1}>
                                <Grid container item xs={12} style={{ paddingBottom: "30px" }}>
                                    <TextField required label="氏名" value={nameText}
                                        className={classes.textField}
                                        onChange={(e) => {
                                            setNameText(e.target.value);
                                        }}
                                        error={(nameText?.length ?? "") <= 0}
                                        helperText={(nameText?.length ?? 0) <= 0 ? "氏名を入力してください。" : ""}
                                    />
                                </Grid>
                                <Grid container item xs={6}>
                                    <Button
                                        onClick={() => { setNameText(user?.name); setViewIndex(0) }}
                                        className={classes.buttonCenter}
                                        disabled={loading}
                                    >キャンセル</Button>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Button
                                        onClick={onSetName}
                                        className={classes.buttonCenter}
                                        disabled={loading}
                                    >設定</Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box style={{ display: viewIndex === 2 ? "block" : "none" }} className={classes.gridBox}>
                            <Grid container spacing={1}>
                                <Grid container item xs={12} style={{}}>
                                    <TextField required type="password" label="パスワード"
                                        value={passwordText} className={classes.textField}
                                        onChange={(e) => {
                                            setPasswordText(e.target.value);
                                        }}
                                        error={passwordErrors?.length > 0}
                                        helperText={toPassErrorToHelperText()}
                                    />
                                </Grid>
                                <Grid container item xs={12} style={{}}>
                                    <TextField required type="password" label="パスワード確認"
                                        value={confirmText} className={classes.textField}
                                        onChange={(e) => {
                                            setConfirmText(e.target.value);
                                        }}
                                        error={passwordText !== confirmText}
                                        helperText={(passwordText !== confirmText ? "パスワードと内容が違います" : "")}
                                    />
                                </Grid>
                                <Grid container item xs={6}>
                                    <Button
                                        onClick={() => { setPasswordText(""); setConfirmText(""); setViewIndex(0) }}
                                        className={classes.buttonCenter}
                                        disabled={loading}
                                    >キャンセル</Button>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Button
                                        onClick={onChangePassword}
                                        className={classes.buttonCenter}
                                        disabled={loading || passwordText !== confirmText || passwordErrors.length > 0 || !passwordText}
                                    >設定</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

        </Popover>);

}


export default UserProfileDialog;