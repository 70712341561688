
import React, { useEffect } from 'react';
import { Box, CircularProgress, Grid, List, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment-timezone"
import { FooterHeight } from '../Constants';
import { MATenant } from '../net/NetworkClient';
import { useDispatch } from 'react-redux';
import { sysadGetTenantSv, sysadTenantsSv } from '../net/SysadAsync';
import { AlertDialogSlice } from '../misc/AlertDialog';
import SysadOrderView from './SysadOrderView';

moment.tz.setDefault('Asia/Tokyo');

const useStyles = makeStyles((theme) => ({
    root: {
        //display: "flex",
        //flexDirection: "column",
        //alignItems: "stretch",
        height: "calc(100vh - " + (FooterHeight + 48) + "px)",
    },
    contents: {
        //flex: 1,
        height: "100%",
        overflow: "hidden",
        padding: theme.spacing(0, 1),
        position: "relative",
        backgroundColor: theme.palette.background.paper
    },
    gridContainer: {
        //flex: 1,
        height: "100%",
        margin: 0
    },
    cardColumn: {

        height: "100%",
        overflowY: "auto"
    },

    cardTitle: {
        color: theme.palette.text.primary,
        cursor: "pointer",
        fontSize: "1.0rem",
    },
    cardContent: {
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    cardText: {
        fontSize: "0.8rem",
    },
}));




function SysadView() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(false);
    const [tenants, setTenants] = React.useState<MATenant[]>([]);
    const [selectedTenantIdx, setSelectedTenantIdx] = React.useState(0);
    const [selectedTenant, setSelectedTenant] = React.useState<MATenant>();

    //表示中のテナントが変更された場合
    useEffect(() => {
        refreshTenants();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshTenants = () => {
        setLoading(true);
        dispatch(sysadTenantsSv({
            callback: (ok, inTenants) => {
                setLoading(false);
                if (!ok || inTenants == null) {
                    setTenants([]);
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "会社一覧の取得に失敗しました",
                        })
                    );
                } else {
                    setTenants(inTenants);
                    setSelectedTenantIdx(0);
                    setSelectedTenant(inTenants.length > 0 ? inTenants[0] : undefined);
                }
            }
        }))
    }

    const refreshOneTenant = (tenantId: string) => {
        setLoading(true);
        dispatch(sysadGetTenantSv({
            tenantId: tenantId,
            callback: (ok, tenant) => {
                setLoading(false);
                if (!ok || tenant == null) {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "更新した会社情報の取得に失敗しました",
                        })
                    );
                } else {
                    var index = 0;
                    var list = tenants.map((t, idx) => {
                        if (t.id === tenant.id) {
                            index = idx;
                            return tenant;
                        } else {
                            return t;
                        }
                    })
                    setTenants(list);
                    setSelectedTenantIdx(index);
                    setSelectedTenant(tenant);
                }
            }
        }))
    }


    return (
        <Box className={classes.root}>
            <Box className={classes.contents}>
                <Box hidden={!loading}>
                    <CircularProgress style={{ color: "#1a90ff" }} size={24} />
                </Box>
                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={4} className={classes.cardColumn}>
                        <List>
                            {
                                tenants.map((tenant, idx) => (
                                    <ListItem button key={tenant.id} selected={selectedTenantIdx === idx}
                                        onClick={() => { setSelectedTenantIdx(idx); setSelectedTenant(tenant); }}>
                                        <ListItemText primary={tenant.name} />
                                    </ListItem>
                                ))
                            }

                        </List>
                    </Grid>
                    <Grid item xs={8}>
                        <SysadOrderView tenant={selectedTenant} onReload={(tid) => {
                            refreshOneTenant(tid);
                        }} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}


export default SysadView;

