
import React from "react";

import { Toolbar, IconButton,  Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    ToolbarBackGroundColor,
    LinkTextColor,
} from "../Constants";
import BusinessIcon from '@material-ui/icons/Business';
import { TenantInfo } from "../net/NetworkClient";

var classNames = require("classnames");

const useStyles = makeStyles((theme) => ({

    toolbar: {
        backgroundColor: ToolbarBackGroundColor,
        paddingLeft: "0px",
    },
    toolbarTenantBtn: {
        color: LinkTextColor,
        fontSize: "0.8rem",
    },
    nonactive_btn: {
        cursor: "default",
        pointerEvents: "none",
    },
    configBtn: {
        color: LinkTextColor
    },
    configBox: {
        marginLeft: "auto",
        marginRight: "0px"
    },

}));

const TenantToolbar: React.FunctionComponent<{
    loading: boolean;
    tenant: TenantInfo|null;
}> = ({ loading,tenant }) => {
    const classes = useStyles();

    var tbtnc = classNames(
        classes.toolbarTenantBtn,
        { [classes.nonactive_btn]: true }
    );

    return (
        <Toolbar variant="dense" className={classes.toolbar}>
            <IconButton
                className={tbtnc}
                onClick={() => {

                }}>
                <BusinessIcon />
                {(tenant?.tenant?.name ?? "") + " > プロジェクト一覧"}   
            </IconButton>
            <Box hidden={!loading}>
                
            <CircularProgress style={{ color: "#1a90ff" }} size={24}/>
            </Box>
            {/* 
            <Box hidden={!isAdmin} className={classes.configBox}>
                <Tooltip title={"会社管理"}>
                    <IconButton
                        aria-label="tenant config"
                        className={classes.configBtn}
                        onClick={() => {
                            history.push("/tenants/" + tenant?.tenant?.id + "/admin");
                        }}>
                        <SettingsIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            */}
        </Toolbar >
    )
}


export default TenantToolbar;