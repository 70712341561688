import './App.css';

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Link, Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./Store";

import {
  AppBar, Box, CssBaseline, IconButton, Toolbar, Tooltip, Typography, Link as MLink, CircularProgress, Avatar
} from '@material-ui/core';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import { BaseBackGroundColor, EyeCatchColor, DrawerBackColor, DrawerSelectedColor, ButtonBackGround, FooterHeight } from './Constants';
import ContentsView from './contents/ContentsView';
import AlertDialog, { AlertDialogSlice } from './misc/AlertDialog';
import ConfirmDialog from './misc/ConfirmDialog';
import WaitingBar from './misc/WaitingBar';
//import ProjectsView from './projects/ProjectsView';
import TenantView from './tenant/TenantView';
import AdminView from './admin/AdminView';
import CalendarView from './calendar/CalendarView';
//import ContentsListView from './contents-list/ContentsListView';
//import preval from 'preval.macro'
import moment from "moment-timezone"


import ApiAuthorizationRoutes from './openid/ApiAuthorizationRoutes';
import { ApplicationPaths } from './openid/ApiAuthorizationConstants';
import authService from './openid/AuthorizeService';
//import {Profile} from "oidc-client";

//import ContentsListSlice from "./contents-list/ContentsListSlice";
import AuthSlice, { UserProfileDialogInfo } from './openid/AuthSlice';
import UserProfileDialog from './profile/UserProfileDialog';
import { getProfileSv, isSysadSv } from './net/ProfileAsync';
import TenantSlice from './tenant/TenantSlice';
import { tenantFindSv } from './net/TenantAsync';
import metadata from './metadata.json';
import ActionSlice, { ActionType } from './misc/ActionSlice';
import GMOResultView from './admin/GMOResultView';
import SysadView from './sysad/SysadView';

moment.tz.setDefault('Asia/Tokyo');

const theme = createMuiTheme({
  typography: {
    fontSize: 14,
  },
  palette: {
    primary: {
      main: "#fff",
    },
    background: {
      default: "#eee",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.8)",
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: { color: "#fff" },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: DrawerBackColor,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "none",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#ffffff",
        color: "rgba(0, 0, 0, 0.8)",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: DrawerSelectedColor,
          color: "#FFF",
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "0.9rem",
        "&$focused": {
          color: EyeCatchColor,
        },
      },
    },
    MuiInput: {
      underline: {
        borderBottom: "1px solid gray",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 5,
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: ButtonBackGround,
        },
        '&$checked + $track': {
          backgroundColor: ButtonBackGround,
        },
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: ButtonBackGround,
        },
      }
    },

  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "cals(100vh - " + FooterHeight + "px)",
    flex: 1,
    flexDirection: "column",
  },
  appBar: {
    backgroundColor: BaseBackGroundColor,
    color: "rgba(255, 255, 255, 1)",
    position: "relative",
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    color: "rgba(255, 255, 255, 1)",
    flexGrow: 1,
    fontWeight: 600,
    whiteSpace: "nowrap"
  },
  appButton: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(0),
  },
  toolbarButtons: {
    marginLeft: "auto",
    color: theme.palette.primary.main,
  },
  toolbarBtn: {
    color: theme.palette.common.white,
  },
  profileBtn: {
    color: ButtonBackGround,
    backgroundColor: theme.palette.common.white,

    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
    cursor: "pointer"
  },
  profileText: {
    color: ButtonBackGround,
    cursor: "pointer"
  },
  tenantAdmin: {
    marginRight: theme.spacing(2)
  },
  logout: {
    cursor: "pointer"
  },
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "" + FooterHeight + "px",
    backgroundColor: BaseBackGroundColor,
    zIndex: 5000,
    paddingLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  footerLink: {
    color: theme.palette.common.white,
    textDecoration: "none",
    marginRight: theme.spacing(3),
    whiteSpace: "nowrap"
  },
  version: {
    color: theme.palette.common.white,
    marginLeft: "auto",
    marginRight: theme.spacing(1),
  },
  hideDownSm: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
  },
  hideUpSm: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
  },
}));



const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const buildStr = "" + metadata.buildMajor + "." + metadata.buildMinor + "." + metadata.buildSubMinor + "(" + metadata.buildRevision + ") " + metadata.buildTag;

  const [authChecked, setAuthChecked] = React.useState(false);

  const actionMessage = useSelector((state: RootState) => state.action.action);

  const user = useSelector(
    (state: RootState) => state.auth.user
  );
  useEffect(() => {
    if (authChecked)
      return;

    const f = async () => {
      await authService.signIn();
      const [isauthed, tmpu] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

      dispatch(AuthSlice.actions.setOidcUser(tmpu));
      if (!tmpu) {
        setAuthChecked(true);
      }

      if (isauthed) {
        dispatch(getProfileSv({
          callback: (ok, mauser) => {
            dispatch(AuthSlice.actions.setUser(mauser));
            if (ok) {

            } else {

            }
          }
        }));
      }
    }
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //ログイン後のテナント読み込み
  useEffect(() => {
    if (!!user) {
      dispatch(tenantFindSv({
        callback: (ok, tenants) => {
          dispatch(TenantSlice.actions.setTenants(tenants ?? []));
          if (!ok) {
            dispatch(
              AlertDialogSlice.actions.openAlert({
                title: "エラー",
                message: "会社情報を取得できませんでした。",
              })
            );
          }
        }
      }));

      //システム管理者かどうかの確認
      dispatch(isSysadSv({
        callback: (isSysad) => {
          dispatch(AuthSlice.actions.setSysad(isSysad));
        }
      }));
    } else {
      dispatch(TenantSlice.actions.setTenants([]));
    }
    if (!authChecked && user) {
      setAuthChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);



  //---------------------
  //アクション処理
  useEffect(() => {
    switch (actionMessage.type) {
      case ActionType.HTTP_401_ERROR:
        authService.signIn(window.location.origin).then((v)=>{
          if(v.status === "success"){
            dispatch(
              AlertDialogSlice.actions.openAlert({
                title: "セッション再接続",
                message: "セッションの有効期限が切れていたため再接続しました。処理を再度実行してください。",
                callback: async () => {
                  //console.log(await authService.signIn(window.location.origin));
                  window.location.replace(window.location.href);
                  //window.location.replace(window.location.origin);
                }
              })
            );
          }
        })
        
        dispatch(ActionSlice.actions.setLoading(false));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionMessage]);

  if (!authChecked) {
    //ログイン情報確認待ち
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Box className={classes.root}>
            <MAAppBar />
            <div>...</div>
            <ApiAuthorizationRoutes />
          </Box>
        </Router>
      </ThemeProvider>
    )
  } else {
    //ログインしてないのでログインページへ移動
    if (!user) {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Box className={classes.root}>
              <MAAppBar />
              <ApiAuthorizationRoutes />
              <ToLogin />
            </Box>
          </Router>
        </ThemeProvider>
      )
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <WaitingBar />
      <AlertDialog />
      <ConfirmDialog />
      <Router>
        <Box className={classes.root}>
          <MAAppBar />


          <Route exact path={["/contents", "/contents/:contentsid", "/contents/:key/key"]} component={ContentsView} />


          <Route exact path={["/tenants/:tenantid"]} component={TenantView} />
          <Route exact path={["/tenants/:tenantid/admin"]} component={AdminView} />


          <Route exact path={["/calendar/:tenantid/:projectid"]} component={CalendarView} />

          <Route exact path={["/", "/index.html"]} component={TopView} />

          <Route exact path={["/gmoresult"]} component={GMOResultView} />

          <Route exact path={["/sysad"]} component={SysadView} />

          <UserProfileDialog />
          <ApiAuthorizationRoutes />
        </Box>
        <Box className={classes.footer}>
          <a className={classes.footerLink} target="_blank" rel="noopener noreferrer" href="https://www.chorei084.com/">ホーム</a>
          <a className={classes.footerLink} target="_blank" rel="noopener noreferrer" href="https://www.chorei084.com/news/"><span>084NEWS</span></a>
          <a className={classes.footerLink} target="_blank" rel="noopener noreferrer" href="https://www.chorei084.com/service/"><span>サービス概要</span></a>

          {/*
          <a className={classes.footerLink} target="_blank" rel="noopener noreferrer" href="https://www.chorei084.com/privacy-policy/">
            <span className={classes.hideDownSm}>利用規約・個人情報の取り扱いについて</span>
            <span className={classes.hideUpSm}>利用規約・個人情報</span>
          </a>
          */}
          <a className={classes.footerLink} target="_blank" rel="noopener noreferrer" href="https://www.chorei084.com/wp-content/uploads/2021/03/termOfUse_Startup.pdf">トライアルサービス利用約款</a>

          <a className={classes.footerLink} target="_blank" rel="noopener noreferrer" href="https://www.chorei084.com/about/">運営会社</a>
          <a className={classes.footerLink} target="_blank" rel="noopener noreferrer" href="https://www.chorei084.com/law">特定商取引法に基づく表記</a>
          <a className={classes.footerLink} target="_blank" rel="noopener noreferrer" href="https://www.chorei084.com/payment">お支払い方法について</a>

          <span className={classes.version}>
            Ver.{buildStr}
          </span>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;

//****************************************
//メニューバー
//****************************************
function MAAppBar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const appLoading = useSelector((state: RootState) => state.action.loading);
  const [loading, setLoading] = React.useState(false);
  const user = useSelector(
    (state: RootState) => state.auth.user
  );
  const sysad = useSelector(
    (state: RootState) => state.auth.sysad
  );
  //選択中のテナント
  const currentTenant = useSelector(
    (state: RootState) => state.tenants.currentTenant
  )

  function onClickProfile() {
    var info: UserProfileDialogInfo = {
      open: true,
      anchorId: "UserProfileBtn",
    };
    dispatch(AuthSlice.actions.setProfileDialog(info));
  }

  //---------------------
  //アクション処理
  useEffect(() => {
    setLoading(appLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appLoading]);

  useEffect(() => {
    if (sysad) {
      history.push("/sysad");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sysad]);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar variant="dense">
        <Link to="/" style={{ textDecoration: "none" }}>
          <IconButton edge="start" className={classes.appButton}>
            <img style={{ height: "24px" }} src={"/084_logo_WH24.png"} alt="ロゴ画像" />
          </IconButton>
        </Link>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Typography variant="h6" className={classes.title}>
            朝礼アプリ
                </Typography>
        </Link>
        <Box style={{ marginRight: "auto", marginLeft: "8px" }}>
          <CircularProgress style={{ color: "#FFF", margin: "0px", display: (loading ? "block":"none") }} size={24} />
        </Box>
        <Box component="span" hidden={!sysad}
          onClick={() => { history.push("/sysad") }}>
          <MLink className={classes.tenantAdmin} href="#" onClick={() => { }}>システム管理</MLink>
        </Box>
        <Box component="span" hidden={sysad || !currentTenant || !currentTenant.admin}
          onClick={() => { history.push("/tenants/" + currentTenant?.tenant?.id + "/admin") }}>
          <MLink className={classes.tenantAdmin} href="#" onClick={() => { }}>会社情報管理</MLink>
        </Box>

        <Tooltip title={user?.name ?? ""}>
          <MLink onClick={onClickProfile} className={classes.profileText} style={{ textDecoration: "none", display: user ? "inline" : "none" }}>
            <Avatar id="UserProfileBtn" className={classes.profileBtn}>
              {user?.name?.slice(0, 1) ?? " "}
            </Avatar>
          </MLink>
        </Tooltip>
        <MLink className={classes.logout} onClick={() => { history.push(ApplicationPaths.LogOut); }}>
          ログアウト
        </MLink>


      </Toolbar>
    </AppBar>
  )
}


//************************************************
//ログイン画面へジャンプするためのコンポーネント
//************************************************
function ToLogin() {
  const history = useHistory();
  history.push(ApplicationPaths.Login);
  return null;
}




function TopView() {
  const history = useHistory();

  //テナント一覧情報
  const tenants = useSelector(
    (state: RootState) => state.tenants.tenants
  );
  //表示メッセージ
  const [message, setMessage] = React.useState("");


  //ユーザーのログイン情報が変更された場合


  //テナントの登録が変更された
  useEffect(() => {
    if (tenants.length > 0 && tenants[0].tenant) {
      history.push("/tenants/" + tenants[0].tenant.id);
    } else {
      setMessage("会社情報がありません。");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants]);


  return (
    <Box>
      <CircularProgress style={{ color: "#1a90ff", margin: "24px", display: !message ? "block" : "none" }} size={30} />
      <div>{message}</div>
    </Box>
  );
}