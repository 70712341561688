import { Card, CardContent, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { ItemTypeKey } from "../../Constants";
import { ContentsJson, DetailJson, LineJson, MorningAssemblyJson, TextJson } from "../../net/NetworkClient";
import ContentsItemListBase, { DataType, IContentsItemListDelegate, IItemCardContentsProp } from "./ContentsItemListBase";
import { useDebounce } from "use-debounce";
import { v4 as uuid } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    typeTitle: {
        textAlign: "left",
        marginTop: theme.spacing(2)
    },
    labelCard:{
        backgroundColor:theme.palette.grey[200]
    }
}));

///テキストパネル
const ContentsItemTitleText: React.FunctionComponent<{
    contentsid:string|undefined;
    jsonData: MorningAssemblyJson | undefined;
    selectedItem: { key: string; id: string } | undefined;
    onUpdate: (newData: ContentsJson) => void;
    onChanging: (changing: boolean) => void;
}> = ({ contentsid,jsonData, selectedItem, onUpdate, onChanging }) => {

    var contents = jsonData?.contents?.find(
        (c) => c.uuid === selectedItem?.id
    )
    return (
        <ContentsItemListBase
            delegate={new TextDelegate()}
            contentsid={contentsid??""}
            cardContents={TitleTextCardContents}
            jsonData={jsonData}
            selectedItem={selectedItem}
            onUpdate={onUpdate}
            onChanging={onChanging}
            cardHeader={<TitlesHeader contents={contents} onUpdate={onUpdate} onChanging={onChanging} />}
        >

        </ContentsItemListBase>
    )
}
interface TitleData {
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    title5: string;
}
const TitlesHeader: React.FunctionComponent<{
    contents?: ContentsJson;
    onUpdate?: (newData: ContentsJson) => void;
    onChanging?: (changing: boolean) => void;
}>
    = ({ contents, onUpdate, onChanging }) => {
        var TextCount = (): number => {
            switch (contents?.type) {
                case ItemTypeKey.general_titletext1:
                    return 1;
                case ItemTypeKey.general_titletext2:
                    return 2;
                case ItemTypeKey.general_titletext3:
                    return 3;
                case ItemTypeKey.general_titletext4:
                    return 4;
                case ItemTypeKey.general_titletext5:
                    return 5;
            }
            return 0;
        };
        function getTitleTarget(): object | undefined {
            if (contents?.details?.texts)
                return contents.details.texts[0];
            return undefined;
        }
        function getTitle(index: number, json: object | undefined): string {
            if (!(json instanceof TextJson))
                return "";

            if (json && json.lines && json.lines.length > index) {
                return json.lines[index].title ?? "";
            }
            return "";
        }
        const [data, setData] = React.useState<TitleData | undefined>(jsonToData());

        useEffect(() => {
            var copy = jsonToData();
            if (copy && !isEqual(copy, data)) {
                setData(copy);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [contents]);

        //OnChangeの度にJsonを更新すると処理が重たいので、debounce処理
        const [debouncedData] = useDebounce(data, 500);
        useEffect(() => {
            if (!isEqual(jsonToData(), data)) {
                updateContentsJson();
            }
            return;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [debouncedData]);

        function jsonToData(): TitleData | undefined {
            var tc = TextCount();
            if (tc <= 0)
                return undefined;
            return {
                title1: getTitle(0, getTitleTarget()),
                title2: getTitle(1, getTitleTarget()),
                title3: getTitle(2, getTitleTarget()),
                title4: getTitle(3, getTitleTarget()),
                title5: getTitle(4, getTitleTarget())
            }
        }

        //データ更新
        function updateContentsJson() {
            var newdata = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));

            if (!newdata.details)
                newdata.details = new DetailJson();
            
            var tc = TextCount();

            //全体のラベルを設定
            newdata.details.texts?.map((val, idx) => {
                if(!val.lines || val.lines.length < tc){
                    if(!val.lines)
                        val.lines = [];
                    while(val.lines.length < tc){
                        val.lines.push(new LineJson());
                    }
                }

                val.lines?.map((line, ln) => {
                    switch (ln) {
                        case 0:
                            line.title = data?.title1;
                            break;

                        case 1:
                            line.title = data?.title2;
                            break;

                        case 2:
                            line.title = data?.title3;
                            break;

                        case 3:
                            line.title = data?.title4;
                            break;

                        case 4:
                            line.title = data?.title5;
                            break;
                    }
                })
            });

            if (onUpdate)
                onUpdate(newdata);
            if (onChanging)
                onChanging(false);
        }

        function isEqual(v1?: TitleData, v2?: TitleData) {
            const eq = (s1?: string, s2?: string): boolean => {
                if (!s1 && !s2)
                    return true;
                return s1 === s2;
            }
            return (
                eq(v1?.title1, v2?.title1)
                && eq(v1?.title2, v2?.title2)
                && eq(v1?.title3, v2?.title3)
                && eq(v1?.title4, v2?.title4)
                && eq(v1?.title5, v2?.title5)
            );
        }


        function copyData(): TitleData {
            return JSON.parse(JSON.stringify(data));
        }
        const classes = useStyles();

        if(!getTitleTarget())
            return null;

        return (
            <Card variant="outlined" className={classes.labelCard}>
                <CardContent>
                    タイトル設定
            <TextField
                        label="タイトル１"
                        value={data?.title1}
                        fullWidth
                        error={!data}
                        helperText={!data ? "値を入力して下さい" : ""}
                        onChange={(e) => {
                            if (onChanging)
                                onChanging(true);
                            var copy = copyData();
                            copy.title1 = e.target.value;
                            setData(copy);
                        }}
                    ></TextField>


                    <TextField
                        label="タイトル２"
                        value={data?.title2}
                        fullWidth
                        error={!data}
                        helperText={!data ? "値を入力して下さい" : ""}
                        style={{ display: (TextCount() >= 2 ? "block" : "none") }}
                        onChange={(e) => {
                            if (onChanging)
                                onChanging(true);
                            var copy = copyData();
                            copy.title2 = e.target.value;
                            setData(copy);
                        }}
                    ></TextField>


                    <TextField
                        label="タイトル３"
                        value={data?.title3}
                        fullWidth
                        error={!data}
                        helperText={!data ? "値を入力して下さい" : ""}
                        style={{ display: (TextCount() >= 3 ? "block" : "none") }}
                        onChange={(e) => {
                            if (onChanging)
                                onChanging(true);
                            var copy = copyData();
                            copy.title3 = e.target.value;
                            setData(copy);
                        }}
                    ></TextField>


                    <TextField
                        label="タイトル４"
                        value={data?.title4}
                        fullWidth
                        error={!data}
                        helperText={!data ? "値を入力して下さい" : ""}
                        style={{ display: (TextCount() >= 4 ? "block" : "none") }}
                        onChange={(e) => {
                            if (onChanging)
                                onChanging(true);
                            var copy = copyData();
                            copy.title4 = e.target.value;
                            setData(copy);
                        }}
                    ></TextField>



                    <TextField
                        label="タイトル５"
                        value={data?.title5}
                        fullWidth
                        error={!data}
                        helperText={!data ? "値を入力して下さい" : ""}
                        style={{ display: (TextCount() >= 5 ? "block" : "none") }}
                        onChange={(e) => {
                            if (onChanging)
                                onChanging(true);
                            var copy = copyData();
                            copy.title5 = e.target.value;
                            setData(copy);
                        }}
                    ></TextField>

                </CardContent>
            </Card>
        );
    }

///テキスト処理デリゲート
class TextDelegate implements IContentsItemListDelegate<TextJson>{
    getUUID(item: TextJson): string {
        return item.uuid ?? "";
    }
    isSuported(type: string | undefined): boolean {
        switch (type) {
            case ItemTypeKey.general_titletext1:
            case ItemTypeKey.general_titletext2:
            case ItemTypeKey.general_titletext3:
            case ItemTypeKey.general_titletext4:
            case ItemTypeKey.general_titletext5:
                return true;
        }
        return false;
    }

    copyData(data: DataType<TextJson>): DataType<TextJson> {
        return {
            title: "" + data.title,
            list: data.list.map((p) => new TextJson(p)),
        }
    }

    contentsToData(contents: ContentsJson): DataType<TextJson> {
        return {
            title: contents.title ?? "",
            list: contents.details?.texts ?? []
        }
    }

    buildContentsJson(contents: ContentsJson, data: DataType<TextJson>): ContentsJson {
        var copy = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));
        copy.title = "" + data.title;

        const tmp = new DetailJson();
        tmp.texts = data.list;
        const detail = DetailJson.fromJS(JSON.parse(JSON.stringify(tmp)));
        copy.details = detail;
        return copy;
    }

    makeNewItem(): TextJson {
        var re = new TextJson();
        re.uuid = uuid();
        return re;
    }
}

interface TitleTextData {
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    text5: string;
}

///テキストのカード内容
const TitleTextCardContents: React.FunctionComponent<IItemCardContentsProp>
    = ({ index, item, contents, onUpdate, onChanging }) => {
        var textCount = 0;
        switch (contents?.type) {
            case ItemTypeKey.general_titletext1:
                textCount = 1;
                break;
            case ItemTypeKey.general_titletext2:
                textCount = 2;
                break;
            case ItemTypeKey.general_titletext3:
                textCount = 3;
                break;
            case ItemTypeKey.general_titletext4:
                textCount = 4;
                break;
            case ItemTypeKey.general_titletext5:
                textCount = 5;
                break;
        }
        const [data, setData] = React.useState<TitleTextData>({
            text1: getText(0, item),
            text2: getText(1, item),
            text3: getText(2, item),
            text4: getText(3, item),
            text5: getText(4, item),
        });
        function copyData(): TitleTextData {
            return JSON.parse(JSON.stringify(data));
        }
        function getTitle(index: number): string {
            if(!contents?.details?.texts)
                return "";
            
            if(!contents?.details?.texts[0])
                return "";
            var lines = contents?.details?.texts[0].lines;
            if(!lines)
                return "";
            
            if(lines.length <= index)
                return "";
            
            return lines[index].title ?? "";
        }
        function getText(index: number, json: object | undefined): string {
            if (!(json instanceof TextJson))
                return "";
            if (json && json.lines && json.lines.length > index) {
                return json.lines[index].text ?? "";
            }
            return "";
        }

        //データが等しいかどうか確認する
        function isEqual(v1: TitleTextData, v2: TitleTextData): boolean {
            const eq = (s1: string, s2: string): boolean => {
                if (!s1 && !s2)
                    return true;
                return s1 === s2;
            }
            return (eq(v1.text1, v2.text1)
                && eq(v1.text2, v2.text2)
                && eq(v1.text3, v2.text3)
                && eq(v1.text4, v2.text4)
                && eq(v1.text5, v2.text5)
            );
        }

        function toData(d: TextJson): TitleTextData {
            return {
                text1: getText(0, d),
                text2: getText(1, d),
                text3: getText(2, d),
                text4: getText(3, d),
                text5: getText(4, d),
            } as TitleTextData;
        }

        useEffect(() => {
            var copy = copyData();
            if (!isEqual(copy, data)) {
                setData(copy);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [item]);

        //OnChangeの度にJsonを更新すると処理が重たいので、debounce処理
        const [debouncedData] = useDebounce(data, 500);
        useEffect(() => {
            if (item instanceof TextJson) {
                if (!isEqual(toData(item), data)) {
                    updateContentsJson();
                }
            }
            return;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [debouncedData]);

        //データ更新
        function updateContentsJson() {

            const prec = new TextJson(item);
            var lines: LineJson[] = [];

            for (var i = 0; i < textCount; i++) {
                var line = new LineJson();
                switch (i) {
                    case 0:
                        line.title = getTitle(0);
                        line.text = data.text1;
                        break;
                    case 1:
                        line.title = getTitle(1);
                        line.text = data.text2;
                        break;
                    case 2:
                        line.title = getTitle(2);
                        line.text = data.text3;
                        break;
                    case 3:
                        line.title = getTitle(3);
                        line.text = data.text4;
                        break;
                    case 4:
                        line.title = getTitle(4);
                        line.text = data.text5;
                        break;
                }
                lines.push(line);
            }

            prec.lines = lines;

            var newdata = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));

            if (!newdata.details) newdata.details = new DetailJson();
            newdata.details.texts = newdata.details?.texts?.map((p) =>
                p.uuid === prec.uuid ? prec : p
            );

            if (onUpdate)
                onUpdate(newdata);
            if (onChanging)
                onChanging(false);
        }


        if (!item) return null;

        return (
            <React.Fragment>

                <TextField
                    name="item"
                    label={getTitle(0)}
                    value={data.text1}
                    fullWidth
                    multiline
                    rows={3}
                    error={!data}
                    helperText={!data ? "値を入力して下さい" : ""}
                    onChange={(e) => {
                        if (onChanging)
                            onChanging(true);
                        var copy = copyData();
                        copy.text1 = e.target.value;
                        setData(copy);
                    }}
                ></TextField>

                <TextField
                    name="item"
                    label={getTitle(1)}
                    value={data.text2}
                    fullWidth
                    multiline
                    rows={3}
                    error={!data}
                    helperText={!data ? "値を入力して下さい" : ""}
                    style={{ display: (textCount >= 2 ? "block" : "none") }}
                    onChange={(e) => {
                        if (onChanging)
                            onChanging(true);
                        var copy = copyData();
                        copy.text2 = e.target.value;
                        setData(copy);
                    }}
                ></TextField>

                <TextField
                    name="item"
                    label={getTitle(2)}
                    value={data.text3}
                    fullWidth
                    multiline
                    rows={3}
                    error={!data}
                    helperText={!data ? "値を入力して下さい" : ""}
                    style={{ display: (textCount >= 3 ? "block" : "none") }}
                    onChange={(e) => {
                        if (onChanging)
                            onChanging(true);
                        var copy = copyData();
                        copy.text3 = e.target.value;
                        setData(copy);
                    }}
                ></TextField>

                <TextField
                    name="item"
                    label={getTitle(3)}
                    value={data.text4}
                    fullWidth
                    multiline
                    rows={3}
                    error={!data}
                    helperText={!data ? "値を入力して下さい" : ""}
                    style={{ display: (textCount >= 4 ? "block" : "none") }}
                    onChange={(e) => {
                        if (onChanging)
                            onChanging(true);
                        var copy = copyData();
                        copy.text4 = e.target.value;
                        setData(copy);
                    }}
                ></TextField>

                <TextField
                    name="item"
                    label={getTitle(4)}
                    value={data.text5}
                    fullWidth
                    multiline
                    rows={3}
                    error={!data}
                    helperText={!data ? "値を入力して下さい" : ""}
                    style={{ display: (textCount >= 5 ? "block" : "none") }}
                    onChange={(e) => {
                        if (onChanging)
                            onChanging(true);
                        var copy = copyData();
                        copy.text5 = e.target.value;
                        setData(copy);
                    }}
                ></TextField>
            </React.Fragment>
        )
    }


export default ContentsItemTitleText;
