
import React from "react";

import { Toolbar, IconButton, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    DrawerWidth,
    ToolbarBackGroundColor,
    LinkTextColor,
} from "../Constants";
import SaveIcon from "@material-ui/icons/Save";
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from "@material-ui/icons/Add";
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';

const useStyles = makeStyles((theme) => ({

    toolbar: {
        position:"fixed",
        paddingLeft: "0px",
        backgroundColor: ToolbarBackGroundColor,
        [theme.breakpoints.up('sm')]: {
            marginLeft: "" + DrawerWidth + "px",
            width: "calc(100% - " + DrawerWidth + "px)",
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: "0px",
            width: "100%",
        },
        borderBottom: "1px solid rgba(0, 0, 0, 0.12);"
        
    },
    toolbtnMenu: {
        color: LinkTextColor,
        fontSize: "0.8rem",
    },
    
    toolbtnSaveTemplate: {
        color: LinkTextColor,
        fontSize: "0.8rem",
        marginLeft: "auto",
    },
    toolbtnSave: {
        color: LinkTextColor,
        fontSize: "0.8rem",
    },
    toolbtnAdd: {
      //padding: "0px",
      color: LinkTextColor,
      fontSize: "0.8rem",
      paddingLeft: "0px",
    },
}));

const ContentsToolbar: React.FunctionComponent<{
    dirty: boolean;
    onSave: () => void;
    onAddItem:()=>void;
    onMenuToggle: () => void;
    onSaveTemplate:()=>void;
}> = ({ dirty, onSave,onAddItem, onMenuToggle ,onSaveTemplate}) => {

    const classes = useStyles();

    return (
        <Toolbar variant="dense" className={classes.toolbar}>
            <Hidden smUp implementation="css">
                <IconButton
                    aria-label="add item"
                    className={classes.toolbtnMenu}
                    onClick={() => {
                        onMenuToggle();
                    }}><MenuIcon /></IconButton>
            </Hidden>

            <Hidden xsDown implementation="css">
            <IconButton
                        aria-label="add item"
                        className={classes.toolbtnAdd}
                        onClick={() => {
                            onAddItem();
                        }}><AddIcon />項目追加</IconButton>
            </Hidden>

            <IconButton
                aria-label="save template"
                className={classes.toolbtnSaveTemplate}
                onClick={() => {
                    onSaveTemplate();
                }}>
                <PermDataSettingIcon /> テンプレート管理
            </IconButton>

            <IconButton
                aria-label="save"
                className={classes.toolbtnSave}
                disabled={!dirty}
                onClick={() => {
                    onSave();
                }}>
                <SaveIcon /> 保存
            </IconButton>
        </Toolbar >
    )
}


export default ContentsToolbar;