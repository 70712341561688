import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { useSelector } from "react-redux";
import { RootState } from "../Store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

export const WaitingSlice = createSlice({
  name: "waiting",
  initialState: {
    message: "",
    waitingFlag: false,
  },
  reducers: {
    beginWaiting: (state, action: PayloadAction<string>) => {
      state.waitingFlag = true;
      state.message = action.payload;
    },
    stopWaiting: (state, action) => {
      state.waitingFlag = false;
      state.message = "";
    },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 100000,
  },
  progress: {
    color: "#1a90ff",
    margin: theme.spacing(2),
  },
}));

export default function WaitingBar() {
  const message = useSelector((state: RootState) => state.waiting.message);
  const waitingFlag = useSelector(
    (state: RootState) => state.waiting.waitingFlag
  );
  const classes = useStyles();

  return (
    <Backdrop open={waitingFlag} className={classes.backdrop}>
      <CircularProgress className={classes.progress} size={30} />
      <Typography color="primary">{message}</Typography>
    </Backdrop>
  );
}
