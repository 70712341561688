import React, { useEffect } from 'react';
import {
    Box,
    Button,
    makeStyles,
    Card, CardHeader, Avatar, CardActions
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store";
import { getTenantUsersSv, sendConfirmMailSv, deleteTenantUserSv } from "../net/TenantAsync";
import { AlertDialogSlice } from '../misc/AlertDialog';

import { MAUser, TenantInfo } from '../net/NetworkClient';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { ConfirmDialogSlice } from '../misc/ConfirmDialog';


const useStyles = makeStyles((theme) => ({

    usersBox: {
    },

    avaterActive: {
        backgroundColor: theme.palette.success.main,
    },
    avaterDeactive: {

    },
    userCard: {
        marginBottom: "2px",
    },

    mailbtn: {
        marginLeft: "auto",
    },
    delbtn: {
        marginLeft: "auto",
        color: theme.palette.error.main
    }
}));

const AdminUserView: React.FunctionComponent<{
    active: boolean;
    tenantid: string;
    setLoading: (loading: boolean) => void;
}> = ({ active, tenantid, setLoading }) => {
    var classes = useStyles();
    var dispatch = useDispatch();

    const tenants = useSelector(
        (state: RootState) => state.tenants.tenants
    );
    const user = useSelector(
        (state: RootState) => state.auth.user
    );
    const [tenantUsers, setTenantUsers] = React.useState<MAUser[] | undefined>();
    const [currentTenant, setCurrentTenant] = React.useState<TenantInfo>();


    //選択中のテナント情報が変更された
    useEffect(() => {
        if (!!tenantid) {
            var cur = tenants.find(t => { return tenantid === t.tenant?.id });
            if(cur?.tenant?.id !== currentTenant?.tenant?.id)
                setCurrentTenant(cur ?? undefined);
        } else {
            setCurrentTenant(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantid, tenants]);

    //表示時の呼び出し
    useEffect(() => {
        if(active)
            reloadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    //ユーザー一覧の再読み込み
    function reloadUsers() {
        setLoading(true);
        dispatch(getTenantUsersSv({
            tenantId: currentTenant?.tenant?.id ?? "", callback: (ok, users) => {
                setLoading(false);
                if (ok) {
                    if (users && users.length > 0) {
                        setTenantUsers(users);
                    } else {
                        setTenantUsers([]);
                    }
                } else {
                    dispatch(
                        AlertDialogSlice.actions.openAlert({
                            title: "エラー",
                            message: "参加者一覧を取得するのに失敗しました。",
                        })
                    );
                }
            }
        }));
    }

    //認証メールを再送信する
    function onSendMail(inUser: MAUser) {
        if (inUser.emailConfirmed)
            return;
        dispatch(
            ConfirmDialogSlice.actions.openConfirm({
                title: "メール送信の確認",
                message:
                    "メールアドレスの認証してもらうために、メールを送りますか？",
                callback: (inok) => {
                    if (inok) {
                        dispatch(sendConfirmMailSv({
                            tenantId: currentTenant?.tenant?.id ?? "", userName: inUser.userName ?? "", callback: (ok) => {
                                if (!ok) {
                                    dispatch(
                                        AlertDialogSlice.actions.openAlert({
                                            title: "エラー",
                                            message: "メールを送信できませんでした。",
                                        })
                                    );
                                } else {
                                    dispatch(
                                        AlertDialogSlice.actions.openAlert({
                                            title: "送信完了",
                                            message: "メールを送信しました。",
                                        })
                                    );
                                }
                            }
                        }))
                    }
                }
            })
        );
    }

    //ユーザーを削除する
    function onDeleteUser(inUser: MAUser) {
        if (inUser.id === user?.id)
            return;
        dispatch(
            ConfirmDialogSlice.actions.openConfirm({
                title: "削除の確認",
                message:
                    "本当に「" + inUser.name + "」を登録から削除してもよろしいですか？",
                callback: (inok) => {
                    if (inok) {
                        dispatch(deleteTenantUserSv({
                            tenantId: currentTenant?.tenant?.id ?? "", userName: inUser.userName ?? "", callback: (ok) => {
                                reloadUsers();
                                if (!ok) {
                                    dispatch(
                                        AlertDialogSlice.actions.openAlert({
                                            title: "エラー",
                                            message: "ユーザーを削除できませんでした。",
                                        })
                                    );
                                }
                            }
                        }))
                    }
                }
            })
        );
    }



    if (!user)
        return null;
    if (!currentTenant?.admin)
        return null;

    return (
        <Box className={classes.usersBox}>
            {
                tenantUsers?.map((userItem) => {
                    return (
                        <Card className={classes.userCard} key={userItem.id}>
                            <CardHeader
                                avatar={
                                    <Avatar className={userItem.emailConfirmed ? classes.avaterActive : classes.avaterDeactive}>
                                        {userItem.name?.slice(0, 1) ?? "x"}
                                    </Avatar>
                                }
                                title={userItem.name}
                                subheader={userItem.email + (userItem.emailConfirmed ? "" : "(認証待ち)")}
                            />
                            <CardActions >

                                <Button variant="text" classes={{ root: classes.mailbtn }}
                                    disabled={userItem.emailConfirmed}
                                    onClick={() => { onSendMail(userItem) }}>
                                    <MailOutlineIcon />承認メール</Button>

                                <Button variant="text" classes={{ root: classes.delbtn }}
                                    disabled={userItem.id === user.id}
                                    onClick={() => { onDeleteUser(userItem) }}>
                                    <HighlightOffIcon />削除</Button>

                            </CardActions>
                        </Card>
                    )
                })
            }
        </Box>
    )
}



export default AdminUserView;