
import React, { useState, useEffect } from "react";
import { MAProject } from '../net/NetworkClient';
import moment, { Moment } from "moment-timezone";

import { Toolbar, Box, IconButton, CircularProgress, MenuItem, Select, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    ToolbarBackGroundColor, LinkTextColor, ProjectKeyToString,
} from "../Constants";
import TodayIcon from "@material-ui/icons/Today";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useHistory } from "react-router-dom";


moment.tz.setDefault('Asia/Tokyo');


const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        height: "calc(100vh - 48px)",
        overflow: "hide",
    },
    toolbar: {
        backgroundColor: ToolbarBackGroundColor,
        borderBottom: "solid 1px #fff",
        alignItems: "flex-end",
        paddingLeft:0,
    },
    toolbarSelect: {
        "&:before": {
            borderColor: LinkTextColor,
        },
        "&:after": {
            borderColor: LinkTextColor,
        },
        color: LinkTextColor,
        width: "100px",
        marginRight: "8px",
    },
    toolbarIcon: {
        fill: LinkTextColor,
    },
    toolbarLabel: {
        color: LinkTextColor,
        display: "inline",
        fontSize: "1.2rem",
        overflow: "hidden",
        paddingBottom: "3px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },

    toolbarKeyLabel: {
        display: "inline",
        fontSize: "0.8rem",
        color: LinkTextColor,
        paddingBottom: "6px",
    },
    toolbarBtn: {
        color: LinkTextColor,
        fontSize: "0.9rem",
        marginRight: "20px",
        marginLeft: "auto",
        paddingBottom: "6px",
    },
    progress: {},
    contents: {
        flex: 1,
        height: "100%",
        overflow: "auto",
        padding: theme.spacing(0, 1),
    },
    backbtn: {
        color: LinkTextColor,
        fontSize: "0.9rem",
        padding:theme.spacing(1),
    }
}));

const CalendarToolbar: React.FunctionComponent<{
    date?: Moment;
    project?: MAProject;
    loading: boolean;
    onChangeDate: (date: Moment) => void;
}> = ({ date, project, loading, onChangeDate }) => {

    const classes = useStyles();
    const history = useHistory();

    const [currentYear, setCurrentYear] = useState(moment().year());
    const [currentMonth, setCurrentMonth] = useState(moment().month());

    var years = [];
    for (var i = 2020; i < moment().year() + 5; i++) {
        years.push(i);
    }

    //選択中のテナント情報が変更された
    useEffect(() => {
        if (date) {
            setCurrentYear(date.year());
            setCurrentMonth(date.month());
        } else {
            setCurrentYear(moment().year());
            setCurrentMonth(moment().month());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);


    return (

        <Toolbar variant="dense" className={classes.toolbar}>
            <Box>
                <Tooltip title="プロジェクト一覧へ戻る">
                    <IconButton
                        aria-label="back"
                        className={classes.backbtn}
                        onClick={() => {history.push("/tenants/"+project?.tenantId)}}>
                        <KeyboardBackspaceIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box
                className={classes.toolbarLabel}
                style={{ display: !(project?.name ?? null) ? "none" : "block" }}
            >
                {"「" + (project?.name ?? "") + "」"}
            </Box>
            <Box
                className={classes.toolbarKeyLabel}
                style={{ display: !(project?.name ?? null) ? "none" : "block" }}
            >
                {ProjectKeyToString(project,"/")}
            </Box>
            <Box component="span" className={classes.progress} hidden={!loading}>
                <CircularProgress />
            </Box>
            <IconButton
                className={classes.toolbarBtn}
                aria-label="today"
                onClick={() => {
                    onChangeDate(moment(new Date()));
                }}
            >
                <TodayIcon />
                今日
                </IconButton>

            <Select
                value={currentYear}
                className={classes.toolbarSelect}
                inputProps={{ classes: { icon: classes.toolbarIcon } }}
                onChange={(e) => {
                    onChangeDate(
                        moment(date).year(parseInt("" + e.target.value, 10))
                    );
                }}
            >
                {years.map((y) => (
                    <MenuItem key={y} value={y}>
                        {y}年
                    </MenuItem>
                ))}
            </Select>
            <Select
                value={currentMonth}
                className={classes.toolbarSelect}
                inputProps={{ classes: { icon: classes.toolbarIcon } }}
                onChange={(e) => {
                    onChangeDate(
                        moment(date).month(parseInt("" + e.target.value))
                    );
                }}
            >
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((m) => (
                    <MenuItem key={m} value={m}>
                        {m + 1}月
                    </MenuItem>
                ))}
            </Select>
        </Toolbar>
    )
}


export default CalendarToolbar;
