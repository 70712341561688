
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    ProfileV2Client, MAUser
} from './NetworkClient';
import { getAuthedAxisInstance } from "./AxisUtil";
import { SERVER_URL } from '../Constants';

//ユーザープロファイルを取得
export const getProfileSv = createAsyncThunk<boolean, { callback: (ok: boolean, user: MAUser | null) => void }>(
    "profile/getProfile",
    async (arg, { dispatch }) => {
        try {
            var user = await (new ProfileV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getProfile();
            arg.callback(true, user);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, null);
            throw err;
        }
    }
);

//ユーザー名を設定
export const setUserNameSv = createAsyncThunk<boolean, {name:string, callback: (ok: boolean,error:any) => void }>(
    "profile/setName",
    async (arg,{dispatch}) => {
        try {
            await (new ProfileV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).setName(arg.name)
            arg.callback(true,null);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false,err);
            throw err;
        }
    }
);

//パスワードを設定
export const setUserPasswordSv = createAsyncThunk<boolean, {password:string, callback: (ok: boolean,error:any) => void }>(
    "profile/changePassword",
    async (arg,{dispatch}) => {
        try {
            await (new ProfileV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).changePassword(arg.password)
            arg.callback(true,null);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false,err);
            throw err;
        }
    }
);

//システム管理者かどうかを確認
export const isSysadSv = createAsyncThunk<boolean, { callback: (sysad: boolean) => void }>(
    "profile/isSysad",
    async (arg, { dispatch }) => {
        try {
            var sysad = await (new ProfileV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).isSysad();
            arg.callback(sysad);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false);
            throw err;
        }
    }
);