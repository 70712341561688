import moment from "moment-timezone";
import React, { useEffect, useState, FunctionComponent } from "react";
import { useDispatch } from "react-redux";

import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Divider, DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { AlertDialogSlice } from "../misc/AlertDialog";
import {
    MAOrder,
} from "../net/NetworkClient";

import { GetOrdersSv } from "../net/PaymentAsync";
import { ButtonBackGround, numberWithDelimiter } from "../Constants";
moment.tz.setDefault('Asia/Tokyo');

const useStyles = makeStyles((theme) => ({
    top: {
        position: "absolute",
        right: 25,
        top: 20,
    },
    historyList: {
        maxHeight: "350px",
        overflowX: "hidden",
        overflowY: "auto"
    },
    linkStyle: {
        color: ButtonBackGround
    },
}));

const OrderHistoryDialog: FunctionComponent<{
    open: boolean;
    tenantId: string;
    onClose: () => void;
}> = ({ open, onClose, tenantId }) => {
    const classes = useStyles();

    const [orders, setOrders] = useState<MAOrder[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            dispatch(GetOrdersSv({
                tenantId: tenantId, callback: (ok, orders) => {
                    if (ok && orders) {
                        setOrders(orders);
                        console.log(orders)
                    } else {
                        dispatch(
                            AlertDialogSlice.actions.openAlert({
                                title: "エラー",
                                message: "支払履歴の取得に失敗しました。",
                            })
                        );
                    }
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);


    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
            }}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">
                履歴
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1} className={classes.historyList}>

                    {
                        orders.map((order) => {return (!order.paid && !order.registered) ? null : (
                            <React.Fragment key={order.id}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={2}>請求番号</Grid>
                                <Grid item xs={8} >
                                    {order.id}
                                </Grid>
                                <Grid item xs={1}></Grid>

                                <Grid item xs={1}></Grid>
                                <Grid item xs={2}>状態</Grid>
                                <Grid item xs={8} >
                                    {order.paid ? "支払い完了" : ( order.registered ? "料金確定":"料金未確定")}
                                </Grid>
                                <Grid item xs={1}></Grid>


                                <Grid item xs={1}></Grid>
                                <Grid item xs={2}>金額</Grid>
                                <Grid item xs={8} >
                                    ￥{numberWithDelimiter(order.price ?? 0)}
                                </Grid>
                                <Grid item xs={1}></Grid>


                                <Grid item xs={1}></Grid>
                                <Grid item xs={2}>品目</Grid>
                                <Grid item xs={8} >
                                    {
                                        order.licenses?.map((l) => (
                                            <React.Fragment>
                                                <Grid item xs={12}>{l.name} ({(l.projectCount ?? 0) * (l.quantity ?? 0)}プロジェクト)</Grid>
                                                <Grid item xs={12}>{moment(order.startUTC).add(1, "second").format("YYYY/M/D")}　～　{moment(order.endUTC).format("YYYY/M/D")}</Grid>
                                            </React.Fragment>
                                        ))
                                    }
                                </Grid>
                                <Grid item xs={1}></Grid>

{/* 
                                <Grid item xs={3}></Grid>
                                <Grid item xs={8} >
                                    <Link href="#" onClick={() => { alert("未実装") }} className={classes.linkStyle}>
                                        請求書ダウンロード
                                    </Link>

                                    <Link href="#" style={{ display: order.paid ? "inline" : "none" }} onClick={() => { alert("未実装") }} className={classes.linkStyle}>
                                        領収書ダウンロード
                                    </Link>
                                </Grid>
                                <Grid item xs={1}></Grid>
*/}
                                <Grid item xs={12}><Divider light /></Grid>
                            </React.Fragment>
                        )})
                    }
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                            onClose();
                        }} >
                閉じる
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default OrderHistoryDialog;
