import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum ActionType {
  EMPTY = "EMPTY",

  DEL_PROJECT = "DEL_PROJECT",
  CONFIRM_SAVE = "CONFIRM_SAVE",
  //CONFIRM_OK = "CONFIRM_OK",
  CHANGE_CONTENTS_IN_CAL = "CHANGE_CONTENTS_IN_CAL",
  CONFIRM_DUPULICATE = "CONFIRM_DUPULICATE",

  CONFIRM_DEL_PROJECT_IN_TENANT = "CONFIRM_DEL_PROJECT_IN_TENANT",
  CONFIRM_INVALIDATE_IN_TENANT = "CONFIRM_INVALIDATE_IN_TENANT",
  EDIT_PROJECT_IN_TENANT = "EDIT_PROJECT_IN_TENANT",
  SET_PROJECT_ADMIN_IN_TENANT = "SET_PROJECT_ADMIN_IN_TENANT",
  VALIDATE_PROJECT_IN_TENANT = "VALIDATE_PROJECT_IN_TENANT",

  
  CONTENT_ITEM_LOAD_START = "CONTENT_ITEM_LOAD_START",
  CONTENT_ITEM_LOAD_END = "CONTENT_ITEM_LOAD_END",

  HTTP_401_ERROR = "HTTP_401_ERROR",
}
export type ActionMessage = {
  type: ActionType;
  value?: string;
  value2?: string;
};

export const emptyAction: ActionMessage = { type: ActionType.EMPTY };
const ActionSlice = createSlice({
  name: "action",
  initialState: {
    action: emptyAction,
    loading:false,
  },
  reducers: {
    setLoading:(state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    doAction: (state, action: PayloadAction<ActionMessage>) => {
      state.action = action.payload;
    },
    clearAction:(state,action)=>{
      state.action = emptyAction;
    }
  },
});

export default ActionSlice;
