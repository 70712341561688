import { TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { ItemTypeKey } from "../../Constants";
import { ContentsJson, DetailJson, MorningAssemblyJson, SpeakerJson } from "../../net/NetworkClient";
import ContentsItemListBase, { DataType, IContentsItemListDelegate, IItemCardContentsProp } from "./ContentsItemListBase";
import { useDebounce } from "use-debounce";
import { v4 as uuid } from "uuid";

///スピーチパネル
const ContentsItemSpeach: React.FunctionComponent<{
    contentsid: string | undefined;
    jsonData: MorningAssemblyJson | undefined;
    selectedItem: { key: string; id: string } | undefined;
    onUpdate: (newData: ContentsJson) => void;
    onChanging: (changing: boolean) => void;
}> = ({ contentsid, jsonData, selectedItem, onUpdate, onChanging }) => {

    return (
        <ContentsItemListBase
            contentsid={contentsid ?? ""}
            delegate={new SpeachDelegate()}
            cardContents={SpeachCardContents}
            jsonData={jsonData}
            selectedItem={selectedItem}
            onUpdate={onUpdate}
            onChanging={onChanging}
        >

        </ContentsItemListBase>
    )
}

///スピーチ処理デリゲート
class SpeachDelegate implements IContentsItemListDelegate<SpeakerJson>{
    getUUID(item: SpeakerJson): string {
        return item.uuid ?? "";
    }
    isSuported(type: string | undefined): boolean {
        return type === ItemTypeKey.general_speech;
    }

    copyData(data: DataType<SpeakerJson>): DataType<SpeakerJson> {
        return {
            title: "" + data.title,
            list: data.list.map((p) => new SpeakerJson(p)),
        }
    }

    contentsToData(contents: ContentsJson): DataType<SpeakerJson> {
        return {
            title: contents.title ?? "",
            list: contents.details?.speakers ?? []
        }
    }

    buildContentsJson(contents: ContentsJson, data: DataType<SpeakerJson>): ContentsJson {
        var copy = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));
        copy.title = "" + data.title;

        const tmp = new DetailJson();
        tmp.speakers = data.list;
        const detail = DetailJson.fromJS(JSON.parse(JSON.stringify(tmp)));
        copy.details = detail;
        return copy;
    }

    makeNewItem(): SpeakerJson {
        var re = new SpeakerJson();
        re.uuid = uuid();
        return re;
    }
}


///スピーチのカード内容
const SpeachCardContents: React.FunctionComponent<IItemCardContentsProp>
    = ({ index, item, contents, onUpdate, onChanging }) => {

        const [data, setData] = React.useState<string>(item instanceof SpeakerJson ? item.name ?? "" : "");


        //OnChangeの度にJsonを更新すると処理が重たいので、debounce処理
        const [debouncedData] = useDebounce(data, 500);
        useEffect(() => {
            if (item instanceof SpeakerJson) {
                if (item.name !== data) {
                    updateContentsJson();
                }
            }
            return;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [debouncedData]);

        //データ更新
        function updateContentsJson() {
            const prec = new SpeakerJson(item);
            prec.name = data;

            var newdata = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));

            if (!newdata.details) newdata.details = new DetailJson();
            newdata.details.speakers = newdata.details?.speakers?.map((p) =>
                p.uuid === prec.uuid ? prec : p
            );
            if (onUpdate)
                onUpdate(newdata);
            if (onChanging)
                onChanging(false);
        }


        if (!item) return null;

        return (
            <TextField
                name="item"
                label="発表者"
                value={data}
                fullWidth
                error={!data}
                helperText={!data ? "値を入力して下さい" : ""}
                onChange={(e) => {
                    if (onChanging)
                        onChanging(true);
                    setData(e.target.value);
                }}
            ></TextField>
        )
    }


export default ContentsItemSpeach;
