import React from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import GroupIcon from "@material-ui/icons/Group";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import BuildIcon from "@material-ui/icons/Build";
import moment from "moment-timezone";
import PhotoIcon from '@material-ui/icons/Photo';
import { MAProject } from "./net/NetworkClient";
import ContactlessIcon from '@material-ui/icons/Contactless';
import DescriptionIcon from '@material-ui/icons/Description';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

moment.tz.setDefault('Asia/Tokyo');


const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL!;
const REACT_APP_AppNameForOIDC=process.env.REACT_APP_AppNameForOIDC!;
const REACT_APP_UseRadioGym=(""+process.env.REACT_APP_UseRadioGym!).toLowerCase() === "true";

export const SERVER_URL = REACT_APP_SERVER_URL;
export const AppNameForOIDC = REACT_APP_AppNameForOIDC;
export const UseRadioGym = REACT_APP_UseRadioGym;

// // //Xcodeでデバッグ用
// export const SERVER_URL = "https://localhost:5000";
// export const AppNameForOIDC = "chorei084-debug";
// export const UseRadioGym = false;

//VisualStudioでデバッグ用
// export const SERVER_URL = "https://localhost:5000";
// export const AppNameForOIDC = "chorei084";
// export const UseRadioGym = true;

//朝礼084　リリース版 Azure
//  export const SERVER_URL = "https://app.chorei084.com";
//  export const AppNameForOIDC = "chorei084-release";
// export const UseRadioGym = false;

// //会社開発用Azure
// export const SERVER_URL = "https://chorei084dev.azurewebsites.net";
// export const AppNameForOIDC = "chorei084-dev";
// export const UseRadioGym = false;

export const FooterHeight = 30;
export const DrawerWidth = 280;
export const BaseBackGroundColor = "#01a0e9";
export const ButtonBackGround = "#01a0e9";
export const LinkTextColor = "#333";
export const EyeCatchColor = "#FF9A44";
export const ToolbarBackGroundColor = "#FFF";
export const DrawerBackColor = "#fff";
export const DrawerSelectedColor = ButtonBackGround;
export const UnregisteredUpdateURL = "[unregistered]";

export enum ItemTypeKey {
  config = "config",
  radioCalisthenics = "radioCalisthenics",
  workProcedure = "workProcedure",
  precautions = "precautions",
  safetyCall = "safetyCall",
  kyActivity = "kyActivity",
  rollCall = "rollCall",
  siteUpdates = "siteUpdates",

  general_radioCalisthenics = "general_radioCalisthenics",
  general_speech = "general_speech",
  general_text = "general_text",
  general_titletext1 = "general_titletext1",
  general_titletext2 = "general_titletext2",
  general_titletext3 = "general_titletext3",
  general_titletext4 = "general_titletext4",
  general_titletext5 = "general_titletext5",
  general_chant = "general_chant",
  general_photoList = "general_photoList",
  general_phototextList = "general_phototextList",
  general_photoUpload = "general_photoUpload",
  general_registration = "general_registration",
  general_questionnaire = "general_questionnaire",
}

export class ItemMenuAction {
  key: string = "";
  label: string = "";
  constructor(key: string, label: string) {
    this.key = key;
    this.label = label;
  }
}
export const ItemMenuAction_CLICK = new ItemMenuAction("CLICK", "選択");
export const ItemMenuAction_ADD = new ItemMenuAction("ADD", "この下へ項目を追加");
export const ItemMenuAction_UP_FIRST = new ItemMenuAction("UP_FIRST", "最初へ移動");
export const ItemMenuAction_UP = new ItemMenuAction("UP", "１つ上へ移動");
export const ItemMenuAction_DOWN = new ItemMenuAction("DOWN", "１つ下へ移動");
export const ItemMenuAction_DOWN_LAST = new ItemMenuAction("DOWN_LAST", "最後へ移動");
export const ItemMenuAction_DELETE = new ItemMenuAction("DELETE", "削除");

export function stringToItemTypeKey(key: string) {
  let keyType = key as keyof typeof ItemTypeKey;
  return ItemTypeKey[keyType];
}

//プロジェクトからログインキーを文字列にして返す
export function ProjectKeyToString(prj?: MAProject, divider?: string): string {

  if (!prj)
    return "データ不正";

  if ((prj.userKey ?? -1) < 0 || (prj.adminKey ?? -1) < 0) {
    return "利用停止中";
  }
  var div = divider ?? "/";

  var re = "管理番号:" + ProjectAdminKeyToString(prj) ?? "";
  re += "" + div;
  re += "朝礼番号:" + ProjectUserKeyToString(prj) ?? "";
  return re;
}
export function ProjectAdminKeyToString(prj?: MAProject): string | undefined {
  if (!prj)
    return undefined;

  if ((prj.userKey ?? -1) < 0 || (prj.adminKey ?? -1) < 0)
    return undefined;

  return ("000000" + prj?.adminKey).slice(-7);
}
export function ProjectUserKeyToString(prj?: MAProject): string | undefined {
  if (!prj)
    return undefined;

  if ((prj.userKey ?? -1) < 0 || (prj.adminKey ?? -1) < 0)
    return undefined;

  return ("00000" + prj?.userKey).slice(-5);
}

export const PostalCodeFormat = (str: string): string => {
  var s = str.replace(/\D/g, '-');
  if (s.length <= 3)
      return s;
  return s.slice(0, 3) + '-' + s.slice(3, s.length);
}
export const emailValidation = (value: string): boolean => {
  if (!value) return false;
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!regex.test(value)) return false;
  return true;
}
export const phoneNumberValidation = (value: string): boolean => {
  if (!value) return false;
  const regex = /^(((0(\d{1}[-(]?\d{4}|\d{2}[-(]?\d{3}|\d{3}[-(]?\d{2}|\d{4}[-(]?\d{1}|[5789]0[-(]?\d{4})[-)]?)|\d{1,4}-?)\d{4}|0120[-(]?\d{3}[-)]?\d{3})$/;
  if (!regex.test(value)) return false;
  return true;
}
export const postalCodeValidation = (value: string): boolean => {
  if (!value) return false;
  const regex = /^[0-9]{3}-?[0-9]{4}$/;
  if (!regex.test(value)) return false;
  return true;
}


export function GetItemIcon(keyName:string,fontsize:any = undefined):Object{
  return GetItemKeyIcon(stringToItemTypeKey(keyName));
}
export function GetItemKeyIcon(key: ItemTypeKey,fontsize:any = undefined):  Object{
  switch (key) {
    case ItemTypeKey.config: return <AssignmentIcon fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.radioCalisthenics: return <AccessibilityNewIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.workProcedure: return <BuildIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.precautions: return <ErrorOutlineIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.safetyCall: return <RecordVoiceOverIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.kyActivity: return <GroupIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.rollCall: return <ConfirmationNumberIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.siteUpdates: return <PhotoIcon  fontSize={fontsize?fontsize:"default"}/>;

    case ItemTypeKey.general_radioCalisthenics: return <AccessibilityNewIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_speech: return <ContactlessIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_text: return <DescriptionIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_titletext1: return <DescriptionIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_titletext2: return <DescriptionIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_titletext3: return <DescriptionIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_titletext4: return <DescriptionIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_titletext5: return <DescriptionIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_chant: return <RecordVoiceOverIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_photoList: return <PhotoIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_phototextList: return <PhotoIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_photoUpload: return <CameraAltIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_registration: return <ConfirmationNumberIcon  fontSize={fontsize?fontsize:"default"}/>;
    case ItemTypeKey.general_questionnaire: return <QuestionAnswerIcon  fontSize={fontsize?fontsize:"default"}/>;
  }
  return <AssignmentIcon  fontSize={fontsize?fontsize:"default"}/>;
}
export function IsEditableItemTitle(key: ItemTypeKey): boolean {
  switch (key) {
    case ItemTypeKey.config: return false;
    case ItemTypeKey.radioCalisthenics: return false;
    case ItemTypeKey.workProcedure: return false;
    case ItemTypeKey.precautions: return false;
    case ItemTypeKey.safetyCall: return false;
    case ItemTypeKey.kyActivity: return false;
    case ItemTypeKey.rollCall: return false;
    case ItemTypeKey.siteUpdates: return false;

    case ItemTypeKey.general_radioCalisthenics: return false;
    case ItemTypeKey.general_speech:  return true;
    case ItemTypeKey.general_text: return true;
    case ItemTypeKey.general_titletext1: return true;
    case ItemTypeKey.general_titletext2: return true;
    case ItemTypeKey.general_titletext3: return true;
    case ItemTypeKey.general_titletext4: return true;
    case ItemTypeKey.general_titletext5: return true;
    case ItemTypeKey.general_chant: return true;
    case ItemTypeKey.general_photoList: return true;
    case ItemTypeKey.general_phototextList: return true;
    case ItemTypeKey.general_photoUpload: return true;
    case ItemTypeKey.general_registration: return true;
    case ItemTypeKey.general_questionnaire: return true;
  }
  return false;
}

export　const numberWithDelimiter = (number: string | number) => {
  return String(number).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}
