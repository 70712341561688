import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "oidc-client";
import { MAUser } from "../net/NetworkClient"

export type UserProfileDialogInfo = {
  open: boolean;
  anchorId: string;
};
export const ClosedPrifileDialogInfo: UserProfileDialogInfo = {
  open: false,
  anchorId: "",
}

const AuthSlice = createSlice({
  name: "action",
  initialState: {
    oidcUser: null as (Profile | null),
    user: null as (MAUser | null),
    userProfileDialogInfo: ClosedPrifileDialogInfo,
    sysad: false
  },
  reducers: {
    //OpenIDのユーザー情報を登録
    setOidcUser: (state, action: PayloadAction<Profile | null>) => {
      state.oidcUser = action.payload;
    },
    //DB上のユーザ情報を登録
    setUser: (state, action: PayloadAction<MAUser | null>) => {
      state.user = action.payload;
    },
    setProfileDialog: (state, action: PayloadAction<UserProfileDialogInfo>) => {
      state.userProfileDialogInfo = action.payload;
    },
    //システム管理者かどうかを登録
    setSysad: (state, action: PayloadAction<boolean>) => {
      state.sysad = action.payload;
    },
  },
});

export default AuthSlice;
