import React from "react";
import {
    ItemTypeKey,
    stringToItemTypeKey,
    ItemMenuAction,
    ItemMenuAction_CLICK,
    ItemMenuAction_ADD,
    ItemMenuAction_UP_FIRST,
    ItemMenuAction_UP,
    ItemMenuAction_DOWN,
    ItemMenuAction_DOWN_LAST,
    ItemMenuAction_DELETE,
    LinkTextColor,
    GetItemKeyIcon,
} from "../Constants";
import {
    Box,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Divider,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { ContentsJson } from "../net/NetworkClient";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    textPrimary: {
        fontSize: "0.8rem",
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: LinkTextColor,
    },
    itemCategory: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
    },
    
    itemPrimary: {
        fontSize: "inherit",
    },
    itemIcon: {
        fontSize: "1rem",
        minWidth: "auto",
        marginRight: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
    btn: {
        color: LinkTextColor,
    },
    actionRoot: {
        zIndex: 100,
    },
    menuList: {
        zIndex: 110,
    },
}));

const ContentsNavItem: React.FunctionComponent<{
    contentsJson: ContentsJson;
    selected: boolean;
    uparrow: boolean;
    downarrow: boolean;
    loading:boolean;
    onAction: (itemId: string | undefined, itemkey: ItemTypeKey, action: ItemMenuAction) => void;
}> = ({
    contentsJson, selected, uparrow, downarrow,loading, onAction
}) => {

        const classes = useStyles();
        const itemKey = stringToItemTypeKey(contentsJson.type ?? "");
        const itemIcon = GetItemKeyIcon(itemKey);
        const [menuopen, setMenuopen] = React.useState(false);
        const anchorRef = React.useRef<HTMLButtonElement>(null);
        const prevOpen = React.useRef(menuopen);


        const onClose = (
            event: React.MouseEvent<EventTarget>,
            action?: ItemMenuAction
          ) => {
            if (
              anchorRef.current &&
              anchorRef.current.contains(event.target as HTMLElement)
            ) {
              return;
            }
            setMenuopen(false);
            if (action) {
              onAction(contentsJson.uuid, itemKey, action);
            }
          };
        
          function handleListKeyDown(event: React.KeyboardEvent) {
            if (event.key === "Tab") {
              event.preventDefault();
              setMenuopen(false);
            }
          }
        React.useEffect(() => {
            if (prevOpen.current === true && menuopen === false) {
              anchorRef.current!.focus();
            }
        
            prevOpen.current = menuopen;
          }, [menuopen]);


        return (
            <ListItem
                button
                className={clsx(classes.item, classes.itemCategory, classes.textPrimary)}
                onClick={() => {
                    onAction(contentsJson.uuid, itemKey, ItemMenuAction_CLICK);
                }}
                selected={selected}
                disabled={loading}
            >
                <ListItemIcon className={classes.itemIcon}>{itemIcon}</ListItemIcon>
                <ListItemText classes={{ primary: classes.textPrimary }}>
                    {contentsJson.title ?? ""}
                </ListItemText>
                <ListItemSecondaryAction className={classes.actionRoot}>
                    <Box component="span">
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            size="small"
                            className={classes.btn}
                            ref={anchorRef}
                            aria-controls={menuopen ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            disabled={loading}
                            onClick={(e) => {
                                setMenuopen((prevOpen) => !prevOpen);
                            }}
                        >
                            <MenuIcon fontSize="small" />
                        </IconButton>
                        <Popper
                            open={menuopen}
                            anchorEl={anchorRef.current}
                            placement={"left-start"}
                            role={undefined}
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === "bottom" ? "left top" : "left bottom",
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener
                                            onClickAway={(e) => {
                                                onClose(e, undefined);
                                            }}
                                        >
                                            <MenuList
                                                className={classes.menuList}
                                                autoFocusItem={menuopen}
                                                id="menu-list-grow"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                <MenuItem
                                                    onClick={(e) => {
                                                        onClose(e, ItemMenuAction_ADD);
                                                    }}
                                                >
                                                    この下へ項目を追加</MenuItem>
                                                <Divider />
                                                <MenuItem
                                                    disabled={!uparrow}
                                                    onClick={(e) => {
                                                        onClose(e, ItemMenuAction_UP_FIRST);
                                                    }}
                                                >
                                                    最初へ移動</MenuItem>
                                                <MenuItem
                                                    disabled={!uparrow}
                                                    onClick={(e) => {
                                                        onClose(e, ItemMenuAction_UP);
                                                    }}
                                                >
                                                    １つ上へ移動</MenuItem>
                                                <MenuItem
                                                    disabled={!downarrow}
                                                    onClick={(e) => {
                                                        onClose(e, ItemMenuAction_DOWN);
                                                    }}
                                                >
                                                    １つ下へ移動</MenuItem>
                                                <MenuItem
                                                    disabled={!downarrow}
                                                    onClick={(e) => {
                                                        onClose(e, ItemMenuAction_DOWN_LAST);
                                                    }}
                                                >
                                                    最後へ移動</MenuItem>

                                                <Divider />

                                                <MenuItem
                                                    onClick={(e) => {
                                                        onClose(e, ItemMenuAction_DELETE);
                                                    }}
                                                >
                                                    削除</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Box>
                </ListItemSecondaryAction>
            </ListItem>
        );

    }

export default ContentsNavItem;

/**
 * 基本設定用のナビ項目
 */
export const ConfigNavItem: React.FunctionComponent<{

    selected: boolean;
    loading:boolean;
    onAction: (itemId: string | undefined, itemkey: ItemTypeKey, action: ItemMenuAction) => void;
}> = ({
    selected, loading,onAction
}) => {
        const classes = useStyles();
        const itemKey = ItemTypeKey.config;
        const itemIcon = GetItemKeyIcon(itemKey);
        
        return (
            <ListItem
                button
                className={clsx(classes.item, classes.itemCategory, classes.textPrimary)}
                onClick={() => {
                    onAction(undefined, itemKey, ItemMenuAction_CLICK);
                }}
                selected={selected}
                disabled={loading}
            >
                <ListItemIcon className={classes.itemIcon}>{itemIcon}</ListItemIcon>
                <ListItemText classes={{ primary: classes.textPrimary }}>
                    {"基本設定"}
                </ListItemText>
            </ListItem>
        )
    }