import React, { useEffect } from 'react';

import {
    Box,
    makeStyles, TextField
} from '@material-ui/core';
import { GetItemKeyIcon, IsEditableItemTitle, ItemTypeKey } from '../../Constants';
import { useDebounce } from "use-debounce";

const useStyles = makeStyles((theme) => ({
    textbox: {
        width: "calc(100% - 50px)",
        paddingLeft:"0.3rem"
    },

    titleicon: {
        fontSize: "3rem"
    },

    titleBox:{
        display:"flex",
        alignItems:"center"
    },
    titleText:{
        fontSize: "1.5rem",
        paddingLeft:"0.3rem"
    }


}));

const TitleText: React.FunctionComponent<{
    type: ItemTypeKey;
    value: string;
    onUpdate: (title: string) => void;
    onChanging: (changing: boolean) => void;
}> = ({ type, value, onUpdate, onChanging }) => {
    const classes = useStyles();
    const [data, setData] = React.useState<string>(value ?? "");
    const [upflag, setUpflag] = React.useState(false);

    //アクション処理
    useEffect(() => {
        setData(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    //OnChangeの度にJsonを更新すると処理が重たいので、debounce処理
    const [debouncedData] = useDebounce(data, 500);
    useEffect(() => {
        if(upflag && value !== debouncedData){
            setUpflag(false);
            onUpdate(debouncedData);
            onChanging(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedData]);


    if (!IsEditableItemTitle(type)) {
        return (
            <Box className={classes.titleBox}>
                {GetItemKeyIcon(type, "large")} <span className={classes.titleText}>{value}</span>
            </Box>
        );
    } else {
        return (
            <React.Fragment>
                <span className={classes.titleicon}>{GetItemKeyIcon(type, "large")}</span>

                <TextField
                    className={classes.textbox}
                    name="item"
                    label={<span>タイトル</span>}
                    value={data}
                    error={!data}
                    helperText={!data ? "値を入力して下さい" : ""}
                    onChange={(e) => {
                        setUpflag(true);
                        onChanging(true);
                        setData(e.target.value);
                    }}
                ></TextField>
            </React.Fragment>
        );
    }
};
export default TitleText;
