import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  Card,
  CardContent,
  CardActions,
  Box,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ItemTypeKey } from "../../Constants";
import AddBtn from "./AddBtn";
import {
  PrecautionJson,
  ContentsJson,
  DetailJson,
  MorningAssemblyJson,
} from "../../net/NetworkClient";
import { v4 as uuid } from "uuid";
import { useDebounce } from "use-debounce";
import ActionSlice, { ActionType } from "../../misc/ActionSlice";
import { ConfirmDialogSlice } from "../../misc/ConfirmDialog";
import TitleText from "./TitleText";

const useStyles = makeStyles((theme) => ({
  paper: {
    //margin: "auto",
    overflow: "hidden",
  },
  content: {
    padding: "20px 16px",
    flexGrow: 1,
  },

  addpin: {
    height: "30px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  addpinBox: {
    padding: "0 !important" as any,
    textAlign: "right",
    marginRight: "1px",
  },
  itemActions: {
    justifyContent: "flex-end",
  },
}));

const ContentsItemPrecautions: React.FunctionComponent<{
  jsonData: MorningAssemblyJson | undefined;
  selectedItem: { key: string; id: string } | undefined;
  onUpdate: (newData: ContentsJson) => void;
  onChanging:(changing:boolean)=>void;
}> = ({ jsonData, selectedItem, onUpdate,onChanging }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const [itemId, setItemId] = React.useState("");

  //表示データ
  const [data, setData] = React.useState<{
    title: string;
    precautions: PrecautionJson[];
  }>({
    title: "",
    precautions: [],
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if(selectedItem?.key !== ItemTypeKey.precautions)
      return;

    if (selectedItem?.id && jsonData) {
      if (selectedItem?.id !== itemId) {
        setItemId(selectedItem?.id);
      }
      const contents = jsonData?.contents?.find(
        (c) => c.uuid === selectedItem?.id
      );
      if (contents?.details) {
        const toData = {
          title: contents.title ?? "",
          precautions: contents.details.precautions ?? [],
        };
        if (JSON.stringify(data) !== JSON.stringify(toData)) {
          setData(toData);
        }
      }
      dispatch(ActionSlice.actions.doAction({ type: ActionType.CONTENT_ITEM_LOAD_END}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedItem,jsonData]);


  function setDataAndPost(inData: {
    title: string;
    precautions: PrecautionJson[];
  }) {
    setData(inData);
    const contents = jsonData?.contents?.find(
      (c) => c.uuid === selectedItem?.id
    );
    const strsrc = JSON.stringify(contents);
    var copy = ContentsJson.fromJS(JSON.parse(strsrc));

    const tmp = new DetailJson();
    tmp.precautions = inData.precautions;
    const detail = DetailJson.fromJS(JSON.parse(JSON.stringify(tmp)));
    copy.details = detail;

    const dststr = JSON.stringify(copy);
    if (strsrc !== dststr) {
      onUpdate(copy);
    }
  }

  //表示データのコピー
  function copyData() {
    return {
      title: "" + data.title,
      precautions: data.precautions.map((p) => new PrecautionJson(p)),
    };
  }

  //項目追加
  function addPrecaution(index: number) {
    const item = new PrecautionJson();
    item.uuid = uuid();
    item.item = "";
    var list = [
      ...data.precautions.slice(0, index),
      item,
      ...data.precautions.slice(index),
    ];
    const newdata = copyData();
    newdata.precautions = list;
    //setData(newdata);
    setDataAndPost(newdata);
  }

if(selectedItem?.key !== ItemTypeKey.precautions) return null;

  return (
    <Paper className={classes.paper} elevation={0} square>
      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12}>
        <TitleText
            type={ItemTypeKey.precautions}
            value={data.title}
            onUpdate={(t)=>{}}
            onChanging={(f)=>{}}/>
        </Grid>
        <Grid container className={classes.addpinBox} justify="flex-end">
          <AddBtn
            index={0}
            onClick={(index) => {
              addPrecaution(index);
            }}
          />
        </Grid>

        {data.precautions.map((val, idx) => (
          <Grid item xs={12} key={val.uuid} className={classes.addpinBox}>
            <Grid item xs={12}>
              <PrecautionCard
                contents={jsonData?.contents?.find(
                  (c) => c.uuid === itemId
                )}
                precaution={val}
                uparrow={idx > 0}
                downarrow={(data.precautions.length ?? 0) > idx + 1}
                selectedItem={selectedItem}
                onUpdate={onUpdate}
                onShift={(up, uuid) => {
                  const newdata = copyData();
                  var idx = data.precautions.findIndex((p) => p.uuid === uuid);
                  if (idx >= 0) {
                    const item = newdata.precautions.splice(idx, 1)[0];
                    newdata.precautions.splice(idx + (up ? -1 : +1), 0, item);
                    setDataAndPost(newdata);
                  }
                }}
                onDelete={(uuid) => {
                  dispatch(
                    ConfirmDialogSlice.actions.openConfirm({
                      title: "削除の確認",
                      message: "本当に削除してもよろしいですか？",
                      callback:(ok)=>{
                        if(ok){
                          const newdata = copyData();
                          newdata.precautions = newdata.precautions.filter(
                            (p) => p.uuid !== uuid
                          );
                          setDataAndPost(newdata);
                          dispatch(ActionSlice.actions.clearAction(undefined));
                        }
                      }
                    })
                  );
                }}
                onChanging={onChanging}
              ></PrecautionCard>
            </Grid>
            <Grid container className={classes.addpinBox} justify="flex-end">
              <AddBtn
                index={idx + 1}
                onClick={(index) => {
                  addPrecaution(index);
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

//作業項目1つ分
const PrecautionCard: React.FunctionComponent<{
  contents?: ContentsJson;
  precaution: PrecautionJson;
  uparrow: boolean;
  downarrow: boolean;
  selectedItem: { key: string; id: string } | undefined;
  onUpdate: (newData: ContentsJson) => void;
  onShift: (up: boolean, uuid: string) => void;
  onDelete: (uuid: string) => void;
  onChanging:(changing:boolean)=>void;
}> = ({ contents, precaution, uparrow, downarrow, selectedItem,onUpdate,onShift, onDelete ,onChanging}) => {
  const classes = useStyles();

  //表示データ
  const [data, setData] = React.useState<string>(precaution.item ?? "");
  
  

  function updateContentsJson() {
    const prec = new PrecautionJson(precaution);
    prec.item = data;

    var newdata = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));

    if (!newdata.details) newdata.details = new DetailJson();
    newdata.details.precautions = newdata.details?.precautions?.map((p) =>
      p.uuid === prec.uuid ? prec : p
    );
    onUpdate(newdata);
    onChanging(false);
  }

  //OnChangeの度にJsonを更新すると処理が重たいので、debounce処理
  const [debouncedData] = useDebounce(data, 500);
  useEffect(() => {
    if (precaution.item !== data) {
      updateContentsJson();
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedData]);

  //表示対象が変わるときに
  useEffect(() => {
    if (precaution.item !== data) {
      updateContentsJson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <Card variant="outlined">
      <CardContent>
        <TextField
          name="item"
          label="注意事項"
          value={data}
          fullWidth
          error={!data}
          helperText={!data ? "値を入力して下さい" : ""}
          onChange={(e) => {
            onChanging(true);
            setData(e.target.value);
          }}
        ></TextField>
      </CardContent>

      <CardActions disableSpacing={true} className={classes.itemActions}>
        <Box component="span" visibility={uparrow ? "visible" : "hidden"}>
          <Tooltip title="上へ移動">
            <IconButton
              edge="end"
              aria-label="up"
              onClick={() => {
                onShift(true, precaution.uuid ?? "");
              }}
            >
              <ArrowUpwardIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box component="span" visibility={downarrow ? "visible" : "hidden"}>
          <Tooltip title="下へ移動">
            <IconButton
              edge="end"
              aria-label="down"
              onClick={() => {
                onShift(false, precaution.uuid ?? "");
              }}
            >
              <ArrowDownwardIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box component="span">
          <Tooltip title="削除">
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => {
                onDelete(precaution.uuid ?? "");
              }}
            >
              <HighlightOffIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
    </Card>
  );
};
export default ContentsItemPrecautions;
