
import React, { useEffect } from "react";

import {
    Drawer, IconButton, Hidden,
    List,
    ListItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    DrawerWidth,
    FooterHeight,
    ItemMenuAction,
    ItemMenuAction_ADD,
    ItemTypeKey,
    LinkTextColor
} from "../Constants";
import AddIcon from "@material-ui/icons/Add";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { MAProject, MorningAssemblyJson } from "../net/NetworkClient";
import ContentsNavItem, { ConfigNavItem } from "./ContentsNavItem"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store";
import ActionSlice, { ActionType } from "../misc/ActionSlice";

const useStyles = makeStyles((theme) => ({
    drawer: {
        paddingTop: "8px",
        overflowX: "hidden",
        overflowY: "auto",
        //overflow: "hidden",
        height: "calc(100vh - 48px - " + FooterHeight + "px)",
        marginBottom: "" + FooterHeight + "px",
        [theme.breakpoints.up('sm')]: {
            width: DrawerWidth,
            marginTop: "48px",
            flexShrink: 0,
            //paddingLeft: "16px",
        },
    },
    toolbtn: {
        padding: "4px",
        paddingLeft: "0px",
        color: LinkTextColor,
        fontSize: "0.8rem",
        justifyContent: "left",
        marginLeft: theme.spacing(1),

    },
    item: {
        //paddingTop: 0,
        //paddingBottom: 0,
        paddingLeft: 0,
        color: LinkTextColor,
        marginLeft: theme.spacing(1),
    },
}));

const ContentsNav: React.FunctionComponent<{
    open: boolean;
    project: MAProject | undefined;
    jsonData: MorningAssemblyJson | undefined;
    selectedItem: { key: string; id: string } | undefined;

    onAction: (itemId: string | undefined, itemkey: ItemTypeKey | undefined, action: ItemMenuAction) => void;
    onBackCalendar: () => void;
    onClose: () => void;
}> = ({ open, jsonData, selectedItem, project, onAction, onBackCalendar, onClose }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const actionMessage = useSelector((state: RootState) => state.action.action);

    useEffect(() => {
        switch (actionMessage.type) {
            case ActionType.CONTENT_ITEM_LOAD_START: 
                setLoading(true);
                dispatch(ActionSlice.actions.clearAction(undefined));
            break;
            case ActionType.CONTENT_ITEM_LOAD_END: 
                setLoading(false);
                dispatch(ActionSlice.actions.clearAction(undefined));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionMessage]);



    return (
        <nav className={classes.drawer}>

            <Hidden smUp implementation="css">
                <Drawer variant="temporary" open={open} classes={{ paper: classes.drawer }} onClose={() => { onClose() }}>


                    <IconButton
                        aria-label="back"
                        className={classes.toolbtn}
                        onClick={onBackCalendar}
                        disabled={loading}>
                        <KeyboardBackspaceIcon />カレンダーへ戻る
                    </IconButton>

                    <IconButton
                        aria-label="add item"
                        className={classes.toolbtn}
                        disabled={loading}
                        onClick={() => {
                            onAction(undefined, undefined, ItemMenuAction_ADD);
                        }}><AddIcon />項目追加</IconButton>

                    <List style={{ display: (!project ? "none" : "block") }}>
                        <ListItem className={classes.item}>設定</ListItem>

                        <ConfigNavItem
                            selected={ItemTypeKey.config === selectedItem?.key}
                            loading={loading}
                            onAction={(itemId, key, action) => {
                                onAction(itemId, key, action);
                            }}
                        />

                        {jsonData?.contents?.map((item, index) => (
                            <ContentsNavItem
                                key={item.uuid}
                                contentsJson={item}
                                selected={selectedItem?.id === item.uuid}
                                uparrow={index > 0}
                                downarrow={index < (jsonData.contents?.length ?? 0) - 1}
                                loading={loading}
                                onAction={(itemId, key, action) => {
                                    onAction(itemId, key, action);
                                }}
                            />
                        ))}

                    </List>
                </Drawer>

            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer variant="permanent" open={true} classes={{ paper: classes.drawer }}>


                    <IconButton
                        aria-label="back"
                        className={classes.toolbtn}
                        onClick={onBackCalendar}>
                        <KeyboardBackspaceIcon />カレンダーへ戻る
                    </IconButton>

                    <List style={{ display: (!project ? "none" : "block") }}>
                        <ListItem className={classes.item}>設定</ListItem>

                        <ConfigNavItem selected={ItemTypeKey.config === selectedItem?.key}
                            loading={loading}
                            onAction={(itemId, key, action) => {
                                onAction(itemId, key, action);
                            }} />

                        {jsonData?.contents?.map((item, index) => (
                            <ContentsNavItem
                                key={item.uuid}
                                contentsJson={item}
                                selected={selectedItem?.id === item.uuid}
                                uparrow={index > 0}
                                downarrow={index < (jsonData.contents?.length ?? 0) - 1}
                                loading={loading}
                                onAction={(itemId, key, action) => {
                                    onAction(itemId, key, action);
                                }}
                            />
                        ))}

                    </List>
                </Drawer>
            </Hidden>
        </nav>
    );
}

export default ContentsNav;