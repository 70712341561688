import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AlertDialogProp = {
  title:string,
  message:string,
  callback?:()=>void
}

export const AlertDialogSlice = createSlice({
  name: "alertDialog",
  initialState: {
    title:"",
    message: "",
    open: false,
    callback: (() => {}) as (() => void)
  },
  reducers: {
    openAlert: (state, action:PayloadAction<AlertDialogProp>) => {
      state.open = true;
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.callback = action.payload.callback ?? (() => {});
    },
    closeAlert:(state,action) => {
      state.open = false;
      state.title = "";
      state.message = "";
    }
  },
});



function AlertDialog() {
  const title = useSelector((state: RootState) => state.alert.title);
  const message = useSelector((state: RootState) => state.alert.message);
  const open = useSelector((state: RootState) => state.alert.open);
  const callback = useSelector((state: RootState) => state.alert.callback);
  const dispatch = useDispatch();

  const handleClose = () => {
    if(callback)
      callback();
    
    dispatch(AlertDialogSlice.actions.closeAlert(undefined));
  };

  return (
      <Dialog
      
      fullWidth={true}
      maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}  autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
  );
}
export default  AlertDialog;