import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  ProjectInfo, TenantInfo } from '../net/NetworkClient';


const TenantSlice = createSlice({
    name: "tenants",
    initialState: {
        currentTenant:null as TenantInfo | null,
        tenants: [] as TenantInfo[],
        projects: [] as ProjectInfo[],
    },
    reducers: {
        //選択中のテナント設定
        setCurrent:(state,action:PayloadAction<TenantInfo|null>) =>{
            state.currentTenant = action.payload;
        },

        //テナント配列設定
        setTenants: (state, action: PayloadAction<TenantInfo[]>) => {
            state.tenants = action.payload;
        },

        //テナントデータ更新
        updateTenants:(state,action:PayloadAction<TenantInfo>) =>{
            state.tenants = state.tenants.map<TenantInfo>((tenant)=>{
                return action.payload.tenant?.id === tenant.tenant?.id ? action.payload : tenant;
            });
            if(state.currentTenant?.tenant?.id === action.payload.tenant?.id){
                state.currentTenant = action.payload;
            }
        },
 
        //プロジェクト配列設定
        setProjects: (state, action: PayloadAction<ProjectInfo[]>) => {
            state.projects = action.payload;
        },
        //プロジェクト更新
        updateProject: (state, action: PayloadAction<ProjectInfo>) => {
            state.projects = state.projects.map(v => { return (v.project?.id === action.payload.project?.id ? action.payload : v) });
        },

        //プロジェクト削除
        removeProject: (state, action: PayloadAction<string>) => {
            state.projects = state.projects.filter(v => { return v.project?.id !== action.payload });
        },

        //プロジェクトを追加
        addProject: (state, action: PayloadAction<ProjectInfo>) => {
            var lst = [...state.projects, action.payload];
            state.projects = lst.sort((a, b) => {
                if ((a.project?.name??"") < (b.project?.name??""))
                    return -1;
                if ((a.project?.name??"") > (b.project?.name??""))
                    return 1;
                return 0;
            })
        },
    },
});

export default TenantSlice;
