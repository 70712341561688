import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ContentsV2Client, MAFile, MAProject, MorningAssemblyJson, PostPhotoInfo, UploadInfoResult,MACategoryJson, CopyPhotoInfo
} from './NetworkClient';
import { ItemTypeKey, SERVER_URL } from '../Constants';
import { getAuthedAxisInstance } from "./AxisUtil";
import { Dispatch } from 'react';
import { BlockBlobClient } from '@azure/storage-blob';

//カテゴリJson取得
export const getCategoriesSv = createAsyncThunk<MACategoryJson, {tenantId:string, callback: (ok: boolean,users:MACategoryJson|undefined) => void }>(
  "tenants/getCategories",
  async (arg,{dispatch}) => {
      try {
          var categories = await (new ContentsV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getCategories(arg.tenantId);
          arg.callback(true,categories);
          return categories;
      } catch (err) {
          console.log(err);
          arg.callback(false,undefined);
          throw err;
      }
  }
);

/**
 * サーバへファイルをアップロードして、コンテンツに結びつけて登録する
 * @param file 登録するファイル
 * @param contentsId 朝礼ID
 * @param key アップロードするファイルに結びついている朝礼項目の種類
 * @param uuid アップロードするファイルに結びついている朝礼項目ID
 * @param dispatch ディスパッチ処理
 */
export const postFile =
  (file: File,
    contentsId: string,
    key: ItemTypeKey,
    uuid: string,
    sizeType:number,
    dispatch: Dispatch<any>): Promise<{ ok: boolean, file?: MAFile }> => {
    return new Promise((resolve, reject) => {
      dispatch(getUploadInfoSv({
        callback: (ok, upinfo) => {
          if (!ok || !upinfo) {
            resolve({ ok: false });
            return;
          }
          try {
            var url =""+ upinfo.uploadUrl + "?" + upinfo.sasToken;
            const blob = new BlockBlobClient(url);
            const promise = blob.uploadData(file);
            Promise.all([promise]).then(() => {
              var photoInfo = new PostPhotoInfo();
              photoInfo.fileId = upinfo.fileId;
              photoInfo.fileName = file.name;
              photoInfo.kind = key;
              photoInfo.userName = "browser";
              photoInfo.uuid = uuid;
              photoInfo.sizeType = sizeType;
              dispatch(postContentPhotoSv({
                contentsId, postInfo: photoInfo, callback: (ok, file) => {
                  if (file) {
                    resolve({ ok: true, file: file });
                  } else {
                    resolve({ ok: false });
                  }
                }
              }));
            });
          } catch (err) {
            console.log(err);
            resolve({ ok: false });
          }
        }
      }));
    });
  }

//コンテンツデータ取得
export const getProjectByContentsSv = createAsyncThunk<MAProject, { contentsId: string, callback: (ok: boolean, project?: MAProject) => void }>(
  "contents/getProjectByContents",
  async (arg,{dispatch}) => {
    try {
      var re = await (new ContentsV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getProjectByContents(arg.contentsId);
      arg.callback(true, re);
      return re;
    } catch (err) {
      console.log(err);
      arg.callback(false, undefined);
      throw err;
    }
  }
);

//アップロード先URLを取得
export const getUploadInfoSv = createAsyncThunk<UploadInfoResult, { callback: (ok: boolean, data?: UploadInfoResult) => void }>(
  "contents/getUploadInfo",
  async (arg, { dispatch }) => {
    try {
      var re = await (new ContentsV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getUploadInfo();
      arg.callback(true, re);
      return re;
    } catch (err) {
      console.log(err);
      arg.callback(false, undefined);
      throw err;
    }
  }
);

//アップロードしたファイルを朝礼へ結びつけ
export const postContentPhotoSv = createAsyncThunk<MAFile, { contentsId: string, postInfo: PostPhotoInfo, callback: (ok: boolean, file?: MAFile) => void }>(
  "contents/postContentPhoto",
  async (arg, { dispatch }) => {
    try {
      var re = await (new ContentsV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).postContentPhoto(arg.contentsId, arg.postInfo);
      arg.callback(true, re);
      return re;
    } catch (err) {
      console.log(err);
      arg.callback(false, undefined);
      throw err;
    }
  }
);

//朝礼データを編集
export const putContentsSv = createAsyncThunk<void, { id: string, data: MorningAssemblyJson, callback: (ok: boolean) => void }>(
  "contents/contentsPut",
  async (arg, { dispatch }) => {
    try {
      await (new ContentsV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).put(arg.id, arg.data);
      arg.callback(true);
    } catch (err) {
      console.log(err);
      arg.callback(false);
      throw err;
    }
  }
);

//過去の写真一覧を取得
export const findPhotosSv = createAsyncThunk<MAFile[], {contentsId: string, callback: (ok: boolean, files?: MAFile[]) => void }>(
  "contents/findPhotos",
  async (arg, { dispatch }) => {
    try {
      var re = await (new ContentsV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).findPhotos(arg.contentsId);
      arg.callback(true, re);
      return re;
    } catch (err) {
      console.log(err);
      arg.callback(false, undefined);
      throw err;
    }
  }
);

//サーバにあるファイルをコピーして指定の朝礼へ関連付ける
export const copyFileToContentPhotoSv = createAsyncThunk<MAFile, {contentsId: string,srcFileId:string,uuid:string, callback: (ok: boolean, file?: MAFile) => void }>(
  "contents/copyFileToContentPhoto",
  async (arg, { dispatch }) => {
    try {
      var info = new CopyPhotoInfo();
      info.srcFileId = arg.srcFileId;
      info.uuid = arg.uuid;
      var re = await (new ContentsV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).copyFileToContentPhoto(arg.contentsId,info);
      arg.callback(true, re);
      return re;
    } catch (err) {
      console.log(err);
      arg.callback(false, undefined);
      throw err;
    }
  }
);
