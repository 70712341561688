
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    MAPayment,
    MAOrder,
    OrderItem,
    OrderRedirect,
    PaymentV2Client, PostSettlementInfo, SettlementResult, CurrentPaymentInfo, PostNPInvoiceInfo
} from './NetworkClient';
import { getAuthedAxisInstance } from "./AxisUtil";
import { SERVER_URL } from '../Constants';


export type Order = {
    orderCode: string,
    count: number
}
//支払い項目マスター取得
export const GetPaymentsSv = createAsyncThunk<boolean, {code:string, callback: (ok: boolean,res:MAPayment[]|undefined) => void }>(
    "GMO/GetPayments",
    async (arg, { dispatch }) => {
        try {
            var payments = await (new PaymentV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getPayments(arg.code);
            arg.callback(true,payments);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false,undefined);
            throw err;
        }
    }
);

//現在の支払状況表示
export const GetCurrentPaymentInfoSv = createAsyncThunk<boolean, {tenantId:string, callback: (ok: boolean,info:CurrentPaymentInfo|undefined) => void }>(
    "GMO/GetCurrentPaymentInfo",
    async (arg, { dispatch }) => {
        try {
            var info = await (new PaymentV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getCurrentPaymentInfo(arg.tenantId);
            arg.callback(true,info);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false,undefined);
            throw err;
        }
    }
);


//支払い履歴
export const GetOrdersSv = createAsyncThunk<boolean, {tenantId:string, callback: (ok: boolean,res:MAOrder[]|undefined) => void }>(
    "GMO/GetOrders",
    async (arg, { dispatch }) => {
        try {
            var orders = await (new PaymentV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getOrders(arg.tenantId);
            arg.callback(true,orders);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false,undefined);
            throw err;
        }
    }
);


//支払い開始
export const GMOSettlementSv = createAsyncThunk<boolean, {tenantId:string, orders: OrderItem[],paymentType:string,userName:string,mailAddress:string,note:string, callback: (ok: boolean, result?:SettlementResult) => void }>(
    "GMO/Settlement",
    async (arg, { dispatch }) => {
        try {
            var data = new PostSettlementInfo();
            data.orderItemJson = JSON.stringify(arg.orders);
            data.paymentType = arg.paymentType;
            data.userName = arg.userName;
            data.mailAddress = arg.mailAddress;
            data.note = arg.note ?? ""; 

            var res = await (new PaymentV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).gMOSettlement(arg.tenantId,data);
            arg.callback(true, res);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);

//支払い開始
export const GMOGetResultSv = createAsyncThunk<boolean, {orderRe:OrderRedirect, callback: (ok: boolean,order:MAOrder|undefined) => void }>(
    "GMO/GetResult",
    async (arg, { dispatch }) => {
        try {
            var res = await (new PaymentV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).gMOGetResult(arg.orderRe);
            arg.callback(true,res);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false,undefined);
            throw err;
        }
    }
);

//架け払い開始
export const PostNPInvoiceSv = createAsyncThunk<boolean, {tenantId:string, info: PostNPInvoiceInfo, callback: (ok: boolean, order?:MAOrder) => void }>(
    "Payment/PostNPInvoice",
    async (arg, { dispatch }) => {
        try {
            var res = await (new PaymentV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).postNPInvoice(arg.tenantId,arg.info);
            arg.callback(true, res);
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);

export const StopSubscriptionSv = createAsyncThunk<boolean, {tenantId:string, callback: (ok: boolean,err:string) => void }>(
    "Payment/StopSubscription",
    async (arg, { dispatch }) => {
        try {
            await (new PaymentV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).stopSubscription(arg.tenantId);
            arg.callback(true, "");
            return true;
        } catch (err) {
            console.log(err);
            arg.callback(false, ""+err);
            throw err;
        }
    }
);