import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  Box,
  CircularProgress,
  TextField,
} from "@material-ui/core";

import { ItemTypeKey, stringToItemTypeKey } from "../../Constants";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import moment, { Moment } from "moment-timezone";
import RefreshIcon from "@material-ui/icons/Refresh";
import { getContentsRollCallsSv } from "../../net/CalendarAsync"
import { ContentsJson, DetailJson, MARollCall, MorningAssemblyJson } from "../../net/NetworkClient";
import TitleText from "./TitleText";
import { useDebounce } from "use-debounce";
import ActionSlice, { ActionType } from "../../misc/ActionSlice";

moment.tz.setDefault('Asia/Tokyo');
const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: "hidden",
    height: "100%"
  },
  content: {
    margin: "20px 16px",
  },
  message: {
    marginRight: theme.spacing(6)
  },

  rollcall: {
    marginLeft: "30px",
    display: "inline-flex",
  },
  checkIcon: {
    marginRight: "5px",
    color: "green",
  },
  uptime: {
    position: "absolute",
    top: "12px",
    right: "12px",
  },
}));

const ContentsItemRollCall: React.FunctionComponent<{
  contentsId: string | undefined;
  jsonData: MorningAssemblyJson | undefined;
  selectedItem: { key: string; id: string } | undefined;
  onUpdate: (newData: ContentsJson) => void;
  onChanging: (changing: boolean) => void;
}> = ({ contentsId, jsonData, selectedItem, onUpdate, onChanging }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  function isSupported(key: string | undefined) {
    return (key === ItemTypeKey.rollCall
      || key === ItemTypeKey.general_registration);
  }
  const hasMessage = selectedItem?.key === ItemTypeKey.general_registration;

  const [rollCalls, setRollCalls] = React.useState<MARollCall[]>([]);

  const [loading, setLoading] = React.useState(false);

  const [data, setData] = React.useState({ title: "", message: "" });

  const [rollCallsUpdated, setRollCallsUpdated] = React.useState<Moment | undefined>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isSupported(selectedItem?.key))
      return;

    if (selectedItem?.id && jsonData) {
      const contents = jsonData?.contents?.find(
        (c) => c.uuid === selectedItem?.id
      );

      setData({
        title: contents?.title ?? "",
        message: contents?.details?.message ?? ""
      });

      refreshData();
    }

    dispatch(ActionSlice.actions.doAction({ type: ActionType.CONTENT_ITEM_LOAD_END }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, jsonData]);



  //OnChangeの度にJsonを更新すると処理が重たいので、debounce処理
  const [debouncedData] = useDebounce(data, 500);
  useEffect(() => {
    updateContentsJson(debouncedData);
    onChanging(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedData]);



  const refreshData = () => {
    setLoading(true);
    dispatch(
      getContentsRollCallsSv({
        contentsId: contentsId ?? "",
        callback: (ok, rollcalls) => {
          setLoading(false);
          setRollCalls(rollcalls ?? []);
          setRollCallsUpdated(moment());
        },
      })
    );
  };



  function updateContentsJson(indata: { title: string, message: string }) {
    const contents = jsonData?.contents?.find(
      (c) => c.uuid === selectedItem?.id
    );
    if (!isSupported(contents?.type))
      return;
    if (contents) {
      const strsrc = JSON.stringify(contents);
      const copy = new ContentsJson(contents);
      copy.title = indata.title;
      if (hasMessage) {
        if (!copy.details)
          copy.details = new DetailJson();
        copy.details.message = indata.message;
      }
      const dststr = JSON.stringify(copy);
      if (strsrc !== dststr) {
        onUpdate(copy);
      }
    }
  }
  if (!isSupported(selectedItem?.key)) return null;

  return (
    <Paper className={classes.paper} elevation={0} square>

      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={10}>
          <TitleText
            type={stringToItemTypeKey(selectedItem?.key ?? "")}
            value={data.title}
            onUpdate={(t) => {
              setData({ title: t, message: data.message });
              //updateContentsJson(t);
            }}
            onChanging={onChanging}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: "right", paddingRight: "30px" }}>

          <Box component="span" hidden={!loading}>
            <CircularProgress style={{ color: "#1a90ff" }} size={30} />
          </Box>
          <Tooltip title={rollCallsUpdated?.format("YYYY/MM/DD HH:mm:ssに更新") ?? ""}>
            <IconButton aria-label="reflesh" onClick={refreshData}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} style={{ display: hasMessage ? "block" : "none" }}
          className={classes.message}>
          <TextField
            name="item"
            label="メッセージ"
            value={data.message}
            fullWidth
            onChange={(e) => {
              onChanging(true);
              setData({ title: data.title, message: e.target.value });
            }}
          ></TextField>
        </Grid>
        {rollCalls.map((val, idx) => {
          if (val.uuid === selectedItem?.id) {
            return (
              <Grid key={val.id} item xs={12}>
                <Typography className={classes.rollcall}>
                  <HowToRegIcon className={classes.checkIcon} />
                  {val.name}({moment(val.createdUTC).format("HH:mm:ss")})
                </Typography>
              </Grid>
            );
          } else {
            return null;
          }
        })}
      </Grid>
    </Paper>
  );
}

export default ContentsItemRollCall;
