import { Button, FormControl, FormControlLabel, FormHelperText,  InputLabel, Paper, Radio, TextField, Tooltip } from "@material-ui/core";
import React, { useEffect } from "react";
import { ButtonBackGround, ItemTypeKey, UnregisteredUpdateURL } from "../../Constants";
import { ContentsJson, DetailJson, MorningAssemblyJson, UpdateJson } from "../../net/NetworkClient";
import ContentsItemListBase, { DataType, IContentsItemListDelegate, IItemCardContentsProp } from "./ContentsItemListBase";
import { useDebounce } from "use-debounce";
import { v4 as uuid } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import localFiles from "./LocalFiles";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import { ButtonGroup } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { copyFileToContentPhotoSv } from "../../net/ContentsAsync";
import { PhotoSelectDialogSlice } from "../PhotoSelectDialog";
import { AlertDialogSlice } from "../../misc/AlertDialog";

const useStyles = makeStyles((theme) => ({
    paper: {
        overflow: "hidden",
    },
    content: {
        padding: "20px 16px",
        flexGrow: 1,
    },
    itemActions: {
        justifyContent: "flex-end",
    },
    addpinBox: {
        padding: "0 !important" as any,
        textAlign: "right",
        marginRight: "1px",
    },
    pathLabel: {
        paddingLeft: "8px",
        bottom: 0,
    },
    fileBtn: {
        padding: 0,
        marginTop: theme.spacing(2)
    },
    ctrl: {
        paddingBottom: theme.spacing(2),
    },
    img: {
        maxWidth: "100px",
        maxHeight: "100px",
    },
    imgPaper: {
        marginTop: theme.spacing(2),
        minWidth: "100px",
        minHeight: "100px",
        background: "rgba(200,200,200,0.1)",
        padding: theme.spacing(1),
        marginRight:"100%"
    },
    sizeBtnSelected: {
        textTransform: "none",
        color: "white",
        backgroundColor: ButtonBackGround,
    },
    sizeBtn: {
        textTransform: "none",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.common.white,
    },

    findPhotoBtn: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(1)
    },

    resizeOp: {
        flexDirection: "row"
    }
}));


///テキストパネル
const ContentsItemPhotoList: React.FunctionComponent<{
    contentsid: string | undefined;
    jsonData: MorningAssemblyJson | undefined;
    selectedItem: { key: string; id: string } | undefined;
    onUpdate: (newData: ContentsJson) => void;
    onChanging: (changing: boolean) => void;
}> = ({ contentsid, jsonData, selectedItem, onUpdate, onChanging }) => {

    return (
        <ContentsItemListBase
            delegate={new PhotoListDelegate()}
            contentsid={contentsid ?? ""}
            cardContents={PhotoListCardContents}
            jsonData={jsonData}
            selectedItem={selectedItem}
            onUpdate={onUpdate}
            onChanging={onChanging}
        >

        </ContentsItemListBase>
    )
}

///テキスト処理デリゲート
class PhotoListDelegate implements IContentsItemListDelegate<UpdateJson>{
    getUUID(item: UpdateJson): string {
        return item.uuid ?? "";
    }
    isSuported(type: string | undefined): boolean {
        return type === ItemTypeKey.siteUpdates
            || type === ItemTypeKey.general_photoList
            || type === ItemTypeKey.general_phototextList;
    }

    copyData(data: DataType<UpdateJson>): DataType<UpdateJson> {
        return {
            title: "" + data.title,
            list: data.list.map((p) => new UpdateJson(p)),
        }
    }

    contentsToData(contents: ContentsJson): DataType<UpdateJson> {
        return {
            title: contents.title ?? "",
            list: contents.details?.updates ?? []
        }
    }

    buildContentsJson(contents: ContentsJson, data: DataType<UpdateJson>): ContentsJson {
        var copy = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));
        copy.title = "" + data.title;

        const tmp = new DetailJson();
        tmp.updates = data.list;
        const detail = DetailJson.fromJS(JSON.parse(JSON.stringify(tmp)));
        copy.details = detail;
        return copy;
    }

    makeNewItem(): UpdateJson {
        var re = new UpdateJson();
        re.uuid = uuid();
        return re;
    }
}

interface PhotoListData {

}

interface PhotoListData {
    uuid: string;
    title: string;
    detail: string;
    sizeType: number;
    url: string;
    localFileObject: any;
    objectUrl: string;
}

///写真のカード内容
const PhotoListCardContents: React.FunctionComponent<IItemCardContentsProp>
    = ({ index, item, contents, onUpdate, onChanging, contentsid }) => {

        const classes = useStyles();

        const dispatch = useDispatch();
        const [data, setData] = React.useState<PhotoListData>(getValue(item));
        const hasDetail = (): boolean => { return contents?.type === ItemTypeKey.general_phototextList };
        var createObjectURL = (window.URL || window.webkitURL).createObjectURL;



        function getValue(json: object | undefined): PhotoListData {
            if (json && json instanceof UpdateJson) {
                var vuuid = json.uuid ?? uuid()
                return {
                    uuid: vuuid,
                    title: json.title ?? "",
                    detail: json.detail ?? "",
                    sizeType: json.sizeType ?? 0,
                    url: json.url ?? "",
                    localFileObject: undefined,
                    objectUrl: localFiles.getObjectUrl(vuuid)
                };
            }
            return {
                uuid: uuid(),
                title: "",
                detail: "",
                sizeType: 0,
                url: "",
                localFileObject: undefined,
                objectUrl: ""
            };
        }

        function copyData(): PhotoListData {
            return {
                uuid: "" + data.uuid ?? uuid(),
                title: "" + data.title,
                detail: "" + data.detail,
                sizeType: data.sizeType,
                url: "" + data.url,
                localFileObject: data.localFileObject,
                objectUrl: data.objectUrl
            }
        }


        //OnChangeの度にJsonを更新すると処理が重たいので、debounce処理
        const [debouncedData] = useDebounce(data, 500);
        useEffect(() => {
            if (item instanceof UpdateJson) {
                if (item.title !== data.title || item.detail !== data.detail || item.url !== data.url || data.objectUrl) {
                    updateContentsJson();
                }
            }
            return;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [debouncedData]);

        //データ更新
        function updateContentsJson() {
            const prec = new UpdateJson(item);

            prec.title = data?.title;
            prec.detail = data?.detail;
            prec.sizeType = data.sizeType;
            if (!!data?.objectUrl) {
                prec.url = UnregisteredUpdateURL;
            } else if (prec.url !== data.url) {
                prec.url = data.url;
            }


            var newdata = ContentsJson.fromJS(JSON.parse(JSON.stringify(contents)));

            if (!newdata.details) newdata.details = new DetailJson();
            newdata.details.updates = newdata.details?.updates?.map((p) =>
                p.uuid === prec.uuid ? prec : p
            );
            if (onUpdate)
                onUpdate(newdata);
            if (onChanging)
                onChanging(false);
        }

        const isNoImage = (): boolean => {
            return (!data.url || data.url === UnregisteredUpdateURL) && !data.objectUrl;;
        }
        const isPdfFile = (): boolean => {
            return ("" + data.url).toLowerCase().endsWith(".pdf")
                || ("" + data.localFileObject?.name).toLowerCase().endsWith(".pdf");
        }
        //ファイルが登録されているかどうかの確認
        const checkFile = (): boolean => {
            if (data.url) {
                if (UnregisteredUpdateURL === data.url) {
                    //サーバ未登録
                    return !!data.localFileObject;
                }
                return true;
            }
            return false;
        }
        if (!item) return null;

        return (
            <React.Fragment>
                <TextField
                    fullWidth
                    error={!data.title}
                    helperText={!data.title ? "値を入力して下さい" : ""}
                    label={"タイトル"}
                    className={classes.ctrl}
                    value={data.title}
                    onChange={(e) => {
                        const copy = copyData();
                        copy.title = e.target.value;
                        if (onChanging)
                            onChanging(true);
                        setData(copy);
                    }}
                ></TextField>

                <TextField
                    style={{ display: (hasDetail() ? "block" : "none") }}
                    fullWidth
                    error={!data.detail}
                    helperText={!data.detail ? "値を入力して下さい" : ""}
                    label={"詳細"}
                    className={classes.ctrl}
                    value={data.detail}
                    onChange={(e) => {
                        const copy = copyData();
                        copy.detail = e.target.value;
                        if (onChanging)
                            onChanging(true);
                        setData(copy);
                    }}
                ></TextField>

                <FormControl fullWidth style={{ display: (isNoImage() ? "inline-flex" : "none") }} className={classes.resizeOp}>
                    <InputLabel shrink={true}>リサイズオプション</InputLabel>
                    <Tooltip title="縦横800px以内にリサイズ">
                        <FormControlLabel
                            value="top"
                            className={classes.fileBtn}
                            control={<Radio
                                checked={data.sizeType === 0}
                                onChange={(e) => {
                                    const copy = copyData();
                                    copy.sizeType = 0;
                                    if (onChanging)
                                        onChanging(true);
                                    setData(copy);
                                }}
                                value="0"
                            />}
                            label="低画質"
                            labelPlacement="end"
                        />
                    </Tooltip>

                    <Tooltip title="縦横1200px以内にリサイズ">
                        <FormControlLabel
                            value="top"
                            className={classes.fileBtn}
                            control={<Radio
                                checked={data.sizeType === 1}
                                onChange={(e) => {
                                    const copy = copyData();
                                    copy.sizeType = 1;
                                    if (onChanging)
                                        onChanging(true);
                                    setData(copy);
                                }}
                                value="1"
                            />}
                            label="標準画質"
                            labelPlacement="end"
                        />
                    </Tooltip>

                    <Tooltip title="縦横2400px以内にリサイズ">
                        <FormControlLabel
                            value="top"
                            className={classes.fileBtn}
                            control={<Radio
                                checked={data.sizeType === 2}
                                onChange={(e) => {
                                    const copy = copyData();
                                    copy.sizeType = 2;
                                    if (onChanging)
                                        onChanging(true);
                                    setData(copy);
                                }}
                                value="2"
                            />}
                            label="高画質"
                            labelPlacement="end"
                        />
                    </Tooltip>

                    <Tooltip title="サーバでリサイズしません">
                        <FormControlLabel
                            value="top"
                            className={classes.fileBtn}
                            control={<Radio
                                checked={data.sizeType === 99}
                                onChange={(e) => {
                                    const copy = copyData();
                                    copy.sizeType = 99;
                                    if (onChanging)
                                        onChanging(true);
                                    setData(copy);
                                }}
                                value="99"
                            />}
                            label="リサイズ無し"
                            labelPlacement="end"
                        />
                    </Tooltip>
                </FormControl>

                <FormControl fullWidth  style={{ display: (isNoImage() ? "inline-flex" : "none") }} >
                    <InputLabel shrink={true} error={true}>ファイル</InputLabel>
                    <ButtonGroup className={classes.fileBtn}>
                        <Button
                            variant="outlined"
                            component="label"
                        >
                            PCからアップロード...
                            <input
                                accept="image/png,image/jpeg,application/pdf"
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                    if (e.target.files && e.target.files.length > 0) {
                                        var url = createObjectURL(e.target.files[0]);
                                        localFiles.addFile({
                                            uuid: data.uuid,
                                            file: e.target.files[0],
                                            objectUrl: url,
                                        });
                                        var copy = copyData();
                                        copy.objectUrl = url;
                                        copy.localFileObject = e.target.files[0];
                                        setData(copy);
                                    }
                                }}
                            />
                        </Button>
                        <Button onClick={
                            (e) => {
                                dispatch(PhotoSelectDialogSlice.actions.openPhotoSelect(
                                    {
                                        contentsid: contentsid ?? "",
                                        callback: (ok, file) => {
                                            if (ok && !!file) {
                                                dispatch(copyFileToContentPhotoSv({
                                                    contentsId: contentsid ?? "",
                                                    srcFileId: file.id ?? "",
                                                    uuid: data.uuid ?? "",
                                                    callback: (ok2, newFile) => {
                                                        if (ok2 && !!newFile) {
                                                            var copy = copyData();
                                                            copy.url = newFile.path ?? "";
                                                            copy.objectUrl = "";
                                                            copy.localFileObject = undefined;
                                                            setData(copy);
                                                        } else {
                                                            dispatch(
                                                                AlertDialogSlice.actions.openAlert({
                                                                    title: "エラー",
                                                                    message: "ファイルの複製に失敗しました",
                                                                })
                                                            );
                                                        }
                                                        dispatch(PhotoSelectDialogSlice.actions.closePhotoSelect(undefined));
                                                    }
                                                }))
                                            } else {
                                                dispatch(PhotoSelectDialogSlice.actions.closePhotoSelect(undefined));
                                            }

                                        }
                                    }));
                            }
                        } variant="outlined" >過去の朝礼から選択...</Button>

                    </ButtonGroup>

                    <FormHelperText error={!checkFile()}>
                        {checkFile() ? "" : "ファイルを選択して下さい"}
                    </FormHelperText>
                </FormControl>
                
                <Paper variant="outlined" className={classes.imgPaper}  style={{ display: (isNoImage() ?  "none":"inline-flex" ) }}>
                    <img style={{ display: !isPdfFile() && !isNoImage() ? "block" : "none" }}
                        src={data.objectUrl ? data.objectUrl : data.url}
                        className={classes.img}
                        alt="thumbnail"
                    ></img>
                    <PictureAsPdfIcon style={{ width: "64px", height: "auto", display: isPdfFile() ? "block" : "none" }} />
                    <InsertPhotoIcon style={{ width: "64px", height: "auto", display: isNoImage() ? "block" : "none" }} />
                </Paper>
            </React.Fragment>
        )
    }


export default ContentsItemPhotoList;
