import { createAsyncThunk } from "@reduxjs/toolkit";
import { CalendarV2Client, MAContents, MAFile, MARollCall, MorningAssemblyJson } from "./NetworkClient";
import { SERVER_URL } from "../Constants";
import { getAuthedAxisInstance } from "./AxisUtil";

//コンテンツ検索
export const findContentsSv = createAsyncThunk<void, { projectId: string, start?: Date, end?: Date, callback: (ok: boolean, data?: MAContents[]) => void }>(
    "calendar/findContents",
    async (arg,{dispatch}) => {
        try {

            var re = await (new CalendarV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).findContents(arg.projectId, arg.start, arg.end);
            arg.callback(true, re);
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);

//コンテンツデータ取得
export const getContentsDataSv = createAsyncThunk<void, { contentsId: string ,callback: (ok: boolean, data?: MorningAssemblyJson) => void }>(
    "calendar/getContentsData",
    async (arg,{dispatch}) => {
        try {
            var re = await (new CalendarV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getContentsData(arg.contentsId);
            arg.callback(true, re);
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);

//コンテンツデータ登録
export const postContentsSv = createAsyncThunk<void, { projectId: string ,data:MorningAssemblyJson,callback: (ok: boolean, data?: MAContents) => void }>(
    "calendar/postContents",
    async (arg,{dispatch}) => {
        try {
            var re = await (new CalendarV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).postContents(arg.projectId,arg.data);
            arg.callback(true, re);
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);


//コンテンツ削除
export const deleteContentsSv = createAsyncThunk<void, { contentsId: string,callback: (ok: boolean) => void }>(
    "calendar/deleteContents",
    async (arg,{dispatch}) => {
        try {
            await (new CalendarV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).deleteContents(arg.contentsId);
            arg.callback(true);
        } catch (err) {
            console.log(err);
            arg.callback(false);
            throw err;
        }
    }
);

//コンテンツ内のファイル一覧取得
export const getContentsFilesSv = createAsyncThunk<void, { contentsId: string ,callback: (ok: boolean, files?: MAFile[]) => void }>(
    "calendar/getContentsFiles",
    async (arg,{dispatch}) => {
        try {
            var re = await (new CalendarV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getContentsFiles(arg.contentsId);
            arg.callback(true, re);
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);

//コンテンツ内の参加確認一覧取得
export const getContentsRollCallsSv = createAsyncThunk<void, { contentsId: string ,callback: (ok: boolean, rollcalls?: MARollCall[]) => void }>(
    "calendar/getContentsRollCalls",
    async (arg,{dispatch}) => {
        try {
            var re = await (new CalendarV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).getContentsRollCalls(arg.contentsId);
            arg.callback(true, re);
        } catch (err) {
            console.log(err);
            arg.callback(false, undefined);
            throw err;
        }
    }
);

//Excelデータを生成
export const getExcelDataSv = createAsyncThunk<string, { contentsId: string, callback: (ok: boolean,url:string) => void }>(
    "calendar/excel",
    async (arg,{dispatch}) => {
      try {
        var url = await (new CalendarV2Client(SERVER_URL, await getAuthedAxisInstance(dispatch))).excel(arg.contentsId);
        arg.callback(true,url);
        return url;
      } catch (err) {
        console.log(err);
        arg.callback(false,"");
        throw err;
      }
    }
  );