import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    GridList,
    GridListTile,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MAFile } from "../net/NetworkClient";
import { findPhotosSv } from "../net/ContentsAsync";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../Store";
import moment from "moment-timezone";
import { GridListTileBar } from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ButtonBackGround } from "../Constants";
import CameraIcon from '@material-ui/icons/Camera';

const useStyles = makeStyles((theme) => ({
    btn: {
        color: theme.palette.text.primary,
    },
    heading: {

    },
    templateItem: {
        paddingLeft: "1em",
        fontSize: "0.9em"
    },
    label: {
        display: "flex"
    },
    progress: {
        color: "#1a90ff",
    },
    caution: {
        color: theme.palette.error.main,
        fontSize: "0.9em",
        paddingTop: theme.spacing(2)
    },
    gridList: {
        width: "570px",
        height: "400px"
    },
    selectedIcon:{
        color:ButtonBackGround,
        position:"absolute",
        top:theme.spacing(1),
        left:theme.spacing(1),
        backgroundColor:theme.palette.common.white,
        borderRadius:"5px"
    },
    nonselectedIcon:{
        display:"none"
    },
    cameraIcon:{
        color:theme.palette.common.white,
        display:"flex",
        right:0,
        top:0,
        position:"absolute",
        zIndex:100
    }

}));


export type PhotoSelectDialogProp = {
    callback: ((ok: boolean, file: MAFile | undefined) => void) | undefined,
    contentsid: string,
    open: boolean
}
const initialState: PhotoSelectDialogProp = {
    callback: undefined,
    contentsid: "",
    open: false,
};
export const PhotoSelectDialogSlice = createSlice({
    name: "PhotoSelectDialog",
    initialState: initialState,
    reducers: {
        openPhotoSelect: (state, action: PayloadAction<{ contentsid: string, callback: ((ok: boolean, file: MAFile | undefined) => void) }>) => {
            state.open = true;
            state.contentsid = action.payload.contentsid;
            state.callback = action.payload.callback;
        },
        closePhotoSelect: (state, action) => {
            state.open = false;
            state.contentsid = "";
            state.callback = undefined;
        }
    },
});



const PhotoSelectDialog = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [photos, setPhotos] = useState<MAFile[]>();

    const [selected, setSelected] = useState<MAFile>();

    const { contentsid, open } = useSelector((state: RootState) => state.photoSelect);
    const onClose = useSelector((state: RootState) => state.photoSelect.callback);
    //ダイアログを開いた・閉じた
    useEffect(() => {
        if (open) {
            refresh();
        } else {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const refresh = () => {
        setLoading(true);

        dispatch(findPhotosSv({
            contentsId: contentsid ?? "",
            callback: (ok, files) => {
                setSelected(undefined);
                setPhotos(files);
                setLoading(false);
                console.log(files)
            }
        }))
    }


    return (
        <Dialog
            open={open}
            onClose={() => {
                if (onClose)
                    onClose(false, undefined);
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">画像選択
                <Box display={loading ? "block" : "none"} >
                    <CircularProgress className={classes.progress} size={30} />
                </Box>
            </DialogTitle>
            <DialogContent>

                <GridList
                    cellHeight={160}
                    className={classes.gridList}
                    cols={3}
                    spacing={2}
                >
                    {!photos ? <span/> : photos.map((photo) => (


                        <GridListTile key={photo.id} cols={1}
                            onClick={()=>{setSelected(photo)}}
                        >

                            <img src={photo.path} alt="thumbnail"></img>
                            <CameraIcon style={{display:(photo.kind === "siteUpdates" ? "none": "flex")}} className={classes.cameraIcon}/>
                            <CheckBoxIcon
                             className={photo.id === selected?.id ? classes.selectedIcon : classes.nonselectedIcon}/>
                            <GridListTileBar
                                titlePosition="top"
                                style={{height:"24px",display:(!!photo.title?"flex":"none")}}
                                subtitle={<span>{photo.title }</span>}
                            />
                            <GridListTileBar
                                style={{height:"24px"}}
                                subtitle={<span>{moment(photo.createUTC).format("YYYY/MM/DD HH:mm")}</span>}
                            />
                        </GridListTile>


                    ))}
                </GridList>



            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        if (onClose)
                            onClose(false, undefined);
                    }}
                    className={classes.btn}>
                    キャンセル</Button>
                <Button
                    onClick={() => {
                        if (onClose){
                            onClose(true, selected);
                        }
                    }}
                    className={classes.btn}
                    disabled={loading || !selected}>

                    選択</Button>
            </DialogActions>
        </Dialog>
    );
};
export default PhotoSelectDialog;
